import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Athletes'

export const AthletesEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)    
        .setPattern("{criteria}")        
        .setActionName("GetAthletesForAdmin"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("GetAthletesForTrainer"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateAthlete"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetOldUsers"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("AddOldStudent"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("RemoveOldStudent")
]


