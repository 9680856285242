import React from 'react'
import { notification } from 'antd';
import ApiResponse from '../models/api/ApiResponse';
import EndPointConfig from '../models/api/EndPointConfig';
import ParamsApi from '../models/api/ParamsApi';
import { RegisterEndPoints } from './endpoints';

export default class RestApiClient{

   
    private t : any = null;

    public exportExcel= async (ep:string) =>{
        const url : string = `${ process.env.REACT_APP_BASE_API }/${process.env.REACT_APP_API_VERSION}/${ep}`;
        
        const response = await fetch(
            url,{
                method: 'GET',
                headers: this.getHeadersFiles(),
            })
            .then(response => response.blob())
            return response;
    }

    public fetch = (alias: string, params : ParamsApi) : Promise<ApiResponse | null> => {
        let result = null;

        //console.warn("RestAPIClient: ", params);

        const configEP: EndPointConfig = this.getEndPointInfo(alias);

        if (!configEP){
            notification.error({
                message: 'Error - Configuración EP no encontrada',
                description: 'Nada encontrado aquí'
            })

            return Promise.reject(null);
        }

        const actionURL = this.getUrlEndPoint(configEP, params);
        const init = this.generateInitFetch(configEP, params);

        try{
            result = fetch(actionURL, init)
                        .then(response => this.checkStatus(response))
                        .then(response => response.json())
                        .then(response => this.resolveResponse(response))
                        .catch(error => {
                            window.location.href = `/error/${alias}`;
                            return Promise.reject();
                        })
        }catch(error: any){
            notification.error({
                message: 'Error - genérico',
                description: error.message
            })

            window.location.href = `/error/${alias}`;

            return Promise.reject();
        }

        return Promise.resolve(result)
    }

    public closeSession = () : void => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user-admin-data');
        
    }

    private resolveResponse = (response: ApiResponse): Promise<ApiResponse> => {

        var theMessage : any = <></>
        if (response.message !== undefined && response.message.length > 0) {
            theMessage = <>{response.message}</>
            if (response.additionalMessages && response.additionalMessages.length > 0){
                theMessage = <div>
                                {response.message}
                                <span style={{display: "block", marginTop: "5px"}}>Información adicional:</span>
                                <ul>
                                    {response.additionalMessages.map((am: string, index: number)=> {
                                        return <li key={index}>{am}</li>
                                    })}
                                </ul>
                            </div>
            }
        }else{
            theMessage = undefined;
        }
        
        switch (response.code) {
            case 401:
                this.closeSession();
                window.location.href = "/";
              break
            case 404:
              notification.warn({
                message: 'Atención',
                description: theMessage,
              })
              break
            case 500:
                notification.error({
                    message: 'Error',
                    description: theMessage,
                })
              break
            default:
              if (theMessage !== undefined && theMessage !== <></>) {
                notification.success({
                  message: 'Operación realizada',
                  description: theMessage,
                })
              }
              break
          }

        if (
            response.data !== undefined && 
            response.data !== null && 
            response.data.sessionId !== undefined &&
            response.data.sessionId !== null
            ){
            // Workaround for test controller functions
            sessionStorage.setItem('token', response.data.sessionId);
        }

        return Promise.resolve(response)
      }



    private checkStatus = (response: Response): Promise<Response> => {

        if (response.status === 401){
            // No autorizado: Cerramos sesión
            this.closeSession();
            window.location.href = "/";
            return Promise.reject(response);
        }else if(response.status === 500){
           /* notification.error({
                message: 'Atención',
                description: 'Actualmente esta página no está disponible. Por favor, contacte con su club'
            })*/
        } 

        return Promise.resolve(response);
    }

    private getUrlEndPoint = (configEP: EndPointConfig, params: ParamsApi) => {
        let url = `${ process.env.REACT_APP_BASE_API }/${process.env.REACT_APP_API_VERSION}/${configEP.controller}`;
        
        if (configEP.usingActionName) {
            url += `/${configEP.actionName}`;
        }

        if (params && params.path) {
            // Si tengo params.pattern los seteo
            url = this.setPatternParams(params.path, configEP.pattern, url);
        }

        if (params && params.query) {
            // Si tengo params.url los seteo
            url = this.setUrlParams(params.query, url);
        }

        return url;
    }

    private setUrlParams =  (urlParams: any, url: string, useSeparator: boolean = true, urlBase: string = '') => {
        Object.keys(urlParams).map((v, i) => {
            const separator = useSeparator ? (i === 0 ? '?' : '&') : '';
    
            if (urlParams[v] instanceof Object) {
                const base = `${urlBase}${separator}${v}.`;
    
                url += this.setUrlParams(urlParams[v], '', false, base);
            } else {
                url += `${urlBase}${separator}${v}=${urlParams[v]}`;
            }

            return true;
        });
    
        return url;
    }

    private setPatternParams = (patternParams: any, pattern: string, url: string) => {

        let patternVal = pattern;
    
        Object.keys(patternParams).map((v, i) => {
            // v está definido como key en pattern
            if (patternVal.indexOf(v) !== -1) {
                // reemplazamos {v} por {patternParams[v]}
                patternVal = patternVal.replace(`{${v}}`, patternParams[v]);
                // pattern.replace(`{${v}}`, patternParams[v]);
            } else {
                // v NO está definido como key en pattern => PROBLEMA
                console.error("Pattern doesn't match with model");
            }

            return true;
        });
    
        return `${url}/${patternVal}`;
    }
    

    private generateInitFetch = (configEP: EndPointConfig, params: ParamsApi) => {
        return {
            method: configEP.method,
            headers: configEP.isFile ? this.getHeadersFiles() : this.getHeaders(),
            body: params && JSON.stringify(params.body)
        };
    }

    private getHeadersFiles = () : Headers => {
        const headers = new Headers();
    
        if (sessionStorage.getItem('token')){
            headers.append('X-Authorization', `bearer ${sessionStorage.getItem('token')!}`);
        }
    
        headers.append('Content-Type', 'application/vnd.ms-excel;charset=utf-8');
        headers.append('Access-Control-Allow-Origin', '*');
    
        return headers;
    }

    private getHeaders = (): Headers => {
        const headers = new Headers();
    
        if (sessionStorage.getItem('token')){
            headers.append('X-Authorization', `bearer ${sessionStorage.getItem('token')!}`);
        }
    
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
    
        return headers;
    }

    private getEndPointInfo = (alias : string) : EndPointConfig => {
        let result : EndPointConfig = new EndPointConfig();

        const coincidences: EndPointConfig[] = RegisterEndPoints.filter((i: EndPointConfig) => {
            return (i.alias !== null && i.alias.toLowerCase() === alias.toLowerCase()) || (i.actionName !== null && i.actionName.toLowerCase() === alias.toLowerCase());
        })

        if (coincidences.length > 1){
            console.error(`Found multiple EndPoints with ${alias} alias/actionName.`)
        }else if (!coincidences || coincidences.length === 0){
            console.error(`Not found any EndPoing with ${alias} alias/actionName.`)
        }else if (coincidences && coincidences.length === 1){
            result = coincidences.pop()!;
        }

        return result;
    }

}