import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Products'

export const ProductsEP : EndPointConfig[] =[

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName("GetProducts"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("AddOnePayProduct"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("AddSubscriptionProduct"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("AddPayPerUseProduct"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setActionName("ChangeProductState"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setActionName("ChangeProductData"),
]


