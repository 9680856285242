import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, List, Tag, Typography } from 'antd';
import Avatar from 'antd/lib/avatar';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Box } from '..';
import Athletes from '../../models/Athletes';
import Attendance from '../../models/Attendance';
import Group from '../../models/Group';
import DrawerInsideComponent from '../DrawerInside/DrawerInside';
import * as Components from '../../components/index'


import './UserResumeComponent.scss'
import { getInitialsBox } from '../../utils/GeneralUtils';

interface AthleteInfoProps{
    item: Athletes,
    refreshData: () => void
}
const AthleteInfoComponent = (props: AthleteInfoProps) : JSX.Element => {

    const {item} = props;
    const [showActions, setShowActions] = useState<boolean>(false);

    const [groups, setGroups] = useState<Group[]>([]);

    const [showModifyAthlete, setShowModifyAthlete] = useState<boolean>(false);
    const [skippedsCount, setSkippedsCount] = useState<number>(0);
    const [notSkippedCount, setNotSkippedsCount] = useState<number>(0);
    useEffect(()=>{
        if (item){
            let athleteGroups : Group[] = [];
            item.attendances?.map((attendance: Attendance)=> {
                if (attendance.group && athleteGroups.findIndex((g: Group)=> g.id == attendance.group?.id) == -1){
                    if (attendance.user && attendance.group){
                        attendance.group.trainers = [attendance.user];
                    }
                    athleteGroups.push(attendance.group)
                }
            })
            console.log("groups", groups);
            setSkippedsCount(item.attendances?.filter((attendance: Attendance)=> attendance.attendanceType == "skipped")?.length || 0);
            setNotSkippedsCount(item.attendances?.filter((attendance: Attendance)=> attendance.attendanceType != "skipped")?.length || 0);
            setGroups(athleteGroups);
        }
    },[item]);

    const [isEdited, setIsEdited] = useState<boolean>(false);
    const onEdited = (v: boolean) : void => {
        setIsEdited(v);
    }

    return(
        <>
            <DrawerInsideComponent 
                            placement='right'
                            mobilefixed={true} 
                            showadd={showModifyAthlete} 
                            dOnClose={()=>{
                                setShowModifyAthlete(false)

                                if (isEdited && props.refreshData){
                                    props.refreshData();
                                }
                            }} 
                            dTitle={`Editando usuario`} 
                            dwidth={"50%"}
                        >
                            <Components.AthleteInfo onEdited={onEdited} onFinish={()=>{}} athlete={item}/>
                        </DrawerInsideComponent>

            <Box type='ultra-small' noBorders> 
                <div className="user-card">
                    <div className="header-card" onClick={()=>{setShowActions(!showActions)}}>
                        <span className="payment-info">
                            <div className="icon">
                                {
                                    showActions ? <DownOutlined className="additional-info-arrow"/> : <RightOutlined className="additional-info-arrow"/>
                                }
                                {
                                    getInitialsBox(item.name, item.surname)
                                } 
                                &nbsp;&nbsp;
                            </div>
                            <div className="additional-info">
                                <span> 
                                {item.name} {item.surname} {item.secondSurname} <Tag className="default-payment-method" color="blue">ALU-{item.id}</Tag>
                                </span>
                                <small style={{display:"block"}}>
                                    <strong>F. Nacimiento: {moment(item.birthDate).format("DD/MM/YYYY")}</strong>
                                </small>
                                <Button type="primary" size='small' className="button-modify-data" onClick={()=>{setShowModifyAthlete(true);}}>
                                    Modificar
                                </Button>
                            </div>
                        </span>
                    </div>
                    {
                    showActions ?
                        <div className="actions-card">
                            <Typography.Title level={5} className="detail-title" style={{marginBottom: "10px"}}>
                                Grupos
                                <small style={{display:"block", fontSize: "12px"}}>Solo se muestra la información de los grupos para los que hay registradas asistencias.</small>
                            </Typography.Title>
                            <List
                                size="small"
                                bordered
                                dataSource={groups}
                                renderItem={(group: Group) => <List.Item
                                                        >
                                                            <List.Item.Meta
                                                                title={<>{group?.description}</>}
                                                                description={
                                                                    <>
                                                                        {group.sportCenter.description}
                                                                        <small style={{display:"block"}}>
                                                                            {group.sportCenter.address}
                                                                        </small>
                                                                        <small style={{display:"block"}}>
                                                                            {group.sportCenter.phone}
                                                                        </small>
                                                                    </>
                                                                }
                                                            />
                                                            <List.Item.Meta
                                                                title={<>{group?.startDate}</>}
                                                                description={
                                                                    <>
                                                                        {group?.endDate}
                                                                    </>
                                                                }
                                                            />
                                                            <List.Item.Meta
                                                                title={<>{group?.timeToStart}</>}
                                                                description={
                                                                    <>
                                                                        {group?.timeToEnd}
                                                                    </>
                                                                }
                                                            />
                                                            <List.Item.Meta
                                                                title={<>{group?.trainers && group?.trainers.length > 0 ? <>{group?.trainers[0].name} {group?.trainers[0].surname} {group?.trainers[0].secondSurname}</> : <></>}</>}
                                                                description={
                                                                    group?.trainers && group?.trainers.length > 0 ? <>{group?.trainers[0].username}</> : <></>
                                                                }
                                                            />
                                                        </List.Item>}
                                        />
                            <Typography.Title level={5} className="detail-title" style={{marginBottom: "10px"}}>
                                Asistencias ({skippedsCount} Faltas / {notSkippedCount} Asistencias / {item?.attendances?.length} Total [Ratio: {Math.round(((skippedsCount*100)/(item?.attendances?.length || 1))*100) / 100}% Faltas/{Math.round(((notSkippedCount*100)/(item?.attendances?.length || 1))*100) / 100}% Asistencias])
                            </Typography.Title>
                            <List
                                size="small"
                                bordered
                                dataSource={item?.attendances?.sort((a1: Attendance, a2: Attendance)=>{ return a1.id >= a2.id ? -1 : 0})}
                                renderItem={(attendance: Attendance) => <List.Item
                                                            //actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                                                        >
                                                            <List.Item.Meta
                                                                title={moment(attendance.attendanceDate).format("DD/MM/YYYY")}
                                                                description={
                                                                    <>
                                                                        {attendance.attendanceType !== "skipped" ? <Tag color="#87d068">Asistió</Tag> : <Tag color="#f50">Falta</Tag>}
                                                                        {attendance.sendNotification ? <Tag color="#87d068">Notificado</Tag> : <Tag color="#f50">No notificado</Tag>}
                                                                    </>
                                                                }
                                                            />
                                                            <List.Item.Meta
                                                                title={attendance.group?.description}
                                                                description={attendance.group?.sportCenter.address}
                                                            />
                                                            <List.Item.Meta
                                                                title={<>H. Inicio: {attendance.group?.timeToStart}</>}
                                                                description={<>H. Inicio: {attendance.group?.timeToEnd}</>}
                                                            />
                                                            <List.Item.Meta
                                                                title={<>{attendance.user?.name} {attendance.user?.surname} {attendance.user?.secondSurname}</>}
                                                                description={attendance.user?.username}
                                                            />
                                                        </List.Item>}
                                        />
                        </div>
                    :
                        <></>
                }
                </div>
            </Box>
        </>
        
    )
}

export default AthleteInfoComponent;