import { ArrowDownOutlined, ArrowUpOutlined, BellOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined, IssuesCloseOutlined, MailOutlined, MessageOutlined, RadarChartOutlined, ReloadOutlined, UnorderedListOutlined, WarningTwoTone } from '@ant-design/icons'
import { Alert, Avatar, Badge, Button, Card, Col, Divider, Drawer, Input, Progress, Row, Select, Statistic, Tooltip, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Column } from '@ant-design/plots';
import { LoginContext } from '../../hooks/context/userContext'



import './WelcomeComponent.scss'
import { Box } from '..';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import DailyEarnComponent from './dailyearn/DailyEarnComponent';
import Season from '../../models/Season';
import DailyEarn from '../../models/DailyEarn';

interface HeadMap{
    time: Date,
    total: number,
    type: string
}

interface ProductsByMonth{
    paymentDate: string,
    total: number,
    amount: number,
    productType: string
}

interface BuyedProducts{
    time: Date,
    total: number,
    type: string
}



interface EarnByProduct{
    amount: number,
    time: number,
    product: string
}

interface EnrollmentsVSRegisteredUsers{
    time: Date,
    total: number,
    type: string
}

interface EnrollmentsResume{
    seasonId: number,
    description: string,
    total: number,
    totalPre: number,
    totalActives: number,
    totalError: number,
    totalCancelled: number,
    totalFree: number,
    totalFinished: number
}

interface WelcomeComponentProps{
    showBtnAdvancedStatistics: boolean,
    callBackBtnAdvancedStatistics: any,
    showAdvancedStatistics: boolean,
    seasons: Season[]
}

const WelcomeComponent3 = (props: WelcomeComponentProps) : JSX.Element => {
    return (
        <>algo</>
    )
}

const WelcomeComponent = (props: WelcomeComponentProps) : JSX.Element => {
    let loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();

    const [showBtnAdvancedStatistics, setShowBtnAdvancedStatistics] = useState<boolean>(false);
    const [showAdvancedStatistics, setShowAdvancedStatistics] = useState<boolean>(false);

    

    const [heatMapData, setHeatMapData] = useState<HeadMap[]>([]);
    const getDataFromHeatmapEnrollments = () : void => {
        const params : ParamsApi = {
        query:{
            filter: ''
        }
        }

        restApiClient.fetch("GetDataFromHeatmapEnrollments", params)
        .then((r : ApiResponse | null)=> {
            setHeatMapData(r ? r.data : []);
        })
    }

    const [percentA, setPercentA] = useState<number>(0);
    const [totalA, setTotalA] = useState<number>(0);
    const [totalEurosA, setTotalEurosA] = useState<number>(0);
    
    const [percentB, setPercentB] = useState<number>(0);
    const [totalB, setTotalB] = useState<number>(0);
    const [totalEurosB, setTotalEurosB] = useState<number>(0);
    
    const [percentC, setPercentC] = useState<number>(0);
    const [totalC, setTotalC] = useState<number>(0);
    const [totalEurosC, setTotalEurosC] = useState<number>(0);


    const [statisticsProductsByMonth, setStatisticsProductsByMonth] = useState<ProductsByMonth[]>([]);
    const getDataFromStatisticsProductsByMonth = () : void => {
        const params : ParamsApi = {
            query:{
                filter: ''
            }
        }

        restApiClient.fetch("GetDataFromStatisticsProductsByMonth", params)
        .then((r : ApiResponse | null)=> {
            setStatisticsProductsByMonth(r ? r.data : []);

            if (r && r.data){
                var aPlazos = r.data.filter((item: ProductsByMonth)=> item.productType.toUpperCase()=="SUSCRIPCIÓN");
                var aPlazosTotal = aPlazos.length > 0 ? aPlazos.map((item: ProductsByMonth) => item.total).reduce((a:number,b:number)=>a+b) : 0;
                var aPlazosEuros = aPlazos.length > 0 ? aPlazos.map((item: ProductsByMonth) => item.amount).reduce((a: number, b: number)=>a+b) : 0;
                
                var pagoPorUso = r.data.filter((item: ProductsByMonth)=> item.productType.toUpperCase()=="PAGO POR USO");
                var pagoPorUsoTotal = pagoPorUso.length > 0 ? pagoPorUso.map((item: ProductsByMonth) => item.total).reduce((a:number,b:number)=>a+b) : 0;
                var pagoPorUsoEuros = pagoPorUso.length > 0 ? pagoPorUso.map((item: ProductsByMonth) => item.amount).reduce((a: number, b: number)=>a+b) : 0;
                
                var pagoUnico = r.data.filter((item: ProductsByMonth)=> item.productType.toUpperCase()=="PAGO ÚNICO");
                var pagoUnicoTotal = pagoUnico.length > 0 ? pagoUnico.map((item: ProductsByMonth) => item.total).reduce((a:number,b:number)=>a+b) : 0;
                var pagoUnicoEuros = pagoUnico.length > 0 ? pagoUnico.map((item: ProductsByMonth) => item.amount).reduce((a: number, b: number)=>a+b) : 0;

                var total = aPlazosTotal + pagoPorUsoTotal + pagoUnicoTotal;
                
                setPercentA(Math.round(((aPlazosTotal * 100) / total)*100)/100);
                setTotalA(aPlazosTotal);
                setTotalEurosA(aPlazosEuros);
                setPercentB(Math.round(((pagoPorUsoTotal * 100) / total)*100)/100);
                setTotalB(pagoPorUsoTotal);
                setTotalEurosB(pagoPorUsoEuros);
                setPercentC(Math.round(((pagoUnicoTotal * 100) / total)*100)/100);
                setTotalC(pagoUnicoTotal);
                setTotalEurosC(pagoUnicoEuros);
            }
        })
    }

    

    

    const getWelcomePercents = () : JSX.Element => {
        return <>
                    <Typography.Text>Suscripciones ({totalA} pagos por valor de {totalEurosA}€)</Typography.Text>
                    <Progress strokeColor={'#009688'} percent={percentA} status="active" />
                    <Typography.Text>Pago único ({totalC} pagos por valor de {totalEurosC}€)</Typography.Text>
                    <Progress strokeColor={'#ff9800'} percent={percentC} status="active" />
                    <Typography.Text>Pago por uso ({totalB} pagos por valor de {totalEurosB}€)</Typography.Text>
                    <Progress strokeColor={'#00bcd4'} percent={percentB} status="active" />
                    
               </>

    }

    const [enrollmentsResumeFrom, setEnrollmentsResumeFrom] = useState<EnrollmentsResume>();
    const [enrollmentsResumeTo, setEnrollmentsResumeTo] = useState<EnrollmentsResume>();
    const [seasonActiveId, setSeasonActiveId] = useState<number>(-1);
    const [previousSeasonId, setPreviousSeasonId] = useState<number>(-1);
    const getDataFromEnrollmentsResume = (idSeasonFrom: number, idSeasonTo: number) : void => {
        const params : ParamsApi = {
            body:[idSeasonFrom, idSeasonTo]
        }

        restApiClient.fetch("GetDataFromEnrollmentsResume", params)
        .then((r : ApiResponse | null)=> {

            if (r && r.data.length > 0){
                var activeDataIndex = r?.data.findIndex((d: EnrollmentsResume)=> d.seasonId == seasonActiveId);
                var previous = r?.data.findIndex((d: EnrollmentsResume)=> d.seasonId == previousSeasonId);

                if(activeDataIndex == -1){
                    activeDataIndex = 0;
                }

                if (previous == -1){
                    previous = 0;
                }

                setEnrollmentsResumeFrom(r ? r.data[previous] : []);
                setEnrollmentsResumeTo(r ? r.data[activeDataIndex] : []);
            }
            
        })
    }
    
    useEffect(()=>{
        if (props.seasons && props.seasons.length > 0){
            var activeIndex = props.seasons.findIndex((season: Season) => season.isActiveSeason);
            if (activeIndex == -1){
                activeIndex = 0;
            }
            var nextIndex = activeIndex + 1;

            if (nextIndex > props.seasons.length -1){
                nextIndex = props.seasons.length -1;
            }

            var active = props.seasons[activeIndex].id;
            var previous   = props.seasons[nextIndex].id;
            
            setSeasonActiveId(active);
            setPreviousSeasonId(previous);

            
        }
    },[props.seasons])

    useEffect(()=>{
        getDataFromEnrollmentsResume(seasonActiveId, previousSeasonId);
    },[seasonActiveId, previousSeasonId])

    useEffect(()=>{
        setShowBtnAdvancedStatistics(props.showBtnAdvancedStatistics)
    },[props.showBtnAdvancedStatistics])

    useEffect(()=>{
        setShowAdvancedStatistics(props.showAdvancedStatistics)
    },[props.showAdvancedStatistics])

    const [reloadDataCounter, setReloadDataCounter] = useState<number>(0);
    const loadData = () : void => {
        getDataFromHeatmapEnrollments();
        getDataFromStatisticsProductsByMonth();
    }

    const reloadData = () : void => {
        setReloadDataCounter(reloadDataCounter + 1);
        loadData();
    }

    useEffect(()=>{
        loadData();
    },[]);

    

    return (
        <Box className="welcome-component">
            <Row gutter={16}>
                {
                    showAdvancedStatistics 
                    ? 
                        <Col xs={24} className="seasons-selector">
                        
                            <Select className="season-selector" placeholder='Seleccione una temporada' defaultValue={previousSeasonId}>
                                {
                                    props.seasons.map((season: Season, index: number)=> <Select.Option value={season.id} key={index} className={(season.isActiveSeason ? 'active-season' : '')}>{season.description}{season.isActiveSeason ? ' (Activa)':''}</Select.Option>)
                                }
                            </Select>
                            <label>VS&nbsp;&nbsp;&nbsp;</label>
                            <Select className="season-selector" placeholder='Seleccione una temporada' defaultValue={seasonActiveId}>
                                {
                                    props.seasons.map((season: Season, index: number)=> <Select.Option value={season.id} key={index} className={(season.isActiveSeason ? 'active-season' : '')}>{season.description}{season.isActiveSeason ? ' (Activa)':''}</Select.Option>)
                                }
                            </Select>
                            <Button className='btn-reload-statistics' type="primary" onClick={reloadData}>
                                <ReloadOutlined />
                            </Button>
                        </Col>
                    : 
                        ''
                }
                
                {
                    !props.showAdvancedStatistics ?
                        <Col xs={24} sm={12} md={12} lg={12} xl={5}>
                            <Typography.Title level={3}>
                                Bienvenido {loginContext.name}!
                            </Typography.Title>
                            <Typography.Text className="hide-on-mobile hide-on-tablet">Resumen:</Typography.Text>
                            <ul className="task-resume hide-on-mobile hide-on-tablet">
                                <li><strong>{enrollmentsResumeTo?.totalPre}</strong> Pre inscripciones</li>
                                <li><strong>{enrollmentsResumeTo?.totalActives}</strong> inscripciones activas</li>
                                <li><strong>{enrollmentsResumeTo?.totalError}</strong> pendientes de revisión</li>
                                <li><strong>{enrollmentsResumeTo?.totalCancelled}</strong> canceladas</li>
                                <li><strong>{enrollmentsResumeTo?.totalFree}</strong> gratuitas</li>
                            </ul>
                        </Col>
                    :
                        ''
                }
                

                <Col xs={props.showAdvancedStatistics ? 24 : 0} sm={props.showAdvancedStatistics ? 24 : 0} md={24} lg={24} xl={0} 
                     style={{marginBottom:"24px"}}>
                    {
                        getWelcomePercents()
                    }
                    
                </Col>
                
                {
                    showAdvancedStatistics ?
                        <Col xs={24} md={0}>
                            <Alert
                                message="Algunas gráficas están ocultas por la resolución del dispositivo."
                                type="warning"
                                showIcon
                            />
                        </Col>
                    : 
                        ''
                }
                
                <Col xs={0} sm={0} md={24} lg={24} xl={props.showAdvancedStatistics ? 18 : 13}>
                    <Column 
                        data={statisticsProductsByMonth} 
                        tooltip={{
                            fields:['productType','total','amount'],
                            formatter: (datum: any) => {
                                return { name: datum.productType, value: `${datum.amount}€ (${(Math.round((datum.total*100)))}%)` };
                              },
                        }}
                        xField={'paymentDate'} 
                        yField={'total'} 
                        seriesField={'productType'}
                        isPercent
                        isStack
                        color={['#009688','#ff9800','#00bcd4']}
                        label={{
                            position: 'middle',
                            content: (item: any) => {
                                return `${item.amount}€`;
                              },
                              style: {
                                fill: '#fff'
                              }
                            }}
                        height={200}
                    />
                    <small style={{fontWeight:"bolder"}}>*La gráfica representa el número de pagos realizados para cada tipo de producto en un periodo de tiempo concreto. No se tienen en cuenta las devoluciones.</small>
                </Col>

                <Col xs={0} sm={0} md={0} lg={0} xl={6}>
                    {
                        getWelcomePercents()
                    }
                </Col>
                {
                    showBtnAdvancedStatistics ?
                        <Col xs={24}>
                            <div className='btn-show-advanced-statistics'>
                                <Button type="primary" onClick={()=>{
                                    props.callBackBtnAdvancedStatistics(true);
                                }}><RadarChartOutlined /></Button>
                            </div>
                        </Col>
                    :
                        ''
                }
                {
                    showAdvancedStatistics ? <Col xs={24}><AdvancedStatistics seasons={props.seasons} reloadDataCounter={reloadDataCounter}/></Col> : ''
                }
            </Row>
            
        </Box>
    )
}

interface AdvancedStatisticsProps{
    seasons: Season[],
    reloadDataCounter: number

}
const AdvancedStatistics = (props : AdvancedStatisticsProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [enrollmentsResumeFrom, setEnrollmentsResumeFrom] = useState<EnrollmentsResume>();
    const [enrollmentsResumeTo, setEnrollmentsResumeTo] = useState<EnrollmentsResume>();
    const getDataFromEnrollmentsResume = (idSeasonFrom: number, idSeasonTo: number) : void => {
        const params : ParamsApi = {
            body:[idSeasonFrom, idSeasonTo]
        }

        restApiClient.fetch("GetDataFromEnrollmentsResume", params)
        .then((r : ApiResponse | null)=> {

            if (r && r.data.length > 0){
                var activeDataIndex = r?.data.findIndex((d: EnrollmentsResume)=> d.seasonId == seasonActiveId);
                var previous = r?.data.findIndex((d: EnrollmentsResume)=> d.seasonId == previousSeasonId);

                if(activeDataIndex == -1){
                    activeDataIndex = 0;
                }

                if (previous == -1){
                    previous = 0;
                }

                setEnrollmentsResumeFrom(r ? r.data[previous] : []);
                setEnrollmentsResumeTo(r ? r.data[activeDataIndex] : []);
            }
            
        })
    }


    const [buyedProducts, setBuyedProducts] = useState<BuyedProducts[]>([]);
    const getDataFromVBuyedProducts = () : void => {
        const params : ParamsApi = {
        query:{
            filter: ''
        }
        }

        restApiClient.fetch("GetDataFromVBuyedProducts", params)
        .then((r : ApiResponse | null)=> {
            setBuyedProducts(r ? r.data : []);
        })
    }

    const [dailyEarn, setDailyEarn] = useState<DailyEarn[]>([])
    const getDataFromVDailyEarnings = (idSeasonFrom: number, idSeasonTo: number) : void => {
        const params : ParamsApi = {
            body:[idSeasonFrom, idSeasonTo]
        }

        restApiClient.fetch("GetDataFromVDailyEarnings", params)
        .then((r : ApiResponse | null)=> {
            setDailyEarn(r ? r.data : []);
        })
    }

    const [earnByProduct, setEarnByProduct] = useState<EarnByProduct[]>([]);
    const getDataFromVEconomicEarnByProductType = () : void => {
        const params : ParamsApi = {
            query:{
                filter: ''
            }
        }

        restApiClient.fetch("GetDataFromVEconomicEarnByProductType", params)
        .then((r : ApiResponse | null)=> {
            setEarnByProduct(r ? r.data : []);
        })
    }

    const [enrollmentsVSRegisteredUsers, setEnrollmentsVSRegisteredUsers] = useState<EnrollmentsVSRegisteredUsers[]>([]);
    const getDataFromVEnrollmentsVsRegisteredUsers = () : void => {
        const params : ParamsApi = {
            query:{
                filter: ''
            }
        }

        restApiClient.fetch("GetDataFromVEnrollmentsVsRegisteredUsers", params)
        .then((r : ApiResponse | null)=> {
            setEnrollmentsVSRegisteredUsers(r ? r.data : []);
        })
    }

    const [seasonActiveId, setSeasonActiveId] = useState<number>(-1);
    const [previousSeasonId, setPreviousSeasonId] = useState<number>(-1);

    const calculatePercent = (v1: number, total: number) : number => {
        
        let result : number = 0;

        /*
        total  =====> 100%
        v1     =====> x
        */
       if (total > 0){
            result = (v1 * 100) / total;
       }else{
            result = (v1 * 100) / 1;
       }

        result = Math.round(result * 100) /100;

        if (isNaN(result)){
            return 0;
        }

        return  (result > 100) ? result : (100-result);
    }

    const loadData = () : void => {
        getDataFromVBuyedProducts();
        
        getDataFromVEconomicEarnByProductType();
        getDataFromVEnrollmentsVsRegisteredUsers();
    }

    useEffect(()=>{
        if (props.reloadDataCounter > 0){
            loadData();
        }
    },[props.reloadDataCounter])

    useEffect(()=>{
        if (props.seasons && props.seasons.length > 0){
            var activeIndex = props.seasons.findIndex((season: Season) => season.isActiveSeason);
            if (activeIndex == -1){
                activeIndex = 0;
            }
            var nextIndex = activeIndex + 1;

            if (nextIndex > props.seasons.length -1){
                nextIndex = props.seasons.length -1;
            }

            var active = props.seasons[activeIndex].id;
            var previous   = props.seasons[nextIndex].id;
            
            setSeasonActiveId(active);
            setPreviousSeasonId(previous);

            
        }
    },[props.seasons])

    useEffect(()=>{
        getDataFromEnrollmentsResume(seasonActiveId, previousSeasonId);
        getDataFromVDailyEarnings(seasonActiveId, previousSeasonId);
    },[seasonActiveId, previousSeasonId])

    useEffect(()=>{
        loadData();
    },[])

    

    return(
        <Row gutter={16} className="advanced-statistics">
            <Divider />
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalFinished} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={
                                        calculatePercent(
                                            (enrollmentsResumeTo?.totalActives!+enrollmentsResumeTo?.totalPre!), 
                                            (enrollmentsResumeFrom?.totalFinished!
                                        ))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent((enrollmentsResumeTo?.totalActives!+enrollmentsResumeTo?.totalPre!), (enrollmentsResumeFrom?.totalFinished!))) >= 100 ? '#3f8600' : '#ff4d4f' }}
                                    prefix={(calculatePercent((enrollmentsResumeTo?.totalActives!+enrollmentsResumeTo?.totalPre!), (enrollmentsResumeFrom?.totalFinished!))) >= 100 ?  <CheckOutlined />: <IssuesCloseOutlined />}
                                    suffix="%"
                                />
                            <strong>{(enrollmentsResumeTo?.totalActives!+enrollmentsResumeTo?.totalPre!)}</strong> potenciales
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`En este cuadro se compara las activas de ${props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description} con las finalizadas de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <WarningTwoTone style={{marginLeft:"5px"}} twoToneColor="magenta"/>
                                </Tooltip>
                            </small>
                            
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.total} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.total!, (enrollmentsResumeFrom?.total!))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.total!, (enrollmentsResumeFrom?.total!))) >= 100 ? '#3f8600' : '#ff4d4f' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.total!, (enrollmentsResumeFrom?.total!))) >= 100 ?  <CheckOutlined />: <IssuesCloseOutlined />}
                                    suffix="%"
                                />
                            <strong>{enrollmentsResumeTo?.total}</strong> Totales
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                            
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalPre} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.totalPre!, (enrollmentsResumeFrom?.totalPre!))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.totalPre!, (enrollmentsResumeFrom?.totalPre!))) > 100 ? '#3f8600' : '#ff4d4f' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.totalPre!, (enrollmentsResumeFrom?.totalPre!))) > 100 ?  <CheckOutlined />: <IssuesCloseOutlined />}
                                    suffix="%"
                                />
                            <strong>{enrollmentsResumeTo?.totalPre}</strong> Pre-inscripciones
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalActives} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.totalActives!, (enrollmentsResumeFrom?.totalActives!))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.totalActives!, (enrollmentsResumeFrom?.totalActives!))) > 100 ? '#3f8600' : '#ff4d4f' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.totalActives!, (enrollmentsResumeFrom?.totalActives!))) >= 100 ?  <CheckOutlined />: <IssuesCloseOutlined />}
                                    suffix="%"
                                />
                            <strong>{enrollmentsResumeTo?.totalActives}</strong> Activas
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalError} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.totalError!, enrollmentsResumeFrom?.totalError!)}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.totalError!, (enrollmentsResumeFrom?.totalError!))) > 100 ? '#ff4d4f' : '#3f8600' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.totalError!, (enrollmentsResumeFrom?.totalError!))) >= 100 ?  <CheckOutlined /> : <IssuesCloseOutlined /> }
                                    suffix="%"
                                />
                            <strong>{enrollmentsResumeTo?.totalError}</strong> Revisión
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalCancelled} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.totalCancelled!, (enrollmentsResumeFrom?.totalCancelled!))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.totalCancelled!, (enrollmentsResumeFrom?.totalCancelled!))) < 100 ? '#ff4d4f' : '#3f8600' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.totalCancelled!, (enrollmentsResumeFrom?.totalCancelled!))) < 100 ?  <IssuesCloseOutlined /> : <CheckOutlined /> }
                                    suffix="%"
                                />
                            <strong>{enrollmentsResumeTo?.totalCancelled}</strong> Canceladas
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalFree} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span>
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.totalFree!, (enrollmentsResumeFrom?.totalFree!))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.totalFree!, (enrollmentsResumeFrom?.totalFree!))) > 100 ? '#ff4d4f' : '#3f8600' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.totalFree!, (enrollmentsResumeFrom?.totalFree!))) >= 100 ?  <IssuesCloseOutlined />: <CheckOutlined /> }
                                    suffix="%"
                                />
                            <strong>{enrollmentsResumeTo?.totalFree}</strong> Gratuitas
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
                <Badge count={enrollmentsResumeFrom?.totalFinished} showZero overflowCount={999999} className="super-badge">
                    <Box type='small' style={{marginBottom: "20px"}}>
                        <span> 
                            <Statistic
                                    value={calculatePercent(enrollmentsResumeTo?.totalFinished!, (enrollmentsResumeFrom?.totalFinished!))}
                                    precision={2}
                                    valueStyle={{ color: (calculatePercent(enrollmentsResumeTo?.totalFinished!, (enrollmentsResumeFrom?.totalFinished!))) > 100 ? '#3f8600' : '#ff4d4f' }}
                                    prefix={(calculatePercent(enrollmentsResumeTo?.totalFinished!, (enrollmentsResumeFrom?.totalFinished!))) >= 100 ?  <IssuesCloseOutlined /> : <CheckOutlined />}
                                    suffix="%"
                                />                         
                            <strong>{enrollmentsResumeTo?.totalFinished}</strong> Finalizadas
                            <small style={{marginTop: "2px", display:"block"}}>
                                En {props.seasons.find((season: Season)=>season.id == seasonActiveId)?.description}
                                <Tooltip title={`La viñeta roja con el número representa los valores de ${props.seasons.find((season: Season)=>season.id == previousSeasonId)?.description}`}>
                                    <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                                </Tooltip>
                            </small>
                        </span>
                    </Box>
                </Badge>
            </Col>
            <Divider />
            <Col xs={24} md={0}>
                <Alert
                    message="Algunas gráficas están ocultas por la resolución del dispositivo."
                    type="warning"
                    showIcon
                />
            </Col>
            <Col xs={0} md={24} lg={12}>
                <Typography.Title level={4}>
                    Ganancias diarias
                </Typography.Title>
                <DailyEarnComponent data={dailyEarn} idSeason1={previousSeasonId} idSeason2={seasonActiveId} />
            </Col>
            <Col xs={0} md={24} lg={12}>
                <Typography.Title level={4}>
                    Conversiones (Registros a inscripciones)
                </Typography.Title>
                <DailyEarnComponent data={dailyEarn} idSeason1={previousSeasonId} idSeason2={seasonActiveId} />
            </Col>
        </Row>
    )
}


export default WelcomeComponent;