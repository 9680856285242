import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Discounts'

export const DiscountsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetAllDiscounts"),
        new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("FilterDiscounts"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("AddDiscount"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("ChangeDiscoutState")
]


