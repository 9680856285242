import React, { useEffect, useState, useContext, useRef} from 'react'
import { Tag, Menu, Dropdown, Tooltip, Empty, Button, Divider, Avatar, Drawer  } from 'antd'
import RestApiClient from '../../api/restApiClient'
import ApiResponse from '../../models/api/ApiResponse'

import './usersComponent.scss'
import User from '../../models/Users';
import ParamsApi from '../../models/api/ParamsApi'

import { LoginContext } from '../../hooks/context/userContext'
import * as Components from '../../components'

import {
  ExclamationCircleOutlined,
  FileExcelOutlined,
  IdcardTwoTone,
  LoadingOutlined,
  MailTwoTone,
  SmileTwoTone,
} from '@ant-design/icons';
import { getInitialsBox, getLoginUserInfo, getTagRol } from '../../utils/GeneralUtils'
import { BanTheUser, CloseTheUserSession, LoginAsAnUser, UnbanTheUser } from '../../utils/UserUtils'




interface UsersComponentProps{
  users?: User[],
  isSearching: boolean, 
  showDetail: any,
  generalFilter: string,
  activeSeason:  any, 
  reloadCounter: number,
  useTableScroll: any,
  focusdata?: any
}

const UsersComponent = (props: UsersComponentProps):JSX.Element=>{
  
    const usersListRef = useRef();

    const restApiClient : RestApiClient = new RestApiClient();
    const loginContext = useContext(LoginContext);

    const [reloadCounter, setReloadCounter] = useState<number>(0);

    const [ users, setUsers ] = useState<User[]>([]);
    const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);

    
    const menuActions = (e: any) : void => {
      //las = Login As User
      if (e.key.indexOf("las") !== -1){
        const data : string[] = e.key.split("|");
        const id: string = data[1];
        const email: string = data[2];

        if (data && id && email){
          LoginAsAnUser(id, email, ()=>{});
        }
      //ftb = Fill tables
      }else if (e.key.indexOf("ftb") !== -1){
        const data : string[] = e.key.split("|");
        const email: string = data[1];

        if (data && email){
          FillTables(email);
        }
      }
    }

    const getTargetBox = (index: number, item: User) : JSX.Element => {
      let result : JSX.Element = <>No target</>
  
      result = <Components.Box type='ultra-small'>
                  <div className="user-card">
                    <div className="header-card">
                      <span className="mobile-enrollment-status">{getTagRol(`${index}`,item)}</span>
                      <span className="mobile-inline-offline-user">
                        {getLoginUserInfo(item)}
                      </span>
                      {getMenuActions(index, item, 'mobile-menu-actions', true)}
                    </div>
                    <Divider className='divider-card'/>
                    <div className="body-card">
                      <div className="avatar">
                      {
                          getInitialsBox(item.name, item.surname)
                      } 
                      </div>
                      <div className="info">
                        <span className="mobile-enrollment-student-name"><SmileTwoTone /> {item.surname} {item.secondSurname}, {item.name}</span>
                        <span className="mobile-user-contact"><div className=""><span><a href={`mailto:${item.contactData?.email}`}><MailTwoTone /> {item.contactData?.email}</a></span><br/><span style={{fontSize:"small"}}>{item.contactData?.phone}</span></div> </span>
                      </div>
                    </div>
                </div>
              </Components.Box>
  
      return result;
    }

    const columns : any = [
        {
          title: 'Rol',
          dataIndex: 'idRol',
          key: '2',
          width: 100,
          render: (val: any, item: User) => {
            return getTagRol(val, item);
          },
          renderMobile: (index:number, item: User) => {
            return getTargetBox(index, item);
          }
        },
        {
          title: '',
          filtered: true,
          dataIndex: 'name',
          width: 20,
          key: '33',
          render: (val: any, item: User) => {
            return getLoginUserInfo(item);
          },
        },
        {
          title: 'Nombre',
          filtered: true,
          dataIndex: 'name',
          key: '3',
          render: (val: any, item: User) => {
            return <>
                  {
                    getInitialsBox(item.name, item.surname)
                  }
                  <span>&nbsp; {item.surname.toUpperCase()} {item.secondSurname.toUpperCase()}, {item.name.toUpperCase()}</span>
                 </>
          },
        },
        {
          title: 'Contacto',
          dataIndex: 'contact',
          key: '4',
          render: (val: any, item: User) => <div className="contact-data"><span><a href={`mailto:${item.contactData?.email}`}>{item.contactData?.email}</a></span><span>{item.contactData?.phone}</span></div>
        },
        {
          title: 'Acciones',
          key: 'operation',
          fixed: 'right',
          width: 120,
          render: (index:number, item: User) => {
            return getMenuActions(index, item)
          },
        },
      ];



    

    const getMenuActions = (index: number, item: User, className?: string, isMobile: boolean = false) : JSX.Element => {
      const iam = item.id === loginContext.id

            if (iam){
              return <Tooltip title="Esta es tu ficha. Si necesitas cambiar algo puedes hacerlo desde tu perfil">
                        <span className={className} >
                            Tú <ExclamationCircleOutlined />
                        </span>
                     </Tooltip>
            }else{
              const menu = <Menu onClick={menuActions}>
                          
                          {
                            // solo se puede autenticar como usuario.
                            item.idRol === "customer" ? <><Menu.Item key={`las|${item.id}|${item.username}`}>Logar como usuario</Menu.Item><Menu.Divider /></> : ''
                          }
                          {
                            item.idRol === "customer" ? <><Menu.Item key={`ftb|${item.username}`}>Cargar tablas</Menu.Item><Menu.Divider /></> : ''
                          }
                          {
                            item.idRol === "trainer" ? <><Menu.Item onClick={()=>{transformInAdmin(item.id)}}>Convertir en Admin</Menu.Item><Menu.Divider /></> : ''
                          }
                          {
                            item.idRol === "admin" ? <><Menu.Item onClick={()=>{transformInTrainer(item.id)}}>Convertir en Entrenador</Menu.Item><Menu.Divider /></> : ''
                          }
                          {
                            item.banned ? <Menu.Item key="activate" onClick={()=>{unbanUser(item.id)}}>Activar</Menu.Item> : <Menu.Item key="ban" onClick={()=>{banUser(item.id)}}>Bloquear</Menu.Item>
                          }
                          {
                            item.isLoged ? <Menu.Item key="activate" onClick={()=>{closeUserSession(item.id)}}>Cerrar sesión</Menu.Item> : ''
                          }
                        </Menu>;

              return <Dropdown.Button className={className} overlay={menu} onClick={()=>{onShowExtendedInfo(item.id)}} size="small">Ficha</Dropdown.Button>
            }
    }

    const closeUserSession = (userId?: number) : void => {
      if (userId){
        CloseTheUserSession(userId, ()=>{
          setReloadCounter(reloadCounter - 1);
        });
      }
    }

    const [selectedUserId, setSelectedUserId] = useState<number>(-1);
    const [showExtendedInfo, setShowExtendedInfo] = useState<boolean>(false);
    const onShowExtendedInfo = (userId? : number) : void => {
      if (userId){
        setSelectedUserId(userId);
      }
    }

    useEffect(()=>{
      if (selectedUserId && selectedUserId > 0){
        setShowExtendedInfo(true);
      }
    },[selectedUserId])

    const banUser = (userId?: number) : void => {
      if (userId){
        BanTheUser(userId, ()=>{
          setReloadCounter(reloadCounter - 1)
        });
      }
    }

    const unbanUser = (userId?: number) : void => {
      if (userId){
        UnbanTheUser(userId, ()=>{
          setReloadCounter(reloadCounter - 1)
        });
      }
    }

    const transformInAdmin = (userId?: number):void=>{
      if (userId){
        const params : ParamsApi = {
          query:{
            userId: userId
          }
        }

        restApiClient.fetch("TrainerToAdmin", params)
          .then((r : ApiResponse | null)=> {
              if (r && r.code === 200){
                setReloadCounter(reloadCounter - 1);

              }
          })
      }
    }

    const transformInTrainer = (userId?: number):void=>{
      if (userId){
        const params : ParamsApi = {
          query:{
            userId: userId
          }
        }

        restApiClient.fetch("AdminToTrainer", params)
          .then((r : ApiResponse | null)=> {
              if (r && r.code === 200){
                setReloadCounter(reloadCounter - 1);

              }
          })
      }
    }

    const showUserInfoDrawer = (user: User) : void => {
      props.showDetail(user)
    }

    const FillTables = (email : string) => {
      if (props.focusdata){
        props.focusdata(email);
      }
    }

    


    const downloadUsers = async () => {
      setDownloadingExcel(true);
      let searchText : string = '';
      if (usersListRef && usersListRef.current){
        searchText = await (usersListRef.current as any).getFilter();
      }
  
  
      await restApiClient.exportExcel(`Users/GetUsersExcel?criteria=${searchText}`).then((blob :any) => {
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `usuarios.xlsx`,
          );
  
          // Append to html link element page
          document.body.appendChild(link);
  
          // Start download
          link.click();
  
          link.remove();
      }).finally(()=>{
        setDownloadingExcel(false);
      });
    }

    useEffect(()=>{
      if (usersListRef && usersListRef.current){
        (usersListRef.current as any).updateFilter(props.generalFilter);
      }
    },[props.generalFilter])
    
    useEffect(()=>{
      if (props.users){
        setUsers(props.users);
      }
    },[props]);
    
    useEffect(() => {
      setReloadCounter(props.reloadCounter);
    }, [props.reloadCounter]);

    return (
        <div className="users">
            <Drawer width={"100%"} visible={selectedUserId !== -1 && showExtendedInfo} closable onClose={()=>{setShowExtendedInfo(false); setSelectedUserId(-1)}}>
              <Components.UserResume userId={selectedUserId} />
            </Drawer>
            {
              props.activeSeason ? <Components.TablePagination
                                      ref={usersListRef} 
                                      useTableScroll={props.useTableScroll}
                                      entityName="payments"
                                      reloadCounter={reloadCounter}
                                      aliasEndPoint="GetUsers"
                                      paramsEndPoint={{season: props.activeSeason?.id}}
                                      columns={columns}
                                      hideTextOfFiltersAndButtonsOnMobile = {true}
                                      title="Listado de usuarios"
                                      subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que.... puedes iniciar sesión con la cuenta del usuario pulsando 'logar como usuario? Así podrás ver exactamente lo que ellos ven y resolverles cualquier duda.</small>}
                                      loadingData = {props.isSearching}
                                      filterButtons={[
                                        {label:"Administradores", value: "administrador"}, 
                                        {label:"Monitor/Profesor", value: "monitor/profesor"}, 
                                        {label:"Usuarios Web", value: "usuario"}
                                      ]}
                                      actionButtons={[{styles:{width: "auto", backgroundColor: "green", borderColor: "green"}, label:"Descargar Excel", action: downloadUsers, icon: downloadingExcel ? <LoadingOutlined style={{color: "white"}}/> : <FileExcelOutlined style={{color: "white"}}/>}]}
                                  />
            :
              <Empty description="Selecciona un curso para ver sus inscripciones" />
            }
        </div>
    )
}

export default UsersComponent;