import React from 'react'


import './BoxComponent.scss'

interface BoxComponentProps{
    children: JSX.Element,
    className?: string,
    background?: string,
    type?: 'small' | 'normal' | 'ultra-small'
    style?: any,
    noBorders?:boolean
}

const BoxComponent = (props: BoxComponentProps) : JSX.Element => {

    const {children, background, type, className, noBorders} = props;

    return(
        <div className={`box-component ${(noBorders ? '' : 'with-border-and-shadow')}  ${(type ?? 'normal')} ${className ?? ''}`} 
             style={{
                background:background ?? 'white', 
                color: background ? 'white' : 'default',
                boxShadow: `0 0 10 0px ${(background ?? '#c3c3c399')}`,
                ...props.style,
             }}>
            {children}
        </div>
    )
}

export default BoxComponent;