import React, { useEffect, useState } from 'react'
import { DualAxes, Line } from '@ant-design/plots';

import './DailyEarnComponent.scss'
import DailyEarn from '../../../models/DailyEarn';


interface DailyEarnComponentProps{
  data: DailyEarn[],
  idSeason1: number,
  idSeason2: number
}

const DailyEarnComponent = (props: DailyEarnComponentProps) : JSX.Element => {

    const [data, setData] = useState<DailyEarn[]>([]);

    useEffect(()=>{
      if (props.data && props.idSeason1 && props.idSeason2){
        setData(props.data);
      }
    },[props.data, props.idSeason1, props.idSeason2])


    const config = {
      data,
      xField: 'time',
      yField: 'amount',
      seriesField: 'season',
      xAxis: {
        type: 'time',
      },
      yAxis: {
        label: {
          formatter: (v:any) => `${v}€`,
        },
      },
    };
        

    return (
        <div className='daily-earn'>
            <Line {...config} style={{maxHeight:"200px"}} slider={{
      start: 0,
      end: 1,
    }}/>
        </div>
    )
}

export default DailyEarnComponent;