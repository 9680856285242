import React, { useEffect, useRef, useState } from 'react'

import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import { Button, Col, Popconfirm, Row, Skeleton, Tooltip, Typography } from 'antd';
import { BankOutlined, ClearOutlined, EditOutlined, EnvironmentOutlined, MailOutlined, PhoneOutlined, PlusOutlined, ReloadOutlined, ScheduleOutlined, WarningOutlined, WarningTwoTone } from '@ant-design/icons';

import SportCenter from '../../models/SportCenter';
import * as Components from '../../components'

import './CentersComponent.scss'
import ParamsApi from '../../models/api/ParamsApi';
import DrawerInsideComponent from '../DrawerInside/DrawerInside';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';

interface CentersComponentProps{}

const CentersComponent = (props: CentersComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const centersRef = useRef();
    const [ reloadCounter, setReloadCounter] = useState<number>(0);
    
    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);
    const [loadingCenters, setLoadingCenters] = useState<boolean>(false);
    const [selectedCentre, setSelectedCentre] = useState<SportCenter>() 
    const [showAddCentre, setShowAddCentre] = useState<boolean>(false);

    const onDeleteCentre = (id : number) : void => {

        var params : ParamsApi = {
            query:{
                id: id
            }
        }
        
        restApiClient.fetch("DeleteSportCenter", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    getSportCenters();
                }
            })
    }

    const getSportCenters = () : void => {
        setLoadingCenters(true);
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                }).finally(()=>{
                    setLoadingCenters(false);
                })
    }

    const getMobileSportCenterBox = (index: number, item: SportCenter) : JSX.Element => {
        return <Col xs={24}>
                    <Components.Box type='small' style={{minHeight: "210px", overflow: "hidden"}}>
                        <div>
                            <Components.Box type='small' background={item.isActive ? '#20c997' : '#ff5151'} style={{marginBottom: "5px"}}>
                                <div className="resume-box">
                                    <label className="resume-label">
                                        <BankOutlined /> {item.description.toUpperCase()}
                                    </label>
                                </div>
                            </Components.Box>
                            <div style={{color: "black"}}>
                                <ul style={{listStyle: "none", padding: "2px"}}>
                                    <li><ScheduleOutlined /> {item.activeEnrollments} inscripciones activas </li>
                                    <li><EnvironmentOutlined /> <a target='_blank' href={`http://maps.google.co.uk/maps?q=${item.address}`}>{item.address}</a></li>
                                    <li><MailOutlined /> <a href={`mailto:${item.email}?subject=Contacto web`}>{item.email}</a></li>
                                    <li><PhoneOutlined /> {item.phone}</li>
                                </ul>
                            </div>
                            <div style={{textAlign:"right"}}>
                                <Button type='primary' size='small' style={{marginRight: "5px"}} onClick={()=>{
                                    setSelectedCentre(item);
                                }}><EditOutlined /> Editar</Button>
                                <Popconfirm placement="top" title={'¿Está segur@ de que desea eliminar el centro?'} 
                                    onConfirm={()=>{
                                        onDeleteCentre(item.id)
                                    }} okText="Yes" cancelText="No">
                                    <Button type='primary' danger size='small'><ClearOutlined /> Eliminar</Button>
                                </Popconfirm>
                                
                            </div>
                        </div>
                    </Components.Box>
                </Col>
    }

    const columns : any = [
        {
            title: 'Nombre',
            dataIndex: 'description',
            key: 'description',
            render: (index: number, item: SportCenter) => <><div className={`sport-center-status ${item.isActive ? 'active' : 'inactive'}`}></div> {item.description} <small style={{display: "block"}}>Inscripciones activas: {item.activeEnrollments} {item.activeEnrollments > 0 ? <Tooltip title="No podrás eliminar este centro mientras tenga inscripciones asociadas."><WarningTwoTone /></Tooltip> : ''}</small></>,
            renderMobile:(index: number, item: SportCenter) => {
                return getMobileSportCenterBox(index, item);
            }
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            render: (index: number, item: SportCenter) => <><EnvironmentOutlined /> <a target='_blank' href={`http://maps.google.co.uk/maps?q=${item.address}`}>{item.address}</a></>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (index: number, item: SportCenter) => <><MailOutlined /> <a href={`mailto:${item.email}?subject=Contacto web`}>{item.email}</a></>
        },
        {
            title: 'Teléfono(s)',
            dataIndex: 'phone',
            key: 'phone',
            render: (index: number, item: SportCenter) => <><a href={`tel:${item.phone}`}><PhoneOutlined /> {item.phone}</a></>
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (index: number, item: SportCenter) => {
                return <div className='btn-actions' >
                            <Button type='primary' size='small' style={{marginRight: "5px"}} onClick={()=>{
                                setSelectedCentre(item);
                            }}><EditOutlined /> Editar</Button>
                            <Popconfirm placement="top" title={'¿Está segur@ de que desea eliminar el centro?'} 
                                onConfirm={()=>{
                                    onDeleteCentre(item.id)
                                }} okText="Yes" cancelText="No">
                                <Button type='primary' danger size='small'><ClearOutlined /> Eliminar</Button>
                            </Popconfirm>
                            
                        </div>
            }
        }
    ];

    useEffect(()=>{
        getSportCenters();
    },[]);


    return (
        <div className='centers-component'>
            <DrawerInsideComponent 
                mobilefixed={false} 
                innercontainer={'no-inner'}
                showadd={selectedCentre != undefined} 
                dOnClose={()=>{
                    setSelectedCentre(undefined); 
                }} 
                dTitle={`Acciones centro CEN-${selectedCentre?.id}`} 
                dwidth={"50%"}
            >
                <Components.EditCentre data={selectedCentre!} callBack={()=>{
                    setSelectedCentre(undefined);
                    getSportCenters();
                }}/>
            </DrawerInsideComponent>

            <DrawerInsideComponent 
                mobilefixed={false} 
                innercontainer={'no-inner'}
                showadd={showAddCentre} 
                dOnClose={()=>{
                    setShowAddCentre(false); 
                }} 
                dTitle={`Añadir nuevo centro`} 
                dwidth={"50%"}
            >
                <Components.AddCentre callBack={getSportCenters}/>
            </DrawerInsideComponent>
            <Typography.Title level={5}>
                <Button size='small' type='primary' style={{marginRight: "5px"}} onClick={()=>{setShowAddCentre(true)}}>
                    <PlusOutlined />
                </Button>
                <Button size='small' type='primary' style={{marginRight: "5px"}} onClick={getSportCenters}>
                    <LoadingOrComponent text={<ReloadOutlined />} showSpinner showWhileLoading={false} loading={loadingCenters}/>
                </Button>
                Listado de centros ({sportCenters.length})
            </Typography.Title>
            <br/>
            <Skeleton active loading={loadingCenters}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Components.TablePagination
                                ref={centersRef} 
                                entityName="SportCenters"
                                reloadCounter={reloadCounter}
                                aliasEndPoint="GetSportCentersForTable"
                                columns={columns}
                                title={"Listado de centros"}
                                hideTextOfFiltersAndButtonsOnMobile = {true}
                                loadingData = {loadingCenters}
                                useTableScroll = {{y: 400}}
                                actionButtons={[]}

                            />
                    </Col>
                </Row>
            </Skeleton>
        </div>
    )
}

export default CentersComponent;