import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Table, Tooltip, Dropdown, Menu, Button, Input, Select, DatePicker, InputNumber, Alert, Tabs } from 'antd'

import './productListComponent.scss'
import { GiftOutlined, LoadingOutlined } from '@ant-design/icons';

import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import ApiResponse from '../../../models/api/ApiResponse';
import linq from 'linq';
import ProductInfo from '../../../models/ProductInfo';
import Enumerable from 'linq';
import moment from 'moment';
import * as Components from '../..';
import CategoryInfo from '../../../models/CategoryInfo';
import SportCenter from '../../../models/SportCenter';

const { Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface ProductListComponentProps{}

const ProductListComponent = (props : ProductListComponentProps) : JSX.Element => {
    
    const [onepayproducts, setOnepayproducts] = useState<ProductInfo[][]>([]);
    const [payperuseproducts, setPayperuseproducts] = useState<ProductInfo[][]>([]);
    const [subscriptionproducts, setSubscriptionproducts] = useState<ProductInfo[][]>([]);
    const [activeProductList, setActiveProductList] = useState<ProductInfo[]>([]);
    const [archivedProductList, setArchivedProductList] = useState<ProductInfo[]>([]);
    const [categories, setCategories] = useState<CategoryInfo[]>([])
    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);

    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [updatingData, setUpdatingData] = useState<boolean>(false);

    const restApiClient : RestApiClient = new RestApiClient();

    const getSportCenters = () : void => {
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                })
    }

    const getCategories = () : void => {
        restApiClient.fetch("GetCategories", {}).then((r : ApiResponse | null)=> {
            if (r?.code == 200 && r.data){
                setCategories(r.data);
            }            
        })
    }

    const getTitle = () : JSX.Element => {
        return loadingData ? <LoadingOutlined /> : <>Nombre</>
    }

    const productColumns : any[] = [
        {
            title: getTitle(),
            dataIndex: 'name',
            key: 2,
            render: (index: number, item: ProductInfo) => {
                let classIsActive = item.active ? "active" : "active not";
                let titleToolTip : string = item.active ? "Producto activo" : "Producto inactivo";
    
                return <><Tooltip title={titleToolTip}><div className={classIsActive}></div></Tooltip>&nbsp;&nbsp;{item.name}</>
            },
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 3,
            render: (index: number, item: ProductInfo) => {
                let text : JSX.Element = <></>;

                switch(item.type){
                    case 'OnePay':
                        text = <>Pago único</>;
                        break;
                    case 'PayPerUse':
                        text = <>Pago por uso</>;
                        break;
                    case 'Subscription':
                        text = <>
                                    Suscripción &nbsp;
                                    <small style={{display:"block", fontWeight:700}}>De {item.minQuotes} a {item.maxQuotes} cuotas</small>
                                    {
                                        item.maxDate ? 
                                        <small style={{display:"block", fontWeight:700}}>F. Máxima {moment(item.maxDate).format("DD/MM/YYYY")}</small>
                                        :
                                        ''
                                    }
                                </>
                        break;
                }

                return text;
            }
        },
        {
            title: 'Categoria y núcleo',
            dataIndex: 'category',
            key: 4,
            render: (index: number, item: ProductInfo) => {
                let result : JSX.Element = <></>;
                const categoryDescription = item.categories ? item.categories.description : 'General';

                if (item.idSportCenter && item.sportCenters){
                    result = <>
                                {categoryDescription}
                                <small className="sport-center-name">
                                    {item.sportCenters.description}
                                </small>
                            </>
                }else{
                    result = <>
                                {categoryDescription}
                            </>
                }

                return result;
            }
        },
        {
            title: 'Precio',
            dataIndex: 'pvp',
            key: 5,
            render: (index: number, item: ProductInfo) => {
                return <>
                         {(item.pvp / 100)}€ 
                         {item.initialPay > 0 ? <small style={{display: "block"}}>Coste total: <strong>{(item.pvp / 100) + item.initialPay}€</strong></small> : <></>}
                       </>;
            }
        },
        {
            title: 'Pago inicial',
            dataIndex: 'initialPay',
            key: 6,
            render: (index: number, item: ProductInfo) => {
                return `${item.initialPay}€`;
            }
        },
        {
            title: 'Disponibilidad',
            key: 7,
            render: (index: number, item: ProductInfo) => {
                return <>Del <strong>{moment(item.fromDate).format("DD/MM/YYYY")}</strong> al <strong>{moment(item.toDate).format("DD/MM/YYYY")}</strong></>
            }
        },
        {
            title: 'Primer pago',
            key: 8,
            render: (index: number, item: ProductInfo) => {
                let result : JSX.Element = <>N/A</>
                if (item.type !== "OnePay"){
                    if (item.firstPaymentDay){
                        if (moment(item.firstPaymentDay).isAfter(moment())){
                            //La fecha no ha caducado
                            result = <>El primer cobro se realizará el <strong>{moment(item.firstPaymentDay).format("DD/MM/YYYY")}</strong></>
                        }else{
                            result = <>El primer cobro se realizará <strong>en el momento de la inscripción</strong>.<small style={{display:"block"}}>Se marcó el <strong>{moment(item.firstPaymentDay).format("DD/MM/YYYY")}</strong> pero ya caducó</small></>
                        }
                    }else{
                        result = <>El primer cobro se realiza <strong>el primer día 1 del siguiente mes en el que se realiza la inscripción</strong></>
                    }
                }
                
                return result;
            }
        },
        {
            title: 'Acciones',
            key: 9,
            fixed: 'right',
            width: 120,
            render: (index: number, item: ProductInfo) => {
                const menu = <Menu onClick={()=>{}}>
                                {
                                    item.active ? <Menu.Item key="ban" onClick={()=>{changeProductStatus(false, item.id)}}>Desactivar</Menu.Item> : <Menu.Item key="ban" onClick={()=>{changeProductStatus(true, item.id)}}>Activar</Menu.Item>
                                }
                            </Menu>;

                return <Dropdown.Button overlay={menu} size="small"></Dropdown.Button>
            }
        }
    ]

    const updateProduct = (record: ProductInfo) : void => {
        const params : ParamsApi = {
            body: {
                productId: record.id,
                categoryId: record.idCategory,
                fromDate: record.fromDate,
                toDate: record.toDate,
                maxDate: record.type === "OnePay" ? record.toDate : record.maxDate,
                extra: record.features,
                minQuotes: record.minQuotes,
                maxQuotes: record.maxQuotes,
                description: record.description,
                firstPaymentDay: record.firstPaymentDay,
                termsAndConditions: record.termsAndConditions,
                idSportCenter: record.idSportCenter,
            }
        }


        setUpdatingData(true);
        restApiClient.fetch("ChangeProductData", params)
                .then((r : ApiResponse | null)=>{
                    getProducts();
                })
                .finally(()=>{
                    setUpdatingData(false);
                });
    }

    const changeProductStatus = (status: boolean, productId: number) : void => {
        
        var params : ParamsApi = {
            body:{
                active: status,
                productId: productId
            }
        }

        restApiClient.fetch("ChangeProductState", params).then((r : ApiResponse | null)=>{
            if (r?.code === 200){
                getProducts();
            }
        }).finally(()=>{
            setLoadingData(false);
         });
    }

    const getProducts = () : void => {
        setLoadingData(true);
        restApiClient.fetch("GetProducts", {}).then((r : ApiResponse | null)=>{
            if (r?.code === 200){
                const onePayList = groupByIdCategory(r.data.filter((d : ProductInfo) => d.type === "OnePay"));
                const payPerUseList = groupByIdCategory(r.data.filter((d : ProductInfo) => d.type === "PayPerUse"));
                const subscriptionList = groupByIdCategory(r.data.filter((d : ProductInfo) => d.type === "Subscription"));

                setOnepayproducts(onePayList);
                setPayperuseproducts(payPerUseList);
                setSubscriptionproducts(subscriptionList);

                let productList : ProductInfo[] = showProductList(onePayList).concat(showProductList(payPerUseList)).concat(showProductList(subscriptionList));

                setActiveProductList(linq.from(productList).where(s => s.active).orderByDescending(s => s.id).toArray());
                setArchivedProductList(linq.from(productList).where(s => !s.active).orderByDescending(s => s.id).toArray());
            }
        }).finally(()=>{
            setLoadingData(false);
         });
    }

    const showProductList = (list: ProductInfo[][]) : ProductInfo[] => {
        const productList : ProductInfo[] = [];

        list.map((product: ProductInfo[], i: number) =>{
            if (product.length > 0){
                switch(product[0].type){
                    case "Subscription":
                        const min = linq.from(product).min(p => p.minQuotes);
                        const max = linq.from(product).max(p => p.maxQuotes);
                        const productInfo : any[]  = linq.from(product).where(p => p.minQuotes == min).toArray();

                        if (productInfo){
                            const subscriptionData : ProductInfo = {
                                active: productInfo[0].active,
                                description: productInfo[0].description,
                                features: productInfo[0].features,
                                fromDate: productInfo[0].fromDate,
                                gatewayId: '',
                                id: productInfo[0].id,
                                idCategory: productInfo[0].idCategory,
                                initialPay: productInfo[0].initialPay, 
                                maxDate: productInfo[0].maxDate,
                                maxQuotes: max,
                                interval: productInfo[0].interval,
                                intervalCount: productInfo[0].intervalCount,
                                minQuotes: min, 
                                name: productInfo[0].name,
                                pvp: (productInfo[0].pvp * min),
                                termsAndConditions: productInfo[0].termsAndConditions,
                                toDate: productInfo[0].toDate,
                                firstPaymentDay: productInfo[0].firstPaymentDay,
                                type: productInfo[0].type,
                                userPayFees: productInfo[0].userPayFees,
                                categories: productInfo[0].categories,
                                productExtra: productInfo[0].productExtra,
                                hash: productInfo[0].hash,
                                idSportCenter: productInfo[0].idSportCenter,
                                sportCenters: productInfo[0].sportCenters,
                            }
                            productList.push(subscriptionData);
                        }
                        
                        break;
                    case "OnePay":
                    case "PayPerUse":
                        product.map((p: ProductInfo, ii: number) => {
                        productList.push(p);
                        });
                        break;
                }
            }
        })

        return productList;
    }

    const groupByIdCategory = (list: ProductInfo[]) : ProductInfo[][] => {
        const grouped = Enumerable.from(list).groupBy(s => s.hash, function(e){ return e }).toArray()
        return grouped.map(e => Enumerable.from(e.getSource()).orderByDescending(s => s.active).thenByDescending(s => s.id).toArray());
    }


    const getProductMeta = (productInfo: any) : any[] => {
        return productInfo?.map((pe:any) => pe.description);
    }

    const getInitialOptionsActiveProducts = (productId: number, productInfo: any) : any[] => {
        const list = productInfo?.map((v:any)=>{
            return {value: v.description}
        });

        linq.from(activeProductList).first((product: ProductInfo)=>product.id == productId).features = productInfo?.map((v:any)=>{
            return v.description
        });

        return list;
    }

    const getInitialOptionsArchivedProducts = (productId: number, productInfo: any) : any[] => {
        const list = productInfo?.map((v:any)=>{
            return {value: v.description}
        });

        linq.from(archivedProductList).first((product: ProductInfo)=>product.id == productId).features = productInfo?.map((v:any)=>{
            return v.description
        });

        return list;
    }


    useEffect(()=>{
        getCategories();
        getSportCenters();
        getProducts();
    }, []);
    
    return (
        showAdd ? 
            <Components.AddProducts onclose={()=>{setShowAdd(false)}} onreload={getProducts}  />
            :
            <Row gutter={16} className="product-list">
                <Col xs={24}>
                    <Title className="c-title"  level={3}>
                        <GiftOutlined /> &nbsp;
                        Productos
                        <small className="c-sub-title">
                            Tus productos
                        </small>
                    </Title>
                </Col>
                <Tabs tabPosition='left' style={{width: "100%"}}>
                    <Tabs.TabPane tab={<>Activos <small className='counter-actived'>{activeProductList.length}</small></>} key="1">
                            <Col xs={24}>
                                <Button className="add-product" type="primary" onClick={()=>{setShowAdd(true)}}>Añadir producto</Button>
                                <Button className="refresh-products" type="default" onClick={getProducts}>Recargar</Button>
                                <Table 
                                    rowKey={(record) => record.id} 
                                    columns={productColumns} 
                                    dataSource={activeProductList} 
                                    scroll={{ x: 500  }} 
                                    size="small"
                                    expandable={{
                                        expandedRowRender: record => {

                                            return <>
                                                <Row gutter={16}>
                                                
                                                    <Col xs={24} md={8}>
                                                        <Title level={5}>Nombre</Title>
                                                        <Input defaultValue={record.name} disabled/>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Title level={5}>Categoria</Title>
                                                        <Select placeholder="Seleccione una categoria" defaultValue={record.idCategory ? record.idCategory : 0} style={{width:"100%"}}
                                                                onChange={(e: any)=>{
                                                                    record.idCategory = Number.parseInt(e);
                                                                }}>
                                                            <Option value={0}>General</Option>
                                                            {
                                                                categories.map(category => {
                                                                    return <Option value={category.id}>{category.description}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Title level={5}>Centro</Title>
                                                        <Select placeholder="Seleccione un centro" defaultValue={record.idSportCenter ? record.idSportCenter : 0} style={{width:"100%"}}
                                                                onChange={(e: any)=>{
                                                                    record.idSportCenter = Number.parseInt(e);
                                                                }}>
                                                            <Option value={0}>Todos</Option>
                                                            {
                                                                sportCenters.map(sportCenter => {
                                                                    return <Option value={sportCenter.id}>{sportCenter.description}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Col>
                                                    <Col xs={24}md={record.type !== "OnePay" ? 8 : 24}>
                                                        <Title level={5}>Disponibilidad</Title>
                                                        <RangePicker defaultValue={[moment(record.fromDate), moment(record.toDate)]} onChange={(values: any)=>{
                                                            if (values.length === 2){
                                                                record.fromDate = moment(values[0]).format("YYYY-MM-DD")
                                                                record.toDate = moment(values[1]).format("YYYY-MM-DD")
                                                            }

                                                        }} style={{width: "100%"}}/>
                                                    </Col>
                                                    {
                                                        record.type !== "OnePay" ? 
                                                            <Col xs={24} md={8}>
                                                                <Title level={5}>Fecha máxima</Title>
                                                                <DatePicker defaultValue={moment(record.maxDate)} format="DD/MM/YYYY" onChange={(value: any)=>{
                                                                    if (value){
                                                                        record.maxDate = moment(value).format("YYYY-MM-DD")
                                                                    }
                                                                }} style={{width: "100%"}}/>
                                                            </Col>
                                                        :
                                                        <></>
                                                    }
                                                    {
                                                        record.type !== "OnePay" ? 
                                                            <Col xs={24} md={8}>
                                                                <Title level={5}>Primer cobro</Title>
                                                                <DatePicker defaultValue={moment(record.firstPaymentDay)} format="DD/MM/YYYY" onChange={(value: any)=>{
                                                                    if (value){
                                                                        record.firstPaymentDay = moment(value).format("YYYY-MM-DD")
                                                                    }
                                                                }} style={{width: "100%"}}/>
                                                            </Col>
                                                        :
                                                        <></>
                                                    }
                                                    <Col xs={24} md={record.type !== "OnePay" ? 12 : 24}>
                                                        <Title level={5}>Características</Title>
                                                            <Select 
                                                            onChange={(values: any)=>{record.features = values; }}
                                                            defaultValue={getProductMeta(record.productExtra)} 
                                                            options={getInitialOptionsActiveProducts(record.id, record.productExtra)}
                                                            mode="tags" style={{ width: '100%' }} placeholder="Escribe una característica y pulsa enter" ></Select>
                                                    </Col>
                                                    {
                                                        record.type !== "OnePay" ? 
                                                            <Col xs={24}  md={12}>
                                                                
                                                                <Row>
                                                                    {
                                                                        record.type === "Subscription" ?
                                                                        
                                                                        <Col xs={24}  md={record.type === "Subscription" ? 12 : 24}>
                                                                        <Title level={5}>Min./Max. Cuotas</Title>
                                                                        <Tooltip placement="top" title="La recurrencia no es editable al afectar al producto creado en la pasarela de pagos.">
                                                                            <Input.Group compact>
                                                                                <InputNumber
                                                                                    disabled={true}
                                                                                    defaultValue={record.minQuotes}
                                                                                    max={12}
                                                                                    min={2}
                                                                                />
                                                                                <InputNumber
                                                                                    disabled={true}
                                                                                    defaultValue={record.maxQuotes}
                                                                                    max={12}
                                                                                    min={3}
                                                                                />
                                                                        </Input.Group>
                                                                        </Tooltip>
                                                                    </Col>
                                                                        :
                                                                        ''
                                                                    }
                                                                    <Col xs={24}  md={12}>
                                                                            <Title level={5}>Recurrencia</Title>
                                                                            <Tooltip placement="top" title="La recurrencia no es editable al afectar al producto creado en la pasarela de pagos.">
                                                                                <Input.Group compact>
                                                                                    <InputNumber
                                                                                            disabled
                                                                                            defaultValue={record.intervalCount}
                                                                                            max={31}
                                                                                            min={1}
                                                                                        />
                                                                                    <Select placeholder="Seleccione un estado" defaultValue={record.interval} disabled>
                                                                                        <Option value={2}>Mes/es</Option>
                                                                                        <Option value={3}>Año/es</Option>
                                                                                    </Select>
                                                                                </Input.Group>
                                                                            </Tooltip>
                                                                        </Col>
                                                                </Row>
                                                        </Col>
                                                        :
                                                            ''
                                                    }
                                                    <Col xs={24} md={12}>
                                                        <Title level={5}>Descripción</Title>
                                                        <TextArea placeholder={record.description} 
                                                            defaultValue={record.description} 
                                                            onChange={(e:any)=>{record.description = e.target.value}} rows={4} />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Title level={5}>Términos y condiciones</Title>
                                                        <TextArea placeholder={record.termsAndConditions} 
                                                                defaultValue={record.termsAndConditions} 
                                                                onChange={(e:any)=>{record.termsAndConditions = e.target.value}} rows={4} />
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Alert style={{marginTop:"10px"}} message={<><strong>¡Atención!:</strong>Ciertos cambios de los productos no pueden realizarse al afectar directamente a la pasarela de pago.</>} type="warning" />
                                                        <Alert style={{marginTop:"10px"}} message={<>Los cambios <strong>NO</strong> aplicarán sobre las inscripciones ya realizadas con este producto.</>} type="warning" />
                                                        <Button type="primary" className="btn-update-product" onClick={()=>{updateProduct(record)}} disabled={updatingData}>
                                                            {updatingData ? <><LoadingOutlined />&nbsp;Espere</> : 'Actualizar'}
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                
                                            </>
                                        },
                                    }}
                                />    
                            </Col>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<>Archivo <small className='counter-archived'>{archivedProductList.length}</small></>} key="2">
                        <Col xs={24}>
                            <Button className="add-product" danger type="primary" onClick={()=>{setShowAdd(true)}}>Archivar productos activos</Button>
                            <Button className="add-product" type="primary" onClick={()=>{setShowAdd(true)}}>Añadir producto</Button>
                            <Button className="refresh-products" type="default" onClick={getProducts}>Recargar</Button>
                            <Table 
                                rowKey={(record) => record.id} 
                                columns={productColumns} 
                                dataSource={archivedProductList} 
                                scroll={{ x: 500  }} 
                                size="small"
                                expandable={{
                                    expandedRowRender: record => {

                                        return <>
                                            <Row gutter={16}>
                                            
                                                <Col xs={24} md={8}>
                                                    <Title level={5}>Nombre</Title>
                                                    <Input defaultValue={record.name} disabled/>
                                                </Col>
                                                <Col xs={24} md={8}>
                                                    <Title level={5}>Categoria</Title>
                                                    <Select placeholder="Seleccione una categoria" defaultValue={record.idCategory ? record.idCategory : 0} style={{width:"100%"}}
                                                            onChange={(e: any)=>{
                                                                record.idCategory = Number.parseInt(e);
                                                            }}>
                                                        <Option value={0}>General</Option>
                                                        {
                                                            categories.map(category => {
                                                                return <Option value={category.id}>{category.description}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Col>
                                                <Col xs={24} md={8}>
                                                    <Title level={5}>Centro</Title>
                                                    <Select placeholder="Seleccione un centro" defaultValue={record.idSportCenter ? record.idSportCenter : 0} style={{width:"100%"}}
                                                            onChange={(e: any)=>{
                                                                record.idSportCenter = Number.parseInt(e);
                                                            }}>
                                                        <Option value={0}>Todos</Option>
                                                        {
                                                            sportCenters.map(sportCenter => {
                                                                return <Option value={sportCenter.id}>{sportCenter.description}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Col>
                                                <Col xs={24}md={record.type !== "OnePay" ? 8 : 24}>
                                                    <Title level={5}>Disponibilidad</Title>
                                                    <RangePicker defaultValue={[moment(record.fromDate), moment(record.toDate)]} onChange={(values: any)=>{
                                                        if (values.length === 2){
                                                            record.fromDate = moment(values[0]).format("YYYY-MM-DD")
                                                            record.toDate = moment(values[1]).format("YYYY-MM-DD")
                                                        }

                                                    }} style={{width: "100%"}}/>
                                                </Col>
                                                {
                                                    record.type !== "OnePay" ? 
                                                        <Col xs={24} md={8}>
                                                            <Title level={5}>Fecha máxima</Title>
                                                            <DatePicker defaultValue={moment(record.maxDate)} format="DD/MM/YYYY" onChange={(value: any)=>{
                                                                if (value){
                                                                    record.maxDate = moment(value).format("YYYY-MM-DD")
                                                                }
                                                            }} style={{width: "100%"}}/>
                                                        </Col>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    record.type !== "OnePay" ? 
                                                        <Col xs={24} md={8}>
                                                            <Title level={5}>Primer cobro</Title>
                                                            <DatePicker defaultValue={moment(record.firstPaymentDay)} format="DD/MM/YYYY" onChange={(value: any)=>{
                                                                if (value){
                                                                    record.firstPaymentDay = moment(value).format("YYYY-MM-DD")
                                                                }
                                                            }} style={{width: "100%"}}/>
                                                        </Col>
                                                    :
                                                    <></>
                                                }
                                                <Col xs={24} md={record.type !== "OnePay" ? 12 : 24}>
                                                    <Title level={5}>Características</Title>
                                                        <Select 
                                                        onChange={(values: any)=>{record.features = values; }}
                                                        defaultValue={getProductMeta(record.productExtra)} 
                                                        options={getInitialOptionsArchivedProducts(record.id, record.productExtra)}
                                                        mode="tags" style={{ width: '100%' }} placeholder="Escribe una característica y pulsa enter" ></Select>
                                                </Col>
                                                {
                                                    record.type !== "OnePay" ? 
                                                        <Col xs={24}  md={12}>
                                                            
                                                            <Row>
                                                                {
                                                                    record.type === "Subscription" ?
                                                                    
                                                                    <Col xs={24}  md={record.type === "Subscription" ? 12 : 24}>
                                                                    <Title level={5}>Min./Max. Cuotas</Title>
                                                                    <Tooltip placement="top" title="La recurrencia no es editable al afectar al producto creado en la pasarela de pagos.">
                                                                        <Input.Group compact>
                                                                            <InputNumber
                                                                                disabled={true}
                                                                                defaultValue={record.minQuotes}
                                                                                max={12}
                                                                                min={2}
                                                                            />
                                                                            <InputNumber
                                                                                disabled={true}
                                                                                defaultValue={record.maxQuotes}
                                                                                max={12}
                                                                                min={3}
                                                                            />
                                                                    </Input.Group>
                                                                    </Tooltip>
                                                                </Col>
                                                                    :
                                                                    ''
                                                                }
                                                                <Col xs={24}  md={12}>
                                                                        <Title level={5}>Recurrencia</Title>
                                                                        <Tooltip placement="top" title="La recurrencia no es editable al afectar al producto creado en la pasarela de pagos.">
                                                                            <Input.Group compact>
                                                                                <InputNumber
                                                                                        disabled
                                                                                        defaultValue={record.intervalCount}
                                                                                        max={31}
                                                                                        min={1}
                                                                                    />
                                                                                <Select placeholder="Seleccione un estado" defaultValue={record.interval} disabled>
                                                                                    <Option value={2}>Mes/es</Option>
                                                                                    <Option value={3}>Año/es</Option>
                                                                                </Select>
                                                                            </Input.Group>
                                                                        </Tooltip>
                                                                    </Col>
                                                            </Row>
                                                    </Col>
                                                    :
                                                        ''
                                                }
                                                <Col xs={24} md={12}>
                                                    <Title level={5}>Descripción</Title>
                                                    <TextArea placeholder={record.description} 
                                                        defaultValue={record.description} 
                                                        onChange={(e:any)=>{record.description = e.target.value}} rows={4} />
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Title level={5}>Términos y condiciones</Title>
                                                    <TextArea placeholder={record.termsAndConditions} 
                                                            defaultValue={record.termsAndConditions} 
                                                            onChange={(e:any)=>{record.termsAndConditions = e.target.value}} rows={4} />
                                                </Col>
                                                <Col xs={24}>
                                                    <Alert style={{marginTop:"10px"}} message={<><strong>¡Atención!:</strong>Ciertos cambios de los productos no pueden realizarse al afectar directamente a la pasarela de pago.</>} type="warning" />
                                                    <Alert style={{marginTop:"10px"}} message={<>Los cambios <strong>NO</strong> aplicarán sobre las inscripciones ya realizadas con este producto.</>} type="warning" />
                                                    <Button type="primary" className="btn-update-product" onClick={()=>{updateProduct(record)}} disabled={updatingData}>
                                                        {updatingData ? <><LoadingOutlined />&nbsp;Espere</> : 'Actualizar'}
                                                    </Button>
                                                </Col>
                                            </Row>

                                            
                                        </>
                                    },
                                }}
                            />    
                        </Col>
                    </Tabs.TabPane>
                </Tabs>
            </Row>
                
    )
}

export default ProductListComponent;