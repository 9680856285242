import React from 'react'
import { Button, Col, Form, Input, Row, Select, DatePicker, InputNumber } from 'antd'

import RestApiClient from '../../../../api/restApiClient';
import ApiResponse from '../../../../models/api/ApiResponse';

import ProductOnePayModel from '../../../../models/products/ProductOnePayModel'
import { useState } from 'react';
import CategoryInfo from '../../../../models/CategoryInfo';
import { useEffect } from 'react';
import moment from 'moment';
import ParamsApi from '../../../../models/api/ParamsApi';
import { LoadingOutlined } from '@ant-design/icons';
import SportCenter from '../../../../models/SportCenter';

import locale from 'antd/es/date-picker/locale/es_ES';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface AddOnePayProductProps{
    default: any,
    onfinish: any
}
const AddOnePayProductForm = (props: AddOnePayProductProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [form] = Form.useForm();
    const [categories, setCategories] = useState<CategoryInfo[]>([])
    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);
    const [saving, setSaving] = useState<boolean>(false);

    const onFinish = ()=>{
        const productOnePayModel : ProductOnePayModel = {
            userPayFees: false,
            extra: {
                features: form.getFieldValue("extra")
            },
            category: form.getFieldValue("category") === 0 ? null : form.getFieldValue("category"),
            fromDate: moment(form.getFieldValue("availability")[0]).format("YYYY-MM-DD"), //Use momentJS
            toDate: moment(form.getFieldValue("availability")[1]).format("YYYY-MM-DD"), //Use momentJS
            maxDate: moment(form.getFieldValue("maxDate")).format("YYYY-MM-DD"), //Use momentJS
            active: form.getFieldValue("active") === 1 ,
            pvp: form.getFieldValue("pvp"), 
            termsAndConditions: form.getFieldValue("termsAndConditions"),
            description: form.getFieldValue("description"),
            name: form.getFieldValue("name"),
            sportCenter: form.getFieldValue("sportCenter") === 0 ? null : form.getFieldValue("sportCenter"),
        }

        const params : ParamsApi = {
            body: productOnePayModel
        }

        setSaving(true);
        restApiClient.fetch("AddOnePayProduct", params).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                props.onfinish(true);
            }        
        }).finally(()=>{
            setSaving(false);
        })
    }

    const getSportCenters = () : void => {
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                })
    }

    const getCategories = () : void => {
        restApiClient.fetch("GetCategories", {}).then((r : ApiResponse | null)=> {
            if (r?.code == 200 && r.data){
                setCategories(r.data);
            }            
        })
    }

    const clearForm = () : void => {
        form.resetFields();
    }

    useEffect(()=>{
        getCategories();
        getSportCenters();
        clearForm();
    }, []);

    return (
        <Row gutter={16}>
            <Col xs={24}>
                <Form form={form} onFinish={onFinish} layout="vertical" initialValues={props.default}>
                <Form.Item
                        label="Nombre"
                        name="name"
                        className="form-item"
                        
                        rules={[{required: true, message: 'Por favor, indique un nombre para el producto'}]}
                    >
                        <Input placeholder="Nombre del producto"/>
                    </Form.Item>
                    <Form.Item
                        label="Disponibilidad del producto"
                        name="availability"
                        className="form-item"
                        rules={[{required: true, message: 'Por favor, indique una fecha'}]}
                    >
                        <RangePicker style={{width: "100%"}} locale={locale}/>
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Categoria"
                        name="category"
                        className="form-item"
                        rules={[{required: true, message: 'Si no desea asociar ninguna categoría seleccione "General"'}]}
                        initialValue={0}
                    >
                        <Select placeholder="Seleccione una categoria" defaultValue={0}>
                            <Option value={0}>General</Option>
                            {
                                categories.map(category => {
                                    return <Option value={category.id}>{category.description}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Centro"
                        name="sportCenter"
                        className="form-item"
                        rules={[{required: true, message: 'Si no desea asociar ningún centro seleccione "Todos"'}]}
                        initialValue={0}
                    >
                        <Select placeholder="Seleccione un núcleo" defaultValue={0}>
                            <Option value={0}>Todos</Option>
                            {
                                sportCenters.map(sportCenter => {
                                    return <Option value={sportCenter.id}>{sportCenter.description}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Estado del producto"
                        name="active"
                        className="form-item"
                        rules={[{required: true, message: 'Indique si desea que el producto esté activo o no'}]}
                        initialValue={0}
                    >
                        <Select placeholder="Seleccione un estado" defaultValue={0}>
                            <Option value={0}>Inactivo</Option>
                            <Option value={1}>Activo</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Precio €"
                        name="pvp"
                        className="form-item "
                        rules={[{required: true, message: 'Debe indicar un precio para el producto.'}, {type:"number", message:"Debe ser un valor numérico."}]}
                    >
                        <InputNumber
                            style={{width: "100%"}}
                            max={9999}
                            min={0}
                            step={0.01}
                        />
                    </Form.Item>

                    
                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Descripción"
                        name="description"
                        className="form-item "
                        rules={[{required: true, message: 'Escriba, brevemente, la descripción del producto.'}]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Características extra"
                        name="extra"
                        className="form-item "
                    >
                        <Select mode="tags" style={{ width: '100%' }} placeholder="Escribe una característica y pulsa enter" >
                        </Select>

                    </Form.Item>

                    


                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Términos y condiciones"
                        name="termsAndConditions"
                        className="form-item "
                        rules={[{required: true, message: 'Defina los términos y condiciones asociados al producto.'}]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>


                    
                    <Form.Item style={{textAlign:"right"}}>
                        <Button onClick={clearForm}>
                            Limpiar formulario
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button type="primary" htmlType="submit" disabled={saving}>
                            { saving ? <><LoadingOutlined />&nbsp;</> : '' }
                            Crear producto
                        </Button>
                        
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default AddOnePayProductForm;