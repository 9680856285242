import React, { ReactElement, useEffect, useState } from 'react'
import { gapi } from 'gapi-script';
import parse from 'html-react-parser';
import './GDriveComponent.scss'
import { Alert, Breadcrumb, Button, Col, Drawer, Dropdown, Input, List, Menu, message, Popconfirm, Result, Row, Table, Tabs, Tag, Tooltip, Upload } from 'antd';
import moment from 'moment';
import { CloseCircleOutlined, CopyOutlined, DoubleLeftOutlined, DoubleRightOutlined, FileTextOutlined, FolderOpenOutlined, LoadingOutlined, LoginOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import SharedFile from '../../../models/SharedFile';
import Users from '../../../models/Users';
import Group from '../../../models/Group';
import SportCenter from '../../../models/SportCenter';

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
// https://developers.google.com/drive/api/guides/api-specific-auth?hl=en
const SCOPES = ['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/drive.resource'];

const API_KEY = "AIzaSyDuHEfyTeACrnmACqdufyZKfih-F_-JEeU";
const CLIENT_ID = "30780250571-oe16d8jeegbv4vs0249plr2esmplv9qu.apps.googleusercontent.com";

interface GDriveComponentProps{}

let done = false;

function LogInOutButton(options: {
    loggedIn: boolean;
    logIn: () => void;
    logOut: () => void;
}): ReactElement {
    const { loggedIn, logIn, logOut } = options;
    const buttonText = loggedIn ? <><CloseCircleOutlined /> Desconectar Drive</> : <><LoginOutlined /> Iniciar sesión Drive</>;
    const buttonAction = loggedIn ? logOut : logIn;
    return <Button type="primary" className="ht-button" danger={loggedIn} onClick={buttonAction}>{buttonText}</Button>;
}

interface Path{
  id: string,
  name: string
}


const GDriveComponent = (props : GDriveComponentProps) : JSX.Element => {

    
    const [initialFolderId, setInitialFolderId] = useState<string>('');
    const [initialFolderName, setInitialFolderName] = useState<string>('');
    const restApiClient : RestApiClient = new RestApiClient();

    /**
     * Manage files and folders:
     * 
     *      https://developers.google.com/drive/api/guides/folder#.net
     */
    const [loggedInStatus, setLoggedInStatus] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>([])
    const [previousPageToken, setPreviousPageToken] = useState<string>('');
    
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [path, setPath] = useState<Path[]>([]);
    const [inGetfiles, setInGetFiles] = useState<boolean>(false);
    const [lastFileId, setLastFileId] = useState<string>('');
    const [file, setFile] = useState<UploadFile | null>(null);

    const [newFolderName, setNewFolderName] = useState<string>('');
    const [searchFileName, setSearchFileName] = useState<string>('');
    const [loadingSharedFiles, setLoadingSharedFiles] = useState<boolean>(false);
    const [sharedFiles, setSharedFiles] = useState<SharedFile[]>([]);


    const [showSharedOptions, setShowSharedOptions] = useState<boolean>(false);
    const [selectedFileName, setSelectedFileName] = useState<string>('');
    const [selectedFileId, setSelectedFileId] = useState<string>('');
    const [selectedDownloadLink, setSelectedDownloadLink] = useState<string>('');
    const [selectedSharedLink, setSelectedSharedLink] = useState<string>('');
    const [messageDifferentAccount, setMessageDifferentAccount] = useState<string>('');

    const getDefaultClientDriveFolder = () : void =>{
      restApiClient.fetch("GetDefaultDriveFolder", {})
          .then((r: any)=>{
              if (r.data && r.data.length > 0){
                var email = r.data.split("|")[2];

                if (email !== userGmailEmail){
                  setMessageDifferentAccount(`Ha iniciado sesión con una cuenta de Gmail diferente de la que tenía vinculada. Por favor, cierre sesión y vuelva iniciar sesión con la cuenta <strong>${email}</strong> o establezca una nueva carpeta de inicio. Recuerde que todos aquellos ficheros que se encuentren compartidos seguirán estándolo.`)
                  setInitialFolderId('');
                  setInitialFolderName('');
                  setLastFileId('');   
                }else{
                  setMessageDifferentAccount('')
                  setInitialFolderId(r.data.split("|")[0]);
                  setInitialFolderName(r.data.split("|")[1]);
                  setLastFileId(r.data.split("|")[0]);   
                }
              }
          })
          .finally(()=>{
            setLoadingSharedFiles(false);
          })
    }

    const updateDefaultFolderInServer = (folderId: string) : void =>{

      var params : ParamsApi = {
        body: {
          folderId: folderId
        }
      }

      restApiClient.fetch("UpdateDefaultDriveFolder", params);
    }
    
    const loadSharedFiles = () : void => {
      setLoadingSharedFiles(true);
      restApiClient.fetch("GetSharedDocuments", {})
          .then((r: any)=>{
              if (r.data){
                setSharedFiles(r.data);
              }
          })
          .finally(()=>{
            setLoadingSharedFiles(false);
          })
    }

    const propsFileUpload: UploadProps = {
      name: 'file',
      accept: 'image/*,.pdf',
      action: undefined,
      multiple:false,
      onRemove(){
        setFile(null);
      },
      onChange(info) {
        info.file.error = <></>
        info.file.response = `Quitar ${info.file.name}`;

        info.fileList.forEach((f:any)=>{
          f.response= `Quitar ${f.name}`;
          f.status = "ok";
        })

        if (info.file && info.file.originFileObj){
          setFile(info.file);
        }
      },
      itemRender: (originNode: ReactElement, file: UploadFile, fileList: any) => {
        // No mostramos los anteriores, no nos valen, solo se sube un archivo y será el último
        if (fileList.length > 0 && file == fileList[fileList.length -1]){
          return <div className="uploader-item">{originNode} <Button size="small" icon={<UploadOutlined />} onClick={handleSubmit}></Button></div>
        }else{
          return <></>
        }
      }
      //itemRender: (originNode: ReactElement, file: UploadFile, fileList: object[], actions: { download: ()=>{}, preview:  ()=>{}, remove:  ()=>{} }) => <>Algo</>
    };

    const initClient = (options: {
      updateLoggedInStatus: (status: boolean) => void;
    }) => {
      
      if (done) {
        return;
      }
      done = true;
      setLoadingStatus(true);
      
      gapi.client
        .init({
          apiKey: API_KEY,
          client_id: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES.join(' '),
        })
        .then(() => {
          // Listen for sign-in state changes.
    
          gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(options.updateLoggedInStatus);
    
          // Handle the initial sign-in state.
          options.updateLoggedInStatus(
            gapi.auth2.getAuthInstance().isSignedIn.get()
          );

          try{
            var email = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
            setUserGmailEmail(email);
          }catch(ex){}

          setLoadingStatus(false);
        })
        .catch((err: any) => {
          console.error("Caught error", err);
          setLoadingStatus(false);
        });
    };

    const setPathIn = (index: number) : void => {
      setPath(path.splice(0, index + 1));
    }

    const [nextPageToken, setNextPageToken] = useState<string>('');
    const [actualPageToken, setActualPageToken] = useState<string>('');
    const [previousPageTokens, setPreviousPageTokens] = useState<string[]>(['']);
    const getFileList = (theFolderId? :string, pageToken? : string) : any => {
        
        if (gapi && gapi.client !== undefined){
          const folderId = theFolderId || initialFolderId;
          setLastFileId(folderId);
          setInGetFiles(true);
          gapi.client.drive.files.list({
              'pageSize': 15,
              'q': `trashed = false and parents in '${folderId}'`,
              'pageToken': actualPageToken ?? '',
              // Tipos: https://developers.google.com/drive/api/v3/reference/files
              'fields': "nextPageToken, files(id,kind,name,createdTime,modifiedTime,size, mimeType,parents, webContentLink, webViewLink, iconLink, thumbnailLink)",
              'orderBy': "folder,name" // Ordenar por tipo de archivo y luego por nombre
            })
          .then((response: any) => {
              setInGetFiles(false);
              if (response && response.result && response.result.files){
                  setFileList(response.result.files);
                  
                  if (previousPageTokens.length == 0){
                    previousPageTokens.push('');
                  }
                                
                  setNextPageToken(response.result.nextPageToken ?? '');
              }
          })
          .catch((exception: any)=> Promise.reject(exception)) 
        }
    };

    const searchFile  = async () => {
      setInGetFiles(true);
      const files = await searchFilesRecursive(initialFolderId, searchFileName);
      if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
              var folderId = files[i].parents[0];
              const folder = await gapi.client.drive.files.get({'fileId': folderId, 'fields': 'name'});
              files[i].parentName = folder.result.name;
          }
          setFileList(files);
          setSearchFileName('');
      } else {
          message.warning(`No se han encontrado coincidencias con: ${searchFileName}`);
          setFileList([]);
      }
      setInGetFiles(false);
    }
  
    const searchFilesRecursive = async (folderId: string, searchFileName: string) => {
        let files = await gapi.client.drive.files.list({
            'q': "name contains '" + searchFileName + "' and trashed = false and parents in '"+folderId+"'",
            'fields': "nextPageToken, files(id,kind,name,createdTime,modifiedTime,size, mimeType,parents, webContentLink, webViewLink, iconLink, thumbnailLink)"
        }).then((response: any) => response.result.files);
    
        const folders = await gapi.client.drive.files.list({
            'q': "mimeType='application/vnd.google-apps.folder' and trashed = false and parents in '"+folderId+"'",
            'fields': "nextPageToken, files(id,kind,name,createdTime,modifiedTime,size, mimeType,parents, webContentLink, webViewLink, iconLink, thumbnailLink)"
        }).then((response: any) => response.result.files);
        
        if (folders.length > 0) {
            for (let i = 0; i < folders.length; i++) {
                files = files.concat(await searchFilesRecursive(folders[i].id, searchFileName));
            }
        }
        return files;
    }

    const handleSubmit = (event: any) => {
      event.preventDefault();
      if (file){
        var fileContent = file.originFileObj; // fileContent can be text, or an Uint8Array, etc.
        var fileX = new Blob([fileContent!], {type: file.type});
        var metadata = {
            "name": file.name,
            "mimeType": file.type,
            "parents": [lastFileId], // Google Drive folder id
        };
  
        var accessToken = gapi.auth.getToken().access_token;
        var form = new FormData();
            form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            form.append('file', fileX);
  
        fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", {
            method: 'POST',
            headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
            body: form,
        }).then((res) => {

            if (res && res.ok && res.status === 200){
              message.success(`${file.name} subido correctamente`);
              setFile(null);
              getFileList(lastFileId);
            }else{
              message.error(`No se ha podido cargar ${file.name}.`);
            }

            return res.json();
        }).then(function(val) {
        });
      }
      
    }

    const getActions = (item: any) : JSX.Element => {

        let mimeType: string = item.mimeType;
        let fileOrFolderId: string = item.id;
        let fileOrFolderName: string = item.name;

        let result : JSX.Element = <></>;

        let parentButton : JSX.Element = <></>
        if (item.parentName){
          parentButton = <Button size="small" onClick={()=>{path.push({id: item.parents[0], name: item.parentName}); getFileList(item.parents[0])}}>Abrir ubicación: {item.parentName}</Button>
        }

        const removeButton : JSX.Element = <Popconfirm 
                                                okText='Si, deseo eliminarlo' 
                                                cancelText='No, prefiero mantenerlo' 
                                                title="Esta operación no puede deshacerse" 
                                                placement='right'
                                                okButtonProps={{danger:true}}
                                                cancelButtonProps={{type: "primary"}}
                                                onConfirm={()=>{removeDocument(fileOrFolderId, fileOrFolderName)}}>
                                              <Button danger size='small' type='default'>
                                                Eliminar
                                              </Button> 
                                            </Popconfirm>

                                            

        switch(mimeType){
            case "application/vnd.google-apps.folder":
                result = <div className="google-drive-buttons-actions">
                            <Button size='small' type='default' onClick={()=>{
                                                                                getFileList(fileOrFolderId);                  
                                                                                path.push({id: fileOrFolderId, name: fileOrFolderName});
                                                                                setPath(path);
                                                                              }
                                                                        }>
                              <FolderOpenOutlined /> Abrir
                            </Button> 
                            {parentButton}
                            {removeButton}
                         </div>
                break;
            default:
                result = <div className="google-drive-buttons-actions">
                            <Button size='small' type='default' onClick={()=>{
                                                                                getSharedLink(fileOrFolderName, fileOrFolderId, 'reader')}}>
                              Compartir
                            </Button> 
                            <Button size='small' type='default' onClick={()=>{
                                                                                showDocument(fileOrFolderId)}
                                                                        }>
                              Visualizar
                            </Button> 
                            {parentButton}
                            {removeButton}
                          </div>
                break;
        }

        return result;
    }
    
    const getSharedLink = (fileName: string, selectedFileId: string, mode: 'reader' | 'writer') : void => {

      // Crear un permiso de lectura para cualquier persona
      gapi.client.drive.permissions.create({
        'fileId': selectedFileId,
        'resource': {
          'type': 'anyone',
          'role': mode
        }
      }).then(function() {
        //Obteniendo el enlace de compartición
        gapi.client.drive.files.get({
          'fileId': selectedFileId,
          'fields': 'webContentLink,webViewLink'
        }).then(function(file: any) {
          var downloadLink = file.result.webContentLink;
          var sharedLink = file.result.webViewLink;
          setSelectedFileId(selectedFileId);
          setSelectedFileName(fileName);
          setSelectedDownloadLink(downloadLink);
          setSelectedSharedLink(sharedLink);
          setShowSharedOptions(true);
        }); 
      });
    }

    const showDocument = (fileId: string) : void => {
      //Obteniendo el enlace de visualización
      gapi.client.drive.files.get({
        'fileId': fileId,
        'fields': 'webViewLink'
      }).then(function(file: any) {
        var link = file.result.webViewLink;
        var width = 600;
        var height = 800;
        var left = (window.screen.width - width) / 2;
        var top = (window.screen.height - height) / 2;
        window.open(link, '_blank', 'toolbar=no,location=no,menubar=no,width='+width+',height='+height);
      });
    }

    const removeDocumentFromServer = (fileId: number, fileName: string) : void => {
      const params : ParamsApi = {
          query: {
            documentId: fileId,
          }
      }

      restApiClient.fetch("UnshareDocument", params)
          .then((r: any)=>{
              if (r.data && r.data == true){
                loadSharedFiles();
              }
          })
    }

    const removeDocumentFromServerUsingGoogleDriveId = (fileId: string, fileName: string, removeInDriveToo: boolean = false) : void => {
      const params : ParamsApi = {
          query: {
            googleDriveId: fileId,
          }
      }

      // Está compartido
      setLoadingSharedFiles(true);
      restApiClient.fetch("UnshareDocumentByGoogleDriveId", params)
          .then((r: any)=>{
              if (r.data && r.data == true && removeInDriveToo){
                removeDocumentFromDrive(fileId, fileName);
              }
          })
          .finally(()=>{
            setLoadingSharedFiles(false);
          })
        //
    }

    const removeDocumentFromDrive = (fileId: string, fileName: string) : void => {
      gapi.client.drive.files.delete({
        'fileId': fileId
      }).then(function(response: any) {
          message.success(`${fileName} eliminado correctamente`);
          getFileList(lastFileId);
        },function(error: any){
          message.error(`Error al eliminar el fichero ${fileName}. Vuelva a intentarlo y si el problema persiste contacte con soporte técnico.`);
        });
    }

    const removeDocument = (fileId: string, fileName: string) : void => {
      
      //Primero intentamos dejar de compartirlo (en caso de estarlo) y después eliminamos el fichero de Google Drive


      if (sharedFiles.find((sf: SharedFile) => sf.fileId === fileId)){
        removeDocumentFromServerUsingGoogleDriveId(fileId, fileName, true);
      }else{
        removeDocumentFromDrive(fileId, fileName);
      }
    }

    const createFolder = (isDefaultFolder: boolean = false) => {
      if (newFolderName && newFolderName.length > 0){
        var fileMetadata = lastFileId.length > 0 ? {
          'name': newFolderName,
          'mimeType': 'application/vnd.google-apps.folder',
          'parents':[lastFileId]
        }
        :
        {
          'name': newFolderName,
          'mimeType': 'application/vnd.google-apps.folder'
        };
        
        gapi.client.drive.files.create({
            resource: fileMetadata,
            fields: 'id'
        }).then(function(response: any) {
            message.success(`${newFolderName} creado correctamente`);
            getFileList(lastFileId);
            setNewFolderName('');

            if (isDefaultFolder){
              setInitialFolderId(response.result.id);
              setInitialFolderName(newFolderName);
              // Update folder name
              updateDefaultFolderInServer(`${response.result.id}|${newFolderName}|${userGmailEmail}`)
              setLastFileId(response.result.id);
            }
        }, function(error: any) {
            message.error(`No se ha podido crear la carpeta ${newFolderName}`);
        });
      }else{
        message.warning(`Debe especificar un nombre para poder crear la carpeta`);
      }
      
    }

    const columns : any = [
      {
        title: 'Nombre fichero',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (val: any, item: SharedFile) => {
          return item.fileName;
        },
      },
      {
        title: 'Google Drive ID',
        dataIndex: 'fileId',
        key: 'fileId',
      },
      {
        title: 'Compartido con',
        dataIndex: 'entityDescription',
        key: 'entityDescription',
      },
      {
        title: 'Acciones',
        key: 'actions',
        render: (val: any, item: SharedFile) => {
          return <>
                    <Popconfirm 
                          okText='Si, deseo dejar de compartirlo' 
                          cancelText='No, prefiero mantenerlo' 
                          title="Esta operación no puede deshacerse pero puede volver a compartir el archivo en drive" 
                          placement='right'
                          okButtonProps={{danger:true}}
                          cancelButtonProps={{type: "primary"}}
                          onConfirm={()=>{removeDocumentFromServer(item.id, item.fileName)}}>
                        <Button danger size='small' type='default'>
                          Dejar de compartir
                        </Button> 
                      </Popconfirm>
                      <Button style={{marginLeft: "5px"}}  size='small' type='primary' onClick={()=>{
                                                                          showDocument(item.fileId)}
                                                                  }>
                        Visualizar
                      </Button> 
                 </>
        }
      },
    ];

    const formatFileSize = (bytes: number): string => {
      if (bytes < 1024) {
        return bytes + " bytes";
      } else if (bytes < 1024 * 1024) {
        return Math.ceil(bytes / 1024) + " kB";
      } else if (bytes < 1024 * 1024 * 1024) {
        return Math.ceil(bytes / (1024 * 1024)) + " mB";
      } else if (bytes < 1024 * 1024 * 1024 * 1024) {
        return Math.ceil(bytes / (1024 * 1024 * 1024)) + " gB";
      } else {
        return Math.ceil(bytes / (1024 * 1024 * 1024 * 1024)) + " tB";
      }
    }

    const fileColumns : any = [
      {
        title: 'Nombre',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (val: any, item: any) => {
          return <span className='google-drive-file-name'><img src={item.iconLink} /> <strong>{item.name}</strong></span>;
        },
      },
      {
        title: 'Última modificación',
        dataIndex: 'lastUpdate',
        key: 'lastUpdate',
        render: (val: any, item: any) => {
          return <span>{moment(item.modifiedTime).format("DD/MM/YYYY HH:mm:ss")}</span>;
        },
      },
      {
        title: 'Compartido',
        dataIndex: 'sharedWith',
        key: 'sharedWith',
        render: (val: any, item: any) => {
          
          return sharedFiles.filter((sf: SharedFile) => {
                  return sf.fileId == item.id
                }).map((sf: SharedFile, index: number)=>{
                  return <Tag key={index} color="#2db7f5">{sf.entityDescription}</Tag>
                }) ?? 'Nadie';
        },
      },
      {
        title: 'Tamaño del archivo',
        dataIndex: 'fileSize',
        key: 'fileSize',
        render: (val: any, item: any) => {
          return <span>{item.size ? `${formatFileSize(item.size)}` : '-'}</span>;
        },
      },
      {
        title: 'Acciones',
        dataIndex: 'fileActions',
        key: 'fileActions',
        render: (val: any, item: any) => {
          return <span>{getActions(item)}</span>;
        },
      }
    ]


    gapi.load("client:auth2", () =>{
      return initClient({
        updateLoggedInStatus: (status) => {
          setLoggedInStatus(status);
          
        },
      })
    }
    );

    const refreshLists = () : void => {
      loadSharedFiles(); 
      
      if (loggedInStatus && lastFileId){
        getFileList(lastFileId);
      }
    }

    useEffect(()=>{
      loadSharedFiles(); 
    },[])

    useEffect(()=>{
      if (loggedInStatus && initialFolderId){
        getFileList();
      }
    },[loggedInStatus, initialFolderId])

    const [userGmailEmail, setUserGmailEmail] = useState<string>('');
  const login = () : void => {
    if (gapi.auth2 && gapi.auth2 !== undefined){
      gapi.auth2.init({
        apiKey: API_KEY,
        client_id: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES.join(' '),
      });

      gapi.auth2.getAuthInstance().signIn().then((r: any)=>{
        try{
          var email = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
          setUserGmailEmail(email);
          
        }catch(ex){
          console.error("Do not find the user email. Please, reconect your gmail account with this app");
        }
      })
    }else{
      console.error("GAPI ya está inicializado");
    }
  }

  useEffect(()=>{
    if (userGmailEmail && userGmailEmail.length > 0){
      getDefaultClientDriveFolder();
    }
  },[userGmailEmail])

  const logout = () : void => {
    if (gapi.auth2 && gapi.auth2 !== undefined){
      gapi.auth2.getAuthInstance().signOut();
    }
  }



  useEffect(()=>{
    getFileList(lastFileId, actualPageToken);
  },[actualPageToken])
  


    return(
        <div className="box-google-drive">
          <Drawer visible={showSharedOptions} width="50%" closable onClose={()=>{setShowSharedOptions(false)}}>
            <SharedOptionsComponent onFinish={()=>{loadSharedFiles(); }} onClose={()=>{setShowSharedOptions(false);}} fileInfo={{
              downloadLink: selectedDownloadLink,
              id:selectedFileId,
              name:selectedFileName,
              webviewLink:selectedSharedLink
            }}/>
          </Drawer>

          <div className="header-toolbar">
                  <Button disabled={inGetfiles} className="ht-button" type="primary" title='Recargar' onClick={refreshLists}>
                    { inGetfiles ? <LoadingOutlined /> : <ReloadOutlined />} Refrescar
                  </Button>
                  <LogInOutButton
                        loggedIn={loggedInStatus}
                        logIn={login}
                        logOut={logout}
                    />
                </div>

          {
            <Tabs>
              <Tabs.TabPane tab="Explorador Google Drive" key="item-1-2">
                  <div className="google-drive-component">
                      {
                        loadingStatus ? 
                            <Result
                                title={<><LoadingOutlined /> Por favor, espere. Esta operación puede tomar unos segundos...</>}
                            />
                            : 
                            <>
                              {
                                !loggedInStatus 
                                ? 
                                  <>
                                      <Alert type='warning' message={
                                        <>
                                          <p>
                                            Para poder consultar los ficheros debe iniciar sesión con una cuenta de Google Drive. Por favor, pulse iniciar sesión Drive para autorizar el acceso a su cuenta de Google Drive. Una vez hecho podrá navegar por los ficheros y compartirlos con los usuarios.
                                          </p>
                                          <p>
                                            Recuerde que siempre podrá ver los archivos en Drive y cualquier dispositivo con el que esté sincronizado.
                                          </p>
                                        </>
                                      }/>
                                      
                                  </>
                                :
                                <>
                                  {
                                    initialFolderId && initialFolderId.length > 0 
                                    ?
                                    <>
                                      {
                                        userGmailEmail.length > 0 ? <small>Sesión iniciada con <strong>{userGmailEmail}</strong>. Carpeta por defecto ({initialFolderName})</small> : ''
                                      }
                                      <Breadcrumb className="bread-crumb" style={{marginTop: "10px"}}>
                                        <Breadcrumb.Item onClick={()=>{getFileList(); setPath([])}}>/<strong>{initialFolderName}</strong></Breadcrumb.Item>
                                        
                                        {
                                          path?.map((p: Path, index: number) => {
                                            return <Breadcrumb.Item key={index} onClick={()=>{getFileList(p.id);setPathIn(index)}}>{p.name}</Breadcrumb.Item>
                                          })
                                        }
                                      </Breadcrumb>
                                      

                                      <Row gutter={16} className="body-actions">
                                        
                                        <Col xs={24} md={12} lg={12} className="folder-creations">
                                          <Input.Group compact style={{marginTop: "10px"}} >
                                              <Input style={{ width: 'calc(100% - 200px)' }} value={searchFileName} placeholder="Introduzca el nombre del fichero que desea buscar" onChange={(e)=>{
                                                setSearchFileName(e.target.value);
                                              }}/>
                                              <Button style={{width: "200px"}} disabled={!searchFileName || searchFileName.length == 0} onClick={searchFile}>Buscar</Button>
                                          </Input.Group>
                                          
                                        </Col>
                                        <Col xs={24} md={12} lg={12} className="folder-creations">
                                          <Input.Group compact style={{marginTop: "10px"}} >
                                            <Input style={{ width: 'calc(100% - 200px)' }} value={newFolderName} placeholder="Introduzca el nombre de la carpeta que desea crear" onChange={(e)=>{
                                              setNewFolderName(e.target.value);
                                            }}/>
                                            <Button style={{width: "200px"}} disabled={!newFolderName || newFolderName.length == 0} onClick={()=>{createFolder()}}>Crear carpeta</Button>
                                          </Input.Group>
                                          
                                        </Col>
                                        <Col xs={24} className="uploader">
                                          <Upload {...propsFileUpload} >
                                            <Button style={{marginTop: "10px"}} type='primary'>Seleccionar archivo para subir...</Button>
                                          </Upload>
                                          
                                        </Col>
                                      </Row>

                                      <Table dataSource={fileList} columns={fileColumns} style={{width: "100%"}} pagination={false}/>
                                      <div className="google-drive-navigators">
                                        {
                                          previousPageTokens.length ? 
                                          <Button disabled={actualPageToken === ''} className="google-drive-navigation-button" onClick={()=>{
                                            const lastToken = previousPageTokens.pop(); 
                                            setActualPageToken(lastToken ?? '');
                                            }
                                          }>
                                            <DoubleLeftOutlined /> Página anterior
                                          </Button> 
                                          : 
                                          ''
                                        }

                                        {
                                            <Button disabled={!nextPageToken || nextPageToken === ''} className="google-drive-navigation-button" onClick={()=>{
                                                previousPageTokens.push(actualPageToken)
                                                setActualPageToken(nextPageToken);
                                              }}>
                                              Página siguiente <DoubleRightOutlined />
                                            </Button> 
                                        }

                                        
                                      </div>

                                  </>
                                  : 
                                  <div className="no-initial-folder-defined">
                                  {
                                    messageDifferentAccount.length > 0 ?
                                    <Alert style={{marginBottom: "10px"}} message={parse(messageDifferentAccount)} type="error" showIcon />
                                    :
                                    ''
                                  }
                                  <h4 className="alert">Para poder subir ficheros es necesario que cree una carpeta nueva. Una vez creada todos los archivos que comparta estarán ubicadas en esa carpeta.</h4>
                                  <Input onChange={(e)=>{
                                    setNewFolderName(e.target.value);
                                  }} className="folder-name" placeholder='Por favor, escriba un nombre para la carpeta por defecto'/>
                                  <Button type="primary" className="btn-save-folder-name" onClick={()=>{createFolder(true)}}>Guardar</Button>
                                </div> 
                                  }
                                </>
                                  
                              }
                            </>
                      }
                  </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Documentos compartidos" key="item-1-1">
                <Table dataSource={sharedFiles} columns={columns} rowKey={'index'}/>
              </Tabs.TabPane>
            </Tabs>
            
          }
          
          
            
        </div>
    )
}

interface SharedOptionsProps{
  onFinish: () => void,
  onClose: () => void,
  fileInfo:{
    name: string,
    webviewLink: string,
    downloadLink: string,
    id: string
  }
}
const SharedOptionsComponent = (props: SharedOptionsProps) : JSX.Element => {
  const restApiClient : RestApiClient = new RestApiClient();
  const [loadingSaveSharedFile, setLoadingSaveSharedFile] = useState<boolean>(false);

  const saveSharedFile = (entityId: number, entityType: string) : void => {
    const params : ParamsApi = {
      body:{
        idEntity: entityId,
        entityType: entityType,
        fileName: props.fileInfo.name,
        fileId: props.fileInfo.id,
        webLink: props.fileInfo.webviewLink,
        downloadLink: props.fileInfo.downloadLink
      }
    }

    setLoadingSaveSharedFile(true);
    restApiClient.fetch("ShareDocument", params)
        .then((r: any)=>{
          props.onFinish();
        })
        .finally(()=>{
          setLoadingSaveSharedFile(false);
        })
  }

  const [userList, setUserList] = useState<Users[]>([]);
  const getUsers = () : void => {
    var params : ParamsApi = {
      query:{
          filter: "",
          page: "0",
          N: 9999
      }
  }

  restApiClient.fetch("GetUsers", params)
      .then((r: any)=>{
          if (r.data.elements){
            setUserList(r.data.elements);
          }
      })
  }

  const [groupList, setGroupList] = useState<Group[]>([]);
  const getGroups = () : void => {
    var params : ParamsApi = {
      query:{
          filter: "",
          page: "0",
          N: 9999
      }
  }

  restApiClient.fetch("GetGroups", params)
      .then((r: any)=>{
          if (r.data.elements){
            setGroupList(r.data.elements);
          }
      })
  }

  const [sportCenterList, setSportCenterList] = useState<SportCenter[]>([]);
  const getSportCenters = () : void => {
    restApiClient.fetch("GetSportCenters", {})
              .then((r : any) => {
                setSportCenterList(r.data);
              })
  }

  const columnsUsers = [
    {
      title: 'Nombre',
      key: 'name',
      render: (item: Users) => { 
                    
          return `${item.name} ${item.surname} ${item.secondSurname}`
          
      }
    },
    {
      title: 'Email (Compartir con...)',
      key: 'email',
      render: (item: Users) => { 
          return <Button style={{width: "100%"}} onClick={()=>{
                    saveSharedFile(item.id!, "user");
                  }}>
                    {item.contactData?.email}
                  </Button>
      }
    }
  ]

  const columnsGroups = [
    {
      title: 'Nombre Grupo',
      key: 'name',
      render: (item: Group) => { 
                    
          return `${item.description}`
          
      }
    },
    {
      title: 'Acciones',
      key: 'email',
      render: (item: Group) => { 
          return <Popconfirm
                    placement="left"
                    title={`¿Estás seguro/a de que deseas compartir el archivo con ${item.description}?`}
                    onConfirm={()=>{
                      saveSharedFile(item.id!, "group");
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button onClick={()=>{
                      saveSharedFile(item.id!, "group");
                    }}>
                      Compartir
                    </Button>
                  </Popconfirm>
      }
    }
  ]

  const columnsCenter = [
    {
      title: 'Nombre Centro',
      key: 'name',
      render: (item: SportCenter) => { 
                    
          return `${item.description}`
          
      }
    },
    {
      title: 'Acciones',
      key: 'email',
      render: (item: SportCenter) => { 
          console.log("Item", item);
          return <Button onClick={()=>{
                    saveSharedFile(item.id!, "sport_center");
                  }}>
                    Compartir
                  </Button>
      }
    }
  ]

  useEffect(()=>{
    getUsers();
    getGroups();
    getSportCenters();
  },[])

  return (
    <div className="shared-options">
      <p>
        <h3>Resumen
          <small style={{display: "block"}}>Información de google sobre el fichero que deseas compartir</small>
        </h3>
        <ul style={{listStyle: "none"}}>
          <li><strong>Nombre fichero:</strong> {props.fileInfo.name}</li>
          <li><strong>ID: </strong>{props.fileInfo.id}</li>
          <li><strong>Visualización:</strong> <a target='_blank' href={props.fileInfo.webviewLink}>{props.fileInfo.webviewLink}</a></li>
          <li><strong>Descarga:</strong> <a href={props.fileInfo.downloadLink}>{props.fileInfo.downloadLink}</a></li>
        </ul>
      </p>
      <div className="options">
        <Tabs>
          <Tabs.TabPane tab="Usuarios" key="item-1">
                  <Table dataSource={userList} columns={columnsUsers} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Grupo" key="item-2">
            <Table dataSource={groupList} columns={columnsGroups} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Centro" key="item-3">
              <Table dataSource={sportCenterList} columns={columnsCenter} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="actions">
        <Button onClick={props.onClose} type="primary">Cerrar</Button>
      </div>
    </div>
  )
}

export default GDriveComponent;