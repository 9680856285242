import { Col, Row } from 'antd';
import React, { useContext, useEffect } from 'react'
import * as Components from '../index'
import { AppCfgContext } from '../../hooks/context/appConfigContext'

import './HeaderComponent.scss'
import { LoginContext } from '../../hooks/context/userContext';


interface HeaderComponentProps {}

const HeaderComponent = (props: HeaderComponentProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);
    const loginContext = useContext(LoginContext);
    
    useEffect(()=>{
        console.log("Data", appConfigContext);
    },[appConfigContext])
    
    return (
        <Row className="app-header">
            <Col xs={24} sm={24} md={4} className="logo-container">
                <img  className="logo" src={appConfigContext.cfg.urlsShieldImg}/>
            </Col>
            
            <Col xs={24} sm={24} md={16} className="balance-container">
                {
                    loginContext.idRol === 'admin' ? <Components.Balance /> : <></>
                }
            </Col>
            <Col xs={24} sm={24} md={4} className="search-container">
                
                <Components.Session />
            </Col>
        </Row>
    )
}

export default HeaderComponent;