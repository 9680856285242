import React, { useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd'

import StatisticsDataComponent from './statisticsDataComponent'

import './statisticsComponent.scss'
import Enrollments from '../../models/Enrollments'
import Season from '../../models/Season'

const { Title } = Typography;

interface StatisticsProps{
  season?: Season,
  enrollments: any[],
  payments: any[]
}

const StatisticsComponent = (props: StatisticsProps) : JSX.Element =>{
    const [season, setSeason] = useState<Season>();
    const [statisticsEnrollments, setStatisticsEnrollments] = useState<any[]>([]);
    const [statisticsPayments, setStatisticsPayments] = useState<any[]>([]);

    useEffect(()=>{
        // Obtener del back los datos estadísticos
        setStatisticsEnrollments(props.enrollments);
    }, [props.enrollments])

    useEffect(()=>{
        // Obtener del back los datos estadísticos
        setStatisticsPayments(props.payments);
    }, [props.payments])

    useEffect(()=>{
      setSeason(props.season);
    }, [props.season])

    return(
        <Row gutter={16} className="statistics">
            <Col xs={24} sm={24} md={24} lg={11}>
                <Title level={4}>Inscripciones realizadas {season ? `(${season.description})` : ''}</Title>
                <StatisticsDataComponent data={statisticsEnrollments}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11}>
                <Title level={4}>Pagos realizados {season ? `(${season.description})` : ''}</Title>
                <StatisticsDataComponent data={statisticsPayments}/>
            </Col>
        </Row>
    )
}

export default StatisticsComponent;