import { Button, Col, DatePicker, Form, Input, Row, Select, Tag, TimePicker, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import Group from '../../models/Group';

import * as Components from '..'

import './GroupsComponent.scss'
import { getLoginUserInfo, getTagRol } from '../../utils/GeneralUtils';
import User from '../../models/Users';
import Athletes from '../../models/Athletes';
import moment from 'moment';
import { LoadingOutlined, WarningTwoTone } from '@ant-design/icons';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';
import SportCenter from '../../models/SportCenter';

import locale from 'antd/es/date-picker/locale/es_ES';

interface GroupsAddComponentProps{
    callBack: any,
    data: Group
}

const GroupsAddComponent = (props: GroupsAddComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const usersListRef = useRef();
    const studentsListRef = useRef();

    const [reloadCounter, setReloadCounter] = useState<number>(1);
    const [selectedCentre, setSelectedCentre] = useState<number>();
    const [selectedTeachers, setSelectedTeachers] = useState<any[]>([]);
    const [selectedAthletes, setSelectedAthletes] = useState<any[]>([]);

    const [form] = Form.useForm();

    const [updating, setUpdating] = useState<boolean>(false);

    const teachersColumns : any = [
        {
          title: '',
          filtered: true,
          dataIndex: 'name',
          width: 20,
          key: '33',
          render: (val: any, item: User) => {
            return getLoginUserInfo(item);
          },
        },
        {
          title: 'Nombre',
          filtered: true,
          dataIndex: 'name',
          key: '3',
          render: (val: any, item: User) => `${item.surname} ${item.secondSurname}, ${item.name}`,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: '4',
          render: (val: any, item: User) => <a href={`mailto:${item.contactData?.email}`}>{item.contactData?.email}</a>
        },
        {
          title: 'Teléfono',
          dataIndex: 'phone',
          key: '5',
          render: (val: any, item: User) => <span>{item.contactData?.phone}</span>
        }
      ];

      const alumnsColumns : any[] = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 50
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name',
          render: (index: number, item: Athletes) => `${item.surname} ${item.secondSurname}, ${item.name}`,

        },
        {
          title: 'Nacimiento',
          dataIndex: 'birthDate',
          key: 'birthDate',
          render: (index: number, item: Athletes) => moment(item.birthDate).format("DD/MM/YYYY"),
        },
        {
          title: 'Grupos',
          dataIndex: 'groups',
          key: 'groups', 
          render: (index: number, item: Athletes) => {
            if (!item.groups || item.groups.length == 0){
              return <Tooltip title="Sin grupo asignado"><WarningTwoTone twoToneColor="#ffc300" style={{fontSize: "1.5em"}}/></Tooltip>;
            }

            return item.groups.map((group : Group)=><Tag>{group.description}</Tag>)
          }
        }
    ];


    const onFinish = () : void => {
        var params : ParamsApi = {
            body:{
                description: form.getFieldValue("description"),
                sportCenterId: form.getFieldValue("sportCenter"),
                selectedAthletes:selectedAthletes,
                selectedTeachers: selectedTeachers,
                weekDays: form.getFieldValue("weekDays"),
                endDate: moment(form.getFieldValue("endDate")).format("YYYY-MM-DD").toString(),
                startDate: moment(form.getFieldValue("startDate")).format("YYYY-MM-DD").toString(),
                timeToStart: moment(form.getFieldValue("timeToStart")).format("HH:mm").toString(),
                timeToEnd: moment(form.getFieldValue("timeToEnd")).format("HH:mm").toString()
            }
        }

        setUpdating(true);
        restApiClient.fetch("AddGroup", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    form.resetFields();
                    props.callBack();
                }
            }).finally(()=>{
                setUpdating(false);
            })
    }

    
    const onReset = () : void => {
        if (props.data){
            form.resetFields();
            setReloadCounter(reloadCounter+1);
            setSelectedAthletes([]);
            setSelectedTeachers([]);
            getSportCenters();
        }
    }

    useEffect(()=>{
        onReset();
    },[props.data]);

    const [loadingCenters, setLoadingCenters] = useState<boolean>(false);
    const [sportCenters, setSportCenters] = useState<SportCenter[]>();
    const getSportCenters = () : void => {
        setLoadingCenters(true);
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                }).finally(()=>{
                    setLoadingCenters(false);
                })
    }

    useEffect(()=>{
        getSportCenters();
    },[]);


    
    return (
        <div className="groups-edit-component">
            
            <Form layout='vertical' form={form} onFinish={onFinish}>
                <Col xs={24}>
                    <div className="buttons">
                        <Button danger onClick={onReset}>Reiniciar</Button>
                        <Button htmlType='submit' type='primary'>
                            <LoadingOrComponent loading={updating} loadingText="Guardando" text={"Guardar"}/>
                        </Button>
                    </div>
                    
                </Col>
                <Form.Item
                    label="Nombre"
                    name="description"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input placeholder='Nombre del grupo'/>
                </Form.Item>
                <Form.Item
                    label="Días semana"
                    name="weekDays"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Seleccione los días"
                        >
                            <Select.Option value={"L"}>Lunes</Select.Option>
                            <Select.Option value={"M"}>Martes</Select.Option>
                            <Select.Option value={"X"}>Miércoles</Select.Option>
                            <Select.Option value={"J"}>Jueves</Select.Option>
                            <Select.Option value={"V"}>Viernes</Select.Option>
                            <Select.Option value={"S"}>Sábado</Select.Option>
                            <Select.Option value={"D"}>Domingo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Centro"
                    name="sportCenter"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder={loadingCenters ? <><LoadingOutlined /> Cargando...</> : <>Seleccione un centro</>}
                        >
                            {
                                sportCenters?.map((sportCenter : SportCenter)=>{
                                    return <Select.Option value={sportCenter.id}>{sportCenter.description}</Select.Option>
                                })
                            }
                    </Select>
                </Form.Item>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label="Hora de entrada"
                            name="timeToStart"
                            className="form-item"
                            rules={[{required: true, message: 'Campo obligatorio'}]}
                        >
                            <TimePicker format={"HH:mm"} style={{width: "100%"}} locale={locale}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label="Hora de salida"
                            name="timeToEnd"
                            className="form-item"
                            rules={[{required: true, message: 'Campo obligatorio'}]}
                        >
                            <TimePicker format={"HH:mm"}  style={{width: "100%"}} locale={locale}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label="Fecha inicio"
                            name="startDate"
                            className="form-item"
                            rules={[{required: true, message: 'Campo obligatorio'}]}
                        >
                            <DatePicker  style={{width: "100%"}} format={"DD/MM/YYYY"} locale={locale}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label="Fecha Fin"
                            name="endDate"
                            className="form-item"
                            rules={[{required: true, message: 'Campo obligatorio'}]}
                        >
                            <DatePicker style={{width: "100%"}} format={"DD/MM/YYYY"} locale={locale}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Components.TablePagination
                                      ref={usersListRef} 
                                      useTableScroll={{y: 300}}
                                      entityName="users"
                                      reloadCounter={reloadCounter}
                                      aliasEndPoint="GetUsers"
                                      columns={teachersColumns}
                                      defaultFilter={'MONITOR/PROFESOR'}
                                      selectedRowKeys={selectedTeachers}
                                      title="Listado de profesores"
                                      onCheckedItem={(items: any)=>{
                                        setSelectedTeachers(items);
                                      }}
                                      showCheckbox
                                      subtitle={<small style={{display:"block", fontSize:"11px"}}>Selecciona el/los monitores/profesores que formarán parte del grupo.</small>}
                                      loadingData = {false}
                                  />
                    </Col>
                    <Col xs={24}>
                        <Components.TablePagination
                            ref={studentsListRef} 
                            useTableScroll={{y: 600}}
                            entityName="students"
                            reloadCounter={reloadCounter}
                            aliasEndPoint="GetAthletesForAdmin"
                            selectedRowKeys={selectedAthletes}
                            columns={alumnsColumns}
                            title="Listado de alumnos"
                            showCheckbox
                            subtitle={<small style={{display:"block", fontSize:"11px"}}>Selecciona el/los alumnos que formarán parte del grupo.</small>}
                            loadingData = {false}
                            onCheckedItem={(items: any)=>{
                                setSelectedAthletes(items);
                              }}
                        />
                    </Col>
                    <Col xs={24}>
                        <div className="buttons">
                            <Button danger onClick={onReset}>Reiniciar</Button>
                            <Button htmlType='submit' type='primary'>
                                <LoadingOrComponent loading={updating} loadingText="Guardando" text={"Guardar"}/>
                            </Button>
                        </div>
                        
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default GroupsAddComponent;