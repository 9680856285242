import React from 'react'

import * as Components from '../../components'

import './ProductsPage.scss'


const ProductsPage = () : JSX.Element => {
    return (
        <div className='products-page'>
            <div id="marker-app"></div>
            <Components.ProductList />
        </div>
    )
}


export default ProductsPage;