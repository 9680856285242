import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';

import './BalanceComponent.scss'

interface BalanceComponentProps{}

interface BalanceInfo{
    available: number,
    connectReserved: number,
    pending: number
}

const BalanceComponent = (props: BalanceComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [balanceInfo, setBalanceInfo] = useState<BalanceInfo>({available: 0, connectReserved: 0, pending: 0})
    const [loading, setLoading] = useState<boolean>(false);

    const getBalanceInfo = () : void => {
        var params : ParamsApi = {}

        setLoading(true);
        restApiClient.fetch("GetMyBalance", params)
            .then((r: any)=>{
                if (r.data){
                    setBalanceInfo(r.data);
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    }

    useEffect(()=>{
        getBalanceInfo();
    },[]);

    return(
        <div className="balance-component">
            <span className="title"><Tooltip placement='right' title="Saldo disponible, reservado y pendiente de su cuenta con Stripe. Pulse para saber más.">Saldos <a target='_blank' className="link-to-stripe" href='https://stripe.com/docs/connect/account-balances'>Stripe</a> <WarningOutlined /></Tooltip></span>
            <div className="available">
                <Tooltip title="Saldo disponible: Preparado para su envío">{(balanceInfo.available /100)}€ { loading ? <LoadingOutlined /> : <></>}</Tooltip>&nbsp;&nbsp;|&nbsp;&nbsp;
            </div>
            <div className="connect-reserved">
                <Tooltip title="Saldo reservado">{(balanceInfo.connectReserved /100)}€ { loading ? <LoadingOutlined /> : <></>}</Tooltip>&nbsp;&nbsp;|&nbsp;&nbsp;
            </div>
            <div className="pending">
                <Tooltip title="Saldo pendiente: Aún no disponible para su envío">{(balanceInfo.pending / 100)}€ { loading ? <LoadingOutlined /> : <></>}</Tooltip>
            </div>
        </div>
    )
}

export default BalanceComponent;