import { DeleteOutlined, MailOutlined, SmileTwoTone, UsergroupAddOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Drawer, Form, Input, Menu, Popconfirm, Tooltip } from 'antd';
import React, { useRef, useState } from 'react'
import { Box, TablePagination } from '..';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';


import './OldStudentsComponent.scss'
import { getInitialsBox } from '../../utils/GeneralUtils';

interface OldStudentsComponentProps{}
interface OldStudent{
    id: number,
    completeName: string,
    email: string
}

const OldStudentsComponent = (props: OldStudentsComponentProps) : JSX.Element => {
    const [form] = Form.useForm();
    const oldStudentsListRef = useRef();
    const [reloadCounter, setReloadCounter] = useState<number>(0);
    const restApiClient : RestApiClient = new RestApiClient();

    const onFinish = () : void => {
        const paramsApi : ParamsApi = {
            body: {
                name: form.getFieldValue("name"),
                surname: form.getFieldValue("surname"),
                secondSurname: form.getFieldValue("secondSurname"),
                email: form.getFieldValue("email"),
            }
        }

        restApiClient.fetch("AddOldStudent", paramsApi).then((r: ApiResponse | null)=>{
            if (r && r.code == 200){
                setReloadCounter(reloadCounter+1);
                setAddNewOldUser(false);
            }
        });
    }

    const [showAddNewOldUser, setAddNewOldUser] = useState<boolean>(false);
    const addNewOldUsers = () : void => {
        setAddNewOldUser(true);
    }

    const removeFromList = (id: number) : void => {
        const params : ParamsApi = {
            query: {
                oldStudentId: id
            }
        }

        restApiClient.fetch("RemoveOldStudent",params)
            .then(r => {
                if (r && r.code == 200){
                    setReloadCounter(reloadCounter+1);
                }
            })
    }

    const getMenuActions = (item: OldStudent, className?: string, isMobile?: boolean) : JSX.Element => {
            return  <div >
                        &nbsp;
                        <Popconfirm title="¿Eliminar de la lista de antiguos alumnnos?" okType='danger' cancelText="Cancelar" onConfirm={()=>{removeFromList(item.id)}} okText="Si, eliminar">
                            <Button size='small' className='mobile-menu-actions'  danger>
                                <DeleteOutlined /> Quitar de la lista
                            </Button>
                        </Popconfirm>
                    </div>
      }

    const getTargetBox = (index: number, item: OldStudent) : JSX.Element => {
        let result : JSX.Element = <>No target</>
    
        result = <Box type='ultra-small' key={index}>
                    <div className="old-student-card">
                        <div className="header-card">
                            {getMenuActions(item, 'mobile-menu-actions', true)}
                        </div>
                        <Divider className='divider-card'/>
                        <div className="body-card">
                            <div className="avatar">
                            {
                                getInitialsBox(item.completeName, item.completeName.split(" ")[1])
                            }
                            </div>
                            <div className="info">
                            <span className="mobile-enrollment-student-name"><SmileTwoTone /> {item.completeName}</span>
                            <span className="mobile-enrollment-date"><MailOutlined /> {item.email}</span>
                            </div>
                        </div>
                    </div>
                </Box>
    
        return result;
      }

    const columns : any = [
        {
          title: 'Nombre',
          dataIndex: 'completeName',
          render:(index: number, item: OldStudent)=> {
            var name = item.completeName.split(" ")[0];
            var surname = item.completeName.split(" ")[1] ?? name;
            return <>
                        {
                                getInitialsBox(name, surname)
                            }&nbsp;{item.completeName}
                   </>
          },
          renderMobile: (index:number, item: OldStudent) => {
            return getTargetBox(index, item);
          }
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: "",
            render: (index: number, item: OldStudent) => {
                return getMenuActions(item);
            }
        }
      ];

    return (
        <div className='old-students-component'>
            <Drawer visible={showAddNewOldUser} closable title="Añadir nuevo antiguo alumno" width={"300px"} onClose={()=>{setAddNewOldUser(false)}}>
                <Form form={form} className="frm-login" onFinish={onFinish} layout="vertical">
                    <Form.Item
                        label="Nombre"
                        name="name"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Primer apellido"
                        name="surname"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Segundo apellido"
                        name="secondSurname"
                        className="form-item"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Correo electrónico"
                        name="email"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Añadir
                    </Button>
                </Form>
            </Drawer>
            <TablePagination
                    ref={oldStudentsListRef} 
                    useTableScroll={{y: 300}}
                    entityName="athletes"
                    reloadCounter={reloadCounter}
                    aliasEndPoint="GetOldUsers"
                    columns={columns}
                    title="Listado de antiguos alumnos"
                    subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que.... puedes crear descuentos que apliquen solo a antiguos alumnos?.</small>}
                    loadingData = {false}
                    actionButtons={[{styles:{}, label:"Añadir antiguo alumno", action: addNewOldUsers, icon: <UsergroupAddOutlined /> }]}

                />
        </div>
    )
}

export default OldStudentsComponent;