import React, {useContext,  useEffect,  useState } from 'react'
import { useHistory } from "react-router-dom";

import { Badge, Tooltip } from 'antd'
import { NotificationOutlined,
         MailOutlined,
         ProfileOutlined,
         CreditCardOutlined,
         RadarChartOutlined,
         LogoutOutlined,
         HeartOutlined, 
         GiftOutlined,
         SlidersOutlined
} from '@ant-design/icons'
import * as Drawers from '../drawers/index'
import ApiResponse from '../../../models/api/ApiResponse'
import RestApiClient from '../../../api/restApiClient'

import { LoginContext } from '../../../hooks/context/userContext'

interface DashboardHelperProps{}

const DashboardHelperComponent = (props: DashboardHelperProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();
    const history = useHistory();

    const [showProductsDrawer, setShowProductsDrawer]  = useState<boolean>(false);
    const [showContactDrawer, setShowContactDrawer] = useState<boolean>(false);
    const [showAlertsDrawer, setShowAlertsDrawer] = useState<boolean>(false);
    const [showProfileDrawer, setShowProfileDrawer] = useState<boolean>(false);
    const [showRFEADrawer, setShowRFEADrawer] = useState<boolean>(false);

    const [totalUnreadAlerts, setTotalUnreadAlerts] = useState<number>(0);

    const doLogout = () : void => {

        restApiClient.fetch("DoLogout", {
            query:{
                sessionId: sessionStorage.getItem("token")
            }
        }).finally(()=>{
            restApiClient.closeSession();
            history.push("/");
        });
        
    }

    const getTotalUnreadedAlerts = () : void => {

        restApiClient.fetch("GetTotalUnreadAlerts", {
            query:{
                userId: loginContext.id
            }
        }).then((r : ApiResponse | null)=> {
            setTotalUnreadAlerts(r?.data);
        });
        
    }

    useEffect(()=>{
        getTotalUnreadedAlerts();
    }, [loginContext.notificationNumber])

    useEffect(()=>{
        getTotalUnreadedAlerts();
    }, [])


    const goOmnicode = () : void => {
        window.open('https://www.omnicode.es','_blank')
    }

    return (
        <>
            <ul className="helper-balls dashboard-balls">
                <Tooltip placement="left" title="Notificaciones - Próximamente">
                
                    <li id="btn-alerts" className="h-notifications disabled" onClick={()=>{ /*setShowAlertsDrawer(true);*/ }}>
                        
                        <NotificationOutlined />
                        <Badge count={totalUnreadAlerts} offset={[10, -15]}>
                            <div></div>
                        </Badge>
                    <small className="h-section-name">Alertas</small>
                    </li>
                
                </Tooltip>
                <Tooltip placement="left" title="Tu perfil">
                <li className="h-enrollments" onClick={()=>{ setShowProfileDrawer(true); }}>
                    <ProfileOutlined />
                    <small className="h-section-name">Perfil</small>
                </li>
                </Tooltip>
                <Tooltip placement="left" title="Gestión de productos">
                <li className="h-credit-card" onClick={()=>{ setShowProductsDrawer(true); }}>
                    <GiftOutlined />
                    <small className="h-section-name">Pagos</small>
                </li>
                </Tooltip>
                
                <Tooltip placement="left" title="Configuración - Próximamente">
                <li className="h-rfea disabled" >
                    <small className="h-section-name" >Configuración</small>
                    <SlidersOutlined />
                </li>
                </Tooltip>

                <Tooltip placement="left" title="Cerrar sesión">
                <li className="h-logout" onClick={doLogout}>
                    <small className="h-section-name" >Salir</small>
                <LogoutOutlined />
                </li>
                </Tooltip>
                    
                <Tooltip placement="left" title="Hecho con amor por el equipo de Omnicode.es">
                    <li className="h-heart" onClick={goOmnicode}>
                        <HeartOutlined />
                        <small className="h-section-name" >Omnicode</small>
                    </li>
                </Tooltip>
                
                <li className="created-by-omnicode">
                    <small>Creado desde Málaga con <HeartOutlined /> por <a onClick={goOmnicode}>Omnicode</a></small>
                </li>
            </ul>

            {
                // Drawer for contact form
            }
            <Drawers.AlertsDrawer visible={showAlertsDrawer} setShowDrawer={setShowAlertsDrawer}/>

            {
                // Drawer for profile form
            }
            <Drawers.ProfileDrawer visible={showProfileDrawer} setShowDrawer={setShowProfileDrawer} />

            {
                // Drawer for products forms
            }
            <Drawers.ProductsDrawer visible={showProductsDrawer} setShowDrawer={setShowProductsDrawer} />



        </>
    )
}

export default DashboardHelperComponent;