import React, { useEffect, useState } from 'react'
import RestApiClient from '../../api/restApiClient';

import * as Components from '../../components'
import ApiResponse from '../../models/api/ApiResponse';
import Season from '../../models/Season';
import User from '../../models/Users';

import './UsersPage.scss'


const UsersPage = () : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [reloadUsersCounter, setReloadUserCounter] = useState<number>(0);
    const [season, setSeason] = useState<Season>();
    const [seasons, setSeasons] = useState<Season[]>([]);
    const [searchingUsers , setSearchingUsers] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [generalFilter, setGeneralFilter] = useState<string>("");
    
    const GetSeasons = () : void =>{
        restApiClient.fetch("GetSeasons", {})
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSeasons(r.data);
                    if (r.data.length>0){
                        var data: Season[] = r.data;
                        var activeSeason = data.find((s: Season) => { return s.isActiveSeason });

                        setSeason(activeSeason ? activeSeason : data[0]);
                    }
                }
            })
    }

    const onShowUserDetail = (userData : User) : void => {
        setSelectedUser(userData)
    }

    const onFocusFromUsersData = (email: number) : void => {
        const filter = `${email}`;
        setGeneralFilter(filter);
    }
    
    useEffect(()=>{
        GetSeasons();
    },[])


    return(
        <div className="users-page">
            <div id="marker-app"></div>
            {
            // Drawer for edit user profile
            }
            <Components.DrawerInside mobilefixed={selectedUser !== undefined && selectedUser !== null ? true : false} 
                                        showadd={selectedUser !== undefined && selectedUser !== null ? true : false} 
                                        dOnClose={()=>{setSelectedUser(undefined)}} 
                                        dTitle="Información del usuario" 
                                        dwidth={"50%"}>
                <Components.UsersInfo close={()=>{setSelectedUser(undefined);}} userdata={selectedUser}/>
            </Components.DrawerInside>

            <Components.Users reloadCounter={reloadUsersCounter}
                                          useTableScroll={undefined} 
                                          activeSeason={season}
                                          showDetail={onShowUserDetail} 
                                          isSearching={searchingUsers} 
                                          users={users} 
                                          generalFilter={generalFilter}
                                          focusdata={onFocusFromUsersData}
                                        />
        </div>
    )
}

export default UsersPage;