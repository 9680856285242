import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Communications'

export const EmailsEP : EndPointConfig[] =[
    new EndPointConfig()
    .setMethod('POST')
    .setController(controller)        
    .setActionName("SendEmails"),
    new EndPointConfig()
    .setMethod('POST')
    .setController(controller)        
    .setActionName("SendAndroidNotification")
]


