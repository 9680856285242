import React, { useEffect, useState, useContext } from 'react'
import Athlete, { EmptyAthlete } from '../../models/Athletes';

import { Form, Input, Row, Col, DatePicker, Button, Select, Typography } from 'antd'

import { LoginContext } from '../../hooks/context/userContext'

import moment from "moment";
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';

import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import { validateDNINIE } from '../../utils/ValidationsUtils';

const { Option } = Select;

interface InfoAthleteFormProps{
    onFinish: () => void
    athlete?: Athlete
    onEdited?: (v: boolean) => void
}

const InfoAthleteFormComponent = (props : InfoAthleteFormProps) : JSX.Element => {
    
    const loginContext = useContext(LoginContext);

    const [form] = Form.useForm();

    const [athlete, setAthlete] = useState<Athlete>(EmptyAthlete);

    const restApiClient : RestApiClient = new RestApiClient();

    const onFinish = () => {
        const athleteData = form.getFieldsValue() as Athlete;
                  athleteData.idUser = loginContext.id;
                  athleteData.id = athlete.id;
                  
        const paramsApi : ParamsApi = {
            body: athleteData
        }

        restApiClient.fetch("UpdateAthlete", paramsApi).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                // Limpiamos el formulario
                //form.resetFields();
                
                props.onFinish();

                if (props.onEdited){
                    props.onEdited(true);
                }
                
            }
            
        });
        
        
    }

    useEffect(()=>{
        if (props.athlete){
            props.athlete.birthDate = moment(props.athlete.birthDate);
            setAthlete(props.athlete);
        }
    }, [props.athlete])
    
    useEffect(()=>{
        form.setFieldsValue(athlete);
    }, [athlete])

    return (
        <Form layout="vertical" size="small" onFinish={onFinish} form={form} initialValues={athlete}>
            <Typography.Title level={5}>Introduzca los datos de <u>{athlete.name}</u> </Typography.Title>
            <Row gutter={20}>
                <Col xs={24} md={12} lg={12}>
                    <Form.Item
                        name="name"
                        className="form-item"
                        label="Nombre"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Nombre"/>
                    </Form.Item>
                    <Form.Item
                        name="surname"
                        label="Primer apellido"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Primer apellido"/>
                    </Form.Item>
                    <Form.Item
                        name="secondSurname"
                        label="Segundo apellido"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Segundo apellido"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                    <Form.Item name="birthDate"
                        label="F. Nacimiento"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <DatePicker format="DD/MM/YYYY" locale={locale}  placeholder="Fecha de nacimiento" style={{width: "100%"}} />
                    </Form.Item>
                    <Form.Item
                        name="dniNie"
                        label="DNI/NIE"
                        className="form-item"
                        rules={
                            [
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                       const birthDate = getFieldValue('birthDate');

                                       if (value === undefined && birthDate  !== undefined && moment().diff(birthDate, 'years') >= 14){
                                           return Promise.reject("Obligatorio para mayores de 14 años");
                                        }else if (value !== undefined && birthDate  !== undefined && moment().diff(birthDate, 'years') >= 14 && !validateDNINIE(value)){
                                            return Promise.reject("Documento no válido");
                                        }
                                       
                                       return Promise.resolve();
                                      
                                    },
                                  })
                            ]
                        }
                    >
                        <Input placeholder="DNI"/>
                    </Form.Item>
                    <Form.Item
                        name="relationShip"
                        label="Parentesco"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                        initialValue={props.athlete?.relationShip}
                        
                    >
                        <Select placeholder="Indique relación" >
                            <Option value="myself">Actua en representación propia</Option>
                            <Option value="fmolg">Es padre/madre, tutor/tutora o responsable legal de los alumnos inscritos</Option>
                            <Option value="couple">Es su pareja</Option>
                        </Select>
                    </Form.Item>
                    
                </Col>

                <Typography.Title level={5}>Otros datos sobre <u>{athlete.name}</u> </Typography.Title>

                <Col xs={24}>

                    <Form.Item
                        name="externalUrl"
                        className="form-item"
                    >
                        <Input placeholder="Referencia externa (URL)"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={8}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="btn-save-athlete">
                            Actualizar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default InfoAthleteFormComponent;