import { ClearOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React, { useState } from 'react'
import ApiResponse from '../../../models/api/ApiResponse';
import LoadingOrComponent from '../utils/LoadingOrComponent';


import './ActionButtonsComponent.scss'


interface ActionButtonsComponentProps{
    buttonsSize: 'small' | 'middle' | 'large' | undefined,
    orientation: 'column' | 'row',
    additionalClasses?: string,
    showEdit?: boolean,
    onClickEdit?:()=>void,
    showRemove?: boolean,
    onClickRemove?:(itemId: number)=> Promise<ApiResponse>,
    callBackRemove?: ()=>void,
    especialButtons?:JSX.Element[],
    popConfirmPlacement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom',
    itemId: number
}

const ActionButtonsComponent = (props: ActionButtonsComponentProps) : JSX.Element => {

    const { showEdit, onClickEdit, showRemove, onClickRemove, additionalClasses, especialButtons, callBackRemove, itemId, orientation, popConfirmPlacement, buttonsSize } = props;

    const onEdit = () : void => {
        if (onClickEdit){
            onClickEdit();
        }
    }

    const [removing, setRemoving] = useState<boolean>(false);
    const onRemove = () : void => {
        if (onClickRemove){
            setRemoving(true);
            onClickRemove(itemId).then((r:any)=>{if (callBackRemove) callBackRemove()}).finally(()=>{setRemoving(false)})
        }
    }

    return (<div className="action-buttons-component">
                <div className={`btn-actions ${orientation} ${additionalClasses}`}>
                        {
                            showEdit ? <Button size={buttonsSize} type="primary" onClick={onEdit}><EditOutlined /> Editar</Button> : '' 
                        }

                        {
                            showRemove ? <Popconfirm placement={popConfirmPlacement} onConfirm={()=>{onRemove()}} title="¿Está seguro de que desea eliminar este registro?" okText="Si, deseo borrarlo" okType='danger' cancelText="No, mantener">
                                            <Button size={buttonsSize} danger><LoadingOrComponent loading={removing} showSpinner text={<><ClearOutlined /> Eliminar</>} loadingText="Eliminando..."/></Button>
                                        </Popconfirm> 
                                    : 
                                    '' 
                        }
                        {
                            especialButtons?.map(button => button)
                        }
                    </div>
            </div>)
}

export default ActionButtonsComponent;