import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Config'

export const ConfigEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("UpdateDefaultDriveFolder"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDefaultDriveFolder"),
    new EndPointConfig()
            .setMethod('GET')
        .setController(controller)        
        .setActionName("GetClientConfig"),

    new EndPointConfig()
            .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateMaintenanceStatus"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateInitials"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdatePaymentTypes"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateEmailConfiguration"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateColors"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateImageUrls"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateLegalTextPage"),
]









