import { Typography } from 'antd'
import React from 'react'

import * as Components from '../../components'

import './SeasonsPage.scss'
const SeasonsPage = () : JSX.Element => {


    return(
        <div className='seasons-page'>
            <Components.Seasons />
        </div>
    )
}

export default SeasonsPage;