import { UsersEP } from './UsersEP'
import { TestEP } from './testEP'
import { AthletesEP } from './AthletesEP'
import { EnrollmentsEP } from './EnrollmentsEP'
import { SeasonsEP } from './SeasonsEP'
import { SportCentersEP } from './SportCentersEP'
import { ProductsEP } from './ProductsEP'
import { AlertsEP } from './AlertsEP'
import { AppEP } from './AppEP'
import { CategoriesEP } from './CategoriesEP'
import { PaymentsEP } from './PaymentsEP'
import { DiscountsEP } from './DiscountsEP'
import { ReportsEP } from './ReportsEP'
import { GroupsEP } from './GroupsEP'
import { EmailsEP } from './EmailsEP'
import { StatisticsEP } from './StatisticsEP'
import {DocumentsEP} from './DocumentsEP'
import {ConfigEP} from './ConfigEP'

export const RegisterEndPoints = []
    .concat(UsersEP)
    .concat(ConfigEP)
    .concat(TestEP)
    .concat(AthletesEP)
    .concat(EnrollmentsEP)
    .concat(SeasonsEP)
    .concat(SportCentersEP)
    .concat(ProductsEP)
    .concat(AlertsEP)
    .concat(AppEP)
    .concat(CategoriesEP)
    .concat(DiscountsEP)
    .concat(PaymentsEP)
    .concat(GroupsEP)
    .concat(EmailsEP)
    .concat(StatisticsEP)
    .concat(DocumentsEP)
    .concat(ReportsEP);