import React from 'react'
import { GDrive } from '../../components';


import './DocsPage.scss'

const DocsPage = () : JSX.Element => {

    return (
        <div className='docs-page'>
            <div id="marker-app"></div>
            <GDrive />
        </div>
    )
}

export default DocsPage;