import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'SportCenters'

export const SportCentersEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetSportCenters"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("ChangeSportCenter"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("DeleteSportCenter"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("AddSportCenter"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("UpdateSportCenter"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetSportCentersForTable"),
        
]


