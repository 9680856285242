import React from 'react'

import * as Components from '../../components'

import './OldStudentsPage.scss'

const OldStudentsPage = () : JSX.Element => {

    return(
        <div className="old-students">
            <Components.OldStudents />
        </div>
    )
}

export default OldStudentsPage;