import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Typography, Table, Empty, Divider, Select, Tooltip, Button, Tag, Popconfirm, Collapse, Input, notification } from 'antd'
import parse from 'html-react-parser';
import Enrollment, * as EnrollmentModels from '../../models/Enrollments';
import moment from 'moment';
import 'moment/locale/es' 
import './enrollments.detail.scss'
import PaymentData from '../../models/PaymentData';
import { InfoCircleTwoTone, CheckCircleTwoTone, TransactionOutlined, StopOutlined, EuroCircleOutlined, CoffeeOutlined, LoadingOutlined, CrownOutlined, SafetyCertificateOutlined, FrownOutlined, SkinTwoTone } from '@ant-design/icons';
import ApiResponse from '../../models/api/ApiResponse'
import RestApiClient from '../../api/restApiClient'
import ParamsApi from '../../models/api/ParamsApi';
import { LoginContext } from '../../hooks/context/userContext'
import linq from 'linq';
import * as PaymentUtils from '../../utils/PaymentsUtils'
import SportCenter from '../../models/SportCenter';
import CategoryInfo from '../../models/CategoryInfo';
import EnrollmentFinantialDetailsComponent from './enrollments.finantial.details';
import Meta from '../../models/Meta';

const { Title } = Typography;
const { Option } = Select;
const { Paragraph } = Typography;
const { Panel } = Collapse;

interface EnrollmentDetailProps{
    data?: Enrollment,
    little?:boolean
}

const EnrollmentDetailComponent = (props : EnrollmentDetailProps) : JSX.Element => {
    let loginContext = useContext(LoginContext);
    
    const restApiClient : RestApiClient = new RestApiClient();

    const [changeSizeLoading, setChangeSizeLoading] = useState<boolean>(false);
    const [enrollment, setEnrollment] = useState<Enrollment>(EnrollmentModels.EmptyEnrollment);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [currentStepStatus, setCurrentStepStatus] = useState<"wait" | "process" | "finish" | "error" | undefined>('wait')
    const [refundedData, setRefundedData] = useState<PaymentData[]>([]);
    const [actualSize, setActualSize] = useState<string>("");
    const [actualSportCenter, setActualSportCenter] = useState<number>(0);
    const [cancelOnProcess, setCancelOnProcess] = useState<boolean>(false);
    const [freeOnProcess, setFreeOnProcess] = useState<boolean>(false);
    const [stripeSubscriptionData, setStripeSubscriptionData] = useState<any>();
    const [loadingStripeData, setLoadingStripeData] = useState<boolean>(false);
    const [cancellingPayments, setCancellingPayments] = useState<boolean>(false);
    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);
    const [categories, setCategories] = useState<CategoryInfo[]>([]);

    const [actualCategoryId, setActualCategoryId] = useState<number>(0);

        

    const [chargeIdRefunded, setChargeIdRefunded] = useState<string>('');
    

    const [returningAmount, setReturningAmount] = useState<boolean>(false);


    const changeCategory = (newId: number) => {
        
        const params : ParamsApi = {
            body:{
                categoryId: newId,
                enrollmentId: enrollment.id
            }
        }

        restApiClient.fetch("ChangeEnrollmentCategory", params)
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setActualCategoryId(newId);
                    }
                })
    }

    const getCategories = () : void => {
        restApiClient.fetch("GetCategories", {})
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    setCategories(r.data);
                }
            })
    }

    const getSportCenters = () : void => {
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                })
    }

    const cancelPayments = (enrollmentId : number) : void => {

        var params : ParamsApi = {
            query:{
                enrollmentId: enrollmentId
            }
        }
        
        setCancellingPayments(true);
        restApiClient.fetch("NoMoreCharges", params).then((r :ApiResponse | null) => {
        }).finally(()=>{
            setCancellingPayments(false);
        });
    }

    const refundAmount = (paymentId: number, chargeId : string) : void => {
        setReturningAmount(true);
        PaymentUtils.RefundPayments(paymentId, chargeId).then((r: any)=>{
            setChargeIdRefunded(chargeId);
        }).finally(()=>{
            setReturningAmount(false);
        })
    }

    const paymentsColumns : any[] = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            render: (value: any) => { 
                let payDate = moment(value, "DD/MM/YYYY HH:mm", true).format("DD/MM/YYYY HH:mm");

                if (payDate.toString() === "Fecha inválida"){
                    payDate = moment(value).format("DD/MM/YYYY HH:mm");
                }
                
                return payDate.toString()
            }
        },
        {
            title: 'Concepto',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Cantidad',
            dataIndex: 'amount',
            key: 'amount',
            render: (value: any, row: PaymentData) => {
                let result : JSX.Element = <></>;
                const amount = value / 100;

                const meta = row.paymentMeta.find(m => m.key === 'gateway_charge_id');
                const refunded = row.paymentMeta.find(m => m.key.indexOf("refund-") !== -1);
                if (!refunded && row.status !== "" && row.status !== "error" && meta && meta.value && meta.value.length > 0 && meta.value !== chargeIdRefunded){
                    result = <Popconfirm placement="topLeft" title={"Confirmar devolución"} onConfirm={()=>{refundAmount(row.id, meta.value)}} okText="Si, devolver" cancelText="Cancelar">
                                <Button disabled={returningAmount} className="button-action refund" 
                                        size="small" 
                                        type="primary" 
                                        danger>
                                            <span><TransactionOutlined style={{display: "inline"}}/> Devolver {`${amount}€`} </span>
                                </Button>
                            </Popconfirm>

                    
                }else if(refunded){
                    const refundDate = row.paymentMeta.find(m => m.key.indexOf(refunded?.key.replace("refund-", "")) != -1);
                    result = <>{amount}€ <small style={{display:"block"}}>Devuelto el <strong>{refundDate?.value}</strong></small></>
                }else{
                    result = <>{amount}€</>
                }
                
                return result;

            }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (value: 'payed' | 'error' | 'refund', record: PaymentData) => {
                let result : any = value;
                switch(value){
                    case 'error':
                        if (record.productInfo && record.productInfo.type === "Subscription"){
                            result = <Tooltip placement='topLeft' title='En el caso de suscripciones el cobro volverá a intentar pasarse proximamente. Si se sigue devolviendo la cuota quedaría pendiente de pago.'><InfoCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>
                        }else{
                            result = <Tooltip placement='topLeft' title='Se produjo un error durante el cobro.'><InfoCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>
                        }
                        break;
                    case "refund":
                        result = <Tooltip placement='topLeft' title='Devolución realizada sobre el importe total de la inscripción que consulta.'><InfoCircleTwoTone twoToneColor="#FFC107" /></Tooltip>
                        break;
                    case 'payed':
                        result = <Tooltip placement='topLeft' title='Pago procesado correctamente.'><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip>;
                        break;
                    default:
                        break;
                }

                return result;
            }
        }
    ]

    const [payed, setPayed] = useState<number>(0);

    const setResume = () => {
        let _payed = 0;
        let _pending = 0;
        let _quotes = 0;

        if (enrollment.paymentData !== null && enrollment.paymentData !== undefined && enrollment.paymentData.length > 0){
            enrollment.paymentData.forEach((payment : PaymentData) => {
                _payed += payment.amount;
            });
        }

        if (enrollment.productInfo !== undefined && enrollment.productInfo !== null){
            switch(enrollment.productInfo.type){
                case "OnePay":
                    _pending = 0;
                    _quotes = 0;
                    break;
                case "Subscription":
                    const totalAmount = (enrollment.productInfo.maxQuotes * (enrollment.productInfo.pvp / 100)) + enrollment.productInfo.initialPay;
                    _pending = totalAmount - _payed;
                    _quotes = enrollment.productInfo.maxQuotes;
                    break;
                case "PayPerUse":
                    _quotes = 0;
                    break;
            }
        }

        

        setPayed(_payed / 100);
    }

    const getRefundedData = () => {
        const rows: PaymentData[] | undefined = enrollment.paymentData;
        
        const refundedData : PaymentData[] = [];
        rows?.forEach(row => {
            let refundedAmounts = row.paymentMeta.filter(pm => {
                return pm.key.indexOf("refund") !== -1;
            });
            
            refundedAmounts.forEach(ra => {
                const refundId = ra.key.split("-")[1];
                const refundMetaDate = row.paymentMeta.find(pm => pm.key === refundId+"-id")?.value;
                let refundData : PaymentData = {
                    id: 0,
                    productInfo: undefined,
                    amount: Number.parseInt(ra.value),
                    month: "00",
                    date: refundMetaDate,
                    description: "Devolución de cantidad",
                    gateway: "",
                    paymentMeta: [],
                    status: "refund"
                }
    
                refundedData.push(refundData);
    
            });
        });
        setRefundedData(refundedData);
    }

    const changeSportCenter = (sportCenter: number) => {
        if (sportCenter == 0){
            notification.warn({
                message: 'Atención',
                description: "Debe asignar un centro",
              })
        }else{
            const params : ParamsApi = {
                body:{
                    enrollmentId: enrollment.id,
                    newSportCenterId: sportCenter
                }
            };
    
            restApiClient.fetch("ChangeSportCenter", params).then((r :ApiResponse | null) => {
                if (r?.code === 200){
                    setActualSportCenter(sportCenter);
                }
            }).finally(()=>{
            });
        }

    }

    const changeSize = (size: any) => {

        if (size === ""){
            notification.warn({
                message: 'Atención',
                description: "Debe asignar una talla",
              })
        }else{
            const params : ParamsApi = {
                body:{
                    sessionId: sessionStorage.getItem("token"),
                    enrollmentId: enrollment.id,
                    newSize: size
                }
            };
    
            setChangeSizeLoading(true);
            restApiClient.fetch("ChangeEnrollmentClothingSize", params).then((r :ApiResponse | null) => {
                if (r?.code === 200){
                    setActualSize(size);
                }
            }).finally(()=>{
                setChangeSizeLoading(false);
            });
        }
        
        
    }

    const onGetStripeEnrollData = () => {
        const subscriptionId : string | undefined = linq.from(enrollment.meta).firstOrDefault(m => m.key === "stripe_subscription_id")?.value;

        if (subscriptionId){
            const params : ParamsApi = {
                path:{
                    subscriptionId : subscriptionId
                }
            };
    
            setLoadingStripeData(true);
            restApiClient.fetch("GetStripeSubscriptionData", params).then((r: ApiResponse | null)=>{
                if (r != null && r.code == 200){
                    setStripeSubscriptionData(r.data);
                }
            }).finally(()=>{
                setLoadingStripeData(false);
            });
        }else{
            setLoadingStripeData(false);
            setStripeSubscriptionData(null);
        }
       
    }

    const onCancelEnrollment = (enrollmentId : number) => {
        const params : ParamsApi = {
            query:{
                enrollmentId : enrollmentId
            }
        };

        setCancelOnProcess(true);
        restApiClient.fetch("CancelEnrollmentAdmin", params).finally(()=>{
            setCancelOnProcess(false);
        });
    }

    const onFreeEnrollment = (enrollmentId : number) => {
        const params : ParamsApi = {
            path:{
                enrollmentId: enrollmentId,
                idFromUserThatMakeTheEnrollFree: loginContext.id
            }
        };

        setFreeOnProcess(true);
        restApiClient.fetch("SetFreeEnroll", params).finally(()=>{
            setFreeOnProcess(false);
        });
    }

    useEffect(()=>{
        setResume();
        onGetStripeEnrollData();
        getRefundedData();
        if (enrollment.size){
            setActualSize(enrollment.size);
        }
        if (enrollment.categoryId){
            setActualCategoryId(enrollment.categoryId);
        }
        if (enrollment.idSportCenter){
            setActualSportCenter(enrollment.idSportCenter);
        }
    },[enrollment])

    useEffect(()=>{
        if (props.data){
            setEnrollment(props.data);

            switch(props.data.status){
                case 'active':
                    setCurrentStep(1);
                    setCurrentStepStatus('process');
                    break;
                case 'cancelled':
                    setCurrentStep(1);
                    setCurrentStepStatus('error');
                    break;
                case 'finished':
                    setCurrentStep(2);
                    setCurrentStepStatus('finish');
                    break;
                case 'pre':
                    setCurrentStepStatus('wait');
                    setCurrentStep(0);
                    break;
            }
        }
    }, [props.data])

    useEffect(()=>{
        getSportCenters();
        getCategories();
    },[]);

    const getHeaderStripe = (stripeSubscriptionStatus: "trialing" | "canceled"): JSX.Element => {
        let result : JSX.Element = <></>;

        switch(stripeSubscriptionStatus){
            case "trialing":
                result = <>
                    Datos stripe&nbsp;&nbsp;
                    <CrownOutlined className="stripe trialing" /> 
                    <small className="stripe trialing-leyend">
                        En periodo de prueba hasta el <strong>{moment(stripeSubscriptionData.trialEnd).format("DD/MM/YYYY")}</strong></small> 
                </>
                break;
            case "canceled":

                let message = <>Tras el periodo pagado la inscripción se cancelará el <strong>{moment(stripeSubscriptionData.currentPeriodEnd).format("DD/MM/YYYY")}</strong></>

                if (moment().isAfter(stripeSubscriptionData.currentPeriodEnd)){
                    message = <>El periodo de pago finalizó correctamente el <strong>{moment(stripeSubscriptionData.currentPeriodEnd).format("DD/MM/YYYY")}</strong></>
                }

                if (enrollment.status == "finished"){
                    // La inscripción finalizó correctamente
                    result = <>
                    Datos stripe&nbsp;&nbsp;
                    <CrownOutlined className="stripe trialing" /> 
                    <small className="stripe trialing-leyend">
                        {message}
                    </small> 
                </>
                }else{
                    result = <>
                    Datos stripe&nbsp;&nbsp;
                    <FrownOutlined className="stripe canceled"/>
                    <small className="stripe trialing-leyend">
                        {message}
                    </small> 
                </>
                }
                
                break;
            default:
                result = <>
                    Datos stripe&nbsp;&nbsp;
                    <SafetyCertificateOutlined className="stripe active"/> 
                </>
                break;
        }
            
        return result;
    }

    return (
        <Row gutter={16} className="subscription-payment-data">
            <Col xs={24} className="detail-info-box">
                <Title level={5} className="title">
                    Resumen
                </Title>
                <div className="payment-list-container">
                    <p className="resume">
                        Inscripción <strong>INS-{enrollment.id}</strong> de <strong>{enrollment.athlete.name} {enrollment.athlete.surname} {enrollment.athlete.secondSurname}</strong>&nbsp; 
                        realizada el <strong>{moment(enrollment.enrollDate).format("DD/MM/YYYY")}</strong> 
                            {enrollment.status === 'active' || enrollment.status === 'finished' || enrollment.status === 'cancelled' ? parse(`, activada el <strong>${enrollment.meta.find(m => m.key === 'activated_at')?.value}</strong>&nbsp`) : ''}
                            {enrollment.status === 'finished' ? parse(`, finalizada el <strong>${enrollment.meta.find(m => m.key === 'finished_at')?.value},</strong>&nbsp`) : ''}
                            {enrollment.status === 'cancelled' ? parse(`, cancelada el <strong>${enrollment.meta.find(m => m.key === 'cancel_date')?.value},</strong>&nbsp`) : ''}
                        para la temporada <strong>{enrollment.season}</strong> en la categoria <strong>{enrollment.category}</strong> del <strong>{enrollment.location}</strong>.
                    </p>
                    <p>
                        {(enrollment.status === 'active' && enrollment.meta.some((m: Meta)=>{ return m.key === "automatic_cancelled" && m.value === "1"})) ? parse(`Esta inscripción finalizó los pagos de manera automática el <strong>${enrollment.meta.find(m => m.key === 'cancel_date')?.value}.</strong>&nbsp`) : ''}

                    </p>
                    {
                        loadingStripeData ? <LoadingOutlined /> : ''
                    }
                    {
                        stripeSubscriptionData && !loadingStripeData?
                        <p>
                            <Divider />
                            <Collapse>
                                <Panel header={getHeaderStripe(stripeSubscriptionData.status)} key="1">
                                    <strong>ID</strong>: <Paragraph style={{display:"inline-block", marginBottom: "0"}} copyable={{ tooltips: false }}>{stripeSubscriptionData.id}</Paragraph><br/>
                                    <strong>ID Cliente</strong>: <Paragraph style={{display:"inline-block", marginBottom: "0"}} copyable={{ tooltips: false }}>{stripeSubscriptionData.customerId}</Paragraph><br/>
                                    {
                                    stripeSubscriptionData.status === "canceled" ? 
                                                ''
                                                : 
                                                <><strong>Fecha de próximo cargo</strong>: {moment(stripeSubscriptionData.billingCycleAnchor).format("DD/MM/YYYY")}<br/></>
                                    }
                                        
                                    <strong>Periodo actual</strong>: Del {moment(stripeSubscriptionData.currentPeriodStart).format("DD/MM/YYYY")}&nbsp;
                                    al {moment(stripeSubscriptionData.currentPeriodEnd).format("DD/MM/YYYY")}
                                </Panel>
                            </Collapse>
                            
                            
                            <Divider />
                        </p>
                        :
                        ''
                    }
                    
                </div>
            </Col>
            <Col xs={24} className="detail-info-box-header">
                    <Title level={5} className="title">
                        Acciones principales
                    </Title>
                    <Divider />
                    <Row gutter={16}>
                        <Col xs={24}>
                            <Title level={5} className="sub-title">
                                Sobre la pagos
                            </Title>
                        </Col>
                        <Col xs={24} md={12}>
                            <Button disabled={!["active"].includes(enrollment.status) || enrollment.productInfo.type === "OnePay" || cancellingPayments || (enrollment.status === 'active' && enrollment.meta.some((m: Meta)=>{ return m.key === "automatic_cancelled" && m.value === "1"}))} 
                                    className="button-action cancel-payments" 
                                    size="middle" 
                                    type="primary" 
                                    onClick={()=>{cancelPayments(enrollment.id)}}
                                    danger>
                                {
                                    cancellingPayments ? 
                                    <LoadingOutlined />
                                    :
                                    <EuroCircleOutlined />
                                }
                                Cancelar próximos pagos
                            </Button>
                        </Col>
                        <Divider />
                        <Col xs={24}>
                            <Title level={5} className="sub-title">
                                Sobre la estados
                            </Title>
                        </Col>
                        <Col xs={24} md={12}>
                            <Button disabled={["cancelled", "finished", "expired"].includes(enrollment.status) || (enrollment.status === 'active' && enrollment.meta.some((m: Meta)=>{ return m.key === "automatic_cancelled" && m.value === "1"}))} 
                                    className="button-action cancel" 
                                    onClick={()=>{onCancelEnrollment(enrollment.id)}}
                                    size="middle" 
                                    type="primary" 
                                    danger>
                                {
                                    cancelOnProcess ? <LoadingOutlined /> : <StopOutlined />
                                }
                                Cancelar inscripción
                            </Button>
                        </Col>
                        <Col xs={24} md={12}>
                            <Button disabled={enrollment.status !== 'pre' } 
                                    className="button-action free" 
                                    onClick={()=>{ onFreeEnrollment(enrollment.id); }}
                                    size="middle" 
                                    type="primary">

                                {
                                    freeOnProcess ? <LoadingOutlined /> : <CoffeeOutlined />
                                }
                                
                                Establecer como gratuita
                            </Button>
                        </Col>
                        <Divider />
                        
                        <Col xs={24} sm={24} md={12}>
                            <span className="actions-title">
                                Cambiar centro designado
                            </span>
                            <Select className="select-sport-center"
                                    disabled={enrollment.isDeliveredMaterial || enrollment.status === "cancelled"} 
                                    placeholder={sportCenters?.length > 0 && actualSportCenter !== 0 ? sportCenters.find(sc => sc.id === actualSportCenter)?.description : "Seleccione un centro"}
                                    onChange={changeSportCenter}> 
                                    {
                                        sportCenters.map((sc : SportCenter, index: number) => {
                                            return <Option key={index} value={sc.id}>{sc.description}</Option>
                                        })
                                    }
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <span className="actions-title">
                                Cambiar categoria
                            </span>
                            <Select className="select-size"
                                    style={{width:"100%"}}
                                    disabled={enrollment.status === "cancelled"} 
                                    value={actualCategoryId} 
                                    onChange={changeCategory}> 
                                    {
                                        categories.map((category: CategoryInfo, index: number)=>{
                                            return <Option key={index} value={category.id}>{category.description}</Option>
                                        })
                                    }
                                </Select>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <br/><span className="actions-title">
                                Cambiar talla
                            </span>
                            <Input.Group compact className="select-change-size-group">
                            <Select className="select-size"
                                    disabled={enrollment.status === "cancelled"} 
                                    placeholder="Indique una talla" 
                                    value={actualSize} 
                                    onChange={changeSize}> 
                                    <Option value="">Sin asignar</Option>
                                    <Option value="6">6</Option>
                                    <Option value="8">8</Option>
                                    <Option value="10">10</Option>
                                    <Option value="12">12</Option>
                                    <Option value="XS">XS</Option>
                                    <Option value="S">S</Option>
                                    <Option value="M">M</Option>
                                    <Option value="L">L</Option>
                                    <Option value="XL">XL</Option>
                                    <Option value="XXL">XXL</Option>
                                    <Option value="XXXL">XXXL</Option>
                                    <Option value="XXXXL">XXXXL</Option>
                                    <Option value="XXXXXL">XXXXXL</Option>
                                </Select>
                                <div>
                                    <SkinTwoTone className="skin-select" twoToneColor={'red'}/> 
                                </div>
                            </Input.Group>
                            
                        </Col>
                        <Col xs={24}>
                            <br/>
                            <Collapse>
                                <Panel header="Leyenda estados" key="1">
                                    <ul className="leyend">
                                        <li><Tag className="enrollment-status-tag" color="volcano">Pre-inscripción</Tag>: Estado inicial, previo al primer pago.</li>
                                        <li><Tag className="enrollment-status-tag" color="green">Gratuita</Tag>: Inscripción sin coste.</li>
                                        <li><Tag className="enrollment-status-tag" color="green">Activa</Tag>: Tras realizar el primer pago y antes del fin de temporada.</li>
                                        <li><Tag className="enrollment-status-tag" color="purple">Caducada</Tag>: Al mes de estar en PRE el sistema la pondrá como caducada.</li>
                                        <li><Tag className="enrollment-status-tag" color="magenta">Cancelada</Tag>: Cuando de manera manual un administrador o usuario realiza la cancelación de la inscripción.</li>
                                        <li><Tag className="enrollment-status-tag" color="cyan">Finalizada</Tag>: Cuando finaliza la temporada la inscripción pasa de estado activa a finalizada.</li>
                                    </ul>
                                </Panel>
                            </Collapse>
                        </Col>
                        
                    </Row>
                    
            </Col>
            
            {
                (!props.little || props.little == undefined) && enrollment && enrollment.productInfo && enrollment.productInfo?.type === "Subscription" 
                ?
                <Col xs={24} className="detail-info-box">
                    <div className="payment-list-container">
                        <Collapse>
                            <Panel header="Plan de pagos" key="1">
                                <EnrollmentFinantialDetailsComponent enrollment={enrollment}/>
                            </Panel>
                        </Collapse>
                    </div>
                </Col>
                :
                ''
            }
            

            {
                (!props.little || props.little == undefined) ?
                    <>
                        <Col xs={24} className="detail-info-box">
                            <div className="payment-list-container">
                                <Collapse defaultActiveKey={[1]}>
                                    <Panel header="Histórico de pagos" key="1">
                                        {
                                            enrollment.paymentData && enrollment.paymentData.length > 0 ?
                                            <Table 
                                                columns={paymentsColumns} 
                                                dataSource={enrollment.paymentData} 
                                                scroll={{ x: 360 }} 
                                                footer={() => <small><b>ATENCIÓN: </b>Algunos pagos pueden tardar unos minutos en aparecer.</small>}
                                            />
                                            :
                                            <Empty description="No ha realizado pagos aún"/>
                                        }
                                    </Panel>
                                </Collapse>
                            </div>
                        </Col>

                        <Col xs={24} className="detail-info-box">
                                <Collapse>
                                    <Panel header="Histórico de devoluciones" key="1">
                                    {
                                    refundedData && refundedData.length > 0 ?
                                    <Table 
                                        columns={paymentsColumns} 
                                        dataSource={refundedData} 
                                        scroll={{ x: 360 }}
                                        footer={() => <small><b>ATENCIÓN: </b>Algunas devoluciones pueden tardar unos minutos en aparecer.</small>}
                                    />
                                    :
                                    <Empty description="No tiene devoluciones aún."/>
                                }
                                    </Panel>
                                </Collapse>
                        </Col>
                    </>
                    :
                    <></>
            }
            
        </Row>
    )
}

export default EnrollmentDetailComponent;