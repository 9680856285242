import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Documents'

export const DocumentsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("ShareDocument"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setActionName('UnshareDocument'),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setActionName('UnshareDocumentByGoogleDriveId'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetMyDocuments'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetSharedDocuments'),

        
]


