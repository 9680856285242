import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Enrollments'

export const EnrollmentsEP : EndPointConfig[] =[
    
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)    
        .setPattern("{seasonId}/{criteria}")    
        .setActionName("GetEnrollments"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("ChangeMaterialStatus"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setActionName("CancelEnrollmentAdmin"),
    new EndPointConfig()
        .setMethod('GET')
        .setPattern("{enrollmentId}/{idFromUserThatMakeTheEnrollFree}")
        .setController(controller)
        .setActionName("SetFreeEnroll"),
    new EndPointConfig()
        .setMethod('GET')
        .setPattern("{subscriptionId}")
        .setController(controller)
        .setActionName("GetStripeSubscriptionData"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("ChangeEnrollmentClothingSize"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("NoMoreCharges"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("SetEnrollActive"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setIsFile(true)
        .setActionName("GetEnrollmentsExcel")

        
]