import { Avatar, Tag, Tooltip } from 'antd';
import React from 'react';
import User from '../models/Users';

export const getTagRol = (text: string, item: User) : JSX.Element => {
    let result : JSX.Element = <></>

          switch(item.idRol){
            case "admin":
              result = <Tag className="user-type-tag" color="magenta">
                          Administrador
                       </Tag>;
              break;
            case "trainer":
              result = <Tag className="user-type-tag" color="gold">
                          Profesor/Monitor
                       </Tag>;
              break;
            case "customer":
              result = <Tag className="user-type-tag" color="blue">
                          Usuario
                       </Tag>;
              break;
            default:
              result = <>{text}</>;
              break;
          }

          return result;
  }

export     const getLoginUserInfo = (item: User) : JSX.Element => {
    let classIsLoged = item.isLoged ? "loged" : "loged not";
          let titleToolTip : string = item.isLoged ? "El usuario ha iniciado sesión" : "Usuario desconectado";

          if (item.banned){
            classIsLoged = 'loged banned'
            titleToolTip = 'Usuario baneado'
          }

          return <Tooltip title={titleToolTip}><div className={classIsLoged}></div></Tooltip>
  }



  


export  const getInitialsBox = (name: string, surname: string) : JSX.Element => {

  //Creamos un array de colores que contiene tantos elementos como letras tiene el abecedario
  const colors : string[] = [
    "#f44336", 
    "#e91e63", 
    "#9c27b0", 
    "#673ab7", 
    "#3f51b5", 
    "#2196f3", 
    "#03a9f4", 
    "#00bcd4", 
    "#009688", 
    "#4caf50", 
    "#8bc34a", 
    "#cddc39", 
    "#ffeb3b", 
    "#ffc107", 
    "#ff9800", 
    "#ff5722", 
    "#795548", 
    "#9e9e9e", 
    "#607d8b",
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4"
  ];

  //Transformamos la primera letra del nombre en un número que corresponderá con su posición en el abecedario.
  //De esta forma, si el nombre es "Antonio", el resultado será 0, que corresponde con la letra "A" en el abecedario.
  const indexName : number = name[0].toUpperCase().charCodeAt(0) - 65;
  //De forma similar, obtenemos el índice de la primera letra del apellido.
  const indexSurname : number = surname[0].toUpperCase().charCodeAt(0) - 65;
  //Sumamos ambos índices y obtenemos un número entre 0 y 25.
  //Este número nos servirá para seleccionar un color aleatorio del array de colores.
  const indexColor : number = (indexName + indexSurname) % colors.length;

  const randomColor : string = colors[indexColor];
  return <Avatar shape="square" size={25} icon={
            <> 
              <div className="initials-box" style={{backgroundColor: randomColor}}>
                    <span>{name[0].toUpperCase()}{surname[0].toUpperCase()}</span>
                  </div>
            </>
        } />
}

