import React, { useContext, useEffect } from 'react'
import { Row, Col } from 'antd'

import * as Components from '../../../components/index'

import { AppCfgContext } from '../../../hooks/context/appConfigContext'


import './register.scss'

interface RegisterProps {}

const RegisterPage = (props : RegisterProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

    useEffect(()=>{
        console.log("appConfigContext", appConfigContext)
    }, [appConfigContext])

    return (
        <Row className="register" justify="center" align="middle" style={{backgroundImage:`url(${appConfigContext.cfg.urlsMainImg})`}}>
            <Components.Cookies /> 
            <Col className="container" xs={24} sm={24} md={15} lg={12} xl={9} xxl={6}>
                <Row className="container-row">
                    <Col xs={24} className="form">
                    <div className="circle-title" style={{backgroundImage: `url(${appConfigContext.cfg.urlsShieldImg})`}}>
                            <span>
                            </span>
                        </div>
                        <Components.Register />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default RegisterPage;