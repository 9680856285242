import { BankOutlined, ClearOutlined, CloseOutlined, CreditCardOutlined, DownOutlined, EuroCircleOutlined, LoadingOutlined, RetweetOutlined, RightOutlined, RollbackOutlined, SendOutlined, TransactionOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Popconfirm, Tag, Typography} from 'antd';
import moment from 'moment';
import React, { useState } from 'react'
import { Box } from '..';

import PaymentData from '../../models/PaymentData';
import { CARD, SEPA_DEBIT } from '../../utils/Constants';
import { CheckPaymentStatus, DeletePayment, GetPaymentData, NotifyErrorInPayment, RefundPayments, RetryPayment } from '../../utils/PaymentsUtils';



import './UserResumeComponent.scss'


interface PaymentInfoProps{
    item: PaymentData,
    onChangeData: () => void
}
const PaymetInfoComponent = (props: PaymentInfoProps) : JSX.Element => {

    const [item, setItem] = useState<PaymentData>(props.item);

    const amount = item?.amount / 100;
    const refunded = item?.paymentMeta?.find(m => m.key.indexOf("refund-") !== -1);
    const amountClass = item?.status === "error" ?  "error" : (refunded ? 'refunded' : 'payed')

    var gatewayCommission  = item?.paymentMeta?.find(m => m.key == "gateway_fees");
    var commission = gatewayCommission && gatewayCommission.value && !isNaN(Number.parseInt(gatewayCommission.value)) ? `${((Number.parseInt(gatewayCommission.value) / 100))}€` : "";
    
    var paymentMethodUsed = item?.paymentMeta?.find(m => m.key == "gateway_payment_method_used");
    var las4Digits = item?.paymentMeta?.find(m => m.key == "gateway_payment_method_used_last4");
    var last4 = <></>
    if (las4Digits && las4Digits.value){
        last4 = <>****{las4Digits.value}</>
    }
    var icon = <EuroCircleOutlined className="card-icon free"/>
    if (paymentMethodUsed && paymentMethodUsed.value){
        switch(paymentMethodUsed?.value?.toLowerCase()){
        case SEPA_DEBIT.toLowerCase():
            icon = <BankOutlined className="bank-icon"/>;
            break;
        case CARD.toLowerCase():
            icon = <CreditCardOutlined className="card-icon"/>;
            break;
        }
    }

    if (item?.status == "refund"){
        icon = <RollbackOutlined className="refunded-icon"/>
    }else if (item?.status == "error"){
        icon = <CloseOutlined className="error-icon"/>
    }

    const [showActions, setShowActions] = useState<boolean>(false);

    const [refundingPayment, setRefundingPayment] = useState<boolean>(false);
    const refundPayment = () : void => {
        if(item && item?.paymentMeta?.find(m => m.key === 'gateway_charge_id')?.value){
            setRefundingPayment(true);
            RefundPayments(item?.id, item?.paymentMeta?.find(m => m.key === 'gateway_charge_id')?.value!, ()=>{
                setRefundingPayment(false);
                getPaymentData();
            });
        }
    }


    const [removingPayment, setRemovingPayment] = useState<boolean>(false);
    const removePayment = () : void => {
        if (item){
            setRemovingPayment(true);
            DeletePayment(item?.id, ()=>{
                setRemovingPayment(false);
                props.onChangeData();
            });
        }
    }
    const [retryingPayment, setRetryingPayment] = useState<boolean>(false);
    const retryPayment = () : void => {
        if(item){
            setRetryingPayment(true);
            RetryPayment(item?.id, ()=>{
                setRetryingPayment(false);
                getPaymentData();
            });
        }
    }
    const [notifyingError, setNotifyingError] = useState<boolean>(false);
    const notifyError = () : void => {
        if (item){
            setNotifyingError(true);
            NotifyErrorInPayment(item?.id, ()=>{
                setNotifyingError(false);
                getPaymentData();
            });
        }
    }

    const [checkingPaymentStatus, setCheckingPaymentStatus] = useState<boolean>(false);
    const checkPaymentStatus = () : void => {
        if (item){
            setCheckingPaymentStatus(true);
            CheckPaymentStatus(item?.id, (response: any)=>{
                setCheckingPaymentStatus(false);
                var it = item;
                it.status = response.data;
                setItem(it);
                setShowActions(false);
            })
            
        }
        
    }

    const [gettingPaymentData, setGettingPaymentData] = useState<boolean>(false);
    const getPaymentData = () : void => {
        if (item){
            setGettingPaymentData(true);
            GetPaymentData(item?.id, (response: any)=>{
                setGettingPaymentData(false);
                setItem(response.data);
                setShowActions(false);
            })
            
        }
        
    }



    return (<Box type='ultra-small' noBorders> 
                <div className="user-card">
                    <div className="header-card" onClick={()=>{setShowActions(!showActions)}}>
                        <span className="payment-info">
                            <div className="icon">
                                {
                                    item.status == "refund" || item.amount == 0 ? <div className="additional-info-arrow"></div>
                                    :
                                    (showActions ? <DownOutlined disabled className="additional-info-arrow"/> : <RightOutlined className="additional-info-arrow"/>)
                                } 
                                
                                {icon} 
                            </div>
                            <div className="additional-info">
                                <span> 
                                    <strong>{amount}€</strong> • {item?.description} • {moment(item?.date).format("[Cargado el] DD [de] MMMM [de] YYYY")}
                                    {item?.status == "processing" ? <Tag style={{marginLeft: "5px"}} color="purple">Operación en proceso</Tag> : <></>}
                                </span>
                                {
                                    refunded ? 
                                    <small style={{display:"block"}}>
                                        Devuelto el {moment(item?.paymentMeta?.find(m => m.key.indexOf("updated_at") !== -1)?.value).format("DD/MM/YYYY")}
                                    </small>
                                    :
                                    <></>
                                }
                                <small style={{display:"block"}}>
                                    {item?.productInfo?.name} • INS-{item?.enrollmentId} • PAG-{item?.id} • Comisión pasarela: {commission.length > 0 ? `${commission}` : 'N/D'} • <strong>ID Pasarela: {item?.paymentMeta?.find(m => m.key === 'gateway_charge_id')?.value || 'N/D'}</strong>
                                </small>
                            </div>
                            
                        </span>
                    </div>
                    {
                        showActions && item.status != "refund" && item.amount > 0?
                            <div className="actions-card">
                                <Divider orientation="left">
                                    Acciones
                                </Divider>
                                {
                                    item?.status == "processing" 
                                    ? 
                                        <Alert type='warning' showIcon message={
                                            <>
                                                <p>Este pago se encuentra en estado 'Procesando' posiblemente por una operación que la pasarela aún no ha terminado. Vuelva a consultar el estado del pago en unos minutos.</p>                                            
                                                <Button onClick={getPaymentData}>
                                                    {checkingPaymentStatus ? <LoadingOutlined /> : <></>}&nbsp;Consultar estado del pago
                                                </Button>
                                            </>
                                        }></Alert> 
                                    : <></>
                                }
                                {
                                    item?.status == "payed" && item?.amount > 0
                                    ?
                                        <Popconfirm placement="topLeft" title={"Confirmar devolución"} onConfirm={refundPayment} okText="Si, devolver" cancelText="Cancelar">
                                            <Button className={`btn-action refund`} size='small'> {refundingPayment ? <LoadingOutlined /> : <RollbackOutlined style={{display: "inline"}}/> } Devolver</Button> 
                                        </Popconfirm> 
                                    : <></>
                                }
                                {
                                    item?.status == "error" 
                                    ? 
                                        <Popconfirm placement="topLeft" title={"Confirmar eliminado"} onConfirm={removePayment} okText="Si, eliminar" cancelText="Cancelar">
                                            <Button className={`btn-action`} size='small' danger> {removingPayment ? <LoadingOutlined /> : <ClearOutlined  style={{display: "inline"}}/>} Eliminar</Button>  
                                        </Popconfirm> 
                                        
                                    : <></>
                                }
                                {
                                    item?.status == "error" 
                                    ? 
                                        <Popconfirm placement="topLeft" title={"Confirmar nuevo intento"} onConfirm={retryPayment} okText="Si, intentar" cancelText="Cancelar">
                                            <Button className={`btn-action retry`} size='small' type='primary'> {retryingPayment ? <LoadingOutlined /> : <RetweetOutlined  style={{display: "inline"}}/>} Intentar cobrar</Button> 
                                        </Popconfirm> 
                                    : <></>
                                }
                                {
                                    item?.status == "error" 
                                    ? 
                                        <Popconfirm placement="topLeft" title={"Notificar error en cargo"} onConfirm={notifyError} okText="Si, notificar" cancelText="Cancelar">
                                            <Button className={`btn-action notify`} size='small' type='primary'> {notifyingError ? <LoadingOutlined /> : <SendOutlined  style={{display: "inline"}}/> }Notificar</Button> 
                                        </Popconfirm>
                                        
                                    : <></>
                                }
                                
                            </div>
                        :
                            <></>
                    }
                    
                </div>
            </Box>)
}

export default PaymetInfoComponent;