import React, { useEffect, useState } from 'react'


import './CloseSeasonsComponent.scss'
import { Button, Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Season from '../../../models/Season';
import LoadingOrComponent from '../../core/utils/LoadingOrComponent';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import moment from 'moment';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

interface CloseSeasonsProps{}

const CloseSeasonsComponent = (closeSeasonComponentProp : CloseSeasonsProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [checked, setChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [activeSeason, setActiveSeason] = useState<Season>();

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    const getActiveSeasonData = () => {
        setLoading(true);
        restApiClient.fetch("GetActiveSeason", {})
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setActiveSeason(r.data);
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    }

    const closeActiveSeason = () => {
        setLoading(true);
        restApiClient.fetch("CloseActiveSeason", {})
            .then((r : ApiResponse | null)=> {
                getActiveSeasonData();
            })
            .finally(()=>{
                setLoading(false);
            })
    }

    useEffect(()=>{
        getActiveSeasonData();
    },[])

    return (
        <div className="close-seasons-component">
            <Typography.Title level={4}>Cierre de temporada activa</Typography.Title>
            
            <p>Cerrar una temporada activa tiene las siguientes implicaciones:</p>
            
            <ul >
                <li>Todas las inscripciones <b>activas</b> pasarán a <b>finalizadas</b></li>
                <li>Todas las inscripciones en <b>pre</b> pasarán a <b>canceladas</b></li>
                <li>Todas las inscripciones en <b>revisión</b> se mantendrán igual</li>
                <li>Los usuarios no podrán realizar ninguna nueva inscripción hasta que no haya una temporada activa</li>
                <li>Los pagos que queden pendientes se procesarán igualmente</li>
                <li>Este proceso <b>NO PUEDE</b> revertirse</li>
            </ul>

            <p>
                Datos de la temporada activa 
                <Button disabled={loading} className="reload-active-season" size='small' type='primary' onClick={getActiveSeasonData}>
                    {
                        loading ? <LoadingOutlined size={8} /> : <ReloadOutlined size={8}/>
                    }
                </Button>
            </p>

            {
                activeSeason ?
                    <ul>
                        <li><b>Nombre:</b> {activeSeason?.description}</li>
                        <li><b>F. Apertura:</b> {activeSeason?.openAt}</li>
                        <li><b>F. Cierre:</b> {activeSeason?.closeAt}</li>
                    </ul>
                :
                <p>No hay temporada activa</p>
            }
            
            
            {
                activeSeason ?
                <>
                    <Checkbox className="chk-close-season" value={checked} onChange={onChange}>
                        Entiendo y acepto las implicaciones. Activa el botón para cerrar la temporada activa.
                    </Checkbox>

                    
                    <Button className="btn-close-season" disabled={!checked} type="primary" danger onClick={closeActiveSeason}>
                        <LoadingOrComponent text={<>Cerrar temporada activa</>} loading={loading} showSpinner loadingText={<>Cerrando temporada</>}/>
                    </Button>
                </>
                :
                <></>
            }
        </div>
    )
}

export default CloseSeasonsComponent;