import React, {useContext, useState} from 'react'
import { Row, Col } from 'antd'
import { AppCfgContext } from '../../hooks/context/appConfigContext'

interface ContainerBaseProps{
    children: React.ReactNode,
    useMargin: boolean
}

const ContainerBase = (props : ContainerBaseProps) : JSX.Element => {
  const appConfigContext = useContext(AppCfgContext);

  const [updateStepByStep, setUpdateStepByStep] = useState<number>(1);

    return(
        <Row className="theme" 
             style={{ backgroundImage:`url(${appConfigContext.cfg.urlsMainImg})`}} 
             justify="center" 
             align="middle" 
             onScrollCapture={()=>{   setUpdateStepByStep(updateStepByStep+1); }}>
            <Col id="container" className="container" style={{padding: props.useMargin ? "20px" : ""}} xs={24} sm={24} md={24} lg={props.useMargin ? 20 : 24}>
                {props.children}
            </Col>
        </Row>
    )
}

export default ContainerBase;