import { CheckOutlined, LoadingOutlined, MailOutlined, StopOutlined } from '@ant-design/icons'
import { Alert, Button, Collapse, Divider, Switch, Tag, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import RestApiClient from '../../../api/restApiClient'
import ApiResponse from '../../../models/api/ApiResponse'
import ParamsApi from '../../../models/api/ParamsApi'
import Athlete from '../../../models/Athletes'
import Attendance from '../../../models/Attendance'
import Group from '../../../models/Group'
import LoadingOrComponent from '../../core/utils/LoadingOrComponent'

import './SetAttendancesComponent.scss'

interface SetAttendancesComponentProps{
    groupInfo?: Group
}
const SetAttendancesComponent = (props: SetAttendancesComponentProps) => {
    const restApiClient : RestApiClient = new RestApiClient();

    const {groupInfo} = props;

    useEffect(()=>{
        console.log("Group info", groupInfo);
        getAttendancesData();
    },[groupInfo])

    const [attendances, setAttendances] = useState<Attendance[]>([]);
    const getAttendancesData = () : void => {
        var params : ParamsApi = {
            body:{
                groupId: groupInfo?.id,
                date: moment().format("YYYY-MM-DD")
            }
        }
        
        restApiClient.fetch("GetAttendancesByDateAndGroup", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    console.log("Attendances", r.data);
                    setAttendances(r.data);
                }
            }).finally(()=>{
                
            })
    }

    const getRelationShipInfo = (relationShip: string) : JSX.Element => {

        let result = <></>;

        switch(relationShip){
            case "myself":
                result = <>Actua en representación propia</>
                break;
            case "fmolg":
                result = <>Es padre/madre, tutor/tutora o responsable legal de los alumnos inscritos</>
                break;
            case "couple":
                result = <>Es su pareja</>
                break;
        }

        return result;
    }

    const [notifyAttendance, setNotifyAttendance] = useState<boolean>(false);
    const onChangeNotifyAttendance = (checked: boolean) => {
        setNotifyAttendance(checked);
      };

    return (
        <div className="set-attendances">
            <Typography.Title level={5}>
                Grupo: {groupInfo?.description}
            </Typography.Title>
            <div>
                ¿Notificar asistencias? <Switch onChange={onChangeNotifyAttendance} title="Notificar asistencias"/> 
            </div>
            <Alert className="alert-5-minutes" message={<>Recuerda que podrás marcar las asistencias <strong>hasta 30 minutos después de la clase</strong></>} type="warning" showIcon/>
            <Divider/>
            {
                groupInfo?.athletes.map((athlete: Athlete) => {
                    return <div className="info">
                                <div className="athlete-data">
                                    <span><strong>Nombre:</strong> {`${athlete.surname} ${athlete.secondSurname}, ${athlete.name}`}</span>

                                </div>
                                <SetAttendancesActions attendances={attendances} athleteId={athlete?.id!} groupId={groupInfo?.id!} notifyAttendance={notifyAttendance}/>
                                <Collapse className='emergency-data'>
                                    <Collapse.Panel header="Contacto de emergencia" key="1">
                                        <div className="contact-data">
                                            <span>
                                                <strong>Relación:</strong> {getRelationShipInfo(athlete.relationShip)}
                                            </span>
                                            <span><strong>Nombre:</strong>  {`${athlete.accountUserName}`}</span>
                                            <span><strong>Teléfono(s):</strong>  {`${athlete.accountUserPhone}`}</span>
                                        </div>
                                        <div className="actions">
                                            <Button style={{width: "100%", marginTop: "10px"}} ghost type="primary" className='btn-action btn-secondary' danger>
                                                <MailOutlined />
                                                Notificar falta
                                            </Button>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                                
                           </div>
                })
            }
        </div>
    )
}

interface SetAttendancesActionsProps{
    athleteId: number,
    groupId: number,
    notifyAttendance: boolean,
    attendances: Attendance[]
}
const SetAttendancesActions = (props: SetAttendancesActionsProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [settingDataOk, setSettingDataOk] = useState<boolean>(false);
    const [settingDataKO, setSettingDataKO] = useState<boolean>(false);
    const [lastMark, setLastMark] = useState<any>(<></>);
    const [checkNotify, setCheckNotify] = useState<any>(<></>);

    const checkAttendances = () : void => {
        if (props.attendances){
            var attendanceData = props.attendances.find((attendance: Attendance) => attendance.idAthlete == props.athleteId && attendance.idGroup == props.groupId);

            if (attendanceData){

                if (attendanceData.attendanceType !== "skipped"){
                    setLastMark(<Tag color="#87d068">Asiste</Tag>);
                    
                }else{
                    setLastMark(<Tag color="#f50">Falta</Tag>);
                }

                if (attendanceData.sendNotification){
                    setCheckNotify(<Tag color="#87d068">Notificado por correo</Tag>);
                }else{
                    setCheckNotify(<></>);
                }
            }
        }
    }

    
    const setAttendanceStatus = (status: boolean) : void => {

        if (status){
            setSettingDataOk(true);
        }else{
            setSettingDataKO(true);
        }

        var params : ParamsApi = {
            body:{
                athleteId: props.athleteId,
                groupId: props.groupId,
                status: status ? 1 : 2,
                notifyAttendance: props.notifyAttendance
            }
        }

        restApiClient.fetch("SetAttendanceStatus", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    if (status){
                        setLastMark(<Tag color="#87d068">Asiste</Tag>);
                        
                    }else{
                        setLastMark(<Tag color="#f50">Falta</Tag>);
                    }

                    if (props.notifyAttendance){
                        setCheckNotify(<Tag color="#87d068">Notificado por correo</Tag>);
                    }else{
                        setCheckNotify(<></>);
                    }
                }
            }).finally(()=>{
                if (status){
                    setSettingDataOk(false);
                }else{
                    setSettingDataKO(false);
                }
            })

    }

    useEffect(()=>{
        checkAttendances();
    },[props.attendances])

    return(
        <div className="actions">
            <div className="last-mark">
                {lastMark} {checkNotify}
            </div>
            <Button disabled={settingDataKO || settingDataOk} type="primary" className='btn-action btn-primary' onClick={()=>{setAttendanceStatus(true)}}>
                <LoadingOrComponent text={<><CheckOutlined /> Asiste</>} loadingText="Asiste" loading={settingDataOk} showSpinner/>
            </Button>
            <Button disabled={settingDataKO || settingDataOk} type="primary" className='btn-action btn-secondary to-right' danger onClick={()=>{setAttendanceStatus(false)}}>
                <LoadingOrComponent text={<><StopOutlined /> Falta</>} loadingText="Falta" loading={settingDataKO} showSpinner/>
            </Button>
        </div>
    )
}

export default SetAttendancesComponent;