import React from 'react'

import * as Pages from '../../../pages/index'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

export const PrivateRoutesComponent = (props : any) => {

    return(
        <Router forceRefresh={true}>
            <Switch>
                <Route path={["/trainers"]} > 
                    <Pages.Container component={<Pages.Trainers />} />
                </Route>
                <Route path={["/groups"]} >
                    <Pages.Container component={<Pages.Teachers />} />
                </Route>
                <Route path={["/users"]} >
                    <Pages.Container component={<Pages.Users />} />
                </Route>
                <Route path={["/emails"]} >
                    <Pages.Container component={<Pages.Emails />} /> 
                </Route>
                <Route path={["/docs"]} >
                    <Pages.Container component={<Pages.Docs />} />
                </Route>
                <Route path={["/products"]} >
                    <Pages.Container component={<Pages.Products />} />
                </Route>
                <Route path={["/discounts"]} >
                    <Pages.Container component={<Pages.Discounts />} />
                </Route>
                <Route path={["/customization"]} >
                    <Pages.Container component={<Pages.Customizations />} />
                </Route>
                <Route path={["/global", "/dashboard"]} >
                    <Pages.Container component={<Pages.Global />} />
                </Route>
                <Route path={["/centers"]} >
                    <Pages.Container component={<Pages.Centers />} />
                </Route>
                <Route path={["/profile"]} >
                    <Pages.Container component={<Pages.Profile />} />
                </Route>
                <Route path={["/old-students"]} >
                    <Pages.Container component={<Pages.OldStudents />} />
                </Route>
                <Route path={["/seasons"]} >
                    <Pages.Container component={<Pages.Seasons />} />
                </Route>
                <Route path={["/categories"]} >
                    <Pages.Container component={<Pages.Categories />} />
                </Route>
                <Route path={["/android"]} >
                    <Pages.Container component={<Pages.Notifications />} />
                </Route>
                <Route path={["/remittances"]} >
                    <Pages.Container component={<Pages.Remittances />} />
                </Route>
                <Route path={["/error"]} >
                    <Pages.Container component={<Pages.Error500 />} />
                </Route>
                <Route>
                    <Pages.Container component={<Pages.Error404 />} />
                </Route>
                
            </Switch>
        </Router>
    )
}