import { Button, Form, Input, Switch } from 'antd';
import React, { useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';

interface EditCentreProps{
    callBack: any
}

const AddCentreComponent = (props : EditCentreProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [form] = Form.useForm();
    const [adding, setAdding] = useState<boolean>(false);

    const onFinish = () : void => {
        var params : ParamsApi = {
            body:form.getFieldsValue()
        }

        setAdding(true);
        restApiClient.fetch("AddSportCenter", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    form.resetFields();
                    props.callBack();
                }
            }).finally(()=>{
                setAdding(false);
            })
    }

    return(
        <div className="edit-centre-component">
            <Form layout='vertical' form={form} onFinish={onFinish}>
            <Form.Item
                    label="Nombre"
                    name="description"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input placeholder='Nombre del centro'/>
                </Form.Item>
                <Form.Item
                    label="Dirección"
                    name="address"
                    className="form-item"
                    help='Los usuarios podrán ver en google maps la dirección exacta si indicas la dirección bien.'
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input placeholder='Dirección completa'/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input placeholder='Email(s) de contacto'/>
                </Form.Item>
                <Form.Item
                    label="Teléfono"
                    name="phone"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input placeholder='Teléfono(s) de contacto'/>
                </Form.Item>
                <Form.Item
                    label="Activo"
                    name="isActive"
                    className="form-item"
                    valuePropName="checked"
                    initialValue={true}
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Switch />
                </Form.Item>

                
                <Form.Item style={{textAlign: "right"}}>
                    <Button type="primary" htmlType="submit" >
                        <LoadingOrComponent text={'Guardar'} loadingText={'Guardando'} showSpinner loading={adding}/>
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddCentreComponent;