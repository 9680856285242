import { Button, Result } from 'antd';
import React from 'react'
import { useHistory } from "react-router-dom";

interface Page500Props{}

const Page500 = (props : Page500Props) : JSX.Element => {

    const history = useHistory();

    const goHome = () : void => {
        window.location.href = "/dashboard"
    }

    const goBack = () : void => {
        history.goBack();
    }

    return <Result
                status="500"
                title="500"
                subTitle="Ups, algo no fue bien. Puedes volver a atrás o al inicio."
                extra={<><Button type="primary" onClick={goBack}>Volver atras</Button><Button type="primary" onClick={goHome}>Inicio</Button></>}
            />
}

export default Page500;