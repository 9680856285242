import React, { useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';

import { Alert, Button, Col, DatePicker, Divider, Input, Row, Select, Switch, Tag, Typography } from 'antd';
import LoadingOrComponent from '../../components/core/utils/LoadingOrComponent';
import { ClearOutlined, MailOutlined } from '@ant-design/icons';
import User from '../../models/Users';

import Group from '../../models/Group';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import moment from 'moment';


import './NotificationsComponent.scss'
import TextArea from 'antd/lib/input/TextArea';


interface NotificationsProps{}

const NotificationsComponent = (props : NotificationsProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [sending, setSending] = useState<boolean>(false);

    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);

    const [semicolonSeparatorEmails, setSemicolonSeparatorEmails] = useState<string>('');
    const [usersIds, setUsersIds] = useState<number[]>([]);
    const [groupsIds, setGroupsIds] = useState<number[]>([]);
    const [sendMeCopy, setSendMeCopy] = useState<boolean>(false);
    const [scheduledSend, setScheduledSend] = useState<any>(moment());
    const [emailBody, setEmailBody] = useState<string>('');
    const [emailSubject, setEmailSubject] = useState<string>('');
    const [semicolonSeparatorBCC, setSemicolonSeparatorBCC] = useState<string>('');

    const [alertMessage, setAlertMessage] = useState<string>('');
    const [infoMessage, setInfoMessage] = useState<string>('');
    

    const loadGroups = () : void => {
        var params : ParamsApi = {
            query:{
                filter: "",
                page: "0",
                N: 9999
            }
        }

        setLoadingGroups(true);
        restApiClient.fetch("GetGroups", params)
            .then((r: any)=>{
                if (r.data.elements){
                    setGroups(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingGroups(false);
            })
    }

    const loadUsers = () : void => {
        var params : ParamsApi = {
            query:{
                filter: "",
                page: "0",
                N: 9999
            }
        }

        setLoadingUsers(true);
        restApiClient.fetch("GetUsers", params)
            .then((r: any)=>{
                if (r.data.elements){
                    setUsers(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingUsers(false);
            })
    }

    const clearFields = () : void => {
        setSemicolonSeparatorEmails('');
        setUsersIds([]);
        setGroupsIds([]);
        setSendMeCopy(false);
        setScheduledSend('');
        setEmailBody('');
        setEmailSubject('');
        setSemicolonSeparatorBCC('');
        setAlertMessage('');
        setInfoMessage('');
    }

    const onFinish = () : void => {
        setAlertMessage('');
        setInfoMessage('');
        var params : ParamsApi = {
            body:{
                userSessionTokenId: sessionStorage.getItem("token"),
                usersIds:usersIds,
                groupsIds: groupsIds,
                sendMeCopy: sendMeCopy,
                scheduleSend: scheduledSend ? moment(scheduledSend).format("YYYY-MM-DD HH:mm").toString() : null,
                body: emailBody,
                title: emailSubject,
                image: ""
            }

        }

        setSending(true);
        restApiClient.fetch("SendAndroidNotification", params)
            .then((r: any)=>{
                if(r && r.data){
                    setAlertMessage(r.data.discarted);
                    setInfoMessage(r.data.sent);
                }
            })
            .finally(()=>{
                setSending(false);
            })
    }

    useEffect(()=>{
        loadGroups();
        loadUsers();
    },[])

    return (
        <div className="notifications-component">
            <div className="target-options">
                <Typography.Title level={5}>
                    Indique el/los destinatario(s)
                </Typography.Title>
                <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={8}>
                        <label>Grupos</label>
                        <Select value={groupsIds} onChange={(v: any)=>{
                            setGroupsIds(v);
                        }} mode='multiple' style={{width: "100%"}} showArrow showSearch placeholder={loadingGroups ? <LoadingOrComponent loading text="Cargando..." showSpinner/> : (groups.length > 0 ? 'Seleccione uno o varios grupos' : 'No hay grupos registrados aún.')}>
                            {
                                groups.map((group: Group, index: number)=>{
                                    return <Select.Option key={index} value={group.id}>{group.description} ({group.sportCenter.description})</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <label>Usuarios</label>
                        <Select value={usersIds} onChange={(v: any)=>{
                            setUsersIds(v);
                        }} mode='multiple' style={{width: "100%"}} showArrow showSearch placeholder={loadingUsers ? <LoadingOrComponent loading text="Cargando..."/> : (users.length > 0 ? 'Seleccione uno o varios usuarios' : 'No hay usuarios registrados aún.')}>
                            {
                                users.map((user: User, index: number) => {
                                    return <Select.Option key={index} value={user.id!}>{user.surname} {user.secondSurname}, {user.name} ({user.contactData?.email})</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                    
                    
                    <Col xs={24} sm={24} md={8} lg={8}>
                        <label>Programar envío</label>
                        <DatePicker value={scheduledSend} onChange={(v: any)=>{
                            setScheduledSend(v);
                        }} showTime format="DD/MM/YYYY HH:mm" style={{width: "100%"}} placeholder="Si desea que el envío se realice en otra fecha puede indicarlo"/>
                    </Col>
                    <Col xs={24} style={{marginTop: "10px"}} >
                        <label>Título</label>
                        <Input value={emailSubject} onChange={(v: any)=>{
                            setEmailSubject(v.target.value);
                        }}/>
                    </Col>
                    <Col xs={24}>
                    {
                            alertMessage && alertMessage.length > 0 
                                ? 
                                <Alert closable style={{marginTop: "10px"}} type='warning' message={
                                                                    <div className="invalid-emails">
                                                                        <Typography.Title level={5}>
                                                                            Emails Inválidos:
                                                                        </Typography.Title>
                                                                        {
                                                                            alertMessage.split(";").filter(email => email.length > 0).map((email: string)=>{
                                                                                return <Tag>{email}</Tag>
                                                                            })
                                                                        }
                                                                        <small style={{display: "block", marginTop: "5px", fontWeight:"bold"}}>ATENCIÓN: Recuerde que para que se envíe la notificación Android el usuario debe tener la aplicación instalada en al menos 1 dispositivo móvil.</small>
                                                                    </div>}/> : ''
                        }

                        {
                            infoMessage && infoMessage.length > 0 
                                ? 
                                <Alert closable style={{marginTop: "10px"}} type='success' message={
                                                                    <div className="invalid-emails">
                                                                        <Typography.Title level={5}>
                                                                            Emails programados:
                                                                        </Typography.Title>
                                                                        {
                                                                            infoMessage.split(";").filter(email => email.length > 0).map((email: string)=>{
                                                                                return <Tag>{email}</Tag>
                                                                            })
                                                                        }
                                                                    </div>}/> : ''
                        }
                        
                    </Col>
                </Row>
            </div>
            <div style={{width: "100%", marginTop: "20px"}}>
                <label>Contenido</label>
                <TextArea maxLength={200} rows={10} onChange={(v)=>{
                    console.log("V", v.target.value);
                    setEmailBody(v.target.value);
                }}></TextArea>
                <small>Max. 200 caracteres: ({200-emailBody.length} restantes).</small>
            </div>
            <div className="buttons">
                <Divider />
                <Button type="primary" danger style={{marginRight: "5px"}} onClick={clearFields}>
                    <ClearOutlined /> Limpiar
                </Button>
                <Button type="primary" onClick={onFinish}>
                    <LoadingOrComponent text={<><MailOutlined />&nbsp;&nbsp;Enviar</>} loading={sending} loadingText="Enviando..." showSpinner/>
                </Button>
            </div>
        </div>
    )
}

export default NotificationsComponent;