import React, { useEffect } from 'react'

import * as Pages from '../../../pages/index'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

export const PublicRoutesComponent = (props : any) => {

    return(
        <Router forceRefresh={true}>
            <Switch>
                <Route exact path={["/","/login", "/dashboard", "/routine/:id", "/validate-account/:validateAccountHash", "/change-my-password/:changePasswordHash", "/error/:errorMethod"]}>
                    <Pages.Login />
                </Route>
                <Route path="/lockscreen">
                    <Pages.LockScreen />
                </Route>
                <Route path="/register">
                    <Pages.Register />
                </Route>
            </Switch>
        </Router>
    )
}