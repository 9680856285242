import { Alert, Button, Col, Divider, Form, Input, Row, Select, Switch, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';

import './CustomizationComponent.scss'
import CloseSeasonsComponent from '../seasons/close/CloseSeasonsComponent';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import { AppCfgContext } from '../../hooks/context/appConfigContext';
import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons';
import ParamsApi from '../../models/api/ParamsApi';

interface CustomizationsProps{}
interface Item{
    label: string,
    key: string,
    children: string
}
const CustomizationComponent = (props : CustomizationsProps) :JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const appConfigContext = useContext(AppCfgContext);

    const GetAppConfig = (callBack: any, callbackValue: any) : void => {
        restApiClient.fetch("GetAppConfig", {})
                .then((r : ApiResponse | null)=> {
                    if (r !== null){
                        const code : number = r.code; 

                        switch(code){
                            case 200:
                                sessionStorage.setItem("app-config", JSON.stringify(r.data));
                                callBack(callbackValue);
                                break;
                        }
                    }
                });
                
    }

    const [isInMaintenance, setIsInMaintenance] = useState<boolean>(false);
    const changeMaintenanceStatus = (v: boolean) : void => {
        setIsInMaintenance(v);
    } 

    const [savingStatus, setSavingStatus] = useState<boolean>(false);
    const saveMaintenanceStatus = () : void => {
        var params : ParamsApi = { body: isInMaintenance};

        setSavingStatus(true);
        restApiClient.fetch("UpdateMaintenanceStatus", params)
            .then((r: any)=>{
                GetAppConfig(setSavingStatus, false)
            })
    }

    const [savingInitials, setSavingInitials] = useState<boolean>(false);
    const saveInitials = () : void => {
        var params : ParamsApi = { body: initials};

        setSavingInitials(true);
        restApiClient.fetch("UpdateInitials", params)
            .then((r: any)=>{
                GetAppConfig(setSavingInitials, false)
            })
    }

    const [mainColor, setMainColor] = useState<string>('');
    const [secondaryColor, setSecondaryColor] = useState<string>('');
    const changeMainColor = (v: any) : void => {
        setMainColor(v.target.value);
    }
    const changeSecondaryColor = (v: any) : void => {
        setSecondaryColor(v.target.value);
    }

    const [savingColors, setSavingColors] = useState<boolean>(false);
    const saveColors = () : void => {
        var params : ParamsApi = { body: {
            mainColor: mainColor,
            secondaryColor: secondaryColor
        }};

        setSavingColors(true);
        restApiClient.fetch("UpdateColors", params)
            .then((r: any)=>{
                GetAppConfig(setSavingColors, false)
            })
    }

    const [useAws, setUseAWS] = useState<boolean>(true);
    const changeUseAWS = (v: boolean) : void => {
        setUseAWS(v);
    }

    const [useSMTP, setUseSMTP] = useState<boolean>(true);
    const changeUseSMTP = (v: boolean) : void => {
        setUseSMTP(v);
    }

    const [legalText, setLegalText] = useState<string>('');
    const legalTextChange = (v: any) => {
        setLegalText(v);
    }

    const [savingLegalText, setSavingLegalText] = useState<boolean>(false);
    const saveLegalText = () : void => {
        var params : ParamsApi = { 
            body: legalText
        };

        setSavingLegalText(true);
        restApiClient.fetch("UpdateLegalTextPage", params)
            .then((r: any)=>{
                GetAppConfig(setSavingLegalText, false)
            })
    }


    const [initials, setInitials] = useState<string>('');
    const [cfgPaymentTypes, setCfgPaymentTypes] = useState<string>('credit-card');
    useEffect(()=>{

        if (appConfigContext){
            setIsInMaintenance(appConfigContext.status !== 'active');
            setInitials(appConfigContext.cfg.textInitials);
            setCfgPaymentTypes(appConfigContext.cfg.cfgPaymentTypes);
            setUseAWS(appConfigContext.cfg.emailCfgUseDefaultCfg);
            setMainColor(appConfigContext.cfg.colorsMain);
            setSecondaryColor(appConfigContext.cfg.colorsSecondary);
            setUrlsMainImg(appConfigContext.cfg.urlsMainImg);            
            setUrlsSecondaryImg(appConfigContext.cfg.urlsSecondaryImg);
            setUrlsShieldImg(appConfigContext.cfg.urlsShieldImg);
            setUrlsLogoImg(appConfigContext.cfg.urlsLogoImg);
            setLegalText(appConfigContext.cfg.textLegalPrivacity);
        }
        
    },[appConfigContext])

    const [urlsMainImg, setUrlsMainImg] = useState<string>('');
    const onChangeUrlsMainImg = (v: any) : void => {
        setUrlsMainImg(v.target.value);
    }
    
    const [urlsSecondaryImg, setUrlsSecondaryImg] = useState<string>('');
    const onChangeUrlsSecondaryImg = (v: any) : void => {
        setUrlsSecondaryImg(v.target.value);
    }
    
    const [urlsShieldImg, setUrlsShieldImg] = useState<string>('');
    const onChangeUrlsShieldImg = (v: any) : void => {
        setUrlsShieldImg(v.target.value);
    }
    
    const [urlsLogoImg, setUrlsLogoImg] = useState<string>('');
    const onChangeUrlsLogoImg = (v: any) : void => {
        setUrlsLogoImg(v.target.value);
    }   
    
    const [savingImageUrls, setSavingImageUrls] = useState<boolean>(false);
    const saveImageUrls = () : void => {
        var params : ParamsApi = {
            body: {
                mainImage: urlsMainImg,
                secondaryImage: urlsSecondaryImg,
                shield: urlsShieldImg,
                logo: urlsLogoImg
            }
        }

        setSavingImageUrls(true);
        restApiClient.fetch("UpdateImageUrls", params)
            .then((r: any)=>{
                GetAppConfig(setSavingImageUrls, false)
            })

    }
    
    

    return(
        <div className='customization-component'>
            <Tabs tabPosition='left'>
                <Tabs.TabPane tab="Personalización APP" key="1">
                    
                    <Divider orientation='left'>
                        Plataforma en mantenimiento
                    </Divider>
                    <p>
                        <small>Te permitirá activar/desactivar el acceso a la plataforma. A los usuarios les aparecerá un mensaje avisándoles de que se están realizando operaciones de mantenimiento.</small>
                    </p>
                    
                    <Switch checked={isInMaintenance} onChange={changeMaintenanceStatus} /> {isInMaintenance ? 'En mantenimiento' : 'Plataforma activa'}

                    <div className='btn-actions'>
                        <Button type='primary' disabled={savingStatus} onClick={saveMaintenanceStatus}>
                            {
                                savingStatus ? <LoadingOutlined /> : <></>
                            }
                            Guardar
                        </Button>
                    </div>
                    
                    <Divider orientation='left'>
                        Iniciales de la plataforma
                    </Divider>
                    <p>
                        <small>Estas iniciales van en el círculo que se encuentra en el login</small>
                    </p>

                    <Input value={initials} onChange={(v: any)=>{setInitials(v.target.value)}} maxLength={3} minLength={3}/>

                    <div className='btn-actions'>
                        <Button type='primary' disabled={savingInitials} onClick={saveInitials}>
                            {
                                savingInitials ? <LoadingOutlined /> : <></>
                            }
                            Guardar
                        </Button>
                    </div>

                    <Divider orientation='left'>
                        Tarjetas caducadas
                    </Divider>
                    <p>
                        <small>
                            Recuerda que desde la ficha del usuario puedes permitir que usen tarjetas que expiren durante el curso/temporada.
                        </small>
                    </p>
                    
                    <Divider orientation='left'>
                        Tipos de pago habilitados
                    </Divider>
                    <p>
                        <small>
                            Permite que tus usuarios realicen pagos mediante tarjeta de crédito o domiciliación SEPA
                        </small>
                    </p>
                    <Select style={{width: "100%"}} defaultValue={'credit-card'} value={'credit-card'}>
                        <Select.Option value={'credit-card'}>Tarjeta de crédito</Select.Option>
                        <Select.Option value={'sepa'} disabled>Domiciliación SEPA (Próximamente)</Select.Option>
                        <Select.Option value={'both'} disabled>Ambos (Próximamente)</Select.Option>
                    </Select>

                    <div className='btn-actions'>
                        <Button type='primary' disabled>
                            Guardar (Próximamente)
                        </Button>
                    </div>

                    <Divider orientation='left'>
                        Correo electrónico
                    </Divider>
                    <p>
                        <small>Puedes decidir si usar la configuración por defecto de AWS (Amazon) que te ofrecemos o si por el contrario prefieres usar tu propia configuración de correo.</small>
                    </p>
                    <Switch disabled checked={useAws} onChange={changeUseAWS} /> { useAws ? 'Prefiero usar la configuración por defecto de AWS (Próximamente)' : 'Prefiero usar mi propia configuración personalizada'}
                    {
                        !useAws ?
                            <Alert className="cfg-own-configuration-alert" type='warning' message={<><strong>ATENCIÓN: </strong>Desde <strong>Inscribble</strong> no nos hacemos responsable si la configuración personalizada no es correcta. Piensa que si cambias esta configuración afectará a todos los emails que genera la plataforma para tí (comunicaciones a los usuarios sobre pagos realizados, errores en pago, resúmenes semanales, mensuales, comunicaciones internas, formulario de contacto, etc</>} />
                        :
                        <></>
                    }
                    {
                        useAws ? <></> : 
                            <Form  layout='vertical' className='custom-email-cfg-form'>
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                                name="emailUser"
                                                className="form-item"
                                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                label="Usuario"
                                            >
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                                name="emailPassword"
                                                className="form-item"
                                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                label="Contraseña"
                                            >
                                            <Input.Password allowClear />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Switch className='switch-smtp-imap' onChange={changeUseSMTP} /> {useSMTP ? 'SMTP' : 'IMAP'}
                                    </Col>
                                    {
                                        useSMTP ? 
                                            <>
                                                <Col xs={24} md={8}>
                                                    <Form.Item
                                                            name="emailUserSMTP"
                                                            className="form-item"
                                                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                            label="Usuario SMTP"
                                                        >
                                                        <Input allowClear />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8}>
                                                    <Form.Item
                                                            name="emailPortSMTP"
                                                            className="form-item"
                                                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                            label="Puerto SMTP"
                                                        >
                                                        <Input allowClear />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8}>
                                                    <Form.Item
                                                            name="emailHostSMTP"
                                                            className="form-item"
                                                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                            label="Host SMTP"
                                                        >
                                                        <Input allowClear />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                            name="emailPortIMAP"
                                                            className="form-item"
                                                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                            label="Puerto IMAP"
                                                        >
                                                        <Input allowClear />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                            name="emailHostIMAP"
                                                            className="form-item"
                                                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                            label="Host IMAP"
                                                        >
                                                        <Input allowClear />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                    }
                                    
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                                name="active"
                                                className="form-item"
                                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                                label="¿Usa SSL?"
                                                initialValue={true}
                                            >
                                            <Switch onChange={()=>{}} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                    }

                    <div className='btn-actions'>
                        <Button disabled type='primary'>Guardar (Próximamente)</Button>
                    </div>

                    <Divider orientation='left'>
                        Colores
                    </Divider>

                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                        name="mainColor"
                                        initialValue={appConfigContext?.cfg?.colorsMain}
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                        label={<>Color principal (Hexadecimal) <div className='actual-color' style={{backgroundColor:mainColor}}></div></>}
                                    >
                                    <Input onChange={changeMainColor}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                        name="secondaryColor"
                                        initialValue={appConfigContext?.cfg?.colorsSecondary}
                                                                            
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                        label={<>Color secundario (Hexadecimal) <div className='actual-color' style={{backgroundColor:secondaryColor}}></div></>}
                                    >
                                    <Input onChange={changeSecondaryColor}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <div className='btn-actions'>
                        <Button type='primary' disabled={savingColors} onClick={saveColors}>
                            {
                                savingColors ? <LoadingOutlined /> : <></>
                            }
                            Guardar
                        </Button>
                    </div>

                    <Divider orientation='left'>
                        URL de Imágenes
                    </Divider>
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                        name="mainImage"
                                        initialValue={appConfigContext?.cfg?.urlsMainImg}
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                        label="Imagen principal"
                                        help={<>Pulse <a href={urlsMainImg} target='_blank' >aquí</a> para visualizar</>}
                                    >
                                    <Input onChange={onChangeUrlsMainImg} suffix={<img className="thumbnails" src={urlsMainImg}/>}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                        name="secondaryImage"
                                        initialValue={appConfigContext?.cfg?.urlsSecondaryImg}
                                        className="form-item"
                                        help={<>Pulse <a href={urlsSecondaryImg} target='_blank' >aquí</a> para visualizar</>}
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                        label="Imagen secundaria"
                                    >
                                    <Input onChange={onChangeUrlsSecondaryImg} suffix={<img className="thumbnails" src={urlsSecondaryImg}/>}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                        name="shieldUrl"
                                        initialValue={appConfigContext?.cfg?.urlsShieldImg}
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                        label="Escudo centro"
                                        help={<>Pulse <a href={urlsShieldImg} target='_blank' >aquí</a> para visualizar</>}
                                    >
                                    <Input onChange={onChangeUrlsShieldImg} suffix={<img className="thumbnails" src={urlsShieldImg}/>}/>
                                </Form.Item>
                               
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                        name="logo"
                                        help={<>Pulse <a href={urlsLogoImg} target='_blank' >aquí</a> para visualizar</>}
                                        initialValue={appConfigContext?.cfg?.urlsLogoImg}
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                        label="Logotipo centro"
                                    >
                                    <Input onChange={onChangeUrlsLogoImg} suffix={<img className="thumbnails" src={urlsLogoImg}/>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <div className='btn-actions'>
                        <Button type='primary' disabled={savingImageUrls} onClick={saveImageUrls}>
                            {
                                savingImageUrls ? <LoadingOutlined /> :  <></>
                            }
                            Guardar
                        </Button>
                    </div>

                    <Divider orientation='left'>
                        Texto legal de la plataforma
                    </Divider>
                    <p>
                        <small>
                            Este será el texto que verán tus usuarios durante el registro en la plataforma
                        </small>
                    </p>
                    <Editor
                        apiKey="dnbmfo8rnew4ei9un6h5a5snnrrcs6jm0macgee7et5teh32"
                        initialValue={appConfigContext?.cfg?.textLegalPrivacity}
                        init={{
                                language:"es",
                                skin: "snow",
                                icons: "thin",
                                placeholder: "Contenido del texto legal...",
                                height: 400,
                                menubar: true,
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen textcolor ",
                                    "insertdatetime media table paste code help wordcount"
                                ],
                                textcolor_rows: "4",

                                toolbar: "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent "
                            }}
                        value={legalText}
                        onEditorChange={legalTextChange}
                    />

                    <div className='btn-actions'>
                        <Button type='primary' disabled={savingLegalText} onClick={saveLegalText}>
                            {
                                savingLegalText ? <LoadingOutlined /> :  <></>
                            }
                            Guardar
                        </Button>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Operaciones críticas" key="2">
                    <CloseSeasonsComponent />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default CustomizationComponent;