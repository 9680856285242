import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Categories'

export const CategoriesEP : EndPointConfig[] =[
    
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)    
        .setActionName("GetCategories"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)    
        .setActionName("GetCategoriesForList"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)    
        .setActionName("ChangeEnrollmentCategory"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)    
        .setActionName("AddCategory"),
     new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)    
        .setActionName("RemoveCategory"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)    
        .setActionName("UpdateCategory"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)   
        .setPattern("category/{categoryId}/status/{active}") 
        .setActionName("ChangeCategoryStatus"),    
]