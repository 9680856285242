import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, notification, Select, Button, Drawer, Skeleton, Typography, Input } from 'antd'

import * as Components from '../../components/index'

import './GlobalPage.scss'
import { LoginContext } from '../../hooks/context/userContext'
import { AppCfgContext } from '../../hooks/context/appConfigContext'
import moment from 'moment'

import RestApiClient from '../../api/restApiClient'
import ApiResponse from '../../models/api/ApiResponse'
import Athletes from '../../models/Athletes'
import User from '../../models/Users'
import Enrollments from '../../models/Enrollments'
import ParamsApi from '../../models/api/ParamsApi'


import Season from '../../models/Season'
import DrawerInsideComponent from '../../components/DrawerInside/DrawerInside'
import Athlete from '../../models/Athletes'
import LoadingOrComponent from '../../components/core/utils/LoadingOrComponent'
import { EuroOutlined, ReconciliationOutlined, ReloadOutlined, SmileOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

interface DashBoardProps {}

const { Option } = Select

const GlobalPage = (props : DashBoardProps) : any => {
    const restApiClient : RestApiClient = new RestApiClient();
    const appConfigContext = useContext(AppCfgContext);
    const loginContext = useContext(LoginContext);

    const [showAdvancedStatistics, setShowAdvancedStatistics] = useState<boolean>(false);

    const [season, setSeason] = useState<Season>();
    const [seasons, setSeasons] = useState<Season[]>([]);

    const [searchingEnrollments, setSearchingEnrollments] = useState<boolean>(false);
    const [selectedEnrollment, setSelectedEnrollment] = useState<Enrollments>();

    const [searchingPayments, setSearchingPayments] = useState<boolean>(false);

    const [athletes, setAthletes] = useState<Athletes[]>([]);
    const [searchingAthletes, setSearchingAthletes] = useState<boolean>(false);
    const [selectedAthlete, setSelectedAthlete] = useState<Athletes>();

    const [searchingUsers , setSearchingUsers] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();


    const [reloadEnrollmentsCounter, setReloadEnrollmentsCounter] = useState<number>(0);
    const [reloadPaymentsCounter, setReloadPaymentsCounter] = useState<number>(0);
    const [reloadUsersCounter, setReloadUserCounter] = useState<number>(0);
    const [reloadStudentsCounter, setReloadStudentsCounter] = useState<number>(0);


    const updateMaterialStatus = (delivered: boolean, enrollmentId: number) => {
        const params : ParamsApi = {
            body:{
                delivered: delivered,
                enrollmentId: enrollmentId
            }
        }

        setSearchingEnrollments(true);
        restApiClient.fetch("ChangeMaterialStatus", params)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setReloadEnrollmentsCounter(reloadEnrollmentsCounter + 1);
                }
            }).catch((reason: any)=>{
                setSearchingEnrollments(false);
            }).finally(()=>{
                setSearchingEnrollments(false);
            });
    }

    const [generalFilter, setGeneralFilter] = useState<string>("");
    const onFocusFromEnrollmentsData = (enrollmentId: number) : void => {
        const filter = `enroll:${enrollmentId}`;
        setGeneralFilter(filter);
    }

    const onFocusFromUsersData = (email: number) : void => {
        const filter = `${email}`;
        setGeneralFilter(filter);
    }

    useEffect(()=>{
        setReloadEnrollmentsCounter(reloadEnrollmentsCounter+1);
        setReloadPaymentsCounter(reloadPaymentsCounter+1);
        setReloadUserCounter(reloadUsersCounter+1);
        setReloadStudentsCounter(reloadStudentsCounter+1);
    },[generalFilter])
    

    const GetAdminAthletes = () => {
    }

    const [loadingSeasons, setLoadingSeasons] = useState<boolean>(false);
    const GetSeasons = () : void =>{
        setLoadingSeasons(true);
        restApiClient.fetch("GetSeasons", {})
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSeasons(r.data);
                    if (r.data.length>0){
                        var data: Season[] = r.data;
                        var activeSeason = data.find((s: Season) => { return s.isActiveSeason });

                        setSeason(activeSeason ? activeSeason : data[0]);
                    }
                }
            })
            .finally(()=>{
                setLoadingSeasons(false);
            })
    }

    const setEnrollmentActive = (enrollmentId: number) : void => {
        const params : ParamsApi ={
            query: {enrollmentId:enrollmentId}
        }

        restApiClient.fetch("SetEnrollActive", params)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setReloadEnrollmentsCounter(reloadEnrollmentsCounter + 1);
                }
            })
    }

    const onShowDetail = (enrollmentData : Enrollments) => {
        setSelectedEnrollment(enrollmentData);
    }

    const onShowUserDetail = (userData : User) : void => {
        setSelectedUser(userData)
    }


    const onShowAthleteDetail= (athleteData: Athlete) : void => {
        setSelectedAthlete(athleteData);
    }

    const handleChangeSeason= (seasonId: number) : void => {
        setSeason(seasons.find(s => s.id === seasonId));
    }

    const refreshAll = () : void => {
        if (season && season.id > 0){
            setGeneralFilter('');
            setReloadEnrollmentsCounter(reloadEnrollmentsCounter + 1);
            setReloadPaymentsCounter(reloadPaymentsCounter + 1);
            setReloadUserCounter(reloadUsersCounter + 1);
            setReloadStudentsCounter(reloadStudentsCounter + 1);
        }
    }
    
    useEffect(()=>{
           refreshAll();
    }, [season]);

    useEffect(()=> {
        switch(loginContext.idRol){
            case "admin":
                //Admin
                GetSeasons();
                break;
            case "trainer":
                // Trainer
                notification.error({
                    message: 'Ups!',
                    description: 'No tiene permisos para consultar este panel. Por favor, cierre sesión y logueese correctamente'
                })
                break;
        }
    }, []);


    return (
        <div className="global-page" id="marker-app">
                <div ></div>
                <LoadingOrComponent text={''} showSpinner={false} loading={loadingSeasons} loadingText={<Typography.Title level={4}>Cargando temporadas</Typography.Title>}/>
                {
                    // Drawer for show Enrollment Detail
                }
                <DrawerInsideComponent 
                    mobilefixed={selectedEnrollment !== undefined && selectedEnrollment !== null ? true : false} 
                    showadd={selectedEnrollment !== undefined && selectedEnrollment !== null ? true : false} 
                    dOnClose={()=>{
                        setSelectedEnrollment(undefined); 
                        //GetAdminEnrollments()
                    }} 
                    dTitle={`Acciones inscripción INS-${selectedEnrollment?.id}`} 
                    dwidth={"50%"}
                >
                    <Components.EnrollmentDetail data={selectedEnrollment} />
                </DrawerInsideComponent>


                {
                    // Drawer for edit user profile
                }
                <Components.DrawerInside mobilefixed={selectedUser !== undefined && selectedUser !== null ? true : false} 
                                         showadd={selectedUser !== undefined && selectedUser !== null ? true : false} 
                                         dOnClose={()=>{setSelectedUser(undefined)}} 
                                         dTitle="Información del usuario" 
                                         dwidth={"50%"}>
                    <Components.UsersInfo close={()=>{setSelectedUser(undefined);}} userdata={selectedUser}/>
                </Components.DrawerInside>


                {
                    // Drawer for show and edit athletes profile
                }
                <Components.DrawerInside mobilefixed={selectedAthlete !== undefined && selectedAthlete !== null ? true : false} 
                                         showadd={selectedAthlete !== undefined && selectedAthlete !== null ? true : false} 
                                         dOnClose={()=>{setSelectedAthlete(undefined)}} 
                                         dTitle="Información del alumno" 
                                         dwidth={"50%"}>
                    <Components.AthleteInfo athlete={selectedAthlete} onFinish={()=>{setSelectedAthlete(undefined); GetAdminAthletes();}}/>
                </Components.DrawerInside>

                <nav className="quick-access">
                    <Link className="link-to-quick-access enrollments-qa" to="#enrollments">
                        <ReconciliationOutlined />
                    </Link>
                    <Link className="link-to-quick-access payments-qa" to="#payments">
                        <EuroOutlined />
                    </Link>
                    <Link className="link-to-quick-access users-qa" to="#users">
                        <UsergroupAddOutlined />
                    </Link>
                    <Link className="link-to-quick-access athletes-qa" to="#athletes">
                        <SmileOutlined />
                    </Link>
                </nav>
                
                <Skeleton active loading={loadingSeasons}>
                    <Row gutter={10}>
                        
                        <Col xs={24} className='hide-on-mobile'>
                            <Drawer visible={showAdvancedStatistics} width="100%" placement='left' closable onClose={()=>{setShowAdvancedStatistics(false)}} 
                                title="Estadísticas"
                                footer={
                                    <div style={{width: "100%", textAlign:"right"}}>
                                        <Button type='primary' onClick={()=>{setShowAdvancedStatistics(false)}}>Cerrar</Button>
                                    </div>
                                }>
                                {
                                showAdvancedStatistics 
                                    ?  
                                        <Components.Welcome seasons={seasons} showBtnAdvancedStatistics={false} showAdvancedStatistics={true} callBackBtnAdvancedStatistics={()=>{
                                            setShowAdvancedStatistics(false);
                                        }}/>
                                    :
                                        ''
                                }
                                
                            </Drawer>
                            <Components.Welcome seasons={seasons} showBtnAdvancedStatistics={true} showAdvancedStatistics={false} callBackBtnAdvancedStatistics={()=>{
                                setShowAdvancedStatistics(true);
                            }}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{margin: "20px 0 0 0"}}>
                            <Input.Group compact>
                                <Select onChange={handleChangeSeason} placeholder={season ? season.description : "Seleccione curso"} style={{minWidth:"265px"}} >
                                    {
                                        seasons.map((season: Season)=><Option value={season.id} key={season.id}>{season.description} {season.isActiveSeason ? '(Curso activa)' : ''}</Option>)
                                    }
                                </Select>
                                <Button onClick={refreshAll} type="primary">
                                    <ReloadOutlined /> <span className='hide-on-mobile hide-on-tablet'>Recargar todo</span>
                                </Button>
                            </Input.Group>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} id="enrollments">
                            <Components.Enrollments reloadCounter={reloadEnrollmentsCounter}
                                                    useTableScroll={{ y: 300 }} 
                                                    showdetail={onShowDetail} 
                                                    activeSeason={season} 
                                                    setactive={setEnrollmentActive} 
                                                    updatematerialstatus={updateMaterialStatus} 
                                                    isSearching={searchingEnrollments} 
                                                    generalFilter={generalFilter}
                                                    focusdata={onFocusFromEnrollmentsData}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} id="payments">
                            <Components.Payments reloadCounter={reloadPaymentsCounter} 
                                                useTableScroll={{ y: 300 }} 
                                                isSearching={searchingPayments} 
                                                activeSeason={season}
                                                generalFilter={generalFilter} 
                                                /> 
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xxl={13} id="users"> 
                            <Components.Users reloadCounter={reloadUsersCounter}
                                            useTableScroll={{ y: 300 }} 
                                            activeSeason={season}
                                            showDetail={onShowUserDetail} 
                                            isSearching={searchingUsers} 
                                            users={users} 
                                            generalFilter={generalFilter}
                                            focusdata={onFocusFromUsersData}
                                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xxl={11} id="athletes"> 
                            <Components.Athletes reloadCounter={reloadStudentsCounter}
                                                useTableScroll={{ y: 300 }} 
                                                showdetail={onShowAthleteDetail} 
                                                isSearching={searchingAthletes} 
                                                generalFilter={generalFilter}
                                                athletes={athletes}/> 
                        </Col>
                        
                    </Row>
                </Skeleton>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default GlobalPage;