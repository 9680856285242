import React from 'react'

import * as Components from '../../components'

import './NotificationsPage.scss'

const NotificationsPage = () : JSX.Element => {

    return (
        <div className="notifications-page">
            <Components.Notifications />
        </div>
    )
}


export default NotificationsPage;