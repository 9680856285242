import React from 'react'

import * as Component from '../../components'


import './CentersPage.scss'


const CentersPage = () : JSX.Element => {
    
    return(
        <div className="centers-page">
            <div id="marker-app"></div>
            <Component.Centers />
        </div>
    )
}

export default CentersPage;