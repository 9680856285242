import React from 'react';

import * as Components from '../index';

interface ProductAndDiscountsComponentProps {}

const ProductAndDiscountsComponent = (props : ProductAndDiscountsComponentProps) : JSX.Element => {
    return (
        <>
            <Components.ProductList />
            <Components.DiscountList />
        </>
    )
}

export default ProductAndDiscountsComponent;