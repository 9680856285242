import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Seasons'

export const SeasonsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetSeasons"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetSeasonsAdmin"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetActiveSeason"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("CloseActiveSeason"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("CreateSeason"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("UpdateSeason"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("RemoveSeason"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)        
        .setActionName("ChangeSeasonStatus")
        
        
]