import React, { useContext } from 'react'
import { Modal, Collapse, Button } from 'antd';

import './CookiesComponent.scss'
import { useEffect } from 'react';
import { useState } from 'react';

const { Panel } = Collapse;

interface CookiesComponentProps{
}

const CookiesComponent = (props: CookiesComponentProps) : JSX.Element => {

    const [visible, setVisible] = useState<boolean>(false);
    const [showCookies, setShowCookies] = useState<boolean>(false);

    const onOk = () : void => {
        setVisible(false);
        setShowCookies(false);
        const cname = "cookies_policy";

        const d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=accept;" + expires + ";path=/";
    }

    const onKo = () : void => {
            setVisible(false);
            window.location.href = "_blank";
    }

    const getCookie = (cname: string) : string => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

    useEffect(()=>{
        //Check if exist cookies data saved
        var cookiesData = document.cookie;

        if (!cookiesData || getCookie("cookies_policy") === ""){
            setShowCookies(true);
        }
    },[])

    return (
        <div className={`cookies ${showCookies ? 'visible': 'hidden'}`}>
            <p>
                Con su acuerdo usamos cookines o tecnologías similares para almacenar, acceder y procesar datos personales en este sitio web. Puede retirar su consentimiento u oponerse al procesamiento
                de datos basado en intereses legítimos en cualquier momento haciendo click en "Configuración".
            </p>
            <p>
                Si acepta accederemos y haremos uso de la información sobre los usuarios que se registran así como de los atletas, inscripciones y pagos que se registren a través de la aplicación. 
                Estos datos serán de uso interno y <strong>no se compartirán con terceros</strong>
            </p>
            <Modal
                className="cookies-modal"
                title="¿Para qué se utilizará mi información y quiénes la utilizarán?"
                style={{ top: 20 }}
                visible={visible}
                onCancel={()=>{setVisible(false)}}
                footer={[
                    <Button className="btn decline" key="back" onClick={onKo}>
                      Rechazar
                    </Button>,
                    <Button key="back" type="primary" onClick={onOk}>
                      Aceptar
                    </Button>,
                  ]}
            >
                <p>
                    Este sitio web almacena información necesaria para el correcto funcionamiento de la aplicación. En la parte inferior se detalla la información que se recopilará y su finalidad.
                    En caso de no estar de acuerdo con el uso de la información puede rechazarlas.
                </p>
                <p>
                    Pulsando "Aceptar todo" usted consiente y permite el uso de las cookies para las siguientes finalidades:
                </p>
                <Collapse defaultActiveKey={[]} >
                    <Panel header="Usuarios" key="1">
                        <p>Se recogerán datos tales como:</p>
                        <ul>
                            <li>Correo electrónico</li>
                            <li>Contraseña</li>
                            <li>Teléfono principal</li>
                            <li>Teléfono secundario</li>
                            <li>Dirección completa</li>
                            <li>Nombre y apellidos</li>
                            <li>DNI/NIE</li>
                        </ul>
                        <p>
                            La finalidad de la recopilación de estos datos es la de poder <strong>proporcionar el acceso a la aplicación de manera segura</strong> así como la de realizar comunicaciones a los Usuarios
                            a través del correo electrónico y/o teléfono móvil.
                        </p>
                    </Panel>
                    <Panel header="Atletas" key="2">
                        <p>Se recogerán datos tales como:</p>
                        <ul>
                            <li>Nombre y apellidos</li>
                            <li>Fecha de nacimiento</li>
                            <li>DNI/NIE (en caso de mayores de 14 años)</li>
                            <li>Relación familiar entre el atleta y el usuario de la aplicación</li>
                        </ul>
                        <p>
                            La finalidad de la recopilación de estos datos es la de poder realizar las inscripciones en el club.
                        </p>
                    </Panel>
                    <Panel header="Inscripciones" key="3">
                        <p>Se recogerán datos tales como:</p>
                        <ul>
                            <li>Ubicación del centro</li>
                            <li>Talla de uniformidad (en caso de requerir uniformidad)</li>
                        </ul>
                        <p>
                            La finalidad de la recopilación de estos datos es la de poder realizar las gestiones internas sobre la organización de los atletas en el sistema, poder identificar los núcleos deportivos a los que acuden los atletas así como poder mantener la información relativa a los estados de las inscripciones (Pagos, núcleos o tallas).
                        </p>
                    </Panel>
                    <Panel header="Inscripciones" key="4">
                        <p>Datos bancarios:</p>
                        <ul>
                            <li>Tarjeta de crédito</li>
                            <li>Datos de pagos</li>
                        </ul>
                        <p>
                            La finalidad de la recopilación de estos datos es la de poder realizar las gestiones internas sobre la realización y el estado de los pagos de las inscripciones del club. 
                            En este caso no almacenamos la información relativa a la tarjeta de crédito. Para este fin se utiliza una pasarela de pagos segura.
                        </p>
                    </Panel>
                </Collapse>
                <br/>
                <p>
                    Puede conocer más detalles sobre la política de cookies en la sección de registro a través del enlace <strong>"He leído, comprendo y acepto los términos y políticas de uso de la aplicación."  </strong>  
                </p>
            </Modal>
        
            <div className="buttons">
                <Button className="btn decline" type="primary" onClick={onKo}>Rechazar</Button>
                <Button className="btn more-info" type="primary" onClick={()=>{setVisible(true);}}>Más información</Button>
                <Button className="btn accept" type="primary" onClick={onOk}>Aceptar</Button>
            </div>
        </div>
    )
}


export default CookiesComponent;
