
import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Payments'

export const PaymentsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setPattern("{season}/{criteria}")        
        .setActionName("GetPayments"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("FullRefundPayment"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("DeletePayment"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setIsFile(true)
        .setActionName("GetEnrollmentsExcel"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setIsFile(true)
        .setActionName("NotifyPaymentError"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setIsFile(true)
        .setActionName("RetryPayment"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetPaymentData"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetMyBalance"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("CheckPaymentStatus")
]