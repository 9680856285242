import React from 'react'

import * as Components from '../../components'

import './TeachersPage.scss'


const TeachersPage = () : JSX.Element => {

    return (
        <div className="teachers-page">
            <div id="marker-app"></div>
            <Components.Groups />
        </div>
    )
}

export default TeachersPage;