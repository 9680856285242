import React from 'react'
import AppCfg from '../../models/AppCfg'

export let cfgDefault : AppCfg = {
    address: '',
    cfg: {
        cfgPaymentTypes: '',
        colorsSecondary: '',
        colorsMain: '',
        emailCfgUseDefaultCfg: true,
        emailCfgUser: '',
        emailTechnicalSupport: '',
        textDescriptions: '',
        textInitials: '',
        urlsLogoImg: '',
        urlsMainImg: '',
        urlsMainWeb: '',
        urlsShieldImg: ''
    },
    contactEmail: '',
    contactPhone: '',
    name: '',
    status: 'active'
} as AppCfg

const sessionAppConfig = sessionStorage.getItem("app-config");

if (sessionAppConfig !== null){
    cfgDefault = JSON.parse(sessionAppConfig) as AppCfg;
}

export const AppCfgContext = React.createContext(cfgDefault);
