import RestApiClient from "../api/restApiClient";
import ApiResponse from "../models/api/ApiResponse";
import ParamsApi from "../models/api/ParamsApi";



const restApiClient : RestApiClient = new RestApiClient();


export const LoginAsAnUser = (uid: string, email: string, callback: any) => {
    const params : ParamsApi = {
      query:{
        userId: uid
      }
    }

    restApiClient.fetch("PrepareDoLoginAsUser", params)
      .then((r : ApiResponse | null)=> {
          if (r && r.code === 200){
            if (callback){
              callback();
            }
            
              const data = r.data;
              const url = `${data.inscriptionsUrl}/login-as-user/${data.uniqueToken}/${email}`;
              window.open(url, "_blank")
          }
      })
}

export const BanTheUser = (userId: number, callback: any) : void => {
    if (userId){
      const params : ParamsApi = {
        query:{
          userId: userId
        }
      }

      restApiClient.fetch("BanUser", params)
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                callback();
            }
        })
    }
  }

  export const UnbanTheUser = (userId: number, callback: any) : void => {
    if (userId){
      const params : ParamsApi = {
        query:{
          userId: userId
        }
      }

      restApiClient.fetch("UnbanUser", params)
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                callback();
            }
        })
    }
  }

  export const CloseTheUserSession = (userId: number, callback: any) : void => {
    if (userId){
      const params : ParamsApi = {
        query:{
          userId: userId
        }
      }
      restApiClient.fetch("CloseUserSession", params)
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                callback();
            }
        })
    }
  }