import { AppstoreAddOutlined, BankOutlined, ClusterOutlined, DiffOutlined, EyeOutlined, GiftOutlined, HomeOutlined, IssuesCloseOutlined, MailOutlined, OrderedListOutlined, ScheduleOutlined, SettingOutlined, ShopOutlined, SmileOutlined, SolutionOutlined, TagsOutlined, TeamOutlined, ThunderboltOutlined, WechatOutlined } from '@ant-design/icons';
import {  Divider, Menu } from 'antd';
import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../hooks/context/userContext'


import './MenuTrainersComponent.scss'

const { SubMenu } = Menu;

interface MenuTrainersComponentProps {}

const MenuTrainersComponent = (props: MenuTrainersComponentProps) : JSX.Element => {

    const history = useHistory();
    let loginContext = useContext(LoginContext);
    
    const [current, setCurrent] = useState<string>('home')
    const [actualCurrent, setActualCurrent] = useState<string>('')
    const [currentName, setCurrentName] = useState<string>('');


    const setSection = (section: string) : void => {
        const sectionSplit : string[] = section.split("/");
        setCurrent(section)
        setActualCurrent(`actual-${section}`)
        switch(sectionSplit[0]){
            case "trainers":
                setCurrentName("Sección: Inicio");
                break;
            case "groups":
                setCurrentName("Sección: Mis grupos");
                break;
            case "emails":
                setCurrentName("Sección: Comunicaciones");
                break;
            case "docs":
                setCurrentName("Sección: Documentos");
                break;
            default:
                setCurrentName('');
                break;
        }
    }

    const handleClick = (e: any) => {
        if (e.key.indexOf('pdf-') !== 0 && e.key !== `actual-${current}` && e.key !== current){
            history.push(`/${e.key}`);
        }
    }

    useEffect(()=>{
        const pathName = history.location.pathname.replace("/","");
        setSection(pathName);
    },[]);

    return (
        <div className="app-menu">
            <Menu className="antd-menu" onClick={handleClick} selectedKeys={[current, actualCurrent]} mode="horizontal">
                <Menu.Item key="trainers" icon={<HomeOutlined />}>
                    Inicio
                </Menu.Item>

                <SubMenu title="Mi aplicación" key="my-app" icon={<AppstoreAddOutlined />}>
                    <Menu.Item key="emails" icon={<MailOutlined />} disabled>
                        Comunicaciones
                    </Menu.Item>
                    <Divider className="divider-no-margin"/>
                    <Menu.Item key="docs" icon={<DiffOutlined />} disabled>
                        Documentación
                    </Menu.Item>
                </SubMenu>

                <Menu.Item className="current-page" key={`actual-${current}`}>
                    {currentName}
                </Menu.Item>
            </Menu>
            
        </div>
    )
}

export default MenuTrainersComponent;