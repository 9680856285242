import { AndroidOutlined, ApartmentOutlined, AppstoreAddOutlined, BankOutlined, CalendarOutlined, ClusterOutlined, DiffOutlined, EyeOutlined, GiftOutlined, HomeOutlined, IssuesCloseOutlined, MailOutlined, OrderedListOutlined, ScheduleOutlined, SettingOutlined, ShopOutlined, SmileOutlined, SnippetsOutlined, SolutionOutlined, TagsOutlined, TeamOutlined, ThunderboltOutlined, UsergroupAddOutlined, WechatOutlined } from '@ant-design/icons';
import {  Divider, Menu } from 'antd';
import React, { useContext, useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../hooks/context/userContext'


import './MenuComponent.scss'

const { SubMenu } = Menu;

interface MenuComponentProps {}

const MenuComponent = (props: MenuComponentProps) : JSX.Element => {
    const headerRef = useRef(null);
    const markerRef = useRef(null);

    const history = useHistory();
    let loginContext = useContext(LoginContext);
    
    const [sticky, setSticky] = useState<string>('');
    
    const [current, setCurrent] = useState<string>('home')
    const [actualCurrent, setActualCurrent] = useState<string>('')
    const [currentName, setCurrentName] = useState<string>('');


    const setSection = (section: string) : void => {
        const sectionSplit : string[] = section.split("/");
        setCurrent(section)
        setActualCurrent(`actual-${section}`)
        switch(sectionSplit[0]){
            case "global":
            case "dashboard":
            case "v2":
                setCurrentName("Sección: Inicio");
                break;
            case "enrollments":
                setCurrentName("Sección: Inscripciones");
                break;
            case "payments":
                setCurrentName("Sección: Pagos");
                break;
            case "users":
                setCurrentName("Sección: Usuarios");
                break;
            case "students":
                setCurrentName("Sección: Alumnos");
                break;
            case "centers":
                setCurrentName("Sección: Centros");
                break;
            case "groups":
                setCurrentName("Sección: Grupos");
                break;
            case "emails":
                setCurrentName("Sección: Comunicaciones");
                break;
            case "android":
                setCurrentName("Sección: Notificaciones (Android)");
                break;
            case "docs":
                setCurrentName("Sección: Documentos");
                break;
            case "products":
                setCurrentName("Sección: Productos");
                break;
            case "discounts":
                setCurrentName("Sección: Descuentos");
                break;
            case "customization":
                setCurrentName("Sección: Personalización");
                break;
            case "old-students":
                setCurrentName("Sección: Antiguos alumnos");
                break;
            case "seasons":
                    setCurrentName("Sección: Temporadas/Cursos");
                    break;
            case "remittances": 
                setCurrentName("Sección: Remesas");
                break;
            case "categories":
                setCurrentName("Sección: Categorias");
                break;
            default:
                setCurrentName('');
                break;
        }
    }

    const handleClick = (e: any) => {
        if (e && e.key && e.key.indexOf('pdf-') !== 0 && e.key !== `actual-${current}` && e.key !== current){
            history.push(`/${e.key}`);
        }
    }

    const updateReferencesScrolls = () : void => {
        var header : any = headerRef.current;
        var marker : any = document.getElementById("marker-app");

        if (header && marker){
            const headerYPosition : number = header.getBoundingClientRect().top;
            const markerYPosition : number = marker.getBoundingClientRect().top;

            try{
                if (headerYPosition < 0){
                    setSticky("sticky");
                }else if (headerYPosition <= markerYPosition && markerYPosition > 0){
                    setSticky("");
                }
            }catch(e){
            }
            
        }
    }

    useEffect(()=>{
        const pathName = history.location.pathname.replace("/","");
        setSection(pathName);
    },[]);

    useEffect(() => {
        const onScroll = (e : any) => {
            updateReferencesScrolls();
        };
        document.getElementById("container")?.addEventListener("scroll", onScroll);
    
        return () => document.getElementById("container")?.removeEventListener("scroll", onScroll);
      }, []);

    return (
        <div className={`app-menu ${sticky}`}>
            <div id="header" ref={headerRef} >
                <Menu className="antd-menu" onClick={handleClick} selectedKeys={[current, actualCurrent]} mode="horizontal">
                    <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                        Inicio
                    </Menu.Item>

                    <SubMenu title="Mi escuela" key="my-school" icon={<ShopOutlined />}>
                        <Menu.Item key="centers" icon={<BankOutlined />}>
                            Centros
                        </Menu.Item>
                        <Menu.Item key="groups" icon={<ClusterOutlined />}>
                            Grupos
                        </Menu.Item>
                        <Divider className="divider-no-margin"/>
                        <Menu.Item key="seasons" icon={<CalendarOutlined />}>
                            Temporadas/Cursos
                        </Menu.Item>
                        <Menu.Item key="categories" icon={<ApartmentOutlined />}>
                            Categorias
                        </Menu.Item>
                        <Divider className="divider-no-margin"/>
                        <Menu.Item key="old-students" icon={<UsergroupAddOutlined />}>
                            Antiguos Alumnos
                        </Menu.Item>
                        <Menu.Item key="old-students" icon={<UsergroupAddOutlined />}>
                            Exclusión Antiguos Alumnos
                        </Menu.Item>
                    </SubMenu>

                    <SubMenu title="Mi aplicación" key="my-app" icon={<AppstoreAddOutlined />}>
                        <Menu.Item key="users" icon={<TeamOutlined />}>
                            Usuarios
                        </Menu.Item>
                        <Divider className="divider-no-margin"/>
                        <Menu.Item key="emails" icon={<MailOutlined />}>
                            Comunicaciones (Correo)
                        </Menu.Item>
                        <Menu.Item key="android" icon={<AndroidOutlined />}>
                            Notificaciones (Android)
                        </Menu.Item>
                        <Divider className="divider-no-margin"/>
                        <Menu.Item key="docs" icon={<DiffOutlined />}>
                            Documentación
                        </Menu.Item>
                        <Divider className="divider-no-margin"/>
                        <Menu.Item key="products" icon={<GiftOutlined />}>
                            Gestión de productos
                        </Menu.Item>
                        <Menu.Item key="discounts" icon={<TagsOutlined />}>
                            Gestión de descuentos
                        </Menu.Item>
                    </SubMenu>

                    
                    <SubMenu title="Configuración" key="configuration" icon={<SettingOutlined />}>
                        <Menu.Item key="customization" icon={<IssuesCloseOutlined />}>Personalización</Menu.Item>
                    </SubMenu>
                    <Menu.Item className="current-page" key={`actual-${current}`}>
                        {currentName}
                    </Menu.Item>
                </Menu>
            </div>
            
            
        </div>
    )
}

export default MenuComponent;