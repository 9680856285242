import React from 'react'

import * as Components from '../../components'

import './ProfilePage.scss'

const ProfilePage = () : JSX.Element => {
    return (
        <div className="profile-page">
            <div id="marker-app"></div>
            <Components.Profile />
        </div>
    )
}

export default ProfilePage;