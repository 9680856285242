import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import RestApiClient from "../../../api/restApiClient";
import ApiResponse from "../../../models/api/ApiResponse";
import ParamsApi from "../../../models/api/ParamsApi";
import LoadingOrComponent from "../../core/utils/LoadingOrComponent";

import './CreateCategoryComponent.scss'


interface CreateCategoryComponentProps{
    onFinish: () => void,
    onReload: () => void
}

const CreateCategoryComponent = (props: CreateCategoryComponentProps): JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const [savingData, setSavingData] = useState<boolean>(false);
    const onFinish = () : void => {
        const paramsApi : ParamsApi = {
            body: form.getFieldsValue()
        }

        setSavingData(true);
        restApiClient.fetch("AddCategory", paramsApi).then((r : ApiResponse | null)=> {
            if (r !== null && r?.code === 200){
                form.resetFields();
                props.onReload();
                props.onFinish();
            }
        }).finally(()=>{
            setSavingData(false);
        })
    }

    const cancelAdd = () : void => {
        props.onFinish();
    }

    const clearForm = () : void => {
        form.resetFields();
    }

    return(
        <div className="create-category-component">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Nombre categoria"
                    name="description"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item
                    label="Edad desde"
                    help="Indica con decimales el valor de la edad. Ejp: 4.15 equivale a 4 años y 3 meses"
                    name="ageFrom"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input type="number" style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item
                    label="Edad hasta"
                    help="Indica con decimales el valor de la edad. Ejp: 3.75 equivale a 3 años y 9 meses"
                    name="ageTo"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input type="number" style={{width: "100%"}}/>
                </Form.Item>
                <div className="btn-actions">
                    <Button disabled={savingData} onClick={clearForm}>Limpiar</Button>
                    <Button disabled={savingData} type="primary" htmlType="submit">
                        <LoadingOrComponent loading={savingData} showSpinner text={"Guardar"} loadingText={"Guardando..."} />
                    </Button>
                    <Button disabled={savingData} type="ghost" onClick={cancelAdd} danger>Cancelar</Button>
                </div>
            </Form>
        </div>
    )

}

export default CreateCategoryComponent;