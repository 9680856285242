import { PoweroffOutlined, PushpinOutlined, TeamOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../hooks/context/userContext'
import { useHistory } from "react-router-dom";

import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';

import './SessionComponent.scss'
import { Tooltip } from 'antd';
import RestApiClient from '../../api/restApiClient';

interface SessionComponentProps{}

const SessionComponent = (props: SessionComponentProps) : JSX.Element => {
    let loginContext = useContext(LoginContext);
    const history = useHistory();

    const restApiClient : RestApiClient = new RestApiClient();
    
    const [isPinned, setIsPinned] = useState<boolean>(false);

    const pinPage = () : void => {
        var actualPinned = localStorage.getItem("pin-page");

        if (window.location.pathname == actualPinned){
            localStorage.removeItem("pin-page");
            setIsPinned(false);
        }else{
            localStorage.setItem("pin-page", window.location.pathname);
            setIsPinned(true);    
        }
    }

    const closeSession = () : void => {

        restApiClient.fetch("DoLogout", {
            query:{
                sessionId: sessionStorage.getItem("token")
            }
        }).finally(()=>{
            restApiClient.closeSession();
            history.push("/");
        });
    }

    useEffect(()=>{
        var actualPinned = localStorage.getItem("pin-page");
        setIsPinned(actualPinned == window.location.pathname);
    },[window.location.pathname])

    return (
        <div className="session">
            <Tooltip title={`${loginContext.name} ${loginContext.surname} ${loginContext.secondSurname}`}>
                <TeamOutlined className={`user-profile-access role-${loginContext.id}`} onClick={()=>{history.push(`/profile`)}}/>
                <span className="username">
                </span>
            </Tooltip>
            <Tooltip title={`${isPinned ? 'Quitar fijación' : 'Fijar página al inicio'}`} placement="bottomRight" >
                <PushpinOutlined className={`pin-page ${isPinned ? 'pinned' : ''}`} onClick={pinPage}/>
            </Tooltip>
            <Tooltip title="Cerrar sesión" placement="bottomRight" >
                <PoweroffOutlined className={`logout`}onClick={closeSession}/>
            </Tooltip>
            
        </div>
    )
}

export default SessionComponent;