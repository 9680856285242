import { AppstoreOutlined, CheckOutlined, CheckSquareOutlined, ClearOutlined, FilterOutlined, LoadingOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, List, Menu, Pagination, Progress, Skeleton, Table, Typography } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import { useHistory } from "react-router-dom";

import './UtilsStyles.scss';
import ActionButton from '../../../models/ActionButton';
import moment from 'moment';

const { Title } = Typography;

interface Props {
    reloadCounter?:number,
    columns: any[],
    expandableInfo?: any,
    aliasEndPoint: string,
    queryParamsMode?: 'query' | 'path' | 'body',
    paramsEndPoint?: any,
    title?:any
    subtitle?:any,
    showCheckbox?: boolean,
    onCheckedItem?: any,
    filterButtons?: any[],
    defaultFilter?: string,
    actionButtons?: ActionButton[]
    hideTextOfFiltersAndButtonsOnMobile?:boolean,
    contextualMenu?: any,
    contextualParameters?:any,
    entityName: string,
    forceRefresh?: number,
    selectedRowKeys?: number[],
    showFilters?:boolean,
    initialPageSize?: number,
    onFilter?: (e:any)=>void,
    loadingData: boolean,
    useTableScroll: any
}

const TablePaginationComponent = forwardRef((props: Props, ref)=>{
    const history = useHistory();

    const restApiClient : RestApiClient = new RestApiClient();

    const { aliasEndPoint, title, subtitle, showCheckbox, filterButtons, actionButtons, entityName, useTableScroll} = props;

    const defaultFilterApply = [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>];

    const [filterApply, setFilterApply] = useState<any[]>(defaultFilterApply)

    const [anchor, setAnchor] = useState<number>(24);
    const [source, setSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [expandableInfo, setExpandableInfo] = useState<any>();
    const [actualPage, setActualPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(props.initialPageSize ?? 10);
    const [reloadCounter, setReloadCounter] = useState<number>(0);

    const [filter, setFilter] = useState<string | undefined>(undefined);
    const [defaultFilter, setDefaultFilter] = useState<string>("");

    const [totalPages, setTotalPages] = useState<number>(0);
    const [selected, setSelected] = useState<any>();
    const [responsive, setResponsive] = useState<string>("mobile");
    const [list, setList] = useState<JSX.Element>(<></>);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const [inputValue, setInputValue] = useState<string>('');


    useImperativeHandle(ref, () => ({
        
        updateFilter(filter: string) {
            setFilter(filter);
            setInputValue(filter);
        },
        getFilter(){
            return filter;
        }
    
    }));

    const checkFilterApplied = (i:number): void =>{
            filterApply.fill(<></>,0, 50);
            filterApply[i] = <CheckSquareOutlined className="icon-filter-applied"/>;
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();
    useEffect(()=>{
        setSelectedRowKeys(props.selectedRowKeys);
    },[props.selectedRowKeys])

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            //Buscar una manera mejor, solo es para que refreseque los datos porque
            //sino el checkbox parece que no se marca. Un ejp que funciona https://codesandbox.io/embed/priceless-ramanujan-dkygk
            setSelectedRowKeys(selectedRows);
            const loquehabiaantes = source;
            setSource([]);
            setTimeout(()=>{
                setSource(loquehabiaantes)
            },0);
            if ( props.onCheckedItem && props.onCheckedItem instanceof Function){
                props.onCheckedItem(selectedRowKeys);
            }
        }
    };

    const getJSXList = () : JSX.Element => {
        let result : JSX.Element = <></>;

        if (responsive === "mobile" && columns.some(c => c.renderMobile)) {
            result = <List
                className={`demo-loadmore-list ${moment().format("YYYYMMDDHHmmss")}`}
                itemLayout="vertical"
                dataSource={source}
                renderItem={item =>
                    <List.Item style={{border: "none", padding: "5px"}}
                                    actions={[]}
                                    >
                        {
                            columns.map((column, index) => {
                                if (column.renderMobile){
                                    return <div key={index}>
                                                {column.renderMobile(index, item)}
                                            </div>
                                }
                            })
                        }
                    </List.Item>
                }
            />;
        }else{
            result = <Table rowKey="id" 
                            size="small"
                            rowSelection={
                                showCheckbox ? {
                                        type:"checkbox",
                                        ...rowSelection
                                    } : undefined
                                }
                            scroll={useTableScroll ?? undefined}
                            dataSource={source} 
                            columns={columns} 
                            pagination={false}
                            expandable={{
                                ...expandableInfo
                            }}
                        />;
        }

        return result;
    }

    const getData = () : void => {
        var increment  =0;
        var interval = setInterval(() => {
            increment += 1;
            if (increment < 100){
                setPercentLoaded(increment);
            }
        }, 60);

        let params = {
            page: isNaN(actualPage-1) ? 0 : actualPage-1,
            N: pageSize,
            //filter: (!theFilter && !filter && props.defaultFilter && props.defaultFilter.length > 0) ? props.defaultFilter : theFilter ?? filter
            filter: filter
        };

        if (props.paramsEndPoint){
            params = {
                ...params,
                ...props.paramsEndPoint
            };
        }

        const paramsApi : ParamsApi = {}
        if (!props.queryParamsMode || props.queryParamsMode === "query"){
            paramsApi.query = params;
        }else if (props.queryParamsMode === "path"){
            paramsApi.path = params;
        }else if (props.queryParamsMode === "body"){
            paramsApi.body = params;
        }

        setLoadingData(true);
        restApiClient.fetch(aliasEndPoint, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSource(r.data.elements);
                    setTotalPages(r.data.totalPages);

                    if (r.data.totalPages >= r.data.actualPage+1){
                        setActualPage(r.data.actualPage+1);
                    }else{
                        setActualPage(1);
                    }
                    setTotalRecords(r.data.totalRecords);
                }
            }).finally(()=>{
                setLoadingData(false);
                clearInterval(interval);
                setPercentLoaded(100);
                setTimeout(()=>{
                    setPercentLoaded(0);
                },3000)
            });
    }


    const filterData = (e: any) : void => {
        setFilter(inputValue);
        //Informamos al padre si es necesrio
        
    }

    useEffect(()=>{
        setLoadingData(props.loadingData);
    },[props.loadingData])

    useEffect(()=>{
        if (props.onFilter && filter){
            props.onFilter(filter);
        }
    },[filter])

    const onChange = (pageNumber: any, pageSize: any)=>{
        setPageSize(pageSize);
        setActualPage(pageNumber);
    }


    useEffect(()=>{
        if (filter !== undefined){
            getData();
        }
        
    },[actualPage, pageSize, filter]);

    useEffect(()=>{
        if (props.defaultFilter !== undefined && props.defaultFilter !== null && (props.defaultFilter.length > 0 || props.defaultFilter === "") && props.defaultFilter != filter){
            setFilter(props.defaultFilter);
        }else if (props.defaultFilter == undefined || props.defaultFilter == null){
            setFilter("");
        }
    },[]);

    useEffect(()=>{
        if(props.columns && props.columns.length > 0){
            if (props.contextualMenu){
                setColumns(props.columns.concat({
                    title: '',
                    key: 'actions',
                    render: (i: number, element: any) => {
                        return <>
                                    <AppstoreOutlined className={`custom-table-actions ${element.id === selected?.id ? 'record-selected' : 'record-unselected'}`} onClick={()=>{setSelected(element);}}/>
                               </> 
            
                    }
                }));
            }else{
                setColumns(props.columns);
            }
        }else{
            setColumns([]);
        }
    }, [props.columns]);

    useEffect(()=>{
        if(props.expandableInfo){
            setExpandableInfo(props.expandableInfo);
        }else{
            setExpandableInfo(undefined);
        }
    }, [props.expandableInfo]);

    useEffect(()=>{
        if (selected){
            setAnchor(23);
        }else{
            setAnchor(24);
        }
    },[selected]);

    useEffect(()=>{
        getJSXList();
    },[source]);

    useEffect(() => {
        const updateWindowDimensions = () => {
            const width : number = window.innerWidth;

            if(width < 768){
                setResponsive("mobile");
            }else if(width < 992){
                setResponsive("tablet");
            }else{
                setResponsive("desktop");
            }
        };

        updateWindowDimensions();

        window.addEventListener("resize", updateWindowDimensions);
    
        return () => window.removeEventListener("resize", updateWindowDimensions) 
    
      }, []);

      useEffect(() => {
        setList(getJSXList());
    }, [source,responsive]);

    useEffect(() => {
        if(props.defaultFilter && props.defaultFilter.length > 0){
            setDefaultFilter(props.defaultFilter);  
        }
        setList(getJSXList());
    }, []);

    useEffect(()=>{
        if (props.forceRefresh && props.forceRefresh > 0){
            getData();
        }
    },[props.forceRefresh]);

    useEffect(()=>{
        if (props.reloadCounter && !isNaN(props.reloadCounter) && props.reloadCounter != reloadCounter && props.reloadCounter > 0){
            setReloadCounter(props.reloadCounter);
            getData();
        }
    },[props.reloadCounter])

    const [percentLoaded, setPercentLoaded] = useState<number>(0);

    return (
        <div className="table-pagination">
            {
                //https://stackoverflow.com/questions/40837075/how-can-i-create-a-progress-bar-for-an-api-request-in-react-native
            }
            <Progress
                className={`hide-on-mobile ${loadingData ? '' : 'fade-out'}`}
                strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                }}
                percent={percentLoaded}
                />
            {
                title ?
                    <Title level={5}>
                        {title} ({totalRecords})
                        {
                            subtitle ?
                                <>{subtitle}</>
                            :
                            ''
                        }
                    </Title>
                :
                ''
            }
            

            {
                actionButtons || filterButtons ?
                    <Col xs={24} md={anchor} className={`tp-filter-buttons ${!props.hideTextOfFiltersAndButtonsOnMobile ? 'in-columns' : ''}`}>
                        {
                            actionButtons ? 
                                actionButtons.filter((ab: ActionButton)=>ab.noShow === undefined || ab.noShow === true).map((ab: ActionButton, i: number)=>{
                                    return ab.item ?? (
                                        <Button type={ab.type ?? "primary"} className={ab.className ?? 'btn-secondary'} style={ab.styles ? ab.styles : {}} key={`ab-${i}`} onClick={ab.action}>
                                            {ab.icon} <span className={props.hideTextOfFiltersAndButtonsOnMobile ? 'hide-on-mobile' : ''}>{ab.label}</span>
                                        </Button>
                                    )
                                })
                                :
                                ''
                        }
                        {
                            filterButtons ?
                                <Dropdown.Button type="primary" overlay={
                                    <Menu onClick={()=>{}}>
                                        {
                                            filterButtons.map((filter: any, i:number)=>{
                                                if (filter === "separator"){
                                                    return <Menu.Divider key={`separator-${i}`}/>
                                                }else{
                                                    return (
                                                        <Menu.Item key={`f-${filter.label}`} onClick={()=>{setInputValue(filter.value); setFilter(filter.value); checkFilterApplied(i)}}>{filterApply[i]}Filtrar por <span className="filter-label">{filter.label}</span> </Menu.Item>
                                                    )
                                                }
                                                
                                            })
                                        }
                                    </Menu>
                                }><FilterOutlined size={3}/> 
                                    <span className={props.hideTextOfFiltersAndButtonsOnMobile ? 'hide-on-mobile' : ''}>Filtros</span>
                                </Dropdown.Button>
                            :
                            ''
                        }
                    </Col>
                    :
                    ''
            }
            
            
                    <Col xs={24} sm={24} md={anchor}>
                        <Input.Group compact style={{display:"flex", marginBottom: "5px", justifyContent: "flex-end"}}>
                        {
                            props.showFilters === undefined || (props.showFilters && props.showFilters === true)  ? 
                                <>
                                    <Input size="small" disabled={loadingData} onPressEnter={(e:any)=>{filterData(e.target.value)}} onChange={(e:any)=>{setInputValue(e.target.value)}} value={inputValue} placeholder="Escribe y pulsa ENTER para buscar"/>
                                    <Button size="small" disabled={loadingData} onClick={filterData}>
                                        <SearchOutlined />
                                    </Button>
                                    <Button size="small" disabled={loadingData} danger onClick={()=>{
                                        setInputValue("");
                                        setFilter("");
                                        setFilterApply(defaultFilterApply);
                                    }}>
                                        <ClearOutlined />
                                    </Button>
                                    <Button size="small" type="primary" className='btn-secondary' disabled={loadingData} onClick={()=>{
                                        getData();
                                    }}>
                                        {
                                            loadingData ? <LoadingOutlined /> : <ReloadOutlined />
                                        }  
                                    </Button>  
                                    
                                </>
                            : 
                            <Button size="small" type="primary" className='btn-secondary' onClick={()=>{
                                getData();
                            }}>
                            <ReloadOutlined />
                            </Button>    
                        }
                        </Input.Group>
                    </Col>
            
            
            
            <Col xs={24} sm={24} md={anchor}>
                <Skeleton loading={loadingData} active>
                    {list}
                </Skeleton>
            </Col>
            <Col xs={24} sm={24} md={anchor} >
                <Pagination 
                    style={{marginBottom: "25px"}}
                    key={`pagination-${actualPage}`}
                    className="pagination"
                    showSizeChanger
                    pageSizeOptions={["1","10","20","30","50","100","300","500","1000"]} 
                    onChange={onChange} 
                    current={actualPage} 
                    total={totalRecords} 
                    pageSize={pageSize}
                    showTotal={total => ``}
                    />
            </Col>
        </div>)
});

export default TablePaginationComponent;