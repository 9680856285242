import React, { useState } from 'react'

import { GiftOutlined, LoadingOutlined, RollbackOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, notification, Row, Select, Tabs, Typography } from 'antd';
import RestApiClient from '../../../../api/restApiClient';
import ApiResponse from '../../../../models/api/ApiResponse';
import SportCenter from '../../../../models/SportCenter';
import { useEffect } from 'react';
import ProductInfo from '../../../../models/ProductInfo';
import Enumerable from 'linq';
import ParamsApi from '../../../../models/api/ParamsApi';

const { Title } = Typography;
const { Option } = Select;

interface AddDiscountFormComponentProps {
    onclose : any,
    onreload : any,
}

interface MinimalProductInfo {
    id : number,
    name: string,
    sportCenterName?: string,
    sportCenterId?: number,
    productType?: 'Subscription' | 'OnePay' | 'PayPerUse' | ''
}

const AddDiscountFormComponent = (props : AddDiscountFormComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [form] = Form.useForm();

    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);
    const [products, setProducts] = useState<MinimalProductInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSportCenter, setSelectedSportCenter] = useState<number>(0);
    const [selectedProduct, setSelectedProduct] = useState<number>(0);
    const [selectedProductType, setSelectedProductType] = useState<'' | 'OnePay' | 'PayPerUse' | 'Subscription'>('')

    const clearForm = () : void => {
        form.resetFields();
        getSportCenters();
        getProducts();
    }

    const getSportCenters = () : void => {
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                })
    }

    const getProducts = () : void => {
        restApiClient.fetch("GetProducts", {}).then((r : ApiResponse | null)=>{
            if (r?.code === 200){
                const minimalProductData : MinimalProductInfo[] = []
                const onePayList = groupByIdCategory(r.data.filter((d : ProductInfo) => d.type === "OnePay" && d.active));
                const payPerUseList = groupByIdCategory(r.data.filter((d : ProductInfo) => d.type === "PayPerUse" && d.active));
                const subscriptionList = groupByIdCategory(r.data.filter((d : ProductInfo) => d.type === "Subscription" && d.active));

                onePayList.forEach((p)=>{
                    p.forEach(product => {
                        minimalProductData.push({id: product.id, name : product.name, sportCenterName : product.sportCenters?.description, sportCenterId : product.sportCenters?.id, productType : product.type})
                    })
                });

                payPerUseList.forEach((p)=>{
                    p.forEach(product => {
                        minimalProductData.push({id: product.id, name : product.name, sportCenterName : product.sportCenters?.description, sportCenterId : product.sportCenters?.id, productType : product.type})
                    })
                });

                subscriptionList.forEach((p)=>{
                    minimalProductData.push({
                        id: p[0].id,
                        name: p[0].name,
                        sportCenterName : p[0].sportCenters?.description,
                        sportCenterId : p[0].sportCenters?.id,
                        productType : p[0].type
                    })
                });

                setProducts(minimalProductData);
            }
        });
    }

    const groupByIdCategory = (list: ProductInfo[]) : ProductInfo[][] => {
        const grouped = Enumerable.from(list).groupBy(s => s.hash, function(e){ return e }).toArray()
        return grouped.map(e => Enumerable.from(e.getSource()).orderBy(s => s.id).toArray());
    }

    const close = (reload: boolean = false) : void => {
        props.onclose();
        if (reload){
            props.onreload();
        }
    }

    const finish = (formFields : any) : void => {

        formFields.idSportCenter = formFields.idSportCenter == 0 ? null : formFields.idSportCenter;
        formFields.idProduct = formFields.idProduct == 0 ? null : formFields.idProduct;
        formFields.active = formFields.active == 1;
        formFields.accumulative = formFields.accumulative == 1;
        formFields.requiredPreviousEnrollments = formFields.requiredPreviousEnrollments == 1;
        formFields.discountAmount = Number.parseInt(formFields.discountAmount);
        formFields.requiredPreviousEnrollmentsAmount = Number.parseInt(formFields.requiredPreviousEnrollmentsAmount);


        const params : ParamsApi = {
            body: formFields
        }

        if (selectedProductType == 'PayPerUse' && formFields.discountType == 'fixed'){
            notification.warn({
                message: 'Atención',
                description: 'No es posible crear un descuento fijo para un producto de pago por uso. Por favor, revise la información proporcionada.',
              })
        }else{
            setLoading(true);
            restApiClient.fetch("AddDiscount", params)
                    .then((r : ApiResponse | null) => {
                        props.onreload();
                        props.onclose();
                    })
                    .finally(()=>{setLoading(false)})
        }

        
    }


    useEffect(()=>{
        getSportCenters();
        getProducts();
    },[]);

    return (
        <Form form={form} layout="vertical" onFinish={finish}>
            <Row gutter={16}>
                <Col xs={24}>
                    <Title className="c-title"  level={3}>
                        <GiftOutlined /> &nbsp;
                        Alta de descuentos
                        <small className="c-sub-title">
                            Crea tus nuevos descuentos
                        </small>
                    </Title>
                </Col>
                <Col xs={24}>
                    <Button type="link" onClick={()=>{close(false)}}><RollbackOutlined /> Volver al listado</Button>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                            name="active"
                            className="form-item"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            label="Descuento activo"
                        >
                            <Select placeholder="Seleccione el tipo de descuento que desea aplicar" >
                                <Option value="1">Si</Option>
                                <Option value="0">No</Option>
                            </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                            name="accumulative"
                            className="form-item"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            label="Permitir que el descuento sea acumulable"
                        >
                            <Select placeholder="Seleccione el tipo de descuento que desea aplicar" >
                                <Option value="1">Si</Option>
                                <Option value="0">No</Option>
                            </Select>
                    </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={8} lg={8}> 
                    <Form.Item
                            name="discountCode"
                            className="form-item"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            label="Código promocional"
                        >
                            <Input type="text" placeholder="Indica el código promocional interno. Este código puede verse por el usuario" maxLength={9}/>
                    </Form.Item>
                </Col>
                <Col xs={24}>  
                    <Form.Item
                            name="discountDescription"
                            className="form-item"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            label="Descripción"
                        >
                            <Input type="text" placeholder="Descripción corta. Esta descripción puede verse por el usuario" maxLength={200}/>
                    </Form.Item> 
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}> 
                    <Form.Item
                            name="idSportCenter"
                            className="form-item"
                            label="Centro"
                        >
                            <Select placeholder="Indique el centro. Deje en blanco para todos" onChange={(v:number)=>{setSelectedSportCenter(v)}}>
                                <Option key={0} value={0}>Cualquiera</Option>
                                {
                                    sportCenters?.map((sportCenter : SportCenter, index: number) => {
                                        return <Option disabled={selectedProduct > 0 && sportCenter.id !== selectedProduct} key={index} value={sportCenter.id}>{sportCenter.description}</Option>
                                    })
                                }
                            </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>  
                    <Form.Item
                            name="idProduct"
                            className="form-item"
                            label="Producto asociado"
                        >
                            <Select placeholder="Indique un producto. Deje en blanco para todos"  onChange={(v:number)=>{const product = products.find(p=>p.id==v); setSelectedProduct(product?.sportCenterId || 0); setSelectedProductType(product?.productType || '')}}>
                                <Option key={0} value={0}>Cualquiera</Option>
                                {
                                    products?.map((product : MinimalProductInfo, index: number) => {
                                        return <Option disabled={product.sportCenterId !== undefined && product.sportCenterId !== selectedSportCenter && selectedSportCenter > 0} key={index} value={product.id}>{product.id < 10 ? `0${product.id}` : product.id}  - {product.name} {product.sportCenterName?`(${product.sportCenterName})`:''}</Option>
                                    })
                                }
                            </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>  
                    <Form.Item
                            name="requiredPreviousEnrollments"
                            className="form-item"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            label="Aplica solo si tiene inscripciones en temporadas pasadas"
                        >
                            <Select placeholder="Indique si este descuento solo aplica en caso de tener inscripciones en temporadas pasadas" >
                                <Option value="1">Si</Option>
                                <Option value="0">No</Option>
                            </Select>
                    </Form.Item> 
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}> 
                    <Form.Item
                            name="requiredPreviousEnrollmentsAmount"
                            className="form-item"
                            label="Número de inscripciones previas"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                        >
                            <Input placeholder="Indique el número de reservas previas (en esta temporada) que debe tener para que se aplique." type="number" min={0} max={999}/>
                    </Form.Item> 
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}> 
                    <Form.Item
                            name="discountType"
                            className="form-item"
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            label={<label>Tipo de descuento <small style={{display:"block"}}>El descuento se aplicará sobre el total de la inscripción.</small></label>}
                        >
                            <Select placeholder="Seleccione el tipo de descuento que desea aplicar" >
                                <Option value="fixed" disabled={selectedProductType == 'PayPerUse'}>Fijo</Option>
                                <Option value="percent">Porcentaje</Option>
                            </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>  
                    <Form.Item
                            name="discountAmount"
                            className="form-item"
                            label={<label>Cantidad a descontar <small style={{display:"block"}}>Si selecciona % deberá poner un valor máximo de 100. El excedente no se tendrá en cuenta.</small></label>}
                            rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            initialValue={1}
                        >
                            <Input placeholder="Indique la cantidad a descontar." type="number" min={1} max={9999} step={0.01}/>
                    </Form.Item>
                </Col>
                <Col xs={24}>  
                    <Form.Item style={{textAlign:"right"}}>
                        <Button onClick={clearForm}>
                            Limpiar formulario
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            { loading ? <><LoadingOutlined />&nbsp;</> : '' }
                            Crear descuento
                        </Button>
                        
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Alert message={<>Recuerda que los descuentos fijos <strong>NO</strong> aplican a los productos de 'pago por uso', solo aplicarán los descuentos porcentuales. Esto se debe a que el pago por uso no tiene una duración determinada y por tanto no puede calcularse el descuento a aplicar en las cuotas.</>} type="info" showIcon />
                </Col>
            </Row>
        </Form>
    )
}

export default AddDiscountFormComponent;