import { CoffeeOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import Avatar from 'antd/lib/avatar';
import moment from 'moment';
import React, { useState } from 'react'
import { Box } from '..';
import Enrollment from '../../models/Enrollments';

import './UserResumeComponent.scss'
import EnrollmentDetailComponent from '../enrollments/enrollments.detail';
import { getInitialsBox } from '../../utils/GeneralUtils';

interface EnrollmentsInfoProps{
    enrollment: Enrollment
}
const EnrollmentInfoComponent = (props: EnrollmentsInfoProps) : JSX.Element => {
    const {enrollment} = props;
    const [showActions, setShowActions] = useState<boolean>(false);

    const GetStatusTag = (state: string) : JSX.Element => {
        let result : JSX.Element = <></>
    
        switch(state){
          case "free":
            result = <Tag className="enrollment-status-tag" color="green"><CoffeeOutlined /> Gratuita</Tag>;
            break;
          case "active":
            result = <Tag className="enrollment-status-tag" color="green">Activa</Tag>;
            break;
          case "revision":
            result = <Tag className="enrollment-status-tag" color="warning">P. Revisión</Tag>;
            break;
          case "cancelled":
            result = <Tag className="enrollment-status-tag" color="magenta">Cancelada</Tag>;
            break;
          case "finished":
            result = <Tag className="enrollment-status-tag" color="cyan">Finalizada</Tag>;
            break;
          case "pre":
            result = <Tag className="enrollment-status-tag" color="volcano">PRE</Tag>;
            break;
          case "expired":
            result = <Tag className="enrollment-status-tag" color="purple">Caducada</Tag>;
            break;
        }
    
        return result;
      }

    return(
        <Box type='ultra-small' noBorders > 
                            <div className="user-card">
                                <div className="header-card" onClick={()=>{setShowActions(!showActions)}}>
                                    <span className="payment-info">
                                        <div className="icon">
                                            {
                                                showActions ? <DownOutlined className="additional-info-arrow"/> : <RightOutlined className="additional-info-arrow"/>
                                            }
                                            {
                                                getInitialsBox(enrollment?.status[0], enrollment?.status[1])
                                            } 
                                            &nbsp;
                                            &nbsp;
                                        </div>
                                        <div className="additional-info">
                                            <span> 
                                                {enrollment.productInfo?.name} {GetStatusTag(enrollment.status)}
                                            </span>
                                            {
                                                ["finished", "cancelled", "pre", "free"].indexOf(enrollment.status) == -1 && enrollment.productInfo?.type !== "OnePay"? 
                                                <small className="product-nextPayment" style={{display: "block"}}>
                                                    {enrollment.season} • {enrollment.athlete.name} {enrollment.athlete.surname} {enrollment.athlete.secondSurname} • <strong>Próximo cobro el día {moment(enrollment.productInfo.firstPaymentDay).date()} de {moment().add(1, "month").format("MMMM [de] YYYY")} de {enrollment.productInfo.pvp / 100}€</strong>
                                                </small>
                                                :
                                                <small className="product-nextPayment" style={{display: "block"}}>
                                                    {enrollment.season} • {enrollment.athlete.name} {enrollment.athlete.surname} {enrollment.athlete.secondSurname} • <strong>No hay próximos cargos</strong>
                                                </small>
                                            }
                                        </div>
                                    </span>
                                </div>
                                {
                                    showActions ?
                                        <div className="actions-card" style={{padding: "10px"}}>
                                            <EnrollmentDetailComponent data={enrollment} little/>
                                        </div>
                                    :
                                        <></>
                                }
                            </div>
                        </Box>
    )
}

export default EnrollmentInfoComponent;