import React from "react";

import { Remittances } from '../../components'

import './RemittancesPage.scss'

const RemittancesPage = () : JSX.Element => {

    return (
        <div className="remittances-page">
            <Remittances />
        </div>
    )
}


export default RemittancesPage;