import ContainerBase from './containerBase/containerBase' 
import LoginComponent from './login/login'
import LockScreenComponent from './lockscreen/lockscreen'
import HelperComponent from './helper/helper'
import DrawerInsideComponent from './DrawerInside/DrawerInside'
import ProfileComponent from './profile/profileComponent'
import RegisterComponent from './register/registerComponent'
import SmileyComponent from './result/smilleyComponent'
import StatisticsComponent from './statistics/statisticsComponent'
import AthletesComponent from './athletes/athletesComponent'
import EnrollmentsComponent from './enrollments/enrollmentsComponent'
import UsersComponent from './users/usersComponent'
import PaymentsComponent from './payments/paymentsComponent'
import EnrollmentDetailComponent from './enrollments/enrollments.detail'
import InfoAthleteFormComponent from './athletes/athletes.info'
import UsersInfoComponent from './users/usersInfoComponent'
import ProductListComponent from './productAndDiscounts/products/productListComponent'
import AddDiscountFormComponent from './productAndDiscounts/discounts/add/addDiscountFormComponent'
import AddProductsComponent from './productAndDiscounts/products/add/addProductsComponent'
import DiscountListComponent from './productAndDiscounts/discounts/discountListComponent'
import ProductAndDiscountsComponent from './productAndDiscounts/productAndDiscountsComponent'
import CookiesComponent from './cookies/CookiesComponent'
import GrafanaComponent from './grafana/GrafanaComponent'
import EnrollmentFinantialDetailsComponent from './enrollments/enrollments.finantial.details'
import SessionComponent from './session/SessionComponent'
import TablePaginationComponent from './core/utils/TablePaginationComponent'
import CustomizationComponent from './customizations/CustomizationComponent'
import WelcomeComponent from './welcome/WelcomeComponent'
import WelcomeTrainersComponent from './welcome/WelcomeTrainersComponent'
import SeasonsComponent from './seasons/SeasonsComponent'
import CategoriesComponent from './categories/CategoriesComponent'
import NotificationsComponent from './notifications/NotificationsComponent'
import RemittancesComponent from './remittances/list/RemittancesComponent'
import CreateRemittanceComponent from './remittances/create/CreateRemittanceComponent'
import ViewRemittanceComponent from './remittances/view/ViewRemittanceComponent'
import CentersComponent from './centers/CentersComponent'
import EditCentreComponent from './centers/EditCentreComponent'
import AddCentreComponent from './centers/AddCentreComponent'
import GroupsComponent from './groups/GroupsComponent'

import HeaderComponent from './header/HeaderComponent'
import MenuComponent from './menu/MenuComponent'
import MenuTrainersComponent from './menu/MenuTrainersComponent'
import BoxComponent from './core/box/BoxComponent'

import OldStudentsComponent from './oldStudents/OldStudentsComponent'
import SetAttendancesComponent from './welcome/setAttendances/SetAttendancesComponent'

import ActionButtonsComponent from './core/actionButons/ActionButtonsComponent'

import UserResumeComponent from './userResume/UserResumeComponent'

import BalanceComponent from './balance/BalanceComponent'

import GDriveComponent from './google/drive/GDriveComponent'


export const Menu = MenuComponent;
export const MenuTrainers = MenuTrainersComponent;
export const Header = HeaderComponent;
export const Container = ContainerBase;  
export const Login = LoginComponent
export const LockScreen = LockScreenComponent
export const Helper = HelperComponent
export const DrawerInside = DrawerInsideComponent;
export const Profile = ProfileComponent;
export const Register = RegisterComponent;
export const SmileyResult = SmileyComponent;
export const Statistics = StatisticsComponent;
export const Athletes = AthletesComponent;
export const Enrollments = EnrollmentsComponent;
export const Users = UsersComponent;
export const Payments = PaymentsComponent;
export const EnrollmentDetail = EnrollmentDetailComponent;
export const AthleteInfo = InfoAthleteFormComponent;
export const UsersInfo = UsersInfoComponent;
export const ProductList = ProductListComponent;
export const AddProducts = AddProductsComponent;
export const DiscountList = DiscountListComponent;
export const AddDiscount = AddDiscountFormComponent;
export const ProductAndDiscounts = ProductAndDiscountsComponent;
export const Cookies = CookiesComponent;
export const Grafana = GrafanaComponent;
export const EnrollmentFinantialDetails = EnrollmentFinantialDetailsComponent;
export const Session = SessionComponent;
export const Customization = CustomizationComponent;
export const TablePagination = TablePaginationComponent;
export const Box = BoxComponent;
export const Welcome = WelcomeComponent;
export const WelcomeTrainers = WelcomeTrainersComponent;
export const Centers = CentersComponent;
export const EditCentre = EditCentreComponent;
export const AddCentre = AddCentreComponent;
export const Groups = GroupsComponent;
export const OldStudents = OldStudentsComponent;
export const SetAttendances = SetAttendancesComponent;
export const Seasons = SeasonsComponent;
export const Categories = CategoriesComponent;
export const ActionButtons = ActionButtonsComponent;
export const Notifications = NotificationsComponent;
export const UserResume = UserResumeComponent;
export const Balance = BalanceComponent;
export const GDrive = GDriveComponent;
export const Remittances = RemittancesComponent;
export const CreateRemittance = CreateRemittanceComponent;
export const ViewRemittance = ViewRemittanceComponent;



