import LoginPage from './auth/login/login'
import LockScreenPage from './auth/lockscreen/lockscreen'
import DashBoardPage from './dashboard/dashboard'
import DashBoardV2Page from './dashboard-v2/dashboard.v2'
import RegisterPage from './auth/register/register'
import ContainerPage from './container/PageContainer'
import EnrollmentsPage from './enrollments/EnrollmentsPage'
import PaymentsPage from './payments/PaymentsPage'
import StudentsPage from './students/StudentsPage'
import TeachersPage from './teachers/TeachersPage'
import UsersPage from './users/UsersPage'
import EmailsPage from './emails/EmailsPage'
import DocsPage from './docs/DocsPage' 
import ProductsPage from './products/ProductsPage'
import DiscountsPage from './discounts/DiscountsPage'
import CustomizationsPage from './customizations/CustomizationsPage'
import GlobalPage from './global/GlobalPage'
import CentersPage from './centers/CentersPage'
import TrainersPage from './trainers/TrainersPage'
import ProfilePage from './profile/ProfilePage'
import OldStudentsPage from './students/OldStudentsPage'
import SeasonsPage from './seasons/SeasonsPage'
import CategoriesPage from './categories/CategoriesPage'
import NotificationsPage from './notifications/NotificationsPage'
import RemittancesPage from './remittances/RemittancesPage'
import Page404 from './auth/404/404'
import Page500 from './auth/500/500'

export const Login = LoginPage
export const LockScreen = LockScreenPage 
export const DashBoard = DashBoardPage
export const DashBoardV2 = DashBoardV2Page
export const Trainers = TrainersPage;
export const Register = RegisterPage 
export const Container = ContainerPage;
export const Enrollments = EnrollmentsPage;
export const Payments = PaymentsPage;
export const Students = StudentsPage;
export const Teachers = TeachersPage;
export const Users = UsersPage;
export const Emails = EmailsPage;
export const Docs = DocsPage;
export const Products = ProductsPage;
export const Discounts = DiscountsPage;
export const Customizations = CustomizationsPage;
export const Global = GlobalPage;
export const Centers = CentersPage;
export const Profile = ProfilePage;
export const OldStudents = OldStudentsPage;
export const Seasons = SeasonsPage;
export const Categories = CategoriesPage;
export const Notifications = NotificationsPage;
export const Remittances = RemittancesPage;
export const Error404 = Page404;
export const Error500 = Page500;