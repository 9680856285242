import React from 'react'

import * as Components from '../../components'

import './CategoriesPage.scss'

const CategoriesPage = () : JSX.Element => {


    return (
        <div className="categories-page">
            <Components.Categories />
        </div>
    )
}

export default CategoriesPage;