import React, { useState, useEffect, useRef } from 'react'
import { Tag, Popconfirm, Button, Empty, Divider  } from 'antd'

import './paymentsComponent.scss'

import * as Components from '../../components'

import {
  BankOutlined,
  BankTwoTone,
  CalendarTwoTone,
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
  CreditCardOutlined,
  CreditCardTwoTone,
  FileExcelOutlined,
  GiftTwoTone,
  IdcardTwoTone,
  LoadingOutlined, RollbackOutlined, ScheduleTwoTone, SendOutlined, TransactionOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import PaymentData from '../../models/PaymentData';
import * as PaymentUtils from '../../utils/PaymentsUtils'
import RestApiClient from '../../api/restApiClient';
import { Box } from '../../components';
import { CARD, SEPA_DEBIT } from '../../utils/Constants';


interface PaymentsComponentProps {
  isSearching: boolean, 
  generalFilter: string,
  reloadCounter: number,
  useTableScroll?: any,
  activeSeason:  any,
}
  

const PaymentsComponent = (props : PaymentsComponentProps) : JSX.Element =>{
  
    const paymentsListRef = useRef();

    const [returningAmount, setReturningAmount] = useState<boolean>(false);
    const [deletingPayment, setDeletingPayment] = useState<boolean>(false);
    const [reloadCounter, setReloadCounter] = useState<number>(0);
    const restApiClient : RestApiClient = new RestApiClient();
    const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);

    const retryPayment = (paymentId: number) : void => {
      setDeletingPayment(true);
      PaymentUtils.RetryPayment(paymentId).then((r: any)=>{
      }).finally(()=>{
        setDeletingPayment(false);
      })
    }

    const notifyPayment = (paymentId: number) : void => {
      setDeletingPayment(true);
      PaymentUtils.NotifyErrorInPayment(paymentId).then((r: any)=>{
      }).finally(()=>{
        setDeletingPayment(false);
      })
    }

    const deletePayment = (paymentId: number) : void => {
      setDeletingPayment(true);
      PaymentUtils.DeletePayment(paymentId).then((r: any)=>{
        setReloadCounter(reloadCounter-1);
      }).finally(()=>{
        setDeletingPayment(false);
      })
    }

    const refundAmount = (paymentId: number, chargeId: string) : void => {
      setReturningAmount(true);
      PaymentUtils.RefundPayments(paymentId, chargeId).then((r: any)=>{
        setReloadCounter(reloadCounter-1);
      }).finally(()=>{
          setReturningAmount(false);
      })
    }

    const downloadPayments = async () => {
      
      setDownloadingExcel(true);
      let searchText : string = '';
      if (paymentsListRef && paymentsListRef.current){
        searchText = await (paymentsListRef.current as any).getFilter();
      }
  
  
      await restApiClient.exportExcel(`Payments/GetPaymentsExcel?criteria=${searchText}&idSeason=${props.activeSeason?.id}`).then((blob :any) => {
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `pagos.xlsx`,
          );
  
          // Append to html link element page
          document.body.appendChild(link);
  
          // Start download
          link.click();
  
          link.remove();
      }).finally(()=>{
        setDownloadingExcel(false);
      });
    }

    const getTargetBox = (item: PaymentData) : JSX.Element => {
      const amount = item.amount / 100;
      const refunded = item.paymentMeta.find(m => m.key.indexOf("refund-") !== -1);
      const amountClass = item.status === "error" ?  "error" : (refunded ? 'refunded' : 'payed')

      var gatewayCommission  = item.paymentMeta.find(m => m.key == "gateway_fees");
      var commission = gatewayCommission && gatewayCommission.value && !isNaN(Number.parseInt(gatewayCommission.value)) ? `${((Number.parseInt(gatewayCommission.value) / 100))}€` : "";
      
      var paymentMethodUsed = item.paymentMeta.find(m => m.key == "gateway_payment_method_used");
      var las4Digits = item.paymentMeta.find(m => m.key == "gateway_payment_method_used_last4");
      var last4 = <></>
      if (las4Digits && las4Digits.value){
        last4 = <>****{las4Digits.value}</>
      }
      var icon = <></>
      if (paymentMethodUsed && paymentMethodUsed.value){
        switch(paymentMethodUsed?.value?.toLowerCase()){
          case SEPA_DEBIT.toLowerCase():
            icon = <BankTwoTone />;
            break;
          case CARD.toLowerCase():
            icon = <CreditCardTwoTone />;
            break;
        }
      }

      var extraInfo = <>{icon} {last4} {commission.length > 0 ? `(${commission} comisión pasarela)` : <></>}</>

      let result : JSX.Element = <>No target</>
  
      result = <Box type='ultra-small'>
                  <div className="payments-card">
                    <div className="header-card">
                      <span className="mobile-enrollment-status">{getPaymentStatus(item.status)}</span>
                      {getMenuActions(item, 'mobile-menu-actions', true)}
                    </div>
                    <Divider className='divider-card'/>
                    <div className="body-card">
                      <div className="info">
                        <span className="mobile-payment-id"><IdcardTwoTone /> {item.id}</span>
                        <span className="mobile-payment-description"><ScheduleTwoTone /> {item.description.replace("Pago inscripción", "")}</span>
                        <span className="mobile-payment-description"><GiftTwoTone /> {item.productInfo?.name}</span>
                        <span className="mobile-payment-date"><CalendarTwoTone /> {moment(item.date).format("DD/MM/YYYY HH:mm")}</span>
                        <span className="mobile-payment-date">{extraInfo}</span>
                      </div>
                      <div className="avatar">
                        <span className={`mobile-payment-amount ${amountClass}`}>
                          {
                            item.status === "error" ?  <CloseOutlined /> : (refunded ? <RollbackOutlined /> : <CheckOutlined />)
                          }
                          {amount}€
                        </span>
                      </div>
                    </div>
                </div>
              </Box>
  
      return result;
    }

    const getPaymentStatus = (value: string) : JSX.Element => {
      if (value === "payed"){
        return <Tag className="payment-status-tag" color="green">Pagado</Tag>;
      }else if( value === "voided"){
        return <Tag className="payment-status-tag" color="red">Expirado</Tag>;
      }else if( value === "refund"){
        return <Tag className="payment-status-tag" color="orange">Devuelto</Tag>;
      }else if( value === "processing"){
        return <Tag className="payment-status-tag" color="violet">Procesando</Tag>;
      }else{
        return <Tag className="payment-status-tag" color="red">Error</Tag>;
      }
    }

    const columns : any = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          renderMobile: (index:number, item: PaymentData) => {
            return getTargetBox(item);
          }
        },
        
        {
          title: 'Descripción',
          dataIndex: 'description',
          key: '1',
        },
        {
          title: 'Fecha',
          dataIndex: 'date',
          key: '2',
          render: (value: any) => moment(value).format("DD/MM/YYYY HH:mm")
        },
        {
          title: 'Estado',
          dataIndex: 'status',
          key: '3',
          render: (value: string) => {
            return getPaymentStatus(value);

          }
        },
        {
          title: 'Importe',
          dataIndex: 'amount',
          key: 'amount',
          render: (value: any, row: PaymentData) => {
                const amount = value / 100;
                return <div>{amount}€</div>;
          }
        },
        {
          title: 'Información extra',
          dataIndex: 'amount',
          key: 'additionalInfo',
          render: (value: any, row: PaymentData) => {
                var gatewayCommission  = row.paymentMeta.find(m => m.key == "gateway_fees");
                var commission = gatewayCommission && gatewayCommission.value && !isNaN(Number.parseInt(gatewayCommission.value)) ? `${((Number.parseInt(gatewayCommission.value) / 100))}€ (Comisión pasarela)` : "";
                
                var paymentMethodUsed = row.paymentMeta.find(m => m.key == "gateway_payment_method_used");
                var las4Digits = row.paymentMeta.find(m => m.key == "gateway_payment_method_used_last4");

                var icon = <></>
                if (paymentMethodUsed && paymentMethodUsed.value){
                  switch(paymentMethodUsed?.value?.toLowerCase()){
                    case SEPA_DEBIT.toLowerCase():
                      icon = <BankOutlined style={{display:"block", marginRight: "3px", fontSize: "15px"}}/>;
                      break;
                    case CARD.toLowerCase():
                      icon = <CreditCardOutlined style={{display:"block", marginRight: "3px", fontSize: "15px"}}/>;
                      break;
                  }
                }

                var last4 = <></>
                if (las4Digits && las4Digits.value){
                  last4 = <div>****{las4Digits.value}</div>
                }
                
                return <div>
                          {commission}
                          <div style={{display: "flex", alignItems: "initial"}}>
                            {icon}
                            {last4}
                          </div>
                          
                       </div>;
          }
        },
        {
          title: 'Acciones',
          key: 'amount',
          fixed: 'right',
          render: (value: any, row: PaymentData) => {
            return getMenuActions(row);
          },
        },
      ];

      const getMenuActions = (row: PaymentData, className: string  = '', isMobile: boolean = false) : JSX.Element => {
        let result : JSX.Element = <></>;

                const meta = row.paymentMeta.find(m => m.key === 'gateway_charge_id');
                const refunded = row.paymentMeta.find(m => m.key.indexOf("refund-") !== -1);
                if (!refunded && row.status === "payed" && meta && meta.value && meta.value.length > 0){
                    result = <Popconfirm placement="topLeft" title={"Confirmar devolución"} onConfirm={()=>{refundAmount(row.id, meta.value)}} okText="Si, devolver" cancelText="Cancelar">
                                <Button disabled={false}  className={`button-action refund ${className}`}
                                        size="small" 
                                        type="primary" 
                                        danger>
                                            <span>
                                              {
                                                returningAmount ?
                                                <LoadingOutlined style={{display: "inline"}}/>:
                                                <TransactionOutlined style={{display: "inline"}}/> 
                                              }
                                            &nbsp;Devolver
                                             </span>
                                </Button>
                            </Popconfirm>
                }else if(refunded){
                    const refundDate = row.paymentMeta.find(m => m.key.indexOf(refunded?.key.replace("refund-", "")) != -1);
                    result = <><small style={{display:"block"}} className={isMobile ? 'mobile-refund-text' : ''}>Devuelto el <strong>{refundDate?.value}</strong></small></>
                }

                if (row.status === "error"){
                  result = <>
                              <Popconfirm placement="topLeft" title={"Confirmar eliminado"} onConfirm={()=>{deletePayment(row.id)}} okText="Si, eliminar" cancelText="Cancelar">
                                <Button disabled={false} className={`button-action ${className}`} 
                                  size="small" 
                                  type="primary" 
                                  danger>
                                      <span>
                                        {
                                          deletingPayment ? 
                                          <LoadingOutlined style={{display: "inline"}}/>
                                          :
                                          <ClearOutlined  style={{display: "inline"}}/>
                                        }
                                        
                                      &nbsp;Eliminar 
                                      </span>
                                </Button>
                              </Popconfirm>
                              <Popconfirm placement="topLeft" title={"Confirmar notificación"} onConfirm={()=>{notifyPayment(row.id)}} okText="Si, notificar" cancelText="Cancelar">
                                <Button disabled={false} className={`button-action refund ${className} notify-payment`} 
                                  size="small" 
                                  type="primary" 
                                  danger>
                                      <span>
                                        {
                                          deletingPayment ? 
                                          <LoadingOutlined style={{display: "inline"}}/>
                                          :
                                          <SendOutlined  style={{display: "inline"}}/>
                                        }
                                        
                                      &nbsp;Notificar 
                                      </span>
                                </Button>
                              </Popconfirm>
                           </>
                }
                
                return result;
      }

      useEffect(() => {
        setReloadCounter(props.reloadCounter);
      }, [props.reloadCounter]); 

      useEffect(()=>{
        if (paymentsListRef && paymentsListRef.current){
          (paymentsListRef.current as any).updateFilter(props.generalFilter);
        }
      },[props.generalFilter])
    
    return (
        <div className="payments">
          {
              props.activeSeason ? <Components.TablePagination
                                      ref={paymentsListRef} 
                                      useTableScroll={props.useTableScroll}
                                      entityName="payments"
                                      reloadCounter={reloadCounter}
                                      defaultFilter=""
                                      aliasEndPoint="GetPayments"
                                      paramsEndPoint={{season: props.activeSeason?.id}}
                                      columns={columns}
                                      hideTextOfFiltersAndButtonsOnMobile = {true}
                                      title="Listado de pagos"
                                      subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que.... si escribes <strong>enroll:100</strong> te mostraremos los pagos de la inscripción número 100? ¡Prueba con cualquiera de las inscripciones existentes!</small>}
                                      loadingData = {props.isSearching}
                                      filterButtons={[
                                                      {label:"Pagados", value: "pagado"}, 
                                                      {label:"Procesando", value: "procesando"}, 
                                                      {label:"Devoluciones", value: "devuelto"}, 
                                                      {label:"Error en cargo", value: "error"}, 
                                                      {label:"Expirados", value: "expirado"}]}
                                      actionButtons={[{styles:{width: "auto", backgroundColor: "green", borderColor: "green"}, label:"Descargar Excel", action: downloadPayments, icon: downloadingExcel ? <LoadingOutlined style={{color: "white"}}/> : <FileExcelOutlined style={{color: "white"}}/>}]}
                                  />
            :
              <Empty description="Selecciona un curso para ver sus inscripciones" />
            }
        </div>
    )
}

export default PaymentsComponent;