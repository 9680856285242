import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Checkbox , Typography, Form, Input, Collapse, Button, Popover } from 'antd'
import { useHistory } from "react-router-dom";

import './registerComponent.scss'

import { PhoneOutlined, SmileOutlined, UnlockOutlined, WarningTwoTone  } from '@ant-design/icons'
import DrawerInsideComponent from '../DrawerInside/DrawerInside';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import { validateDNINIE, validatePassword } from '../../utils/ValidationsUtils';
import { AppCfgContext } from '../../hooks/context/appConfigContext';
import HTMLReactParser from 'html-react-parser';

const { Title }  = Typography
const { Panel } = Collapse

interface RegisterProps {}

const RegisterComponent = (props : RegisterProps): JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

    const restApiClient : RestApiClient = new RestApiClient();

    const history = useHistory();

    const errorHelp : JSX.Element = <Popover content={'Revisa los datos de este formulario'}><WarningTwoTone twoToneColor="red"/></Popover>;
    const errorAcceptPolicy : JSX.Element = <Popover content={'Debe aceptar las políticas de uso'}><WarningTwoTone twoToneColor="red"/>&nbsp;</Popover>;
    const errorAcceptPolicyNotReaded : JSX.Element = <Popover content={'Debe leer los términos y políticas. Al final del documento pulse el botón.'}><WarningTwoTone twoToneColor="red"/>&nbsp;</Popover>;

    const [form] = Form.useForm();
    const [acceptPolicy, setAcceptPolicy] = useState<boolean>(false);
    const [formOk, setFormOk] = useState<boolean>(false);
    const [showPolicy, setShowPolicy] = useState<boolean>(false);
    const [finishReadPolicy, setFinishReadPolicy] = useState<boolean>(false);

    const [error1, setError1] = useState<any>('');
    const [error2, setError2] = useState<any>('');
    const [error3, setError3] = useState<any>('');
    const [error4, setError4] = useState<any>('');
    const [error5, setError5] = useState<any>('');

    const onFinishReadPolicy = () : void => {
        hideShowPolicy();
        setFinishReadPolicy(true);
        checkForm();
    }

    useEffect(()=>{
        if (finishReadPolicy){
            setError5('');
            checkForm();
        }
        
    },[finishReadPolicy]);

    const hideShowPolicy = () : void => {
        setShowPolicy(false);
    }

    const viewShowPolicy = () : void => {
        setShowPolicy(true);
    }

    const goLogin = () : void => {
        history.push("/");
    }
    
    const onChangeAccept = (e: any) => {
        setAcceptPolicy(e.target.checked);

        if (e.target.checked && !finishReadPolicy){
            setError5(errorAcceptPolicyNotReaded);
        }else{
            setError5('');
        }
    }

    const checkForm = () : void => {
        form.validateFields()
            .then((r)=>{
                setError1('');
                setError2('');
                setError3('');

                if (finishReadPolicy){
                    setError5('');
                }

                if (acceptPolicy){
                    setError4('');
                }

                if (acceptPolicy && finishReadPolicy){
                    setFormOk(true);
                }

                
                
            }).catch((e)=>{
                setFormOk(false);
                
                const first : any[] = ['email', 'password', 'repeatPassword'];
                if (form.getFieldsError().some((item) => item.errors.length > 0 && item.name.some((n)=> first.indexOf(n) !== -1))){
                    setError1(errorHelp);
                }else{
                    setError1('');
                }

                const second : any[] = ['phone','address'];
                if (form.getFieldsError().some((item) => item.errors.length > 0 && item.name.some((n)=> second.indexOf(n) !== -1))){
                    setError2(errorHelp);
                }else{
                    setError2('');
                }

                const thirth : any[] = ['name','surname','secondSurname','dniNie'];
                if (form.getFieldsError().some((item) => item.errors.length > 0 && item.name.some((n)=> thirth.indexOf(n) !== -1))){
                    setError3(errorHelp);
                }else{
                    setError3('');
                }

                if (!finishReadPolicy){
                    setError5(errorAcceptPolicyNotReaded);
                }

                if (!acceptPolicy){
                    setError4(errorAcceptPolicy);
                }

                
            });
    }

    const onFinish = () : void => {
        if (acceptPolicy && finishReadPolicy){

            const paramsApi : ParamsApi = {
                body: form.getFieldsValue()
            }
    

            restApiClient.fetch("RegisterTrainer", paramsApi).then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setTimeout(()=>{
                        history.push("/");
                    }, 1500)
                }
            })
        }
    }

    useEffect(()=>{
        if (acceptPolicy){
            setError4('');
            checkForm();
        }else{
            setFormOk(false);
        }
    }, [acceptPolicy]);

    return (
        <Form form={form} onFinish={onFinish} onChange={()=>{ 
            if (acceptPolicy){
                checkForm();
            }
        }}>
            <Row gutter={16} className="register-form" style={{overflow:"auto"}}>
                    <Col xs={24} className="rf-header">
                        <Title level={4}>
                            Bienvenid@
                        </Title>
                        <Title level={5}>
                            Registro (Administrativo)
                        </Title>
                            <br />
                            <Collapse defaultActiveKey={1} accordion >
                                <Panel forceRender={true} className="form-register-accordion" header={<>{error1}<UnlockOutlined /> Datos de acceso </>} key="1">
                                    <Form.Item
                                        name="username"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}, { type: 'email', message: 'No es un email válido' }]}
                                    >
                                        <Input type="email" placeholder="Introduce tu Email"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'},
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if(!validatePassword(value)){
                                                    return Promise.reject("Debe contener mayúsculas, minúsculas, números y al menos 6 caracteres");
                                                }else if (getFieldValue("repeatPassword") !== value){
                                                    return Promise.reject("La contraseña y su repetición deben ser iguales.");
                                                }
                                                return Promise.resolve();
                                            },
                                            })]}
                                    >
                                        <Input.Password  onChange={()=>{form.validateFields(["repeatPassword"])}} type="password" placeholder="Introduce tu Contraseña"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="repeatPassword"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'},
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if(!validatePassword(value)){
                                                    return Promise.reject("Debe contener mayúsculas, minúsculas, números y al menos 6 caracteres");
                                                }else if (getFieldValue("password") !== value){
                                                    return Promise.reject("La contraseña y su repetición deben ser iguales.");
                                                }
                                                return Promise.resolve();
                                            },
                                            })]}
                                    >
                                        <Input.Password onChange={()=>{form.validateFields(["password"])}} type="password" placeholder="Repite contraseña"/>
                                    </Form.Item>
                                </Panel>
                                <Panel forceRender={true} className="form-register-accordion" header={<>{error2}<PhoneOutlined /> Datos de contacto </>} key="2">
                                    <Form.Item
                                        name="phone"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                    >
                                        <Input placeholder="Introduce tu Teléfono principal"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="phone2"
                                        className="form-item"
                                    >
                                        <Input placeholder="Introduce tu Teléfono secundario"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="address"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                    >
                                        <Input placeholder="Introduce tu Dirección completa"/>
                                    </Form.Item>
                                </Panel>
                                <Panel forceRender={true} className="form-register-accordion" header={<>{error3}<SmileOutlined /> Datos personales </>} key="3">
                                    <Form.Item
                                        name="name"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                    >
                                        <Input placeholder="Introduce tu nombre"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="surname"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                    >
                                        <Input placeholder="Introduce tu Primer apellido"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="secondSurname"
                                        className="form-item"
                                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                                    >
                                        <Input placeholder="Introduce tu Segundo apellido"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="DNI_NIE"
                                        className="form-item"
                                        rules={[
                                                {required: true, message: 'Este campo es obligatorio'},
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                       if(!validateDNINIE(value)){
                                                            return Promise.reject("Documento no válido");
                                                        }
                                                       return Promise.resolve();
                                                    },
                                                  })
                                            ]}
                                    >
                                        <Input placeholder="Introduce tu DNI o NIE"/>
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                    </Col>
                    <Col>
                        <br />
                        <Form.Item
                            name="acceptTerms"
                            className="form-item"
                        >
                            <Checkbox onChange={onChangeAccept}>{error5} {error4}<a onClick={(e)=>{e.preventDefault(); viewShowPolicy();}}>He leído, compredo y acepto los términos y políticas de uso de la aplicación.</a></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col className="btn-register-container">
                        <Button disabled={!formOk || !acceptPolicy || !finishReadPolicy} type="primary" htmlType="submit" className="btn-register">
                            ¡Comenzar!
                        </Button>
                        <Button danger type="primary" className="btn-register" onClick={goLogin}>
                            Volver al login
                        </Button>

                    </Col>
            </Row>
            <DrawerInsideComponent mobilefixed={showPolicy} showadd={showPolicy} dOnClose={hideShowPolicy} dTitle="Condiciones de uso" dwidth={"100%"}>
                <Row>
                    <Col>
                        {
                            appConfigContext.cfg?.textLegalPrivacity ? 
                            HTMLReactParser(appConfigContext.cfg?.textLegalPrivacity) :
                            'No se pudieron cargar los textos legales. Por favor, vuelva al login e inténtelo de nuevo.'
                        }
                        <div>
                                
                            <Button type="primary" className="btn-register" onClick={onFinishReadPolicy}>¡Vale!, Leído y entendido</Button>
                        </div>
                    </Col>
                </Row>
            </DrawerInsideComponent>
        </Form>
    )
}

export default RegisterComponent;