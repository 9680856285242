import React, { useEffect, useRef, useState } from 'react'
import { Tag, Menu, Dropdown, Tooltip, Popconfirm, Empty, Avatar, Divider, Button  } from 'antd'

import './enrollmentsComponent.scss'
import Enrollments from '../../models/Enrollments';

import * as Components from '../../components'

import {
  BookTwoTone,
  CalendarTwoTone,
  CoffeeOutlined,
  EnvironmentTwoTone,
  EuroCircleOutlined,
  FileExcelOutlined,
  IdcardTwoTone,
  LoadingOutlined,
  RocketOutlined,
  SkinTwoTone,
  SmileTwoTone,
  TrophyOutlined,
  UserOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Meta from '../../models/Meta';

import RestApiClient from '../../api/restApiClient';
import { Box } from '../../components';
import SportCenter from '../../models/SportCenter';
import ApiResponse from '../../models/api/ApiResponse';
import { getInitialsBox } from '../../utils/GeneralUtils';



interface EnrollmentsComponentProps{
  isSearching: boolean, 
  focusdata: any,
  generalFilter: string,
  updatematerialstatus: any,
  showdetail: any,
  setactive: any,
  activeSeason: any,
  reloadCounter: number,
  useTableScroll: any,
  title?:any
}

const EnrollmentsComponent = (props : EnrollmentsComponentProps) : JSX.Element => {
  const enrollmentListRef = useRef();

  const [ reloadCounter, setReloadCounter] = useState<number>(0);

  const restApiClient : RestApiClient = new RestApiClient();

  const getTargetBox = (item: Enrollments) : JSX.Element => {
    let result : JSX.Element = <>No target</>

    result = <Box type='ultra-small'>
                <div className="enrollments-card">
                  <div className="header-card">
                    <span className="mobile-enrollment-status">{getEnrollStatus(item.status, item)}</span>

                    <span className="mobile-enrollment-clothes">
                      <Button size='small'>{getClothesStatus(item, true)}</Button>
                    </span>
                    <span className="mobile-enrollment-id">
                        <Button size='small'>{getPaymentType(item.status, item)}</Button>
                    </span>
                    {getMenuActions(item, 'mobile-menu-actions', true)}
                  </div>
                  <Divider className='divider-card'/>
                  <div className="body-card">
                    <div className="avatar">
                      {
                        getInitialsBox(item.athlete.name, item.athlete.surname)
                      }
                    </div>
                    <div className="info">
                      <span className="mobile-enrollment-student-name"><SmileTwoTone /> {item.athlete.surname} {item.athlete.secondSurname}, {item.athlete.name}</span>
                      <span className="mobile-enrollment-date"><CalendarTwoTone /> {moment(item.enrollDate).format("DD/MM/YYYY")}</span>
                      <span className="mobile-enrollment-location"><EnvironmentTwoTone /> {item.location}</span>
                      <span className="mobile-enrollment-category"><BookTwoTone /> {item.category}</span>
                    </div>
                  </div>
              </div>
            </Box>

    return result;
  }

 
  
  const columns : any = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        renderMobile: (index:number, item: Enrollments) => {
          return getTargetBox(item);
        }
      },
      {
        title: '',
        dataIndex: 'status',
        key: '4',
        width: 50,
        render: (value: string, row: Enrollments) => {
            return getPaymentType(value, row);
        }
      },
      {
        title: 'F. Inscripción',
        dataIndex: 'date',
        key: 'date',
        width:120,
        render: (value: any, item: Enrollments) => moment(item.enrollDate).format("DD/MM/YYYY")
      },
      {
        title: 'Alumno',
        dataIndex: 'athlete',
        key: 'athlete',
        render: (value: any, item: Enrollments) => {

          return <>
                  {
                    getInitialsBox(item.athlete.name, item.athlete.surname)
                  }
                  <span>&nbsp; {item.athlete ? `${item.athlete.surname} ${item.athlete.secondSurname}, ${item.athlete.name}`.toUpperCase() : ''}</span>
                 </>
        }
      },
      {
        title: 'Centro',
        dataIndex: 'location',
        key: '1',
        render: (value: any, item: Enrollments) => item.location?.toUpperCase()
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        key: '2',
        render: (value: any, item: Enrollments) => item.category?.toUpperCase()
      },
      {
        title: 'Talla',
        dataIndex: 'size',
        key: '3',
        align: 'left',
        width: 100,
        render: (value: string, item: Enrollments) => {
          return getClothesStatus(item);
      }
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: '4',
        width: 150,
        render: (value: string, item: Enrollments) => {
          return getEnrollStatus(value, item);
        }
      },
      {
        title: 'Acciones',
        key: 'actions',
        fixed: 'right',
        width: 120,
        render: (value: any, item: Enrollments) => {
          return getMenuActions(item);
        },
      },
    ];

    const getEnrollStatus = (status: string, item: Enrollments) : JSX.Element => {
      let result : JSX.Element = <></>;
          const suspendedAt : Meta | undefined = item.meta.find(m => m.key === "payment_suspended_at");
          const finisPaymentsAt : Meta | undefined = item.meta.find(m => m.key === "cancel_date");

          const isPaymentFinished = item.status === 'active' && item.meta.some((m: Meta)=>{ return m.key === "automatic_cancelled" && m.value === "1"});

          if (suspendedAt){
            result = <>
                        {GetStatusTag(status)}
                        <small style={{display:"block"}}>Cobros suspendidos: <br/><strong>{suspendedAt.value}</strong></small>
                     </>
          } else if (isPaymentFinished && finisPaymentsAt){
            result = <>
                        {GetStatusTag(status)}
                        <small style={{display:"block"}}>Pagos finalizados: <br/><strong>{finisPaymentsAt.value}</strong></small>
                     </>
          }else{
            result = GetStatusTag(status);
          }

          return result;
    }

  const getClothesStatus = (item: Enrollments, showSize: boolean = true) : JSX.Element => {
    let skinClass : string = 'material-delivered not'
          let skinTitle : string = 'Material no entregado'
          let twoToneColor : string = 'red';

          if (item.isDeliveredMaterial){
            skinClass = 'material-delivered'
            skinTitle = 'Material entregado'
            twoToneColor = 'green'
          }
            
          return <>
                    <Tooltip title={skinTitle}>
                      <SkinTwoTone twoToneColor={twoToneColor} className={skinClass}/> 
                    </Tooltip>
                    {
                      showSize ? <>
                          &nbsp;
                      {item.size} </>  : ''
                    }
                    
                  </>
        }

  const getPaymentType = (value: string, row: Enrollments) : JSX.Element => {
    let result : JSX.Element = <></>;
          if (value !== "pre"){
            const paymentPlan : Meta | undefined = row.meta.find(m => m.key === "payment_plan");

            if (paymentPlan){
              const productType : string = paymentPlan.value;

              switch(productType){
                case 'OnePay':
                  result = <Tooltip placement="top" title="Pago único">
                              <TrophyOutlined className="enrollment-box-icon"  />
                          </Tooltip>;
                  break;
                case 'PayPerUse':
                  result = <Tooltip placement="top" title="Pago mensual">
                              <EuroCircleOutlined className="enrollment-box-icon"  />
                          </Tooltip>;

                  break;
                case 'Subscription':
                  result = <Tooltip placement="top" title="Suscripción">
                              <RocketOutlined className="enrollment-box-icon"  />
                          </Tooltip>;
                  break;
                default: 
                  break;
              }

            }
          }

          return result;
  }

    const getMenuActions = (item: Enrollments, className?: string, isMobile?: boolean) : JSX.Element => {
      const menu = <Menu onClick={()=>{}}>
                        <Menu.Item key="delivered" onClick={()=>{
                          if (item.isDeliveredMaterial){
                            props.updatematerialstatus(false, item.id);
                          }else{
                            props.updatematerialstatus(true, item.id);
                          }
                        }}>
                          {item.isDeliveredMaterial ? 'Material no entregado' : 'Material entregado'}
                        </Menu.Item>
                        {
                          item.status === "revision" ?
                            <>
                              <Menu.Item key="revision">
                                  <Popconfirm placement="left" title={"Confirmar activación"} onConfirm={()=>{props.setactive(item.id)}} okText="Si, activar" cancelText="Cancelar">
                                    Marcar activa
                                  </Popconfirm>
                              </Menu.Item>
                              <Menu.Item key="revision">
                                  <Popconfirm placement="left" title={"Confirma envío"} onConfirm={()=>{}} okText="Si, enviar" cancelText="Cancelar">
                                    Enviar enlace pago por email
                                  </Popconfirm>
                              </Menu.Item>
                            </>
                            
                          :
                          ''
                        }
                        <Menu.Divider />
                        <Menu.Item key="ban" onClick={()=>{loadDataFrom(item.id)}}>Cargar tablas</Menu.Item>
                      </Menu>;

          return <Dropdown.Button className={className} overlay={menu} onClick={()=>{props.showdetail(item)}} size="small">Ficha</Dropdown.Button>
    }

    const loadDataFrom =  async (id: number) => {
      if (enrollmentListRef && enrollmentListRef.current){
        await (enrollmentListRef.current as any).updateFilter(id);
      }
      props.focusdata(id);
    }

  const GetStatusTag = (state: string) : JSX.Element => {
    let result : JSX.Element = <></>

    switch(state){
      case "free":
        result = <Tag className="enrollment-status-tag" color="green"><CoffeeOutlined /> Gratuita</Tag>;
        break;
      case "active":
        result = <Tag className="enrollment-status-tag" color="green">Activa</Tag>;
        break;
      case "revision":
        result = <Tag className="enrollment-status-tag" color="warning">P. Revisión</Tag>;
        break;
      case "cancelled":
        result = <Tag className="enrollment-status-tag" color="magenta">Cancelada</Tag>;
        break;
      case "finished":
        result = <Tag className="enrollment-status-tag" color="cyan">Finalizada</Tag>;
        break;
      case "pre":
        result = <Tag className="enrollment-status-tag" color="volcano">PRE</Tag>;
        break;
      case "expired":
        result = <Tag className="enrollment-status-tag" color="purple">Caducada</Tag>;
        break;
    }

    return result;
  }


  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
  const downloadEnrollments = async () => {
    
    setDownloadingExcel(true);
    let searchText : string = '';
    if (enrollmentListRef && enrollmentListRef.current){
      searchText = await (enrollmentListRef.current as any).getFilter();
    }


    await restApiClient.exportExcel(`Enrollments/GetEnrollmentsExcel?criteria=${searchText}&idSeason=${props.activeSeason?.id}`).then((blob :any) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `inscripciones.xlsx`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        link.remove();
    }).finally(()=>{
      setDownloadingExcel(false);
    });
  }


  useEffect(()=>{
    setReloadCounter(props.reloadCounter);
  },[props.reloadCounter])

  useEffect(()=>{
    if (enrollmentListRef && enrollmentListRef.current){
      (enrollmentListRef.current as any).updateFilter(props.generalFilter);
    }
  },[props.generalFilter])

  const [filters, setFilters] = useState<any[]>([
                                                  {label:"Pre inscripciones", value: "pre"}, 
                                                  {label:"Activas", value: "activa"}, 
                                                  {label:"Pendiente de revisión", value: "revisión"}, 
                                                  {label:"Gratuitas", value: "gratuita"}, 
                                                  {label:"Canceladas", value: "cancelada"}, 
                                                  {label:"Finalizadas", value: "finalizada"}, 
                                                  {label:"Pagadas", value: "pagadas"},
                                                  "separator"
                                                ]);

  const getSportCenters = () : void => {
      restApiClient.fetch("GetSportCenters", {})
              .then((r : ApiResponse | null) => {
                  if (r?.code === 200){
                      setFilters([...filters, 
                          ...r.data?.map((sc: SportCenter)=> {
                            return {label:sc.description, value: sc.description};
                          })])
                  }
              })
  }

  useEffect(()=>{
      getSportCenters();
  },[]);

    return (
        <div className="enrollments">
            {
              props.activeSeason ? <Components.TablePagination
                                      ref={enrollmentListRef} 
                                      entityName="enrollments"
                                      reloadCounter={reloadCounter}
                                      aliasEndPoint="GetEnrollments"
                                      paramsEndPoint={{season: props.activeSeason?.id}}
                                      columns={columns}
                                      title={props.title ?? "Listado de Inscripciones"}
                                      subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que...desde la ficha puedes consultar detalles asociados a la inscripción tales como pagos, devoluciones y un resumen de cómo ha evolucionado en el tiempo esta inscripción?</small>}
                                      loadingData = {props.isSearching}
                                      useTableScroll = {props.useTableScroll}
                                      hideTextOfFiltersAndButtonsOnMobile = {true}
                                      filterButtons={filters}
                                      actionButtons={[{styles:{width: "auto", backgroundColor: "green", borderColor: "green"}, label:"Descargar Excel", action: downloadEnrollments, icon: downloadingExcel ? <LoadingOutlined style={{color: "white"}}/> : <FileExcelOutlined style={{color: "white"}}/>}]}
                                  />
          :
            <Empty description="Selecciona un curso para ver sus inscripciones" />
            }
            
            
        </div>
    )
}

export default EnrollmentsComponent;