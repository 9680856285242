import { CalendarOutlined, ClearOutlined, ClockCircleOutlined, ClusterOutlined, EditOutlined, FileExcelOutlined, InfoCircleOutlined, LoadingOutlined, PlusOutlined, RobotOutlined, TeamOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Divider, notification, Popconfirm, Select, Skeleton, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import Group from '../../models/Group';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';
import * as Components from '../../components'
import locale from 'antd/es/date-picker/locale/es_ES';


import './GroupsComponent.scss'
import User from '../../models/Users';
import Athlete from '../../models/Athletes';
import DrawerInsideComponent from '../DrawerInside/DrawerInside';
import GroupsAddComponent from './GroupsAddComponent';
import GroupsEditComponent from './GroupsEditComponent';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import Season from '../../models/Season';
import moment from 'moment';

const GroupsComponent = () : JSX.Element => {

    const groupsListRef = useRef();
    const [ reloadCounter, setReloadCounter] = useState<number>(0);

    const [showEditComponent, setShowEditComponent] = useState<boolean>(false);
    const editSelected = (group: Group) : void => {
        setShowEditComponent(true);
        setSelectedGroup(group);
    }

    const removeSelected = (id: number) : Promise<ApiResponse> => {
        var params : ParamsApi = {
            query:{
                groupId: id
            }
        }

        return restApiClient.fetch("DeleteGroup", params).then()
    }



    const columns : any = [
        {
            title: 'Nombre',
            dataIndex: 'description',
            key: 'description',
            render: (index: number, item: Group) => {
                return <div className="info-group-name">
                            <label>{item.description}</label>
                            <small>{item.sportCenter.description}</small>
                       </div>
            },
            renderMobile: (index:number, item: Group) => {
                return <GroupMobileItem group={item} editCallback={editSelected} removeCallBack={removeSelected} afterRemoveCallBack={()=>{setReloadCounter(reloadCounter+1)}} downloadAttendancesGroupCallBack={showDownloadAttendancesGroup}/>
            }
        },
        {
            title: 'Días Semana',
            dataIndex: 'weekDays',
            key: 'weekDays'
        },
        {
            title: 'Horario',
            dataIndex: 'timeToStart',
            key: 'timeToStart',
            render: (index: number, item: Group) => {
                return <label><strong>{item.timeToStart}</strong> a <strong>{item.timeToEnd}</strong></label>
            },
        },
        {
            title: 'Inicio actividad',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (index: number, item: Group) => {
                return <label>{`${item.startDate} ${item.timeToStart}`}</label>
            },
        },
        {
            title: 'Fin actividad',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (index: number, item: Group) => {
                return <label>{`${item.endDate} ${item.timeToEnd}`}</label>
            },
        },
        {
            title: 'Nº Alumnos',
            dataIndex: 'athletes',
            key: 'athletes',
            render: (index: number, item: Group) => {
                return item.athletes.length
            }
        },
        {
            title: 'Profesores/Monitores',
            dataIndex: 'teachers',
            key: 'teachers',
            render: (index: number, item: Group) => {
                return item.trainers.map((trainer : User)=><Tag className="tag-name">{trainer.name} {trainer.surname} {trainer.secondSurname??''}</Tag>)
            }
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            render: (index: number, item: Group) => {
                var otherButtons: JSX.Element = <Button type='primary' size='small' className="btn-download-attendances" onClick={()=>{showDownloadAttendancesGroup(item)}}>
                                                    <FileExcelOutlined style={{color: "white"}}/> Asistencias
                                                </Button>
                return <div style={{textAlign:"center"}}>
                            {btnActions(index, item, [otherButtons], 'column', 'left')}
                        </div>
            }
        }
    ];


    const btnActions = (index: number, item: Group, especialButtons:any[], orientation : 'column' | 'row' = 'column', popConfirmPlacement: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom') : JSX.Element => {
        return <Components.ActionButtons 
                                buttonsSize='small'
                                popConfirmPlacement={popConfirmPlacement} 
                                especialButtons={especialButtons}
                                orientation={orientation} 
                                key={index} 
                                itemId={item.id} 
                                showEdit 
                                showRemove 
                                callBackRemove={()=>{setReloadCounter(reloadCounter+1);}} 
                                onClickRemove={removeSelected} 
                                onClickEdit={()=>{editSelected(item)}}/>
    }

    const restApiClient : RestApiClient = new RestApiClient();

    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
    const [showAddGroup, setShowAddGroup] = useState<boolean>(false);

    const [selectedGroup, setSelectedGroup] = useState<Group>();

    const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
    const [downloadingGeneralExcel, setDownloadingGeneralExcel] = useState<boolean>(false);

    const [seasonId, setSeasonId] = useState<number>();
    const [day, setDay] = useState<string>();
    const [athleteId, setAthleteId] = useState<number>();

    const downloadAttendances = async () => {

        setDownloadingExcel(true);

        let uri : string = `Attendances/DownloadAdminAttendances`;

        let separator : string  ="?";
        if (seasonId && seasonId > 0){
            uri = `${uri}${separator}seasonId=${seasonId}`;
            separator = "&";
        }

        if (day){
            uri = `${uri}${separator}day=${day}`;
            separator = "&";
        }

        if (athleteId && athleteId > 0){
            uri = `${uri}${separator}athleteId=${athleteId}`;
            separator = "&";
        }

        if (selectedGroup){
            uri = `${uri}${separator}groupId=${selectedGroup.id}`;
        }

        await restApiClient.exportExcel(uri).then((blob :any) => {
            console.log("Blob", blob, blob.size);
            if (blob.size > 0){
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `asistencias.xlsx`,
                );
        
                // Append to html link element page
                document.body.appendChild(link);
        
                // Start download
                link.click();
        
                link.remove();
            }else{
                notification.warn({
                    message: 'Atención',
                    description: "No se han encontrado registros con el criterio indicado. Si cree que es un error por favor, contacte con soporte técnico.",
                  })
            }
            
        }).finally(()=>{
            setDownloadingExcel(false);
        });
    }

    const  downloadGroups = async () => {
        setDownloadingGeneralExcel(true);
        let searchText : string = '';
        if (groupsListRef && groupsListRef.current){
            searchText = await (groupsListRef.current as any).getFilter();
        }
    
        await restApiClient.exportExcel(`Groups/GetGroupsExcel?criteria=${searchText}`).then((blob :any) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `grupos.xlsx`,
            );
    
            // Append to html link element page
            document.body.appendChild(link);
    
            // Start download
            link.click();
    
            link.remove();
        }).finally(()=>{
            setDownloadingGeneralExcel(false);
        });
    }

    const [showAllAttendances, setShowAllAttendances] = useState<boolean>(false);

    const [showAttendances, setShowAttendances] = useState<boolean>(false);
    const showDownloadAttendancesGroup = (group: Group) : void => {
        setSelectedGroup(group);
        setShowAttendances(true);
    }

    const [seasons, setSeasons] = useState<Season[]>([]);
    const GetSeasons = () : void =>{
        restApiClient.fetch("GetSeasons", {})
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSeasons(r.data);
                }
            })
    }

    useEffect(()=>{
        GetSeasons();
    },[]);

    return (
        <div className="groups-component">
            <DrawerInsideComponent
                mobilefixed={true} 
                innercontainer={'no-inner'}
                showadd={showAllAttendances} 
                dOnClose={()=>{
                    setShowAllAttendances(false);
                }} 
                dTitle={`Descarga asistencias`} 
                dwidth={"60%"}
            >
                <Typography.Title level={5}>De un curso específico</Typography.Title>
                <Select style={{width:"100%"}} placeholder="Seleccione un curso" onChange={(v: any)=>{setSeasonId(v);}}>
                    <Select.Option value={-1} key="todos-1">Cualquiera</Select.Option>
                    {
                        seasons.map((season: Season)=><Select.Option value={season.id} key={season.id}>{season.description} {season.isActiveSeason ? '(Curso activa)' : ''}</Select.Option>)
                    }
                </Select>
                
                <Typography.Title level={5}>De un día específico</Typography.Title>
                <DatePicker format="DD/MM/YYYY" style={{width: "100%"}} locale={locale} onChange={(v: any)=>{
                    if (moment(v).isValid()){
                        setDay(moment(v).format("YYYY-MM-DD"));
                    }else{
                        setDay(undefined);
                    }
                }}/>
                
                <Divider />
                <Alert style={{marginBottom: "10px"}} message={<>Si <strong>NO</strong> especifica ningún campo se descargarán <strong>TODAS</strong> las asistencias existentes para el grupo <strong>{selectedGroup?.description}</strong></>} type="info" showIcon />
                <Button onClick={downloadAttendances} type="primary" className='btn-download-attendances' style={{color: "white", width: "100%"}}> {downloadingExcel ? <LoadingOutlined /> : <FileExcelOutlined/>} Descargar asistencias</Button>
            </DrawerInsideComponent>

            <DrawerInsideComponent
                mobilefixed={true} 
                innercontainer={'no-inner'}
                showadd={showAttendances} 
                dOnClose={()=>{
                    setSelectedGroup(undefined); 
                    setShowAttendances(false);
                }} 
                dTitle={`Descarga asistencias: ${selectedGroup?.description} (GRU-${selectedGroup?.id})`} 
                dwidth={"60%"}
            >
                <Typography.Title level={5}>De un curso específico</Typography.Title>
                <Select style={{width:"100%"}} placeholder="Seleccione un curso" onChange={(v: any)=>{setSeasonId(v);}}>
                    <Select.Option value={-1} key="todos-1">Cualquiera</Select.Option>
                    {
                        seasons.map((season: Season)=><Select.Option value={season.id} key={season.id}>{season.description} {season.isActiveSeason ? '(Curso activa)' : ''}</Select.Option>)
                    }
                </Select>
                
                <Typography.Title level={5}>De un día específico</Typography.Title>
                <DatePicker format="DD/MM/YYYY" style={{width: "100%"}} locale={locale} onChange={(v: any)=>{
                    if (moment(v).isValid()){
                        setDay(moment(v).format("YYYY-MM-DD"));
                    }else{
                        setDay(undefined);
                    }
                }}/>
                
                <Typography.Title level={5}>De un alumno específico</Typography.Title>
                <Select style={{width:"100%"}} placeholder="Seleccione un alumno" onChange={(v: any)=>{setAthleteId(v);}}>
                    <Select.Option value={-1} key="todos-2">Cualquiera</Select.Option>

                    {
                        selectedGroup?.athletes.map((athlete: Athlete, index: number)=> {
                            return <Select.Option key={index} value={`${athlete.id}`}>{`${athlete.name} ${athlete.surname} ${athlete.secondSurname}`.trim()}</Select.Option>
                        })
                    }
                </Select>
                
                <Divider />
                <Alert style={{marginBottom: "10px"}} message={<>Si <strong>NO</strong> especifica ningún campo se descargarán <strong>TODAS</strong> las asistencias existentes para el grupo <strong>{selectedGroup?.description}</strong></>} type="info" showIcon />
                <Button onClick={downloadAttendances} type="primary" className='btn-download-attendances' style={{color: "white", width: "100%"}}> {downloadingExcel ? <LoadingOutlined /> : <FileExcelOutlined/>} Descargar asistencias</Button>
            </DrawerInsideComponent>

            <DrawerInsideComponent 
                mobilefixed={true} 
                innercontainer={'no-inner'}
                showadd={showEditComponent && selectedGroup != undefined} 
                dOnClose={()=>{
                    setSelectedGroup(undefined); 
                    setShowEditComponent(false);
                }} 
                dTitle={`Acciones grupo GRU-${selectedGroup?.id}`} 
                dwidth={"70%"}
            >
                <GroupsEditComponent data={selectedGroup!} callBack={()=>{
                    setReloadCounter(reloadCounter+1);
                    setSelectedGroup(undefined); 
                }}/> 
            </DrawerInsideComponent>

            <DrawerInsideComponent 
                mobilefixed={true} 
                innercontainer={'no-inner'}
                showadd={showAddGroup} 
                dOnClose={()=>{
                    setShowAddGroup(false);
                }} 
                dTitle={`Creación de grupos`} 
                dwidth={"70%"}
            >
                <GroupsAddComponent data={selectedGroup!} callBack={()=>{
                    setReloadCounter(reloadCounter+1);
                    setShowAddGroup(false);
                }}/> 
            </DrawerInsideComponent>

            <Skeleton active loading={loadingGroups}>
                <Col xs={24}>
                    <Components.TablePagination
                            ref={groupsListRef} 
                            entityName="groups"
                            reloadCounter={reloadCounter}
                            aliasEndPoint="GetGroups"
                            columns={columns}
                            expandableInfo={{
                                expandedRowRender: (record : Group) => {
                                    return <div>
                                                <div className="student-list">
                                                    <Typography.Title level={5}>Alumnos</Typography.Title>
                                                    {record?.athletes?.map((athlete: Athlete)=>{ return <Tag>{athlete.name} {athlete.surname} {athlete.secondSurname}</Tag>})}
                                                </div>
                                           </div>
                                }
                            }}
                            title={"Listado de grupos"}
                            loadingData = {loadingGroups}
                            useTableScroll = {{y: 300}}
                            actionButtons={[
                                                {styles:{marginRight: "5px", width: "auto", backgroundColor: "green", borderColor: "green"}, label:"Descargar Grupos", action: downloadGroups, icon: downloadingGeneralExcel ? <LoadingOutlined style={{color: "white"}}/> : <ClusterOutlined style={{color: "white"}}/>},
                                                {styles:{marginRight: "5px", width: "auto", backgroundColor: "green", borderColor: "green"}, label:"Descargar Asistencias", action: ()=>{setSelectedGroup(undefined); setShowAllAttendances(true);}, icon: <FileExcelOutlined style={{color: "white"}}/> },
                                                {
                                                    label:"Crear grupo", 
                                                    action: ()=>{ setShowAddGroup(true); }, 
                                                    icon: <PlusOutlined />
                                                }
                                            ]}

                        />
                </Col>
            </Skeleton>
        </div>
    )
}


interface GroupMobileProps {
    group: Group,
    editCallback: any,
    downloadAttendancesGroupCallBack: any,
    removeCallBack: any
    afterRemoveCallBack: any
}
const GroupMobileItem = (props: GroupMobileProps) : JSX.Element => {

    const {group, editCallback, removeCallBack, downloadAttendancesGroupCallBack, afterRemoveCallBack } = props;

    const btnActions = (index: number, item: Group, especialButtons:any[], orientation : 'column' | 'row' = 'column', popConfirmPlacement: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom') : JSX.Element => {
        return <Components.ActionButtons 
                                buttonsSize='small'
                                popConfirmPlacement={popConfirmPlacement} 
                                especialButtons={especialButtons}
                                orientation={orientation} 
                                key={index} 
                                itemId={item.id} 
                                showEdit 
                                showRemove 
                                callBackRemove={afterRemoveCallBack} 
                                onClickRemove={removeCallBack} 
                                onClickEdit={()=>{editCallback(group)}}/>
    }


    return (
            <Components.Box type='small' style={{overflow: "hidden"}}>
                <div>
                    <div className="resume-box">
                        <label className="resume-label">
                            <ClusterOutlined /> {group.description.toUpperCase()} 
                            <small style={{display:"block"}}>{group.sportCenter.description.toUpperCase()}</small>
                        </label>
                    </div>
                    <Divider style={{padding: "0", margin: "10px"}}/>
                    <div style={{color: "black"}}>
                        <ul style={{listStyle: "none", padding: "2px"}}>
                            <li><CalendarOutlined /> Días de la semana <strong>{group.weekDays}</strong></li>
                            <li><ClockCircleOutlined /> Clases de <strong>{group.timeToStart}</strong> a <strong>{group.timeToEnd}</strong></li>
                            <li><CalendarOutlined /> Válido de <strong>{group.startDate}</strong> a <strong>{group.endDate}</strong></li>
                            <Divider style={{padding: "0", margin: "10px"}}/>
                            <li><RobotOutlined /> Profesores/Monitores: &nbsp;  
                                <p style={{marginTop: "5px"}}>
                                    {group.trainers.map((trainer : User)=><Tag className="tag-name">{trainer.name} {trainer.surname} {trainer.secondSurname??''}</Tag>)}
                                </p>
                            </li>
                            <Divider style={{padding: "0", margin: "10px"}}/>
                            <li><TeamOutlined /> <strong>{group.athletes?.length}</strong> alumno(s) asignado(s):
                                <p style={{marginTop: "5px"}}>
                                    {group.athletes.map((athlete : Athlete)=><Tag className="tag-name">{athlete.name} {athlete.surname} {athlete.secondSurname}</Tag>)}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <Divider style={{margin: "5px 0 10px 0"}}/>
                    <div style={{textAlign:"center"}}>
                        {btnActions(1, group, [<Button type='primary' size='small' className="btn-download-attendances" onClick={()=>{downloadAttendancesGroupCallBack(group)}}>
                                                <FileExcelOutlined style={{color: "white"}}/> Asistencias
                                                </Button>], 'column', 'top')}
                    </div>
                </div>
            </Components.Box>
    )
}

export default GroupsComponent;