import React, { useState } from 'react'
import { Button, Col, DatePicker, Dropdown, Input, Menu, Row, Select, Table, Tabs, Tooltip, Typography } from 'antd'
import { GiftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Discount } from '../../../models/Discount';

import * as Components from '../../index'
import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';
import { useEffect } from 'react';
import linq from 'linq';
import ParamsApi from '../../../models/api/ParamsApi';

const { Title } = Typography;

interface DiscountListComponentProps{}

const DiscountListComponent = (props : DiscountListComponentProps) : JSX.Element => {

    const restApiClient : RestApiClient = new RestApiClient();

    const [ showAddDiscount, setShowAddDiscount ] = useState<boolean>(false);
    const [ activeDiscounts, setActiveDiscounts ] = useState<Discount[]>([]);
    const [ archivedDiscounts, setArchivedDiscounts ] = useState<Discount[]>([]);
    const [ loadingData, setLoadingData ] = useState<boolean>(false);

    const getTitle = () : JSX.Element => {
        return loadingData ? <LoadingOutlined /> : <>Código</>
    }

    const discountColumns : any[] = [
        {
            title: getTitle(),
            dataIndex: 'discountCode',
            key: 2,
            render: (index: number, item: Discount) => {
                let classIsActive = item.active ? "active" : "active not";
                let titleToolTip : string = item.active ? "Descuento activo" : "Descuento inactivo";
    
                return <><Tooltip title={titleToolTip}><div className={classIsActive}></div></Tooltip>&nbsp;&nbsp;{item.discountCode}</>
            },
        },
        {
            title: "Descuento aplicado",
            dataIndex: 'discountDescription',
            key: 3,
            render: (index: number, item: Discount) => {
                return `${item.amount}${item.discountType === "fixed" ? "€" : "%"}`;
            },
        },
        {
            title: "Descripción",
            dataIndex: 'discountDescription',
            key: 4
        },
        {
            title: "Acumulativo",
            dataIndex: 'acucumulative',
            key: 5,
            render: (index: number, item: Discount) => {
                return `${item.accumulative ? 'Si' : 'No'}`;
                               
            },
        },
        {
            title: "Producto",
            dataIndex: 'productName',
            key: 6
        },
        {
            title: "Centro",
            dataIndex: 'sportCenterName',
            key: 7
        }  ,
        {
            title: 'Acciones',
            key: 8,
            fixed: 'right',
            width: 120,
            render: (index: number, item: Discount) => {
                const menu = <Menu onClick={()=>{}}>
                                {
                                    item.active ? <Menu.Item key="ban" onClick={()=>{changeDiscountStatus(false, item.discountId)}}>Desactivar</Menu.Item> : <Menu.Item key="ban" onClick={()=>{changeDiscountStatus(true, item.discountId)}}>Activar</Menu.Item>
                                }
                            </Menu>;

                return <Dropdown.Button overlay={menu} size="small"></Dropdown.Button>
            }
        }      
    ];

    const changeDiscountStatus = (status: boolean, discountId: number) : void => {
        
        var params : ParamsApi = {
            body:{
                active: status,
                discountId: discountId
            }
        }

        restApiClient.fetch("ChangeDiscoutState", params).then((r : ApiResponse | null)=>{
            if (r?.code === 200){
                getDiscounts();
            }
        }).finally(()=>{
            setLoadingData(false);
         });
    }

    const getDiscounts = () : void => {
        setLoadingData(true);
        restApiClient.fetch("GetAllDiscounts", {}).then((r : ApiResponse | null)=>{
            if (r?.code === 200 && r.data != null){

                var activeDiscouts = linq.from((r.data as Discount[])).where(d => d.active).toArray();
                var archivedDiscounts = linq.from((r.data as Discount[])).where(d => !d.active).toArray();

                setActiveDiscounts(activeDiscouts);
                setArchivedDiscounts(archivedDiscounts);
            }
        }).finally(()=>{
            setLoadingData(false);
         });
    }

    useEffect(()=>{
        getDiscounts();
    }, [])

    return (
        showAddDiscount ? 
            <Components.AddDiscount onclose={()=>{setShowAddDiscount(false)}} onreload={getDiscounts}/>
        : 
        <Row gutter={16} className="product-list">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <GiftOutlined /> &nbsp;
                    Descuentos
                    <small className="c-sub-title">
                        Tus descuentos
                    </small>
                </Title>
            </Col>
            <Tabs tabPosition='left' style={{width: "100%"}}>
                    <Tabs.TabPane tab={<>Activos <small className='counter-actived'>{activeDiscounts.length}</small></>} key="1">
                        <Col xs={24}>
                            <Button className="add-discount" type="primary" onClick={()=>{setShowAddDiscount(true)}}>Añadir Descuento</Button>
                            <Button className="refresh-products" type="default" onClick={getDiscounts}>Recargar</Button>
                            <Table 
                                rowKey={(record) => record.discountId} 
                                columns={discountColumns} 
                                dataSource={activeDiscounts} 
                                scroll={{ x: 500  }} 
                                size="small"
                            />    
                        </Col>  
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<>Archivo <small className='counter-archived'>{archivedDiscounts.length}</small></>} key="2">
                        <Col xs={24}>
                            <Button className="add-discount" type="primary" onClick={()=>{setShowAddDiscount(true)}}>Añadir Descuento</Button>
                            <Button className="refresh-products" type="default" onClick={getDiscounts}>Recargar</Button>
                            <Table 
                                rowKey={(record) => record.discountId} 
                                columns={discountColumns} 
                                dataSource={archivedDiscounts} 
                                scroll={{ x: 500  }} 
                                size="small"
                            />    
                        </Col>
                    </Tabs.TabPane>
                </Tabs>
            
        </Row>
    )
}

export default DiscountListComponent;