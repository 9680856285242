import Person from './Person'

import moment from "moment"
import Group from './Group'
import Enrollment from './Enrollments'



export default interface Athlete extends Person{
    id? : number, 
    idUser? : number,
    // Él mismo | Padre, Madre o Tutor Legal (FatherMatherOrLegalGuardian)
    relationShip: "myself" | "fmolg" | "couple",
    personalTrainer: Person,
    registerDate: any, // Use moment
    rfeaCode: string,
    groups: Group[]
    enrollments?: Enrollment[]
}

export const EmptyAthlete : Athlete ={
    birthDate: moment(),
    name: 'test',
    relationShip: 'myself',
    secondSurname: '',
    surname: '',
    dniNie: '',
    id: 0,
    registerDate: moment().add(-1, "y"),
    rfeaCode: '',
    groups: [],
    personalTrainer:{
        name: 'Trainer name',
        surname: 'trainer surname',
        secondSurname: 'trainer surname2',
        birthDate: moment(),
        dniNie: '11111111-H'
    },
    enrollments:[]
}
