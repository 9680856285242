import { BankOutlined, CloseCircleOutlined, CreditCardOutlined, LoadingOutlined, PoweroffOutlined, RetweetOutlined, StopOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Alert, Button, Col, List, Result, Row, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Box } from '..';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import Athlete from '../../models/Athletes';
import Enrollment from '../../models/Enrollments';
import Meta from '../../models/Meta';
import PaymentData from '../../models/PaymentData';
import User from '../../models/Users';
import * as Components from '../../components/index'



import './UserResumeComponent.scss'
import DrawerInsideComponent from '../DrawerInside/DrawerInside';
import EnrollmentInfoComponent from './EnrollmentsInfoComponent';
import AthleteInfoComponent from './AtheleteInfoComponent';
import PaymetInfoComponent from './PaymentInfoComponent';
import { BanTheUser, CloseTheUserSession, LoginAsAnUser, UnbanTheUser } from '../../utils/UserUtils';

interface UserResumeComponentProps{
    userId: number
}

const UserResumeComponent = (props : UserResumeComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const { userId } = props;

    const [maxShowPayments, setMaxShowPayments] = useState<number>(5);

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [userData, setUserData] = useState<User>();
    const getUserData = () : void => {
        if (userId > 0){
            const params : ParamsApi = {
                query:{
                  userId: userId
                }
              }
      
              setLoadingData(true);
              restApiClient.fetch("GetUserResumeForAdmin", params)
                .then((r : ApiResponse | null)=> {
                    if (r && r.data && r.code === 200){
                        setUserData(r.data);
                    }
                }).finally(()=>{
                    setLoadingData(false);
                })
        }
    }

    const getCardCaducity = (caducity?: string) : JSX.Element => {
        let result : JSX.Element = <>N/D</>;

        try{
            if (caducity){
                var firstDayOfDate = `01/${caducity}`
                var date = caducity.split("/");
                var month = date[0];
                var year = date[1];
                if (moment().isAfter(firstDayOfDate)){
                    result = <span className="card-expired">Tarjeta caducada.</span>
                }else{
                    result = <>Caduca en <strong>{(moment(month).format("MMMM").toLocaleUpperCase())} de {(moment(year).format("YYYY"))}</strong></>
                }
            }
        }catch(ex){
            console.error("Exception", ex);
        }

        return result;
    }

    const [paymentMethods, setPaymentMethods] = useState<JSX.Element[]>([]);
    useEffect(()=>{
        let result : JSX.Element[] = [];
        const hasCardPaymentMethod = userData?.usersMeta?.find((meta: any)=> meta.key == "stripe_card_data_id");
        const hasSepaPaymentMethod = userData?.usersMeta?.find((meta: any)=> meta.key == "stripe_sepa_data_id");
        const defaultPaymentMethod = userData?.usersMeta?.find((meta: any)=> meta.key == "stripe_payment_method");

        let cardData = undefined;
        let sepaData = undefined;

        if (hasCardPaymentMethod){
            cardData = userData?.usersMeta?.filter((meta: Meta)=> meta.key.indexOf("stripe_card_data") != -1);
            const newElement = <Box type='ultra-small' noBorders> 
                                    <div className="user-card">
                                        <div className="header-card">
                                            <span className="card-info">
                                                <div className="icon">
                                                    <CreditCardOutlined className="card-icon"/> 
                                                </div>
                                                <div className="additional-info">
                                                    <strong>
                                                        {cardData?.find((meta: any)=> meta.key == "stripe_card_data_type")?.value?.toUpperCase()} ****{cardData?.find((meta: any)=> meta.key == "stripe_card_data_last4")?.value}
                                                        {defaultPaymentMethod?.value == "Card" ? <Tag className="default-payment-method" color="blue">Predeterminado</Tag>: ""}
                                                    </strong>  
                                                    <small style={{display:"block"}}>
                                                        {getCardCaducity(cardData?.find((meta: any)=> meta.key == "stripe_card_data_caducity")?.value)}
                                                    </small>
                                                </div>
                                            </span>
                                            <span className="mobile-inline-offline-user">
                                                {cardData?.find((meta: any)=> meta.key == "stripe_payment_method")?.value === "Card" ? "P": ""}
                                            </span>
                                        </div>
                                    </div>
                                </Box>
            result.push(newElement);
        }

        if (hasSepaPaymentMethod){
            sepaData = userData?.usersMeta?.filter((meta: Meta)=> meta.key.indexOf("stripe_sepa_data") != -1);
            const newElement = <Box type='ultra-small' noBorders> 
                                    <div className="user-card">
                                        <div className="header-card">
                                            <span className="bank-info">
                                                <div className="icon">
                                                    <BankOutlined className="bank-icon"/>
                                                </div>
                                                <div className="additional-info">
                                                    <span>
                                                        <strong>
                                                            Adeudo directo SEPA {sepaData?.find((meta: any)=> meta.key == "stripe_sepa_data_last4")?.value}
                                                            {defaultPaymentMethod?.value == "SEPA" ? <Tag className="default-payment-method" color="blue">Predeterminado</Tag>: ""}
                                                        </strong>
                                                    </span>
                                                    <small style={{display:"block"}}>
                                                        Código País: <strong>{sepaData?.find((meta: any)=> meta.key == "stripe_sepa_data_country")?.value}</strong>
                                                        &nbsp;&nbsp;Código Banco: <strong>{sepaData?.find((meta: any)=> meta.key == "stripe_sepa_data_bankcode")?.value}</strong>
                                                    </small>
                                                </div>
                                            </span>
                                            <span className="mobile-inline-offline-user">
                                                {sepaData?.find((meta: any)=> meta.key == "stripe_payment_method")?.value === "SEPA" ? "P" : ""}
                                            </span>
                                        </div>
                                        
                                    </div>
                                </Box>
            result.push(newElement);
        }

        setPaymentMethods(result);
        
    },[userData]);

    


    const [enrollments, setEnrollments] = useState<JSX.Element[]>([]);
    const processEnrollments = () : void => {
        var enrollmentData : Enrollment[] = [];

        userData?.athletes?.map((athlete: Athlete, index: number) => {
            athlete.enrollments?.map((enrollment: Enrollment, idx: number)=>{
                enrollmentData.push(enrollment);
            })
        })

        var enrollmentsSort = enrollmentData.sort((e1, e2)=> e1.id >= e2.id ? -1 : 0);
        var enrollmentsObjects : JSX.Element[]  =[];

        enrollmentsSort.map((enrollment: Enrollment, idx: number)=>{
            enrollmentsObjects.push(<EnrollmentInfoComponent enrollment={enrollment} key={idx} />);
        });

        setEnrollments(enrollmentsObjects);
    }

    const [payments, setPayments] = useState<JSX.Element[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const processPayment = () : void => {
        var totalAmountPayments = 0;
        var paymentsData : PaymentData[] = [];
        userData?.athletes?.map((athlete: Athlete, idx1: number) => {
            athlete.enrollments?.map((enrollment: Enrollment, idx2: number) => {
                enrollment.paymentData?.map((paymentData: PaymentData, idx3: number) => {
                    paymentsData.push(paymentData);
                })
            })
        })
                    
        //Sort payments by date
        var paymentsObjects : JSX.Element[] = [];
        var paymentSorted : PaymentData[] =paymentsData.sort((p1, p2) => p1.id >= p2.id ? -1 : 0)

        paymentSorted.map((pd : PaymentData, idx: number)=> {
            if (pd.status === "payed"){
                totalAmountPayments += (pd.amount / 100);
            }

            paymentsObjects.push(getPaymentInfo(pd, `${idx}`));
        });

        setTotalAmount(totalAmountPayments);
        setPayments(paymentsObjects);
    }

    const [isLoginLikeUser, setIsLoginLikeUser] = useState<boolean>(false);
    const loginLikeUser = () : void => {
        if (userData){
            setIsLoginLikeUser(true);
            LoginAsAnUser(userData.id+'', userData.username+'', () => {
                setIsLoginLikeUser(false);
            });
        }
    }

    const [loadingAllowEnrollmentsWithErrorPayments, setLoadingAllowEnrollmentsWithErrorPayments] = useState<boolean>(false);
    const [allowedEnrollmentsWithErrorPayments, setAllowedEnrollmentsWithErrorPayments] = useState<boolean>(userData?.allowedEnrollmentsWithErrorPayments || false);
    const changeAllowedEnrollmentsWithErrorPayments = () : void => {
        if (userId){
            setLoadingAllowEnrollmentsWithErrorPayments(true);
            const params : ParamsApi = {
              query:{
                userId: userData?.id,
                newStatus: !allowedEnrollmentsWithErrorPayments
              }
            }
      
            restApiClient.fetch("SwitchAllowEnrollmentWithErrorPayments", params)
              .then((r : ApiResponse | null)=> {
                  if (r && r.code === 200){
                    setAllowedEnrollmentsWithErrorPayments(!allowedEnrollmentsWithErrorPayments);
                  }
              }).finally(()=>{
                    setLoadingAllowEnrollmentsWithErrorPayments(false);
              });
          }

    }

    const [isChangingUserStatus, setIsChangingUserStatus] = useState<boolean>(false);
    const banUser = () : void => {
        if (userData){
            setIsChangingUserStatus(true);

            if (userData.banned){
                UnbanTheUser(userData.id!, ()=>{
                    setIsChangingUserStatus(false);
                    initData();
                });
            }else{
                BanTheUser(userData.id!, ()=>{
                    setIsChangingUserStatus(false);
                    initData();
                });
            }

            
        }
    }
    const [isClossingSession, setIsClossingSession] = useState<boolean>(false);
    const closeUserSession = () : void => {
        if (userData){
            setIsClossingSession(true);
            CloseTheUserSession(userData.id!, ()=>{
                setIsClossingSession(false);
                initData();
            });
        }
      }

    const getPaymentInfo = (item : PaymentData, idx: any) : JSX.Element => {
        return <PaymetInfoComponent item={item} key={idx} onChangeData={initData}/>
    }
    
    

      useEffect(()=>{
        processPayment();
        processEnrollments();
      },[userData])

      const [showEditInfo, setShowEditInfo] = useState<boolean>(false);
      const [dataIsChanged, setDataIsChanged] = useState<boolean>(false);

      const initData = () => {
        getUserData();
        setMaxShowPayments(5);
        setDataIsChanged(false);
      }


      useEffect(()=>{
            initData();
        },[, userId])

    return(
        <div className="user-resume">
            
                {
                    // Drawer for edit user profile
                }
                <DrawerInsideComponent 
                    placement='right'
                    mobilefixed={true} 
                    showadd={showEditInfo} 
                    dOnClose={()=>{
                        setShowEditInfo(false)
                        if (dataIsChanged){
                            getUserData();
                            setDataIsChanged(false);
                        }
                    }} 
                    dTitle={`Editando usuario`} 
                    dwidth={"50%"}
                >
                    <Components.UsersInfo setIsDataChanged={()=>{setDataIsChanged(true)}} close={()=>{}} userdata={userData}/>
                </DrawerInsideComponent>
                
            {
                loadingData 
                    ? 
                            <Result
                                title={<><LoadingOutlined /> Por favor, espere. Esta operación puede tomar unos segundos...</>}
                            />
                    : 
                        <Row gutter={16}>
                            <Col xs={0} sm={0} lg={2}></Col>
                            <Col xs={24} sm={24} lg={6}  className="user-data">
                                <Row>
                                    <Col xs={24}>
                                        <span className="user-name">
                                            <Tooltip title={userData?.isLoged ? 'Usuario conectado' : 'Usuario desconectado'}><span className={userData?.isLoged ? 'dot-online' : 'dot-offline'}>•</span></Tooltip>
                                            <span>{userData?.name} {userData?.surname} {userData?.secondSurname}</span>
                                        </span>
                                        <small className="email">{userData?.username}</small>
                                        
                                        <Tooltip placement="top" title={`Refrescar datos`}>
                                            <Button type="primary" size='middle' className="btn-actions-over-user connect-like" onClick={initData}>
                                                <RetweetOutlined />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip placement="top" title={`Iniciar sesión con cuenta de usuario`}>
                                            <Button type="primary" size='middle' className="btn-actions-over-user connect-like" onClick={loginLikeUser}>
                                                
                                                { isLoginLikeUser ? <LoadingOutlined /> : <UserSwitchOutlined />}
                                                
                                            </Button>
                                        </Tooltip>
                                        <Tooltip placement="top" title={`${userData?.banned ? 'Desbloquear' : 'Bloquear'} acceso a cuenta`}>
                                            <Button type="primary" danger={!userData?.banned} size='middle' className="btn-actions-over-user ban" onClick={banUser}>
                                                {isChangingUserStatus ? <LoadingOutlined /> : <StopOutlined className={`${userData?.banned ? 'icon-ban' : 'icon-no-ban'}`}/>}
                                            </Button>
                                        </Tooltip>

                                        <Tooltip placement="top" title={`${allowedEnrollmentsWithErrorPayments ? 'Bloquear' : 'Permitir'} inscripciones con pagos pendientes`}>
                                            <Button type="primary" danger={allowedEnrollmentsWithErrorPayments} size='middle' className="btn-actions-over-user ban" onClick={changeAllowedEnrollmentsWithErrorPayments}>
                                                {loadingAllowEnrollmentsWithErrorPayments ? <LoadingOutlined /> : <CloseCircleOutlined className={`${allowedEnrollmentsWithErrorPayments ? 'icon-ban' : 'icon-no-ban'}`}/>}
                                            </Button>
                                        </Tooltip>

                                        {
                                            userData?.isLoged ? 
                                                <Tooltip placement="top" title={`Cerrar sesión de la cuenta`}>
                                                    <Button type="primary"  size='middle' className="btn-actions-over-user logout" onClick={closeUserSession}>
                                                        {isClossingSession ? <LoadingOutlined /> : <PoweroffOutlined className={`${userData?.isLoged ? 'icon-loged' : 'icon-no-loged'}`}/>}
                                                    </Button>
                                                </Tooltip>
                                            :
                                                <></>
                                        }
                                        
                                    </Col>
                                    <Col xs={24} className="money-resume">
                                        <span className="detail-title" style={{width: "100%", display:"block"}}>Total gastado</span>
                                        <span className="amount">{Math.round(totalAmount * 100) / 100}€</span>
                                        <small className="total-payments" >
                                            {payments.length} pagos
                                        </small>
                                        <small style={{display: "block"}}>*Descontadas las devoluciones</small>
                                    </Col>
                                    <Col xs={24} className="payment-methods-info">
                                        <Typography.Title level={5} className="detail-title">Métodos de pago</Typography.Title>
                                        {
                                            paymentMethods
                                        }
                                        <Alert type='info' message={<><strong>¡Recuerda!</strong> Solo el usuario puede registrar o cambiar sus métodos de pago</>} />
                                    </Col>
                                    <Col xs={24}>
                                        <Typography.Title level={5} className="detail-title">
                                            Detalles
                                            <Button type="primary" size='small' onClick={()=>{setShowEditInfo(true);}} style={{position: "absolute", right: "0"}}>Modificar</Button>
                                        </Typography.Title>
                                        <span className="account-title">Datos de la cuenta</span>
                                        <span className="account-info">{userData?.dninie}</span>
                                        <span className="account-info">{userData?.contactData?.address}</span>
                                        <span className="account-info">{userData?.contactData?.email}</span>
                                        <span className="account-info">{userData?.contactData?.phone}</span>
                                        <span className="account-info">{userData?.contactData?.phone2}</span>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography.Title level={5} className="detail-title" style={{marginBottom: "10px"}}>
                                            Metadatos
                                        </Typography.Title>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={userData?.usersMeta}
                                            renderItem={(meta: any) => <List.Item
                                                                        //actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                                                                    >
                                                                        <List.Item.Meta
                                                                            title={meta.key}
                                                                            description={meta.value}
                                                                        />
                                                                    </List.Item>}
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} lg={14} className="info">
                                
                                <div className="enrollments">
                                    <Typography.Title level={5} className="detail-title margintop10px">Inscripciones ({enrollments.length})</Typography.Title>
                                    {
                                        enrollments
                                    }
                                    {
                                            enrollments.length == 0 ? 
                                                <Result
                                                    status="success"
                                                    icon={<></>}
                                                    title="Aún no hay inscripciones para este usuario."
                                                    subTitle="Cuando el usuario cree alguna inscripción podrás verla aquí."
                                                />
                                            : 
                                                <></>
                                        }
                                </div>
                                <div className="athletes">
                                    <Typography.Title level={5} className="detail-title">Alumnos ({userData?.athletes?.length})</Typography.Title>

                                    {userData?.athletes?.map((athlete: Athlete, index: number) => {
                                        return <AthleteInfoComponent refreshData={initData} item={athlete} key={index}/>;
                                    })}

                                    {
                                            !userData?.athletes || userData?.athletes.length == 0 ? 
                                                <Result
                                                    status="success"
                                                    icon={<></>}
                                                    title="Aún no hay alumnos registrados para este usuario."
                                                    subTitle="Cuando el usuario inscriba algún alumno podrás verlo aquí."
                                                />
                                            : 
                                                <></>
                                        }
                                </div>

                                
                                <div className="payments">
                                    <Typography.Title level={5} className="detail-title">
                                        Pagos ({payments.length})
                                        {
                                            payments.length > maxShowPayments || maxShowPayments == 9999 ?
                                            <Button type="primary" size='small' className="show-all-payments-btn" onClick={()=>{
                                                setMaxShowPayments(maxShowPayments == 9999 ? 5 : 9999);
                                            }}>
                                                {
                                                    maxShowPayments == 9999 ? "Ver menos" : "Ver todos"
                                                }
                                            </Button>
                                            :
                                            <></>
                                        }
                                        
                                    </Typography.Title>
                                        {
                                            payments.map((jsx: JSX.Element, index: number)=>{
                                                if(index < maxShowPayments){
                                                    return jsx;
                                                }
                                            })
                                        }
                                        {
                                            payments.length == 0 ? 
                                                <Result
                                                    status="success"
                                                    icon={<></>}
                                                    title="Aún no hay pagos registrados para este usuario."
                                                    subTitle="Cuando el usuario realice un pago de alguna inscripción podrás verlo aquí."
                                                />
                                            : 
                                                <></>
                                        }
                                </div>
                                
                            </Col>
                            <Col xs={0} sm={0} lg={2}></Col>
                        </Row>
            }
            
        </div>
    )
}







export default UserResumeComponent;