import { CalendarOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Popconfirm } from "antd";
import React, { useRef, useState } from "react";
import { Box, DrawerInside, TablePagination } from "..";
import RestApiClient from "../../api/restApiClient";
import ParamsApi from "../../models/api/ParamsApi";
import CategoryInfo from "../../models/CategoryInfo";
import LoadingOrComponent from "../core/utils/LoadingOrComponent";
import DrawerInsideComponent from "../DrawerInside/DrawerInside";

import './CategoriesComponent.scss'
import CreateCategoryComponent from "./create/CreateCategoryComponent";
import EditCategoryComponent from "./edit/EditCategoryComponent";

interface CategoriesComponentProps{}

const CategoriesComponent = (props: CategoriesComponentProps) : JSX.Element => {
    const [reloadCounter, setReloadCounter] = useState<number>(0);
    const myRef = useRef();
    const restApiClient : RestApiClient = new RestApiClient();


    const getTargetBox = (index: number, item: CategoryInfo) : JSX.Element => {
        let result : JSX.Element = <>No target</>
    
        result = <Box type='ultra-small' key={index}>
                    <div className="enrollments-card">
                      <div className="header-card">
                        <p className="description"><div className={`status-ball ${item.active ? 'active' : 'inactive'}`}></div>{item.description}</p>
                      </div>
                      <Divider className='divider-card'/>
                      <div className="body-card">
                        <div className="avatar">
                          <p>De <strong>{item.ageFrom}</strong> año(s) a <strong>{item.ageTo}</strong> año(s)</p>
                        </div>
                        <div className="info">
                            {getActionButtons(item)}
                        </div>
                      </div>
                  </div>
                </Box>
    
        return result;
      }

    const columns : any = [
        
        {
          title: 'Edad desde',
          dataIndex: 'ageFrom',
          render: (index: number, item: CategoryInfo) => {
            return <>{item.ageFrom} año(s)</>
          },
          renderMobile: (index:number, item: CategoryInfo) => {
            return getTargetBox(index, item);
          }
        },
        {
          title: 'Edad hasta',
          dataIndex: 'ageTo',
          render: (index: number, item: CategoryInfo) => {
            return <>{item.ageTo} año(s)</>
          }
        },
        {
            title: 'Nombre',
            dataIndex: 'description',
            render: (index: number, item: CategoryInfo) => {
              return <>{item.description}</>
            }
        },
        {
            title: 'Estado',
            dataIndex: 'active',
            render: (index: number, item: CategoryInfo) => {
              return <>{item.active ? 'Activo' : 'Desactivado'}</>
            }
        },
        {
            title: "Acciones",
            dataIndex: "actions",
            key: "actions",
            render: (index: number, item: CategoryInfo) => {
                return <div style={{textAlign:"center"}}>
                        {getActionButtons(item)}
                        </div>
            }
        }
      ];


      const getActionButtons = (item: CategoryInfo) => {
        return <div className="btn-actions">
                    <Button type='primary' size='small' style={{marginBottom: "5px", width: "110px", display:"block"}} onClick={()=>{editSelected(item)}}>
                        <EditOutlined /> Editar
                    </Button>
                    <Popconfirm placement="left" title={'¿Está segur@ de que desea eliminar la categoría?'} 
                        onConfirm={()=>{
                            removeSelected(item);
                        }} okText="Yes" cancelText="No">
                        <Button type='primary' danger size='small' style={{marginBottom: "5px", width: "110px", display:"block"}} >
                            <LoadingOrComponent text={<><ClearOutlined /> Eliminar</>} loading={removing} showSpinner loadingText={<>Eliminando</>}/>
                        </Button>
                    </Popconfirm>
                </div>
      }

    const [selectedCategory, setSelectedCategory] = useState<CategoryInfo>();
    const editSelected = (category: CategoryInfo) : void => {
        setSelectedCategory(category);
    }


    const [showAddCategory, setShowAddCategory] = useState<boolean>(false);
    const addNew = () : void => {
        setShowAddCategory(true);
    }

    const [removing, setRemoving] = useState<boolean>(false);
    const removeSelected = (category: CategoryInfo) : void => {
        const params : ParamsApi = {
            query: {
                categoryId: category.id
            }
        }

        setRemoving(true);
        restApiClient.fetch("RemoveCategory",params)
            .then(r => {
                if (r && r.code == 200){
                    setReloadCounter(reloadCounter+1);
                }
            }).finally(()=>{
                setRemoving(false);
            })
    }

    return(
        <div className="categories-component">
            <DrawerInsideComponent 
                mobilefixed={true} 
                innercontainer={'no-inner'}
                showadd={showAddCategory} 
                dOnClose={()=>{
                    setShowAddCategory(false); 
                }} 
                dTitle={`Añadir una nueva categoria`} 
                dwidth={"50%"}
            >
                <CreateCategoryComponent onReload={()=>{
                    setReloadCounter(reloadCounter+1);
                }} onFinish={()=>{
                    setShowAddCategory(false);
                }}/>
            </DrawerInsideComponent>

            <DrawerInsideComponent 
                mobilefixed={true} 
                innercontainer={'no-inner'}
                showadd={selectedCategory != undefined} 
                dOnClose={()=>{
                    setSelectedCategory(undefined); 
                }} 
                dTitle={`Acciones centro CAT-${selectedCategory?.id}`} 
                dwidth={"50%"}
            >
                <EditCategoryComponent onReload={()=>{
                    setReloadCounter(reloadCounter+1);
                }} onFinish={()=>{
                    setSelectedCategory(undefined);
                }} category={selectedCategory!}/>
            </DrawerInsideComponent>

            <TablePagination
                    ref={myRef} 
                    useTableScroll={{y: 400}}
                    entityName="categories"
                    reloadCounter={reloadCounter}
                    aliasEndPoint="GetCategoriesForList"
                    columns={columns}
                    title="Listado de categorias"
                    subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que.... en función de la edad que le pongas a la categoría se le asignará automáticamente a los alumnos durante la inscripción?.</small>}
                    loadingData = {false}
                    actionButtons={[{styles:{}, label:"Crear categoria", action: addNew, icon: <CalendarOutlined /> }]}

                />
        </div>
    )
}

export default CategoriesComponent;