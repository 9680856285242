import { CalendarOutlined, CalendarTwoTone, CarryOutTwoTone, ClockCircleOutlined, ClusterOutlined, InfoCircleOutlined, RobotOutlined, SmileTwoTone, TeamOutlined, WarningTwoTone } from '@ant-design/icons';
import { Avatar, Collapse , Divider, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react'
import Group from '../../models/Group';
import { LoginContext } from '../../hooks/context/userContext'

import * as Components from '../../components'
import Athletes from '../../models/Athletes';
import './TrainersPage.scss'
import User from '../../models/Users';
import Athlete from '../../models/Athletes';
import { getInitialsBox } from '../../utils/GeneralUtils';

const { Panel } = Collapse;

const TrainersPage = () : JSX.Element => {
    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

    const studentsListRef = useRef();
    const enrollmentListRef = useRef();

    const [reloadCounter, setReloadCounter] = useState<number>(0);
    const loginContext = useContext(LoginContext);

    const columns : any = [
        {
            title: 'Nombre',
            dataIndex: 'description',
            key: 'description',
            render: (index: number, item: Group) => {
                return <div className="info-group-name">
                            <label>{item.description}</label>
                            <small>{item.sportCenter.description}</small>
                       </div>
            },
            renderMobile: (index:number, item: Group) => {
                return <GroupMobileItem group={item} editCallback={()=>{}} removeCallBack={()=>{}}/>
            }
        },
        {
            title: 'Días Semana',
            dataIndex: 'weekDays',
            key: 'weekDays'
        },
        {
            title: 'Horario',
            dataIndex: 'timeToStart',
            key: 'timeToStart',
            render: (index: number, item: Group) => {
                return <label><strong>{item.timeToStart}</strong> a <strong>{item.timeToEnd}</strong></label>
            },
        },
        {
            title: 'Inicio actividad',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (index: number, item: Group) => {
                return <label>{`${item.startDate} ${item.timeToStart}`}</label>
            },
        },
        {
            title: 'Fin actividad',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (index: number, item: Group) => {
                return <label>{`${item.endDate} ${item.timeToEnd}`}</label>
            },
        },
        {
            title: 'Nº Alumnos',
            dataIndex: 'athletes',
            key: 'athletes',
            render: (index: number, item: Group) => {
                return item.athletes.length
            }
        },
        {
            title: 'Profesores/Monitores',
            dataIndex: 'teachers',
            key: 'teachers',
            render: (index: number, item: Group) => {
                return item.trainers.map((trainer : User)=><Tooltip title={`${trainer.surname} ${trainer.secondSurname??''}, ${trainer.name}`}><Tag>{trainer.name} <InfoCircleOutlined /></Tag></Tooltip>)

            }
        }
    ];

    const getTargetBox = (index: number, item: Athletes) : JSX.Element => {
        let result : JSX.Element = <>No target</>
    
        result = <Components.Box type='ultra-small'>
                    <div className="enrollments-card">
                      <div className="header-card">
                        {
                          item.personalTrainer ? 
                            <span className="mobile-enrollment-clothes">
                              {item.personalTrainer.name} {item.personalTrainer.surname}
                            </span>
                          : ''
                        }
                        <span className="mobile-enrollment-clothes">
                          {
                            item.groups.length == 0 ? <>&nbsp;</> : ''
                          }
                          {
                            item.groups.map((group: Group)=>{
                              return <Tag>
                                          {group.description}
                                      </Tag>
                            })
                          }
                        </span>
                      </div>
                      <Divider className='divider-card'/>
                      <div className="body-card">
                        <div className="avatar">
                        {
                            getInitialsBox(item.name, item.surname)
                        } 
                        </div>
                        <div className="info">
                          <span className="mobile-enrollment-student-name"><SmileTwoTone /> {item.surname} {item.secondSurname}, {item.name}</span>
                          <span className="mobile-enrollment-date"><CalendarTwoTone /> {moment(item.birthDate).format("DD/MM/YYYY")}</span>
                          { 
                            item.relationShip !== "myself"
                            ?
                              <span className="mobile-enrollment-category"><CarryOutTwoTone /> {item.accountUserName}</span>
                            :
                              ''
                          }
                        </div>
                      </div>
                  </div>
                </Components.Box>
    
        return result;
      }
    
    const alumnsColumns : any[] = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (val: any, item: Athletes) => {
              return <>
                    {
                            getInitialsBox(item.name, item.surname)
                        } 
                    <span>&nbsp; {item.surname.toUpperCase()} {item.secondSurname.toUpperCase()}, {item.name.toUpperCase()}</span>
                   </>
            },
            renderMobile: (index:number, item: Athletes) => {
  
              return getTargetBox(index, item);
            }
  
          },
          {
            title: 'Nacimiento',
            dataIndex: 'birthDate',
            key: 'birthDate',
            render: (index: number, item: Athletes) => moment(item.birthDate).format("DD/MM/YYYY"),
          },
          {
            title: 'Grupos',
            dataIndex: 'groups',
            key: 'groups', 
            render: (index: number, item: Athletes) => {
              if (!item.groups || item.groups.length == 0){
                return <Tooltip title="Sin grupo asignado"><WarningTwoTone twoToneColor="#ffc300" style={{fontSize: "1.5em"}}/></Tooltip>;
              }
  
              return item.groups.map((group : Group)=><Tag>{group.description}</Tag>)
            }
          }
    ];
    return(
        <div className="trainers-page">
                <div id="marker-app"></div>

                <Components.WelcomeTrainers />
                <Components.TablePagination
                        ref={enrollmentListRef} 
                        entityName="groups"
                        reloadCounter={reloadCounter}
                        aliasEndPoint="GetTrainerGroups"
                        columns={columns}
                        expandableInfo={{
                            expandedRowRender: (record : Group) => {
                                return <div className="student-list">
                                    <Typography.Title level={5}>Alumnos</Typography.Title>
                                    {record?.athletes?.map((athlete: Athlete)=>{ return <Tag>{athlete.name} {athlete.surname} {athlete.secondSurname}</Tag>})}
                                </div>
                            }
                        }}
                        title={"Listado de grupos"}
                        loadingData = {loadingGroups}
                        useTableScroll = {{y: 300}}
                        actionButtons={[]}

                    />

                <Components.TablePagination
                        ref={studentsListRef} 
                        useTableScroll={{y: 600}}
                        entityName="students"
                        reloadCounter={reloadCounter}
                        aliasEndPoint="GetAthletesForTrainer"
                        queryParamsMode='body'
                        paramsEndPoint={{
                            sessionId:sessionStorage.getItem("token"),
                            userId: loginContext.id
                        }}
                        columns={alumnsColumns}
                        title="Listado de alumnos"
                        showFilters={true}
                        subtitle={<small style={{display:"block", fontSize:"11px"}}>Selecciona el/los alumnos que formarán parte del grupo.</small>}
                        loadingData = {false}
                        onCheckedItem={(items: any)=>{
                            }}
                    />
        </div>
    )
}

interface GroupMobileProps {
    group: Group,
    editCallback: any,
    removeCallBack: any
}
const GroupMobileItem = (props: GroupMobileProps) : JSX.Element => {

    const {group, editCallback, removeCallBack } = props;

    return (
            <Components.Box type='small' style={{overflow: "hidden"}}>
                <div>
                    <div className="resume-box">
                        <label className="resume-label">
                            <ClusterOutlined /> {group.description.toUpperCase()} 
                            <small style={{display:"block"}}>{group.sportCenter.description.toUpperCase()}</small>
                        </label>
                    </div>
                    <Divider style={{padding: "0", margin: "10px"}}/>
                    <div style={{color: "black"}}>
                        <ul style={{listStyle: "none", padding: "2px"}}>
                            <li><CalendarOutlined /> Días de la semana <strong>{group.weekDays}</strong></li>
                            <li><ClockCircleOutlined /> Clases de <strong>{group.timeToStart}</strong> a <strong>{group.timeToEnd}</strong></li>
                            <li><CalendarOutlined /> Válido de <strong>{group.startDate}</strong> a <strong>{group.endDate}</strong></li>
                            <Divider style={{padding: "0", margin: "10px"}}/>
                            <li><RobotOutlined /> Profesores/Monitores: &nbsp;  
                                <p style={{marginTop: "5px"}}>
                                    {group.trainers.map((trainer : User)=><Tag className="tag-name">{trainer.name} {trainer.surname} {trainer.secondSurname??''}</Tag>)}
                                </p>
                            </li>
                            <Divider style={{padding: "0", margin: "10px"}}/>
                            <li>
                              
                              <Collapse >
                                <Panel header={<><TeamOutlined /> <strong>{group.athletes?.length}</strong> alumno(s) asignado(s):</>} key="1">
                                  <p style={{marginTop: "5px"}}>
                                      {group.athletes.map((athlete : Athlete)=><Tag className="tag-name">{athlete.name} {athlete.surname} {athlete.secondSurname}</Tag>)}
                                  </p>
                                </Panel>
                              </Collapse>
                            </li>
                        </ul>
                    </div>
                </div>
            </Components.Box>
    )
}


export default TrainersPage;