import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import RestApiClient from "../../../api/restApiClient";
import ApiResponse from "../../../models/api/ApiResponse";
import ParamsApi from "../../../models/api/ParamsApi";
import CategoryInfo from "../../../models/CategoryInfo";
import LoadingOrComponent from "../../core/utils/LoadingOrComponent";

import './EditCategoryComponent.scss'


interface EditCategoryComponentProps {
    category: CategoryInfo,
    onFinish : () => void,
    onReload : () => void
}

const EditCategoryComponent = (props: EditCategoryComponentProps) : JSX.Element => {

    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const {category} = props;

    const [savingData, setSavingData] = useState<boolean>(false);
    const onFinish = () : void => {
        const paramsApi : ParamsApi = {
            body: form.getFieldsValue()
        }

        paramsApi.body.id = category.id;

        setSavingData(true);
        restApiClient.fetch("UpdateCategory", paramsApi).then((r : ApiResponse | null)=> {
            if (r !== null && r?.code === 200){
                form.resetFields();
                props.onReload();
                props.onFinish();
            }
        }).finally(()=>{
            setSavingData(false);
        })
    }

    const cancelAdd = () : void => {
        props.onFinish();
    }

    const setForm = () : void => {
        form.setFieldsValue({
            "description":category.description,
            "ageFrom":category.ageFrom,
            "ageTo":category.ageTo,
        }); 
    }

    const activate = () : void => {
        changeCategoryStatus(true);
    }
    
    const desactivate = () : void => {
        changeCategoryStatus(false);
    }

    const changeCategoryStatus = (active : boolean) : void => {
        const paramsApi : ParamsApi = {
            path: {
                categoryId: category.id,
                active: active
            }
        }

        setSavingData(true);
        restApiClient.fetch("ChangeCategoryStatus", paramsApi).then((r : ApiResponse | null)=> {
            if (r !== null && r?.code === 200){
                category.active = active;
                props.onReload();
            }
        }).finally(()=>{
            setSavingData(false);
        })
    }


    useEffect(()=>{
        if (category){
            setForm();
        }
    },[category])

    return(
        <div className="edit-category-component">
            <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
                    label="Nombre categoria"
                    name="description"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item
                    label="Edad desde"
                    help="Indica con decimales el valor de la edad. Ejp: 4.15 equivale a 4 años y 3 meses"
                    name="ageFrom"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input type="number" style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item
                    label="Edad hasta"
                    help="Indica con decimales el valor de la edad. Ejp: 3.75 equivale a 3 años y 9 meses"
                    name="ageTo"
                    className="form-item"
                    rules={[{required: true, message: 'Campo obligatorio'}]}
                >
                    <Input type="number" style={{width: "100%"}}/>
                </Form.Item>
                <div className="btn-actions">
                    <Button disabled={savingData} onClick={setForm}>Restablecer</Button>
                    {
                        category && category.active ? <Button disabled={savingData} danger onClick={desactivate}>Desactivar</Button> : <Button disabled={savingData} onClick={activate}>Activar</Button>
                    }
                    <Button disabled={savingData} type="primary" htmlType="submit">
                        <LoadingOrComponent loading={savingData} showSpinner text={"Guardar"} loadingText={"Guardando..."} />
                    </Button>
                    
                    
                    <Button disabled={savingData} type="ghost" onClick={cancelAdd} danger>Cancelar</Button>
                </div>
            </Form>
        </div>
    )
}

export default EditCategoryComponent;