import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Reports'

export const ReportsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("GetGrafanaReports"),
]


