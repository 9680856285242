import React, { useRef, useState } from "react";


import './RemittancesComponent.scss'
import { TablePagination } from "../..";
import Remittance from "../../../models/Remittance";
import { SnippetsOutlined } from "@ant-design/icons";

interface RemittancesProps{}


const RemittancesComponent = (props: RemittancesProps) : JSX.Element => {
    const remittancesListRef = useRef();
    const [reloadCounter, setReloadCounter] = useState<number>(0);

    const columns : any = [
        {
          title: '#',
          dataIndex: 'id',
          render:(index: number, item: Remittance)=> {
            return item.id
          },
          renderMobile: (index:number, item: Remittance) => {
            return item.id
          }
        }
      ];

    const addNewRemittance = () : void => {}

    return (
        <div className="remittances-list">
            <TablePagination
                    ref={remittancesListRef} 
                    useTableScroll={{y: 300}}
                    entityName="remittances"
                    reloadCounter={reloadCounter}
                    aliasEndPoint="GetRemittances"
                    columns={columns}
                    title="Listado de remesas"
                    subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que.... puedes crear nuevas remesas para diferentes cursos?.</small>}
                    loadingData = {false}
                    actionButtons={[{styles:{}, label:"Añadir nueva remesa", action: addNewRemittance, icon: <SnippetsOutlined /> }]}

                />
        </div>
    )
}

export default RemittancesComponent;