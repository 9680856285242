import { BellOutlined, CalendarOutlined, CheckSquareOutlined, CheckSquareTwoTone, ClockCircleOutlined, FieldTimeOutlined, MailOutlined, MessageOutlined, UnorderedListOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Row, Skeleton, Tag, Tooltip, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../hooks/context/userContext'



import { Box, SetAttendances } from '..';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';


import './WelcomeTrainersComponent.scss'
import moment from 'moment';
import Group from '../../models/Group';


interface TrainerResume{
    trainerId: number,
    name: string,
    email: string,
    contactPhones: string,
    groupId: number,
    groupName: number,
    weekDays: string,
    workMonday: boolean,
    workTuesday: boolean,
    workWednesday: boolean,
    workThursday: boolean,
    workFriday: boolean,
    workSaturday: boolean,
    workSunday: boolean,
    workToday: boolean,
    numberOfStudents: number,
    timeToStart: any,
    timeToEnd: any,
    startDate: any,
    startEnd: any
}

const WelcomeTrainersComponent = () : JSX.Element => {
    let loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();

    const [trainerResume, setTrainerResume] = useState<TrainerResume[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const getDataFromTrainerResume = () : void => {
        setLoadingData(true);
        const params : ParamsApi = {
            query:{
                filter: ''
            }
        }
        restApiClient.fetch("GetDataFromTrainerResume", params)
        .then((r : ApiResponse | null)=> {
            setTrainerResume(r ? r.data : []);
        })
        .finally(()=>{
            setLoadingData(false);
        })
    }

    const getFromDay = (leterDay: string) : JSX.Element[] => {
        const arrayColors = ['magenta','red','volcano','orange','gold','lime','green','cyan','blue', 'geekblue','purple']
        let result : JSX.Element[] = [];

        switch(leterDay){
            case "L":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workMonday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag className="tag-week-class"  color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
            case "M":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workTuesday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag className="tag-week-class"  color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
            case "X":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workWednesday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag className="tag-week-class"  color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
            case "J":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workThursday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag className="tag-week-class"  color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
            case "V":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workFriday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag className="tag-week-class"  color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
            case "S":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workSaturday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag  className="tag-week-class" color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
            case "D":
                result = trainerResume.filter((tr: TrainerResume)=>tr.workSunday).map((tr : TrainerResume, index: number)=> { 
                    const indexNumber = Math.floor(Math.random() * arrayColors.length-1)+1;
                    const indexColor = arrayColors[indexNumber];
                    
                    var timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                    var timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`

                    return <Tag className="tag-week-class"  color={indexColor} key={index} style={{width: "100%"}}>{tr.groupName} <small style={{display:"block"}}>de {timeToStart} a {timeToEnd}</small></Tag>;
                }); 
                break;
        }

        if (result.length == 0){
            result = [<Tag style={{width: "100%"}}>S/C</Tag>]
        }

        return result;
    }

    const [showSetAttendances, setShowSetAttendances] = useState<boolean>(false);
    const [classData, setClassData] = useState<TrainerResume>()
    const [classExtendedInfo, setClassExtendedInfo] = useState<Group>()
    const [loadingClassData, setLoadingClassData] = useState<boolean>(false);
    const getClassData = (groupId: number) : void => {
        setShowSetAttendances(true);

        var params : ParamsApi = {
            query:{
                filter: groupId,
                page: 0,
                N: 1
            }
        }

        setLoadingClassData(true);

        restApiClient.fetch("GetTrainerGroupById", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200 && r.data?.elements[0]){
                    setClassExtendedInfo(r.data.elements[0]);
                }
            }).finally(()=>{
                setLoadingClassData(false);

            })


    }


    const getWelcomeSchedule  = () : JSX.Element => {
        return <div className="my-day-container">
                    <Typography.Title level={4}>
                        Mi día 
                        <small style={{display:"block"}}>{moment().format("DD/MM/YYYY")}</small>
                    </Typography.Title>
                    <Skeleton active loading={loadingData}>
                        <div className="my-days">
                            {
                                trainerResume.filter((tr : TrainerResume) => {
                                    return tr.workToday
                                }).length == 0 ? 
                                <label>No tienes clases hoy</label>
                                :
                                ''
                            }
                            {
                                trainerResume.filter((tr : TrainerResume) => {
                                    return tr.workToday
                                }).sort((trA, trB) => {
                                    return trA.timeToStart.hours > trB.timeToStart.hours ? 1:-1;
                                }).map((tr: TrainerResume, index: number)=>{
                                    const timeToStart = `${tr.timeToStart.hours >= 10 ? tr.timeToStart.hours : `0${tr.timeToStart.hours}`}:${tr.timeToStart.minutes > 10 ? tr.timeToStart.minutes : `0${tr.timeToStart.minutes}`}`
                                    const timeToEnd = `${tr.timeToEnd.hours >= 10 ? tr.timeToEnd.hours : `0${tr.timeToEnd.hours}`}:${tr.timeToEnd.minutes > 10 ? tr.timeToEnd.minutes : `0${tr.timeToEnd.minutes}`}`
                                    const Now2000 = new Date(2000, 0, 1, new Date().getHours(), new Date().getMinutes(), new Date().getSeconds());
                                    const ClassDate = new Date(2000, 0, 1, tr.timeToEnd.hours, tr.timeToEnd.minutes);
                                    let expired = moment(ClassDate).add(30, 'minutes').isBefore(moment(Now2000));
                                    return <div key={index} className="my-day">
                                                <ClockCircleOutlined className="my-day-clock"/>
                                                <strong>{tr.groupName}</strong>
                                                 <small className="my-day-schedule">
                                                    de <strong>{timeToStart}</strong> a <strong>{timeToEnd}</strong> 
                                                    
                                                </small>
                                                <Tooltip title="Se podrá marcar las asistencias hasta 30 minutos después de finalizar la clase">
                                                    <Button className='btn-attendances btn-primary' size='small' type='primary' disabled={expired} onClick={()=>{
                                                        if (!expired){
                                                            setClassData(tr);
                                                            getClassData(tr.groupId)
                                                        }
                                                    }}>
                                                        <UsergroupAddOutlined />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                })
                            }
                        </div>
                    </Skeleton>
                </div>

    }

    const getWelcomeWeek = () : JSX.Element => {
        return  <div className="my-week-table">
                    <Skeleton active loading={loadingData}>
                        <table >
                            <thead className='ant-table-thead' >
                                <tr>
                                    <th className='my-week-header'>Lunes</th>
                                    <th className='my-week-header'>Martes</th>
                                    <th className='my-week-header'>Miércoles</th>
                                    <th className='my-week-header'>Jueves</th>
                                    <th className='my-week-header'>Viernes</th>
                                    <th className='my-week-header'>Sábado</th>
                                    <th className='my-week-header'>Domingo</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('L')}</td>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('M')}</td>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('X')}</td>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('J')}</td>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('V')}</td>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('S')}</td>
                                    <td style={{verticalAlign: "top"}}>{getFromDay('D')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Skeleton>
                </div>
    }

    const getTotalStudents = () : number => {
        let result : number = 0;

        if (trainerResume && trainerResume.length > 0){
            result = trainerResume?.map((tr:TrainerResume) => tr.numberOfStudents+0)?.reduce((a: number, b: number)=> a+b);
        }

        return result;
    }

    const getTotalClasses = () : number => {
        let result : number = 0;

        if (trainerResume && trainerResume.length > 0){
            result = trainerResume?.filter((tr:TrainerResume) => tr.workToday).length;
        }

        return result;
    }

    useEffect(()=>{
        getDataFromTrainerResume();
    },[]);

    return (
        <div className='wellcome-trainer-component'>
            <Drawer footer={
                <div className="btn-attendances-actions">
                    <Button danger onClick={()=>{setShowSetAttendances(false)}}>Finalizar</Button>
                </div>
            } title={`Asistencias: ${classData?.groupName}`} width={"100%"} visible={showSetAttendances} closable onClose={()=>{setShowSetAttendances(false)}}>
                <Skeleton active loading={loadingClassData}>
                    <SetAttendances groupInfo={classExtendedInfo}/>
                </Skeleton>
            </Drawer>
            <Box type="small">
                <Row gutter={16}>
                    <Col xs={24}>
                        <Typography.Title level={4}>
                            Bienvenido {loginContext.name}!
                        </Typography.Title>
                        <Skeleton active loading={loadingData}>
                            <ul className="task-resume">
                                <li><UserOutlined className="icon" /> <strong>{getTotalStudents()}</strong> Alumnos</li>
                                <li><CalendarOutlined className="icon" /> <strong>{getTotalClasses()}</strong> Clases hoy</li>
                            </ul>
                        </Skeleton>
                    </Col>
                    <Col xs={24}style={{marginBottom:"24px"}}>
                            {
                                getWelcomeSchedule()
                            }
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={4}>
                            Mi semana
                        </Typography.Title>
                        {
                            getWelcomeWeek()
                        }
                    </Col>
                </Row>
            </Box>
        </div>
    )
}


export default WelcomeTrainersComponent;