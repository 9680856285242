import { Alert, Button, Checkbox, DatePicker, Form, Input, notification, Switch } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import Season from '../../../models/Season'
import locale from 'antd/es/date-picker/locale/es_ES';


import './EditSeasonComponent.scss'
import LoadingOrComponent from '../../core/utils/LoadingOrComponent';

const { RangePicker } = DatePicker;

interface EditSeasonComponentProps{
    season: Season,
    onReload: () => void,
    onFinish: () => void
}

const EditSeasonComponent = (props: EditSeasonComponentProps) : JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const [wantSetActive, setWantSetActive]  = useState<boolean>(false);
    const [understandRisk, setUnderstandRisk] = useState<boolean>(false);

    const [savingData, setSavingData] = useState<boolean>(false);
    
    const { season } = props;

    useEffect(()=>{
        fillData();
    },[season])

    const fillData = () : void => {
        form.setFieldsValue({
            "description":season.description,
            "duration":[moment(season.openAt), moment(season.closeAt)],
            "isActive":season.isActiveSeason
        }); 

        setWantSetActive(season.isActiveSeason);
        setUnderstandRisk(season.isActiveSeason);
    }

    const onFinish = () : void => {

        if (wantSetActive && !understandRisk){
            notification.warn({description:"Si desea actualizar una temporada y marcarla como activa debe marcar la casilla 'Entiendo la implicación de crear una nueva temporada activa'", message:"Atención"})
        }else if((wantSetActive && understandRisk) || !wantSetActive){
            var params : ParamsApi = {
                body:{
                    description: form.getFieldValue("description"),
                    openAt: moment(form.getFieldValue("duration")[0]).format("YYYY-MM-DD"),
                    closeAt: moment(form.getFieldValue("duration")[1]).format("YYYY-MM-DD"),
                    isActiveSeason: form.getFieldValue("isActive")
                }
            }

            params.body.id = season.id;
    
            setSavingData(true);
            restApiClient.fetch("UpdateSeason", params)
                .then((r)=>{
                    if (r && r.code && r.code == 200){
                        form.resetFields();
                        props.onReload();
                        props.onFinish();
                    }
                })
                .finally(()=>{
                    setSavingData(false);
                })
        }
    }

    const onCancel = () : void => {
        props.onFinish();
    }

    const resetFields = () : void => {
        form.resetFields();
    }

    return(
        <div className="edit-season-component">
            {
                !season ? 'Debe seleccionar primero un curso o temporada para poder editarlo'
                :
                <Form className='create-season-component' form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item
                        label="Nombre del curso o temporada"
                        name="description"
                        className="form-item"
                        rules={[{required: true, message: 'Campo obligatorio'}]}
                    >
                        <Input style={{width: "100%"}} allowClear/>
                    </Form.Item>
                    <Form.Item
                        label="Duración"
                        name="duration"
                        className="form-item"
                        rules={[{required: true, message: 'Campo obligatorio'}]}
                    >
                        <RangePicker style={{width: "100%"}} locale={locale} format="DD/MM/YYYY" />
                    </Form.Item>
                    <Form.Item
                        label="Activa"
                        name="isActive"
                        className="form-item"
                        valuePropName="checked"
                        initialValue={wantSetActive}
                        rules={[{required: true, message: 'Campo obligatorio'}]}
                    >
                        <Switch onChange={setWantSetActive}/>
                    </Form.Item>
                    {
                        wantSetActive ? <Alert description={<Checkbox onChange={(v)=>{
                            setUnderstandRisk(v.target.checked);
                        }} style={{marginTop: "15px", display:"block"}}>Entiendo la implicación de crear una nueva temporada activa.</Checkbox>} message={<div style={{textAlign:"justify", paddingLeft: "10px"}}>
                                                            <strong>AVISO IMPORTANTE:</strong><br/> Recuerda que si marcas una nueva temporada como activa <strong><u>se realizará el cierre automático de la temporada activa actual (en caso de haber una)</u></strong>. Esto implicaría que <strong>nadie podrá realizar nuevas inscripciones sobre la anterior temporada activa</strong> aunque, las inscripciones ya realizadas, <strong><u>se mantendrán activas hasta el final de los pagos o su cancelación manual.</u></strong>
                                                        </div>} 
                                                type='warning' 
                                                showIcon /> 
                                    : 
                                    ''
                    }
                    
                    <div className="btn-actions">
                        <Button disabled={savingData} onClick={resetFields}>Limpiar</Button>
                        <Button disabled={savingData} type="primary" htmlType="submit" className="btn-save-athlete">
                            <LoadingOrComponent loading={savingData} loadingText="Guardando..." showSpinner text={"Guardar"} />
                        </Button>
                        <Button disabled={savingData} type="ghost" danger onClick={onCancel}>Cancelar</Button>
                    </div>
                </Form>
            }
        </div>
    )
}

export default EditSeasonComponent;
