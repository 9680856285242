import React, { useEffect, useRef, useState } from 'react'
import { Menu, Dropdown, Tag, Tooltip, Avatar, Divider, Button } from 'antd'
import Athletes from '../../models/Athletes';
import moment from 'moment';
import * as Components from '../../components'

import {
  CalendarTwoTone,
  CarryOutTwoTone,
  FileExcelOutlined, IdcardTwoTone, LoadingOutlined, SmileTwoTone, UserOutlined, WarningTwoTone,
} from '@ant-design/icons';

import './athletesComponent.scss'
import RestApiClient from '../../api/restApiClient';
import Group from '../../models/Group';
import { Box } from '../../components';
import ParamsApi from '../../models/api/ParamsApi';
import { getInitialsBox } from '../../utils/GeneralUtils';


interface AthletesComponentProps{
  athletes: any[],
  isSearching: boolean,
  showdetail: any,
  useTableScroll: any,
  reloadCounter: number,
  generalFilter: string,
}

const AthletesComponent = (props: AthletesComponentProps) : JSX.Element => {
    const studentsListRef = useRef();
    const restApiClient : RestApiClient = new RestApiClient();

    const [reloadCounter, setReloadCounter] = useState<number>(0);


    const getTargetBox = (index: number, item: Athletes) : JSX.Element => {
      let result : JSX.Element = <>No target</>
  
      result = <Box type='ultra-small'>
                  <div className="enrollments-card">
                    <div className="header-card">
                      {
                        item.personalTrainer ? 
                          <span className="mobile-enrollment-clothes">
                            {item.personalTrainer.name} {item.personalTrainer.surname}
                          </span>
                        : ''
                      }
                      <span className="mobile-enrollment-clothes">
                        {
                          item.groups.length == 0 ? <>&nbsp;</> : ''
                        }
                        {
                          item.groups.map((group: Group)=>{
                            return <Tag>
                                        {group.description}
                                    </Tag>
                          })
                        }
                      </span>
                      {getMenuActions(index, item, 'mobile-menu-actions')}
                    </div>
                    <Divider className='divider-card'/>
                    <div className="body-card">
                      <div className="avatar">
                          {
                            getInitialsBox(item.name, item.surname)
                          }
                      </div>
                      <div className="info">
                        <span className="mobile-enrollment-student-name"><SmileTwoTone /> {item.surname} {item.secondSurname}, {item.name}</span>
                        <span className="mobile-enrollment-date"><CalendarTwoTone /> {moment(item.birthDate).format("DD/MM/YYYY")}</span>
                        { 
                          item.relationShip !== "myself"
                          ?
                            <span className="mobile-enrollment-category"><CarryOutTwoTone /> {item.accountUserName}</span>
                          :
                            ''
                        }
                      </div>
                    </div>
                </div>
              </Box>
  
      return result;
    }

    const columns : any[] = [
        
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name',
          render: (val: any, item: Athletes) => {
            return <>
                  {
                    getInitialsBox(item.name, item.surname)
                  }
                  <span>&nbsp; {item.surname.toUpperCase()} {item.secondSurname.toUpperCase()}, {item.name.toUpperCase()}</span>
                 </>
          },
          renderMobile: (index:number, item: Athletes) => {

            return getTargetBox(index, item);
          }

        },
        {
          title: 'Grupos',
          dataIndex: 'groups',
          key: 'groups', 
          render: (index: number, item: Athletes) => {
            if (!item.groups || item.groups.length == 0){
              return <Tooltip title="Sin grupo asignado"><WarningTwoTone twoToneColor="#ffc300" style={{fontSize: "1.5em"}}/></Tooltip>;
            }

            return item.groups.map((group : Group)=><Tag>{group.description}</Tag>)
          }
        },
        {
          title: 'Acciones',
          key: 'actions',
          fixed: 'right',
          width: 120,
          render: (index: number, item: Athletes) => {
            return getMenuActions(index, item);
          },
        },
    ];

    const getMenuActions = (index: number, item: Athletes, className: string = '') : JSX.Element => {
      const menu = <Menu onClick={()=>{}} >
                      <Menu.Item key={`ban-${index}`} disabled>Asignar profesor personal</Menu.Item>
                    </Menu>;

      return <Dropdown.Button className={className} overlay={menu} onClick={()=>{props.showdetail(item)}} size="small" >Ficha</Dropdown.Button>
    }

    const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
    const downloadStudents = async () => {
      setDownloadingExcel(true);
      let searchText : string = '';
      if (studentsListRef && studentsListRef.current){
        searchText = await (studentsListRef.current as any).getFilter();
      }
  
  
      await restApiClient.exportExcel(`Athletes/GetAthletesExcel?criteria=${searchText}`).then((blob :any) => {
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `alumnos.xlsx`,
          );
  
          // Append to html link element page
          document.body.appendChild(link);
  
          // Start download
          link.click();
  
          link.remove();
      }).finally(()=>{
        setDownloadingExcel(false);
      });
    }

    const [groups, setGroups] = useState<Group[]>([]);
    const [filterButtons, setFilterButtons] = useState<any[]>([]);
    const loadGroups = () : void => {
      var params : ParamsApi = {
          query:{
              filter: "",
              page: "0",
              N: 9999
          }
      }

      restApiClient.fetch("GetGroups", params)
          .then((r: any)=>{
              if (r.data.elements){
                  setGroups(r.data.elements);
              }
          })
          
  }

  useEffect(()=>{
    if (groups){
      setFilterButtons(groups?.map((group : Group)=>{
        return {label: group.description, value: group.description}
      }));
    }
  },[groups])

  useEffect(()=>{
    loadGroups();
  },[])

    useEffect(() => {
      setReloadCounter(props.reloadCounter);
    }, [props.reloadCounter]);

    useEffect(()=>{
      if (studentsListRef && studentsListRef.current){
        (studentsListRef.current as any).updateFilter(props.generalFilter);
      }
    },[props.generalFilter])

    return (
        <div className="athletes">
            
            {
              <Components.TablePagination
                  ref={studentsListRef} 
                  useTableScroll={props.useTableScroll}
                  entityName="students"
                  reloadCounter={reloadCounter}
                  aliasEndPoint="GetAthletesForAdmin"
                  columns={columns}
                  hideTextOfFiltersAndButtonsOnMobile = {true}
                  title="Listado de alumnos"
                  subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que...si le das a 'cargar tablas' desde el listado de usuarios se filtra este listado con sus alumnos?</small>}
                  loadingData = {props.isSearching}
                  filterButtons={filterButtons.length > 0 ? [...filterButtons] : undefined}
                  actionButtons={[{styles:{width: "auto", backgroundColor: "green", borderColor: "green"}, label:"Descargar Excel", action: downloadStudents, icon: downloadingExcel ? <LoadingOutlined style={{color: "white"}}/> : <FileExcelOutlined style={{color: "white"}}/>}]}
              />
            }
        </div>
    )
}

export default AthletesComponent;