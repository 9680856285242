import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Spin } from 'antd';
import React, { useEffect, useState} from 'react'
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import User from '../../models/Users';
import { validateDNINIE } from '../../utils/ValidationsUtils';



interface UserInfoProps{
    userdata? : User,
    close: any,
    setIsDataChanged?:any
}

const UsersInfoComponent = (props : UserInfoProps) => {
    const restApiClient : RestApiClient = new RestApiClient();

    const spinner : JSX.Element = <LoadingOutlined />
    
    const [form] = Form.useForm();
    const [formUpdateUsername] = Form.useForm();
    const [user, setUser] = useState<User>()
    const [isChangingUsername, setIsChangingUsername] = useState<boolean>(false);
    const [isUpdatingCustomerProfile, setIsUpdatingCustomerProfile] = useState<boolean>(false);

    const updateUserName = () : boolean  =>{
        if (!user || user === undefined || user === null) return false;

        let formValues : any = {
            email: formUpdateUsername.getFieldValue("username"),
            userId: user.id
        }

        const paramsApi : ParamsApi = {
            body: formValues
        }

        setIsChangingUsername(true);
        restApiClient.fetch("ChangeCustomerUserName", paramsApi).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                // Limpiamos el formulario
                props.close()
                dataChanged();
            }
        }).finally(()=>{
            setIsChangingUsername(false)
        });

        return true;
    }

    const dataChanged = () : void => {
        if (props.setIsDataChanged){
            props.setIsDataChanged();
        }
    }

    const updateUser = () : boolean => {
        if (!user || user === undefined || user === null) return false;

        let formValues : any = form.getFieldsValue();
        formValues.userId = user.id

        const paramsApi : ParamsApi = {
            body: formValues
        }
        setIsUpdatingCustomerProfile(true);
        restApiClient.fetch("UpdateCustomerProfile", paramsApi).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                // Limpiamos el formulario
                props.close()
                dataChanged();
            }
        }).finally(()=>{
            setIsUpdatingCustomerProfile(false);
        });

        return true
    }

    useEffect(()=>{
        if (user){
            form.setFieldsValue({
                "phone1":user?.contactData?.phone,
                "phone2":user?.contactData?.phone2,
                "address":user?.contactData?.address,
                "name":user?.name,
                "surname":user?.surname,
                "secondSurname":user?.secondSurname,
                "DNI_NIE":user?.dninie
            }); 

            formUpdateUsername.setFieldsValue({
                "username":user?.username
            }); 
        }
    },[user])

    useEffect(()=>{
        setUser(props.userdata)
    },[props.userdata])

    return (
        <>
            <Form form={formUpdateUsername} onFinish={updateUserName}>
                <Form.Item
                    name="username"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'}, { type: 'email', message: 'No es un email válido' }]}
                >
                    <Input type="email" placeholder="Introduce tu Email" />
                </Form.Item>
                <Alert
                    message="Cambiar el email implicará que el usuario deberá iniciar sesión con el nuevo a partir de ahora"
                    type="warning"
                    closable
                />
                <Button type="primary" htmlType="submit" className="btn-register">
                    {
                        isChangingUsername ? spinner : ''
                    }
                    Cambiar nombre de usuario
                </Button>
            </Form>
            <br/>
            <br/>
            <Form form={form} onFinish={updateUser} onChange={()=>{}}
            >
                <Form.Item
                    name="name"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'}]}
                >
                    <Input placeholder="Introduce tu nombre"/>
                </Form.Item>
                <Form.Item
                    name="surname"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'}]}
                >
                    <Input placeholder="Introduce tu Primer apellido"/>
                </Form.Item>
                <Form.Item
                    name="secondSurname"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'}]}
                >
                    <Input placeholder="Introduce tu Segundo apellido"/>
                </Form.Item>
                <Form.Item
                    name="DNI_NIE"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'},({ getFieldValue }) => ({
                        validator(rule, value) {
                           if(!validateDNINIE(value)){
                                return Promise.reject("Documento no válido");
                            }
                           return Promise.resolve();
                        },
                      })]}
                >
                    <Input placeholder="Introduce tu DNI o NIE"/>
                </Form.Item>
                <Form.Item
                    name="phone1"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'}, {max: 9, message: "El teléfono debe contener 9 números"}, {min: 9, message: "El teléfono debe contener 9 números"}]}
                >
                    <Input placeholder="Introduce tu Teléfono principal"/>
                </Form.Item>
                <Form.Item
                    name="phone2"
                    className="form-item"
                    rules={[{max: 9, message: "El teléfono debe contener 9 números"}, {min: 9, message: "El teléfono debe contener 9 números"}]}
                >
                    <Input placeholder="Introduce tu Teléfono secundario"/>
                </Form.Item>
                <Form.Item
                    name="address"
                    className="form-item"
                    rules={[{required: true, message: 'Este campo es obligatorio'}]}
                >
                    <Input placeholder="Introduce tu Dirección completa"/>
                </Form.Item>
                
                <Alert
                    message="La contraseña del usuario no puede modificarse. Si se quiere restablecer la contraseña debe hacerse desde la página de inicio de sesión."
                    type="warning"
                    closable
                />
                <Button type="primary" htmlType="submit" className="btn-register">
                    {
                        isUpdatingCustomerProfile ? spinner : ''
                    }
                    Actualizar datos
                </Button>
            </Form>
        </>
    )
}

export default UsersInfoComponent;