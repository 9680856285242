import React, { useEffect, useState } from 'react'
import { Row, Col, Result, Button } from 'antd'
import { useHistory, useParams } from "react-router-dom"

import {
    FrownTwoTone
  } from '@ant-design/icons';

import { Typography } from 'antd';

import * as Components from '../../../components/index'

import './loading.scss';
import './login.scss';
import './waves.scss';
import ParamsApi from '../../../models/api/ParamsApi';
import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';
import AppCfg from '../../../models/AppCfg';

const { Title } = Typography;


const LoginPage = (props : any) => {

    let { validateAccountHash } = useParams<{validateAccountHash : string}>();
    let { changePasswordHash } = useParams<{changePasswordHash : string}>();

    const restApiClient : RestApiClient = new RestApiClient();
    
    const history = useHistory();

    const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
    const [errorInConfig, setErrorInConfig] = useState<boolean>(false);
    const [messageLoading, setMessageLoading] = useState<any>(<p>Por favor, espere, cargando la aplicación...</p>);
    const [app, setApp] = useState<AppCfg>();

    const goRegister = (event: any) : void => {
        history.push('/register');
    }

    const checkAppConfig = () : boolean => {
        let found : boolean = false;
        const actualConfig : string | null = sessionStorage.getItem("app-config");
        if (actualConfig){
            found = true;
            setApp(JSON.parse(actualConfig) as AppCfg);
            setErrorInConfig(false);
            setLoadingConfig(false);
        }

        return found;
    }

    const GetAppConfig = () : void => {


        setErrorInConfig(false);
        setLoadingConfig(true);
        setMessageLoading(<p>Consultando con tu club...</p>);

        checkAppConfig()
        
        restApiClient.fetch("GetAppConfig", {})
                .then((r : ApiResponse | null)=> {
                    if (r !== null){
                        const code : number = r.code; 

                        switch(code){
                            case 200:
                                setApp(r.data as AppCfg);
                                sessionStorage.setItem("app-config", JSON.stringify(r.data));
                                break;
                            case 403:
                                setErrorInConfig(true);
                                setMessageLoading(appInManteinance);
                                break;
                            default:
                                setErrorInConfig(true);
                                setMessageLoading(appNotAvailable);
                                break;
                        }
                    }
                    
                })
                .catch(reason => {
                    setErrorInConfig(true);
                    setMessageLoading(appFail);
                })
                .finally(()=>{
                    setLoadingConfig(false);
                })
    }

    const appNotAvailable : JSX.Element = <Result
                                                style={{color: "white"}}
                                                status="500"
                                                title={<span className="text-white">No hemos podido obtener la configuración de tu club.</span>}
                                                subTitle={<span className="text-white">Ups... Parece que la aplicación no está disponible en estos momentos. Por favor, inténtalo más tarde y si el problema persiste habla con soporte técnico.</span>}
                                                extra={<Button type="primary" onClick={GetAppConfig}>Vuelve a intentarlo</Button>}
                                            />

    const appInManteinance : JSX.Element = <Result
                                            status="500"
                                            style={{color: "white"}}
                                            title={<span className="text-white">Realizando tareas de mantenimiento</span>}
                                            subTitle={<span  className="text-white">Estamos trabajando en mejorar la aplicación. Vuelve en unos minutos y vuelve a intentarlo.</span>}
                                            extra={<Button type="primary" onClick={GetAppConfig}>Vuelve a intentarlo</Button>}
                                        />

    const appFail : JSX.Element = <Result
                                        icon={<FrownTwoTone twoToneColor="#eb2f96" />}
                                            title={<span className="text-white">Esta página no tiene actualmente servicio. Por favor, ponte en contacto con tu club para saber más.</span>}
                                        extra={<Button type="primary" onClick={GetAppConfig}>Vuelve a intentarlo</Button>}
                                    />

    useEffect(()=>{
        //http://localhost:3000/validate-account/4770b345-a089-4b77-afa5-fd3ccf4212cd
        if (validateAccountHash !== null && validateAccountHash !== undefined){
            const paramsApi : ParamsApi = {
                path: {
                    hash: validateAccountHash
                }
            }
    
            restApiClient.fetch("ValidateAccount", paramsApi)
                .then((r : ApiResponse | null)=> {})
                .finally(()=>{})
                
        }
    }, [validateAccountHash])

    useEffect(()=>{
        //http://localhost:3000/change-my-password/4770b345-a089-4b77-afa5-fd3ccf4212cd
        if (changePasswordHash !== null && changePasswordHash !== undefined){
            const paramsApi : ParamsApi = {
                path: {
                    hash: changePasswordHash
                }
            }
    
            restApiClient.fetch("ChangeMyPasswordStep2", paramsApi)
                .then((r : ApiResponse | null)=> {
                    
                })
                .finally(()=>{})
                
        }
    }, [changePasswordHash])

    useEffect(()=>{
        if (window.location.href.indexOf("error") == -1){
            GetAppConfig();
        }else{
            setErrorInConfig(true);
            setMessageLoading(appInManteinance);
        }
    },[])
    // Diseño: https://miro.medium.com/max/700/0*RSKAVlzjKB2GwPVR.jpg
    return(
        <>
            <Components.Cookies /> 
            {
                loadingConfig || errorInConfig || app === undefined ? 
                    <Row gutter={16} className="loading">
                        <Col xs={24} className="loading-container">
                        { loadingConfig ? <div className="lds-ripple"><div></div><div></div></div> : '' }
                        <Title level={3} className="message">
                            {messageLoading}
                        </Title>
                        </Col>
                            <div className="my-waves">
                                <div className="waves wave-1">
                                    <svg width="100%" height="400px" fill="none" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#93dbe9" />
                                            <stop offset="50%" stop-color="#689cc5" />
                                            <stop offset="100%" stop-color="#5e6fa3" />
                                        </linearGradient>
                                        <path 
                                        fill="url(#grad1)" 
                                        d="
                                        M0 67
                                        C 273,183
                                            822,-40
                                            1920.00,106 
                                        
                                        V 400 
                                        H 0 
                                        V 67
                                        Z">
                                        <animate 
                                        repeatCount="indefinite" 
                                        fill="url(#grad1)" 
                                        attributeName="d" 
                                        dur="15s"
                                        attributeType="XML"
                                        values="
                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,-40
                                            1222,283
                                            1920,136 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 973,260
                                            1722,-53
                                            1920,120 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z
                                            ">
                                        </animate>
                                    </path>
                                    </svg>
                                </div>
                                <div className="waves wave-2">
                                    <svg width="100%" height="400px" fill="none" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#5e6fa3" />
                                            <stop offset="50%" stop-color="#3b4368" />
                                            <stop offset="100%" stop-color="#191d3a" />
                                        </linearGradient>
                                        <path 
                                        fill="url(#grad2)" 
                                        d="
                                        M0 67
                                        C 273,183
                                            822,-40
                                            1920.00,106 
                                        
                                        V 400 
                                        H 0 
                                        V 67
                                        Z">
                                        <animate 
                                        repeatCount="indefinite" 
                                        fill="url(#grad2)" 
                                        attributeName="d" 
                                        dur="4s"
                                        attributeType="XML"
                                        values="
                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,-40
                                            1222,283
                                            1920,136 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 973,260
                                            1722,-53
                                            1920,120 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z
                                            ">
                                        </animate>
                                    </path>
                                    </svg>
                                </div>
                                <div className="waves wave-3">
                                <svg width="100%" height="400px" fill="none" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                    <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stop-color="#191d3a" />
                                        <stop offset="50%" stop-color="#d9dbee" />
                                        <stop offset="100%" stop-color="#b3b7e2" />
                                    </linearGradient>
                                    <path 
                                    fill="url(#grad3)" 
                                    d="
                                    M0 67
                                    c 273,183
                                        822,-40
                                        1920.00,106 
                                    
                                    V 400 
                                    H 0 
                                    V 67
                                    Z">
                                    <animate 
                                    repeatCount="indefinite" 
                                    fill="url(#grad3)" 
                                    attributeName="d" 
                                    dur="3s"
                                    attributeType="XML"
                                    values="
                                        M0 77 
                                        C 473,283
                                        822,-40
                                        1920,116 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 473,-40
                                        1222,283
                                        1920,136 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 973,260
                                        1722,-53
                                        1920,120 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 473,283
                                        822,-40
                                        1920,116 
                                        
                                        V 400
                                        H 0 
                                        V 67 
                                        Z
                                        ">
                                    </animate>
                                </path>
                                </svg>
                            </div>
                            </div>
                    </Row>
                :
                    <Row className="login" justify="center" align="middle" style={{backgroundImage: `url(${app.cfg.urlsMainImg})`}}>
                        <div style={{position: "absolute", top: 0, left: 0, zIndex: 1, display: "none"}}> 
                            <button onClick={()=>{
                                setLoadingConfig(true);
                                setTimeout(()=>{
                                    setLoadingConfig(false);
                                }, 3000);
                            }}>Force Loading</button>
                            <button onClick={()=>{
                                setErrorInConfig(true);
                                setMessageLoading(appInManteinance);
                                    setTimeout(()=>{
                                        GetAppConfig();
                                    }, 10000);
                            }}>Force Maintenance</button>
                            <button onClick={()=>{
                                setErrorInConfig(true);
                                setMessageLoading(appNotAvailable);
                                    setTimeout(()=>{
                                        GetAppConfig();
                                    }, 10000);
                            }}>Force Not Available</button>
                        </div>
                        <Components.Helper location="login" />
                        <Col className="container" xs={24} sm={24} md={15} lg={12} xl={9} xxl={6}>
                            <Row className="container-row">
                                <Col xs={24} className="header" style={{backgroundImage: `url(${app.cfg.urlsSecondaryImg})`}}>
                                    
                                </Col>
                                <Col xs={24} className="form">
                                    <div className="circle-title" style={{backgroundImage: `url(${app.cfg.urlsShieldImg})`}}>
                                        <span>
                                        </span>
                                    </div>
                                    <Components.Login />
                                </Col>
                                <Col xs={24} className="create-account">
                                    <p>
                                        ¿Eres Profesor o monitor del centro y no tienes cuenta? <a onClick={goRegister}><br/>¡Registrate ahora!</a><br/>
                                    </p>
                                    <small>Todos los derechos reservados <a href='https://www.omnicode.es' target="_blank"> Omnicode®</a></small>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
        </>
        
    )
}

export default LoginPage;