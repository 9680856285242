import { CalendarOutlined, ClearOutlined, ClusterOutlined, EditOutlined, EnvironmentOutlined, EyeTwoTone, HeartTwoTone, ScheduleOutlined, StarOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Tooltip } from 'antd';
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { ActionButtons, Box, DrawerInside, TablePagination } from '..';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import Season from '../../models/Season';
import CreateSeasonComponent from './create/CreateSeasonComponent';
import EditSeasonComponent from './edit/EditSeasonComponent';

import './SeasonsComponent.scss'

interface SeasonsComponentProps{}
const SeasonsComponent = (props: SeasonsComponentProps) : JSX.Element => {
    const myRef = useRef();
    const [reloadCounter, setReloadCounter] = useState<number>(0);
    const restApiClient : RestApiClient = new RestApiClient();

    const getMobileSportCenterBox = (index: number, item: Season) : JSX.Element => {
        return <Box type='small' style={{overflow: "hidden"}}>
                    <div>
                        <div className="resume-box">
                            <label className="resume-label">
                                <ClusterOutlined /> {item.description.toUpperCase()} 
                            </label>
                            {
                                item.isActiveSeason ? 
                                        <Tooltip title="Temporada/curso activo">
                                            <HeartTwoTone twoToneColor="#eb2f96" style={{position: "absolute", top: "15px", right: "10px"}}/>
                                        </Tooltip> 
                                    : 
                                        <Tooltip title="Establecer como temporada/curso activo">
                                            <HeartTwoTone style={{cursor: "pointer", position: "absolute", top: "15px", right: "10px"}} onClick={()=>{setActiveSeason(item.id)}}/>
                                        </Tooltip>
                            }
                            
                        </div>
                        <Divider style={{padding: "0", margin: "10px"}}/>
                        <div style={{color: "black"}}>
                            <ul style={{listStyle: "none", padding: "2px"}}>
                                <li><CalendarOutlined /> F. Inicio<strong>: {moment(item.openAt).format("DD/MM/YYYY")}</strong></li>
                                <li><CalendarOutlined /> F. Fin<strong> {moment(item.closeAt).format("DD/MM/YYYY")}</strong></li>
                                <li><ScheduleOutlined /> <strong>{item.activeEnrollments}</strong> inscripciones activas </li>
                            </ul>
                        </div>
                        <Divider style={{margin: "5px 0 10px 0"}}/>
                        {btnActions(index, item, 'column', 'top')}
                    </div>
                </Box>
    }

    const columns : any = [
        {
          title: 'Nombre',
          dataIndex: 'description',
          render: (index: number, item: Season) => {
            return <>{item.description}<small style={{display: "block"}}>Inscripciones activas: {item.activeEnrollments ? item.activeEnrollments : 0}</small></>

          },
          renderMobile: (index:number, item: Season) => {
            return getMobileSportCenterBox(index, item);
          }
        },
        {
            title: 'Fecha inicio',
            dataIndex: 'openAt',
            render: (index: number, item: Season) => moment(item.openAt).format("DD/MM/YYYY")
        },
        {
            title: 'Fecha fin',
            dataIndex: 'closeAt',
            render: (index: number, item: Season) => moment(item.closeAt).format("DD/MM/YYYY")
        },
        /*{
            title: '¿Extensión?',
            dataIndex: 'extend',
            render: (index: number, item: Season) => item.extend ? moment(item.extend).format("DD/MM/YYYY") : "Sin extensión"
        },
        {
            title: '¿Limitación de registros?',
            dataIndex: 'extend',
            render: (index: number, item: Season) => item.categoryQuota && item.categoryQuota.length > 0 ? <>Con restricciones <EyeTwoTone /></> : "Sin restricciones"
        },*/
        {
            title: "Activa",
            render: (index: number, item: Season) => {
                let result : JSX.Element = <></>

                if (item.isActiveSeason){
                    result = <Tooltip title="Temporada/curso activo"><HeartTwoTone twoToneColor="#eb2f96" /></Tooltip>
                }else{
                    result = <Tooltip title="Establecer como temporada/curso activo"><HeartTwoTone style={{cursor: "pointer"}} onClick={()=>{setActiveSeason(item.id)}}/></Tooltip>
                }
                
                return result;
            }
        },
        {
            title: "Acciones",
            render: (index: number, item: Season) => {
                let result : JSX.Element = btnActions(index, item, 'row', 'left')

                return result;
            }
        }
      ];

      const btnActions = (index: number, item: Season, orientation : 'column' | 'row' = 'column', popConfirmPlacement: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom') : JSX.Element => {
        return <ActionButtons 
                        buttonsSize='small'
                        popConfirmPlacement={popConfirmPlacement} 
                        orientation={orientation} 
                        key={index} 
                        itemId={item.id} 
                        showEdit 
                        showRemove 
                        callBackRemove={onRemove} 
                        onClickRemove={removeSeason} 
                        onClickEdit={()=>{setSelectedSeason(item); setShowEditSeason(true)}}
                        />
      }

      const [selectedSeason, setSelectedSeason] = useState<Season>();
      const [showEditSeason, setShowEditSeason] = useState<boolean>(false);


      const setActiveSeason = (seasonId: number) : void => {
        const params : ParamsApi = {
            body: {
                id: seasonId,
                isActiveSeason: true
            }
        }
        
        restApiClient.fetch("ChangeSeasonStatus", params).then((r)=>{setReloadCounter(reloadCounter+1)})
      }

      const [showAddSeason, setShowAddSeason] = useState<boolean>(false);
      const addNew = () : void => {
        setShowAddSeason(true);
      }

      const removeSeason = (seasonId: number) : Promise<ApiResponse> => {
        const params : ParamsApi = {
            query: {
                seasonId: seasonId
            }
        }
        
        return restApiClient.fetch("RemoveSeason", params).then()
      } 

      const onRemove = () : void => {
        setReloadCounter(reloadCounter+1);
      }

    return (
        <div className="seasons-component">
            <DrawerInside 
                mobilefixed={true} 
                showadd={showEditSeason} 
                innercontainer="no-inner"
                dOnClose={()=>{setShowEditSeason(false)}} 
                dTitle={<>Editar curso o temporada {selectedSeason?.description}</>} 
                dwidth={"50%"}>
                    <EditSeasonComponent
                        season={selectedSeason!} 
                        onReload={()=>{
                            setReloadCounter(reloadCounter+1);
                        }} 
                        onFinish={()=>{
                            setShowEditSeason(false);
                        }}
                    />
            </DrawerInside>

            <DrawerInside 
                mobilefixed={true} 
                showadd={showAddSeason} 
                innercontainer="no-inner"
                dOnClose={()=>{setShowAddSeason(false)}} 
                dTitle="Añadir un curso/temporada" 
                dwidth={"50%"}>
                    <CreateSeasonComponent 
                        onReload={()=>{
                            setReloadCounter(reloadCounter+1);
                        }} 
                        onFinish={()=>{
                            setShowAddSeason(false);
                        }}
                    />
            </DrawerInside>
            <TablePagination
                    ref={myRef} 
                    useTableScroll={{y: 300}}
                    entityName="seasons"
                    reloadCounter={reloadCounter}
                    aliasEndPoint="GetSeasonsAdmin"
                    columns={columns}
                    title="Listado de temporadas/cursos"
                    subtitle={<small style={{display:"block", fontSize:"11px"}}>¿Sabías que.... puedes crear cupos para las categorias de una temporada/curso?.</small>}
                    loadingData = {false}
                    hideTextOfFiltersAndButtonsOnMobile
                    actionButtons={[{styles:{}, label:"Crear temporada/curso", action: addNew, icon: <CalendarOutlined /> }]}

                />
        </div>
    )
}



export default SeasonsComponent;