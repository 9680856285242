export const validatePassword = (password : string) : boolean => {
    const lowerCaseCharsReg = /[a-z]/
    const upperCaseCharsReg = /[A-Z]/
    const numbersCharsReg = /[0-9]/

    return password !== undefined 
            && password !== null 
            && lowerCaseCharsReg.test(password) 
            && upperCaseCharsReg.test(password) 
            && numbersCharsReg.test(password) 
            && password.length > 6;
}

export const validateDNINIE = (dniornie: string) : boolean => {
    let numero : any;
    let letr : string;
    let letra : string;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dniornie = dniornie.toUpperCase();

    if(expresion_regular_dni.test(dniornie) === true){
        numero = dniornie.substr(0,dniornie.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = dniornie.substr(dniornie.length-1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != letr) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        }else{
            //alert('Dni correcto');
            return true;
        }
    }else{
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}

/*
    // Validacion de un password en un input form
    ({ getFieldValue }) => ({
        validator(rule, value) {
            if(validatePassword(value)){
                return Promise.reject("Debe contener mayúsculas, minúsculas, números y al menos 6 caracteres");
            }else if (!getFieldValue("password") === value){
                return Promise.reject("La contraseña y su repetición deben ser iguales.");
            }
            return Promise.resolve();
        },
        })


    // Validación de DNI NIE en un input form
    ({ getFieldValue }) => ({
        validator(rule, value) {
            if(!validateDNINIE(value)){
                return Promise.reject("Documento no válido");
            }
            return Promise.resolve();
        },
        })

*/