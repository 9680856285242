import React, {useEffect, useState }  from 'react'

import DrawerInsideComponent from '../../DrawerInside/DrawerInside'

import * as Components from '../../index'


interface AlertsDrawerProps{
    visible: boolean,
    setShowDrawer: any
}


const AlertsDrawerComponent = (props : AlertsDrawerProps): JSX.Element => {

    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    useEffect(()=>{
        setShowDrawer(props.visible);
    }, [props.visible])

    return(
    <DrawerInsideComponent 
        mobilefixed={showDrawer} 
        showadd={showDrawer} 
        dOnClose={()=>{ props.setShowDrawer(false); }} 
        dTitle=""
        dwidth={"35%"}
    >
        alertas del administrador
    </DrawerInsideComponent>)
}

export default AlertsDrawerComponent;