import React from 'react'

import * as Components from '../../components'

import './CustomizationsPage.scss'

const CustomizationsPage = () : JSX.Element => {

    return(
        <div className='customizations-page'>
            <div id="marker-app"></div>
            <Components.Customization />
        </div>
    )
}

export default CustomizationsPage;