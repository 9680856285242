import React from 'react'
import * as Components from '../../components'

import './DiscountsPage.scss';

const DiscountsPage = () : JSX.Element => {

    return (
        <div className='discounts-page'>
            <div id="marker-app"></div>
            <Components.DiscountList />
        </div>
    )
}


export default DiscountsPage;