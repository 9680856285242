import RestApiClient from "../api/restApiClient";
import ApiResponse from "../models/api/ApiResponse";
import ParamsApi from "../models/api/ParamsApi";


export const RetryPayment = async (paymentId: number, callBack?: any) => {
    const restApiClient : RestApiClient = new RestApiClient();
    const params : ParamsApi = {
        query:{
            paymentId: paymentId
        }
    }

    restApiClient.fetch("RetryPayment", params)
    .then((r: ApiResponse | null) => {
        if (callBack){
            callBack();
        }
        return r;
    });
}

export const NotifyErrorInPayment = async (paymentId: number, callBack?: any) => {
    const restApiClient : RestApiClient = new RestApiClient();
    const params : ParamsApi = {
        query:{
            paymentId: paymentId
        }
    }

    restApiClient.fetch("NotifyPaymentError", params)
    .then((r: ApiResponse | null) => {
        if (callBack){
            callBack();
        }
        return r;
    });
}

export const DeletePayment = async (paymentId: number, callBack?: any) => {
    const restApiClient : RestApiClient = new RestApiClient();
    const params : ParamsApi = {
        query:{
            paymentId: paymentId
        }
    }

    restApiClient.fetch("DeletePayment", params)
    .then((r: ApiResponse | null) => {
        if (callBack){
            callBack();
        }
        return r;
    });
}

export const RefundPayments = async (paymentId: number, chargeId: string, callBack?: any) => {
    const restApiClient : RestApiClient = new RestApiClient();
    const params : ParamsApi = {
        body:{
            paymentId: paymentId,
            chargeId: chargeId
        }
    }

    restApiClient.fetch("FullRefundPayment", params)
    .then((r: ApiResponse | null) => {
        if (callBack){
            callBack();
        }
        return r;
    });
}

export const CheckPaymentStatus = async (paymentId: number, callBack?: any) => {
    const restApiClient : RestApiClient = new RestApiClient();
    const params : ParamsApi = {
        query:{
            paymentId: paymentId
        }
    }

    restApiClient.fetch("CheckPaymentStatus", params)
    .then((r: ApiResponse | null) => {
        if (callBack){
            callBack(r);
        }
        return r;
    });
}

export const GetPaymentData = async (paymentId: number, callBack?: any) => {
    const restApiClient : RestApiClient = new RestApiClient();
    const params : ParamsApi = {
        query:{
            paymentId: paymentId
        }
    }

    restApiClient.fetch("GetPaymentData", params)
    .then((r: ApiResponse | null) => {
        if (callBack){
            callBack(r);
        }
        return r;
    });
}