
export default class EndPointConfig {
    public usingActionName: boolean = true;

    constructor(
        public method: string = 'GET' || 'POST' || 'PATCH' || 'DELETE',
        public controller: string = "",
        public actionName: string = "",
        public pattern: string = "",
        public alias: string = "",
        public isFile: boolean = false
    ) {}

    public setActionName = (actionName: string): EndPointConfig => {
        this.usingActionName = true;
        this.actionName = actionName;

        return this;
    };

    public setIsFile = (isFile: boolean): EndPointConfig => {
        this.isFile = isFile;

        return this;
    };

    public setController = (controller: string): EndPointConfig => {
        this.controller = controller;

        return this;
    };

    public setMethod = (method: string = 'GET' || 'POST' || 'PATCH' || 'DELETE'): EndPointConfig => {
        this.method = method;
        return this;
    };

    public setPattern = (pattern: string): EndPointConfig => {
        this.pattern = pattern;
        return this;
    };

    public setAlias = (alias: string): EndPointConfig => {
        this.alias = alias;

        return this;
    };

}