import React, {useEffect, useState }  from 'react'
import * as Components from '../../index'

import DrawerInsideComponent from '../../DrawerInside/DrawerInside'

interface AlertsDrawerProps{
    visible: boolean,
    setShowDrawer: any
}


const ProductsDrawerComponent = (props : AlertsDrawerProps): JSX.Element => {

    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    useEffect(()=>{
        setShowDrawer(props.visible);
    }, [props.visible])

    return(
    <DrawerInsideComponent 
        mobilefixed={showDrawer} 
        showadd={showDrawer} 
        dOnClose={()=>{ props.setShowDrawer(false); }} 
        dTitle="" 
        dwidth={"90%"}
    >
        <Components.ProductAndDiscounts />
    </DrawerInsideComponent>)
}

export default ProductsDrawerComponent;