import React from 'react'
import { Button, Col, Form, Input, Row, Select, DatePicker, InputNumber } from 'antd'

import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';
import ApiResponse from '../../../../models/api/ApiResponse';

import ProductPayPerUseModel from '../../../../models/products/ProductPayPerUseModel'
import { useState } from 'react';
import CategoryInfo from '../../../../models/CategoryInfo';
import { useEffect } from 'react';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import SportCenter from '../../../../models/SportCenter';

import locale from 'antd/es/date-picker/locale/es_ES';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
interface AddPayPerUseProductProps{
    default: any,
    onfinish: any
}

const AddPayPerUseProductForm = (props: AddPayPerUseProductProps) : JSX.Element => {

    const restApiClient : RestApiClient = new RestApiClient();
    const [form] = Form.useForm();
    const [categories, setCategories] = useState<CategoryInfo[]>([])
    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    
    const onFinish = (data: any)=>{
        const payPerUseModel : ProductPayPerUseModel = {
            userPayFees: false,
            extra: {
                features: form.getFieldValue("extra")
            },
            category: form.getFieldValue("category") === 0 ? null : form.getFieldValue("category"),
            fromDate: moment(form.getFieldValue("availability")[0]).format("YYYY-MM-DD"), //Use momentJS
            toDate: moment(form.getFieldValue("availability")[1]).format("YYYY-MM-DD"), //Use momentJS
            maxDate: moment(form.getFieldValue("maxDate")).format("YYYY-MM-DD"), //Use momentJS
            active: form.getFieldValue("active") === 1 ,
            pvp: form.getFieldValue("pvp"), 
            termsAndConditions: form.getFieldValue("termsAndConditions"),
            description: form.getFieldValue("description"),
            name: form.getFieldValue("name"),
            initialPay: form.getFieldValue("initialPay"),
            interval:form.getFieldValue("interval"),
            intervalCount:form.getFieldValue("intervalCount"),
            sportCenter: form.getFieldValue("sportCenter") === 0 ? null : form.getFieldValue("sportCenter"),
            firstPaymentDay: form.getFieldValue("firstPaymentDay")
        }

        const params : ParamsApi = {
            body: payPerUseModel
        }

        setSaving(true);
        restApiClient.fetch("AddPayPerUseProduct", params).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                props.onfinish(true);
            }
        }).finally(()=>{
            setSaving(false);
        })
    }

    const getSportCenters = () : void => {
        restApiClient.fetch("GetSportCenters", {})
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setSportCenters(r.data);
                    }
                })
    }

    const getCategories = () : void => {
        restApiClient.fetch("GetCategories", {}).then((r : ApiResponse | null)=> {
            if (r?.code == 200 && r.data){
                setCategories(r.data);
            }            
        })
    }

    const clearForm = () : void => {
        form.resetFields();
    }

    useEffect(()=>{
        getCategories();
        getSportCenters();
        clearForm();
    }, []);

    return (
        <Row gutter={16}>
        <Col xs={24}>
            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={props.default}>
            <Form.Item
                    label="Nombre"
                    name="name"
                    className="form-item"
                    rules={[{required: true, message: 'Por favor, indique un nombre para el producto'}]}
                >
                    <Input placeholder="Nombre del producto"/>
                </Form.Item>
                <Form.Item
                    label="Disponibilidad del producto"
                    name="availability"
                    className="form-item"
                    rules={[{required: true, message: 'Por favor, indique una fecha'}]}
                >
                    <RangePicker style={{width: "100%"}} locale={locale}/>
                </Form.Item>
                <Form.Item
                    label={<>Fecha Máxima (<small>Fecha a partir de la cuál no pueden seguir pagándose cuotas. Esto afectará a la cancelación automática del producto.</small>)</>}
                    name="maxDate"
                    className="form-item"
                    rules={[{required: true, message: 'Por favor, indique una fecha'}]}
                >
                    <DatePicker style={{width: "100%"}} locale={locale}/>
                </Form.Item>

                <Form.Item
                    label={<>Fecha de primer pago</>}
                    help="La fecha de primer pago afecta a cómo el sistema realiza el primer cobro. Si no se indica fecha de primer pago el cobro se realizará el día 1 del siguiente mes al que se realizó la inscripción. Si se indica una fecha y esta no ha caducado el primer cobro se realizará en esa fecha y, si ha caducado, se realizará inmediatamente. Como los cobros se realizan el día 1 de cada mes al usuario se le cobrará la parte proporcional del mes."
                    name="firstPaymentDay"
                    className="form-item"
                >
                    <DatePicker style={{width: "100%"}} locale={locale}/>
                </Form.Item>
                
                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Categoria"
                    name="category"
                    className="form-item"
                    rules={[{required: true, message: 'Si no desea asociar ninguna categoría seleccione "General"'}]}
                    initialValue={0}
                >
                    <Select placeholder="Seleccione una categoria">
                        <Option value={0}>General</Option>
                        {
                            categories.map(category => {
                                return <Option value={category.id}>{category.description}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Centro"
                        name="sportCenter"
                        className="form-item"
                        rules={[{required: true, message: 'Si no desea asociar ningún centro seleccione "Todos"'}]}
                        initialValue={0}
                    >
                        <Select placeholder="Seleccione un núcleo" defaultValue={0}>
                            <Option value={0}>Todos</Option>
                            {
                                sportCenters.map(sportCenter => {
                                    return <Option value={sportCenter.id}>{sportCenter.description}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Estado del producto"
                    name="active"
                    className="form-item"
                    rules={[{required: true, message: 'Indique si desea que el producto esté activo o no'}]}
                    initialValue={0}
                >
                    <Select placeholder="Seleccione un estado" >
                        <Option value={0}>Inactivo</Option>
                        <Option value={1}>Activo</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label={<>Pago inicial <small>&nbsp;&nbsp;(Si lo tiene, indica el importe que debe pagar el usuario en el momento de la inscripción.)</small></>}
                        name="initialPay"
                        className="form-item"
                        rules={[{type:"number", message:"Debe ser un valor numérico."}]}
                    >
                        <InputNumber
                                style={{width: "100%"}}
                                max={9999}
                                min={0}
                                step={0.01}
                            />
                </Form.Item>
                
                
                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Precio €"
                    name="pvp"
                    className="form-item"
                    rules={[{required: true, message: 'Debe indicar un precio para el producto.'}, {type:"number", message:"Debe ser un valor numérico."}]}
                >
                    <InputNumber
                            style={{width: "100%"}}
                            max={9999}
                            min={0}
                            step={0.01}
                        />
                </Form.Item>

                <Input.Group compact>
                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="Cada..."
                        name="intervalCount"
                        className="form-item"
                        rules={[{required: true, message: 'Debe indicar un número entre 1 y 31'}]}
                        initialValue={1}
                    >
                        <InputNumber
                                max={31}
                                min={1}
                            />
                        
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: "0 !important"}}
                        label="(Indica cada cuánto debe pasarse la cuota)"
                        name="interval"
                        className="form-item"
                        rules={[{required: true, message: 'Debe seleccionar una recurrencia.'}]}
                        initialValue={2}
                    >
                        
                        <Select placeholder="Seleccione una recurrencia">
                                <Option value={2}>Mes/es</Option>
                                <Option value={3}>Año/es</Option>
                            </Select>
                    </Form.Item>
                </Input.Group>

                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Descripción"
                    name="description"
                    className="form-item"
                    rules={[{required: true, message: 'Escriba, brevemente, la descripción del producto.'}]}
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Características extra"
                    name="extra"
                    className="form-item"
                >
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Escribe una característica y pulsa enter" >
                    </Select>

                </Form.Item>

                


                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Términos y condiciones"
                    name="termsAndConditions"
                    className="form-item"
                    rules={[{required: true, message: 'Defina los términos y condiciones asociados al producto.'}]}
                >
                    <TextArea rows={4} />
                </Form.Item>


                
                <Form.Item style={{textAlign:"right"}}>
                    <Button onClick={clearForm}>
                        Limpiar formulario
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button type="primary" htmlType="submit" disabled={saving}>
                        { saving ? <><LoadingOutlined />&nbsp;</> : '' }
                        Crear producto
                    </Button>
                    
                </Form.Item>
            </Form>
        </Col>
    </Row>
    )
}

export default AddPayPerUseProductForm;