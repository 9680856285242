import { GiftOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tabs, Typography } from 'antd';
import moment from 'moment';
import React from 'react'
import AddOnePayProductForm from './addOnePayProductForm';
import AddPayPerUseProductForm from './addPayPerUseProductForm';
import AddSubscriptionProductForm from './addSubscriptionProductForm';

const { TabPane } = Tabs;
const { Title } = Typography;

interface AddProductProps{
    onclose: any,
    onreload: any,
}

// Usamos los default por si tenemos que introducir de pruebas sin tener que rellenar todo de nuevo
const exampleProductOnePayModel = {
    name: "P. ejp. OnePay",
    active: 1,
    category: 0,
    description: "Descripción",
    extra: ["Uniforme gratis", "Seguro COVID"],
    availability:[moment(), moment().add(4, "months")],
    maxDate: moment().add(3, "days"),
    pvp: 310,
    termsAndConditions: "Términos y condiciones",
    userPayFees: false,
    interval: 3,
    intervalCount:1,
}

const exampleProductSubscriptionModel = {
    name: "P. ejp. Subscription",
    active: 1,
    category: 0,
    description: "Descripción",
    extra: ["Uniforme gratis", "Seguro COVID"],
    availability:[moment(), moment().add(4, "months")],
    maxDate: moment(),
    pvp: 310,
    termsAndConditions: "Términos y condiciones",
    userPayFees: false,
    initialPay: 50,
    interval: 2,
    intervalCount:1,
    maxQuotes: 10,
    minQuotes: 2,
    
}

const exampleProductPayPerUseModel = {
    name: "P. ejp. PayPerUse",
    active: 1,
    category: 0,
    description: "Descripción",
    extra: ["Uniforme gratis", "Seguro COVID"],
    availability:[moment(), moment().add(4, "months")],
    maxDate: moment().add(1, "year"),
    pvp: 310,
    termsAndConditions: "Términos y condiciones",
    userPayFees: false,
    initialPay: 50,
    interval: 0,
    intervalCount:1,
}

const AddProductComponent = (props: AddProductProps) : JSX.Element => {

    const close = (reload: boolean = false) : void => {
        props.onclose();
        if (reload){
            props.onreload();
        }
    }

    return (
        <Row gutter={16}>
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <GiftOutlined /> &nbsp;
                    Alta de productos
                    <small className="c-sub-title">
                        Crea tus nuevos productos
                    </small>
                </Title>
            </Col>
            <Col xs={24}>
                <Button type="link" onClick={()=>{close(false)}}><RollbackOutlined /> Volver al listado</Button>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Productos de pago único" key="1">
                        <AddOnePayProductForm onfinish={close} default={{}}/>
                    </TabPane>
                    <TabPane tab="Productos de suscripción" key="2">
                        <AddSubscriptionProductForm onfinish={close} default={{}}/>
                    </TabPane>
                    <TabPane tab="Productos de pago por uso" key="3">
                        <AddPayPerUseProductForm onfinish={close}  default={{}}/>
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    )

}

export default AddProductComponent;