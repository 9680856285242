import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Typography, notification } from 'antd'

import * as Components from '../../components/index'

import './dashboard.scss'
import { LoginContext } from '../../hooks/context/userContext'
import { AppCfgContext } from '../../hooks/context/appConfigContext'
import moment from 'moment'

import RestApiClient from '../../api/restApiClient'
import ApiResponse from '../../models/api/ApiResponse'
import ParamsApi from '../../models/api/ParamsApi'
import Athletes from '../../models/Athletes'

interface DashBoardProps {}

const { Title } = Typography

const DashboardTrainerPage = (props : DashBoardProps) : any => {
    const restApiClient : RestApiClient = new RestApiClient();
    const appConfigContext = useContext(AppCfgContext);
    const loginContext = useContext(LoginContext);

    const [athletes, setAthletes] = useState<Athletes[]>([]);

    const GetTrainerAthletes = () => {
        var params : ParamsApi = {
            body: {
                sessionId: loginContext.sessionId,
                userId: loginContext.id
            }
        }

        restApiClient.fetch("GetAthletesForTrainer", params)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setAthletes(r.data);
                }
            })
    }

    useEffect(()=> {
        switch(loginContext.idRol){
            case "admin":
                //Admin
                notification.error({
                    message: 'Ups!',
                    description: 'No tiene permisos para consultar este panel. Por favor, cierre sesión y logueese correctamente'
                })
                break;
            case "trainer":
                // Trainer
                GetTrainerAthletes()
                break;
        }
    }, []);


    return (
        <Components.Container useMargin>
                <Components.Helper location="dashboard" />
                <Row gutter={10}>
                    <Col xs={24} className="welcome-title hello" style={{backgroundImage: `radial-gradient(circle at -13.87% 32.88%, ${appConfigContext.cfg.colorsMain} 0, ${appConfigContext.cfg.colorsMain} 25%, ${appConfigContext.cfg.colorsMain} 50%, ${appConfigContext.cfg.colorsSecondary} 75%, ${appConfigContext.cfg.colorsSecondary} 100%)`}}>
                        <img className="client-logo" src={`${appConfigContext.cfg.urlsLogoImg}`} />
                        <Title className="title" level={2}>{`Bienvenid@ ${loginContext.name} ${loginContext.surname} ${loginContext.secondSurname}`}</Title>
                        <small className="last-connection">Última conexión: {moment().format("DD/MM/YYYY")}</small>
                    </Col>
                    
                    <Col xs={24}>
                       Próximamente
                    </Col>
                    
                </Row>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </Components.Container>
    )
}

export default DashboardTrainerPage;