import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Groups'

export const GroupsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetGroups"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetTrainerGroups"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetTrainerGroupById"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("SetAttendanceStatus"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateGroup"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("AddGroup"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("DeleteGroup"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("GetAttendancesByDateAndGroup"),
]