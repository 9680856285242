import React, { useContext } from 'react'
import { Row, Col, Divider } from 'antd'
import * as Components from '../../components/index'
import { LoginContext } from '../../hooks/context/userContext'
import { AppCfgContext } from '../../hooks/context/appConfigContext'
import { ADMIN_ROLE } from '../../utils/Constants'


interface PageContainer{
    component: JSX.Element
} 

const ContainerPage = (props : PageContainer) : any => {

    const appConfigContext = useContext(AppCfgContext);
    const loginContext = useContext(LoginContext);

    return(
        <Components.Container useMargin={false}>
                <Row gutter={10} id="page-container-elements">
                    <Col xs={24}>
                        <Row gutter={30}>
                            <Col xs={24}>
                                <Components.Header />
                            </Col>
                            <Col xs={24}>
                                {
                                    loginContext.idRol == ADMIN_ROLE
                                    ?
                                    <Components.Menu />
                                    :
                                    <Components.MenuTrainers />

                                }
                            </Col>
                            <Col xs={24}> 
                                {props.component}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                <Row gutter={16} style={{textAlign:"center", marginBottom:"20px"}}>
                    <Col xs={24} className="create-account">
                        <Divider style={{margin:"15px"}}/>
                        <small>Todos los derechos reservados <a href='https://www.omnicode.es' target="_blank"> Omnicode®</a></small>
                        <small style={{fontSize:"10px", display:"block"}}>Software realizado por <a href='https://www.omnicode.es' target="_blank"> Omnicode®</a></small>
                        <br />
                        <br />
                    </Col>
                </Row>
        </Components.Container> 
    )
}

export default ContainerPage;