import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Statistics'

export const StatisticsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDataFromHeatmapEnrollments"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDataFromStatisticsProductsByMonth"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDataFromVBuyedProducts"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("GetDataFromVDailyEarnings"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDataFromVEconomicEarnByProductType"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDataFromVEnrollmentsVsRegisteredUsers"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDataFromTrainerResume"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("GetDataFromEnrollmentsResume"),    
]


