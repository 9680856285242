import { PieChartOutlined } from '@ant-design/icons';
import { Alert, Col, Collapse, Row, Space } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import Report from '../../models/Report'
import { AppCfgContext } from '../../hooks/context/appConfigContext'


import './GrafanaComponent.scss'

const { Panel } = Collapse;

interface GrafanaComponentProps {}

const GrafanaComponent = (props : GrafanaComponentProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

    
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [reports, setReports] = useState<Report[]>([]);



    const getReports = () => {

        const params : ParamsApi = {
            body:{
                from: moment().add(-1, "month"),
                to: moment()
            }
        }

        restApiClient.fetch("GetGrafanaReports", params)
                .then((r : ApiResponse | null) => {
                    if (r?.code === 200){
                        setReports(r.data);
                    }
                })
    }

    useEffect(()=>{
        getReports();
    }, []);


    // const [src] = useState<string>(`https://clubatletismomalaga:clubatletismopwd@authproxy.sampleDomain/z/aaaaaaaaa/organization_1?orgId=1&from=now-90d&to=now&var-org_user=${type}&var-state=${state}&var-top=5&kiosk=tv`);
    ///Documentación

        // Instalar y securizar grafana: https://blog.hackzenwerk.org/2019/05/13/setup-grafana-on-ubuntu-18-04-with-letsencrypt/
        // Cómo embeber grafana en React https://gist.githubusercontent.com/thenishchalraj/46b943cfd91fa46a818efdcc1b8aed07/raw/eba8ee92818ccdd982fa945bcfd88d76693ff38f/ReactSampleGrafanaComponent.js y
        // https://thenishchalraj.medium.com/dynamically-embed-grafana-dashboards-in-a-react-component-483b9ecd1dcd

        // https://mohitshrestha02.medium.com/grafana-105-sneak-peeking-users-organizations-and-their-permissions-with-an-example-7f22d9af9055


    return (
        <Space direction="vertical" style={{width: "100%", margin:"10px 0"}}>
            <Collapse collapsible="header">
                <Panel header={<><PieChartOutlined /> Información gráfica</>} key="1">
                    <Row gutter={16}>
                        {
                            <Alert style={{width:"100%"}} message={<p>¿Quieres que añadamos a tu panel alguna gráfica? Escríbenos a <a href={`mailto:we.love@omnicode.es?subject=Añadir gráficas a mi panel [${appConfigContext.name}]&body=Hola! soy ${appConfigContext.name} y me gustaría que añadiesen una gráfica que represente la siguiente información:`}>we.love@omnicode.es</a> indicando el nombre de tu club y tu CIF y cuéntanos qué necesitas.</p>} type="info" showIcon />
                        }

                        {
                            reports.map((report: Report, i: number)=> {
                                return <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}><iframe key={report.id} className="grafana" src={report.data} /></Col>
                            }) 
                        }
                    </Row>
                </Panel>
            </Collapse>
        </Space>
        
        
    )
}

export default GrafanaComponent;